import React, { useState } from 'react';
import Icon from '@components/lib-components/icon/Icon';
import Accordion from '@components/lib-components/accordian/Accordian';
import { MsmeContainer } from '../MsmeContainer';
import {
  CompanyEntity,
  EnterpriseType,
  MsmeBusinessInformationData,
  RelatedParty,
} from '../../data/msmeTypes';
import { carterUniqueData, redEximData } from '../../data/msmeData';
import { hasFlag } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { CompanyRelations } from '../overview/components/CompanyRelations';

interface BusinessInfoRowProps {
  label: string;
  children: React.ReactNode;
  showInfoIcon?: boolean;
  className?: string;
}

const BusinessInfoRow: React.FC<BusinessInfoRowProps> = ({ label, children, className = '' }) => (
  <div className={`flex py-4 px-6 ${className}`}>
    <div className='w-48 flex items-center gap-2'>
      <span className='text-sm'>{label}</span>
    </div>
    <div className='flex-1 flex flex-wrap gap-2'>{children}</div>
  </div>
);

const CountryBadge: React.FC<{ name: string }> = ({ name }) => {
  const [displayName, countryCode] = React.useMemo(() => {
    const parts = name.split('-');
    return [parts[0].trim(), parts[parts.length - 1]?.trim()?.toUpperCase() || ''];
  }, [name]);

  const flagEmoji = React.useMemo(() => {
    if (countryCode && hasFlag(countryCode)) {
      return getUnicodeFlagIcon(countryCode);
    }
    return null;
  }, [countryCode]);

  return (
    <span className='inline-flex items-center gap-2 px-3 py-1.5 rounded-lg border border-gray-200 text-sm text-gray-700 bg-white'>
      {flagEmoji ? (
        <span className='text-base leading-none'>{flagEmoji}</span>
      ) : (
        <Icon icon='GlobeAltIcon' size={16} color='gray' />
      )}
      {displayName}
    </span>
  );
};

interface TermsSectionContentProps {
  purchasingTerms: string;
  sellingTerms: string;
}

const TermsSectionContent: React.FC<TermsSectionContentProps> = ({
  purchasingTerms,
  sellingTerms,
}) => (
  <div className='bg-white'>
    <div className='flex items-start py-4 px-6 border-gray-100 bg-gray-50'>
      <div className='w-48'>
        <span className='text-sm'>Purchasing Terms</span>
      </div>
      <div className='flex-1'>
        <div className='text-sm text-gray-700'>{purchasingTerms}</div>
      </div>
    </div>
    <div className='flex items-start py-4 px-6 border-y border-gray-100'>
      <div className='w-48'>
        <span className='text-sm'>Selling Terms</span>
      </div>
      <div className='flex-1'>
        <div className='text-sm text-gray-700'>{sellingTerms}</div>
      </div>
    </div>
  </div>
);

const ListContent: React.FC<{ items: string[] }> = ({ items }) => (
  <div className='px-0 py-0 bg-white'>
    {items.map((item, index) => (
      <div key={index} className={`px-6 py-4 text-sm ${index % 2 === 1 ? 'bg-gray-50' : ''}`}>
        {index + 1}. <span className='font-normal pl-4'>{item}</span>
      </div>
    ))}
  </div>
);

const defaultBusinessData: MsmeBusinessInformationData = {
  importsFrom: [],
  exportsTo: [],
  domesticTerms: {
    purchasing: 'N.A',
    selling: 'N.A',
  },
  internationalTerms: {
    purchasing: 'N.A',
    selling: 'N.A',
  },
  majorCustomers: [],
  majorSuppliers: [],
};

interface MsmeBusinessInformationProps {
  businessType: EnterpriseType;
  competitors?: CompanyEntity[];
  relatedParties?: RelatedParty[];
}

export const MsmeBusinessInformation: React.FC<MsmeBusinessInformationProps> = ({
  businessType,
  competitors = [],
  relatedParties = [],
}) => {
  const [openAccordions, setOpenAccordions] = useState({
    domesticTerms: false,
    internationalTerms: false,
    majorCustomers: false,
    majorSuppliers: false,
  });

  const toggleAccordion = (key: keyof typeof openAccordions) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Get data based on business type
  const currentData =
    businessType === EnterpriseType.Proprietorship ? carterUniqueData : redEximData;

  // Safely access data with fallbacks
  const businessData = {
    ...defaultBusinessData,
    ...currentData,
  };

  return (
    <MsmeContainer>
      <h3 className='text-lg font-medium text-heading mb-6'>Business Information</h3>
      <div className='border border-gray-200 rounded-lg overflow-hidden bg-white'>
        <BusinessInfoRow
          label='Imports From'
          showInfoIcon
          className='bg-white border-b border-gray-100'
        >
          {businessData.importsFrom?.map((country: string, idx: number) => (
            <CountryBadge key={idx} name={country} />
          ))}
          {!businessData.importsFrom?.length && (
            <span className='text-sm'>This company does not import.</span>
          )}
        </BusinessInfoRow>

        <BusinessInfoRow
          label='Exports to'
          showInfoIcon
          className='bg-gray-50 border-b border-gray-100'
        >
          {businessData.exportsTo?.map((country: string, idx: number) => (
            <CountryBadge key={idx} name={country} />
          ))}
          {!businessData.exportsTo?.length && (
            <span className='text-sm'>This company does not export.</span>
          )}
        </BusinessInfoRow>

        <Accordion
          className='rounded-none bg-white !m-0 !border-0 py-0'
          childrenClassName='!p-0'
          title='Domestic Terms'
          count={2}
          isOpen={openAccordions.domesticTerms}
          onToggle={() => toggleAccordion('domesticTerms')}
        >
          <TermsSectionContent
            purchasingTerms={businessData.domesticTerms?.purchasing || 'N.A'}
            sellingTerms={businessData.domesticTerms?.selling || 'N.A'}
          />
        </Accordion>

        <Accordion
          className='rounded-none !bg-gray-50 !m-0 !border-0 py-0'
          childrenClassName='!p-0'
          title='International Terms'
          count={2}
          isOpen={openAccordions.internationalTerms}
          onToggle={() => toggleAccordion('internationalTerms')}
        >
          <TermsSectionContent
            purchasingTerms={businessData.internationalTerms?.purchasing || 'N.A'}
            sellingTerms={businessData.internationalTerms?.selling || 'N.A'}
          />
        </Accordion>

        <Accordion
          className='rounded-none bg-white !m-0 !border-0 py-0'
          childrenClassName='!p-0'
          title='Major Customers'
          count={businessData.majorCustomers?.length || 0}
          isOpen={openAccordions.majorCustomers}
          onToggle={() => toggleAccordion('majorCustomers')}
        >
          <ListContent items={businessData.majorCustomers || []} />
        </Accordion>

        <Accordion
          className='rounded-b-md rounded-t-none bg-gray-50 !m-0 !border-0 py-0'
          childrenClassName='!p-0'
          title='Major Suppliers'
          count={businessData.majorSuppliers?.length || 0}
          isOpen={openAccordions.majorSuppliers}
          onToggle={() => toggleAccordion('majorSuppliers')}
        >
          <ListContent items={businessData.majorSuppliers || []} />
        </Accordion>
      </div>
      <CompanyRelations competitors={competitors} relatedParties={relatedParties} />
    </MsmeContainer>
  );
};
