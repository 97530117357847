export const credhiveReportInsights = [
  {
    insight_type: 'Complete Insight',
    insight_info:
      'Gain holistic view of the company performance and potential using CredHiveAI Generated Financial Analysis.',
  },
  {
    insight_type: 'Affordable Pricing',
    insight_info: 'Pay for it once and we will update you if anything changes!',
  },
  {
    insight_type: 'Convenience',
    insight_info:
      'Have all critical info at your fingertips within Credhive Portal or download report locally, your choice!',
  },
];
