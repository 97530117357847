import { useEffect, useState } from 'react';
import { TableHeaderProps } from './tableHeader.types';
import Icon from '../../icon/Icon';

const TableHeader = ({
  header,
  classname,
  index,
  fixedColumnIndex,
  sortParam,
  setSortParam,
}: TableHeaderProps) => {
  const stickyStyle = 'sticky left-0 z-10 bg-surface-base ';
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [fieldSorted, setFieldSorted] = useState<string>(sortParam ? sortParam.split(' ')[0] : '');
  const [typeSorting, setTypeSorting] = useState<string>(sortParam ? sortParam.split(' ')[1] : '');

  const handleClickSortButton = (headerName?: string) => {
    if (setSortParam) {
      if (headerName !== fieldSorted) {
        setSortParam(`${headerName} desc`);
      }

      if (headerName === fieldSorted && typeSorting === 'desc') {
        setSortParam(`${headerName} asc`);
      }

      if (headerName === fieldSorted && typeSorting === 'asc') {
        setSortParam(`${headerName} desc`);
      }
    }
  };

  const computedIconToRender = (headerName?: string) => {
    if (headerName !== fieldSorted) {
      return 'ArrowSmallUpIcon';
    }

    if (headerName === fieldSorted && typeSorting === 'desc') {
      return 'ArrowSmallUpIcon';
    }

    return 'ArrowSmallDownIcon';
  };

  useEffect(() => {
    if (sortParam) {
      setFieldSorted(sortParam.split(' ')[0]);
      setTypeSorting(sortParam.split(' ')[1]);
    }
  }, [sortParam]);

  if (header?.type === 'sort' && header?.columnName) {
    return (
      <th
        key={`table-${index}`}
        style={{ backgroundColor: isHovered ? 'outline outline-orange-200' : '' }}
        className={`${header.headerClasses || ''} ${classname} text-gray-600 text-sm font-medium font-sans cursor-pointer ${index === fixedColumnIndex ? stickyStyle : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={() => handleClickSortButton(header.columnName)}
      >
        <button type='button' className='flex items-center align-middle w-full h-full'>
          <span>{header.name}</span>
          <div
            className={`ml-1 ${isHovered || fieldSorted === header.columnName ? 'visible' : 'invisible'}`}
          >
            <Icon icon={computedIconToRender(header.columnName)} size={20} variant='mini' />
          </div>
        </button>
      </th>
    );
  }

  return (
    <th
      key={`table-${index}`}
      className={`${header.headerClasses || ''} ${classname} bg-secondary border-b p-4 text-sm text-gray-600 font-sans ${index === fixedColumnIndex ? stickyStyle : ''}`}
    >
      {header.name}
    </th>
  );
};

export default TableHeader;
