import { CorporateType } from '@app/store/slices/companySlice';

export enum UrlKeys {
  login = 'login',
  register = 'register',
  forgotPassword = 'forgotPassword',
  reset = 'reset-password',
  search = 'search',
  settings = 'settings',
  corporateCompanyInfo = 'corporateCompanyInfo',
  nonCorporateCompanyInfo = 'nonCorporateCompanyInfo',
  corporateCompanyInfoRedirect = 'corporateCompanyInfoRedirect',
  nonCorporateCompanyInfoRedirect = 'nonCorporateCompanyInfoRedirect',
  director = 'director',
  paymentResult = 'payment-result',
  paymentPending = 'payment-pending',
  paymentSuccess = 'payment-success',
}

export const UrlMapping = {
  [UrlKeys.login]: '/login',
  [UrlKeys.register]: '/register',
  [UrlKeys.forgotPassword]: '/forgot-password',
  [UrlKeys.reset]: '/reset-password',
  [UrlKeys.search]: '/search',
  [UrlKeys.settings]: '/settings',
  [UrlKeys.corporateCompanyInfo]: `/:activeCountry/:${CorporateType.Corporate}/:id/:tab`,
  [UrlKeys.nonCorporateCompanyInfo]: `/:activeCountry/${CorporateType.NonCorporate}/:id/:tab`,
  [UrlKeys.corporateCompanyInfoRedirect]: `/:activeCountry/:${CorporateType.Corporate}/:id`,
  [UrlKeys.nonCorporateCompanyInfoRedirect]: `/:activeCountry/${CorporateType.NonCorporate}/:id`,
  [UrlKeys.director]: `/:activeCountry/director/:dinId`,
  [UrlKeys.paymentResult]: '/payment-result',
  [UrlKeys.paymentPending]: '/payment-pending',
  [UrlKeys.paymentSuccess]: '/payment-success',
};
