import { useState } from 'react';
import Icon from '@components/lib-components/icon/Icon';
import Button from '@components/lib-components/button/Button';
import { useDispatch } from 'react-redux';
import './CreditModal.css';
import { setIsBlur } from '@app/store/slices/authSlice';
import { useReportIssueMutation } from '@app/store/api/companyApi';
import { CREDIT_SELECTORS } from '@e2e/constants/credit';
import { CREDIT_TEXT } from '@e2e/constants/credit';

interface CreditModalProps {
  setShowBuyCreditsModal: (show: boolean) => void;
  setShowRedirectionModal: (show: boolean) => void;
}

function CreditModal({ setShowBuyCreditsModal }: CreditModalProps) {
  const dispatch = useDispatch();
  const [isIssueReported, setIsIssueReported] = useState(false);
  const [reportIssue, { isLoading }] = useReportIssueMutation();

  const handleReportIssue = async () => {
    if (!isIssueReported) {
      try {
        await reportIssue({
          issue: 'Payment notification',
          description: 'Tokens Requested',
        }).unwrap();
        setIsIssueReported(true);
        setTimeout(() => {
          setShowBuyCreditsModal(false);
          dispatch(setIsBlur(false));
        }, 1000);
      } catch (error) {
        console.error('Failed to report issue:', error);
        // Keep the modal open and button enabled on error
      }
    }
  };

  return (
    <div className='credit-modal-backdrop' data-testid={CREDIT_SELECTORS.MODAL_BACKDROP}>
      <div className='credit-modal-content' data-testid={CREDIT_SELECTORS.MODAL_CONTENT}>
        <img
          className='absolute opacity-5 left-[-10px] top-[-10px]'
          src='/credhive_logo.svg'
          width={100}
          alt='Credhive Logo'
        />
        <div className='flex justify-between items-center'>
          <Icon icon='AppLogoWithoutLabel' className='invisible self-center hover:cursor-pointer' />
          <h1 className='text-slate-600 font-semibold text-2xl text-center'>Add CredHive Tokens</h1>
          <span
            onClick={() => {
              setShowBuyCreditsModal(false);
              dispatch(setIsBlur(false));
            }}
            className='cursor-pointer'
            data-testid={CREDIT_SELECTORS.CLOSE_ICON}
          >
            <Icon icon={'XMarkIcon'} size={25} variant='outline' color='gray' />
          </span>
        </div>
        <p className='text-xs mb-3 text-center'>These will help you unlock company reports</p>
        <p className='text-sm mx-auto text-center mt-5 mb-3'>
          Please contact your Account Manager for more credits
        </p>
        {!isIssueReported ? (
          <Button
            text={isLoading ? CREDIT_TEXT.LOADING_MESSAGE : CREDIT_TEXT.NOTIFY_BUTTON_TEXT}
            onClick={handleReportIssue}
            className='text-md text-white hover:text-white bg-blue-700 w-full rounded-md p-3 disabled:bg-blue-600 disabled:text-white'
            disabled={isLoading}
            data-testid={CREDIT_SELECTORS.NOTIFY_BUTTON}
          />
        ) : (
          <p className='mt-4 text-green-600 text-center'>{CREDIT_TEXT.SUCCESS_MESSAGE}</p>
        )}
      </div>
    </div>
  );
}

export default CreditModal;
