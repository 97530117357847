import Icon from '../../components/lib-components/icon/Icon';
import Login from './Login';
import LoginImage from '../../assets/LoginImage.webp';
import '../../index.css';

function LoginPage() {
  return (
    <div className='flex h-screen w-full justify-between'>
      <div className='w-8/12 relative hidden md:block'>
        <Icon icon='AppLogo' className='absolute top-4 left-4 z-10' />
        <img src={LoginImage} className='fill-available object-cover  h-screen' />
      </div>
      <div className='md:w-5/12 m-auto'>
        <Login />
      </div>
    </div>
  );
}

export default LoginPage;
