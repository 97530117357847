import React, { useMemo, useState } from 'react';
import Table from '@app/components/lib-components/table/table/Table';
import { Metadata, CompanyFinancialData, LLPFinancialData } from '@app/types/financial';
import { DocumentModal } from '@app/components/lib-components/modal/DocumentModal';
import { useToggle } from '@app/hooks/useToggle';
import pdfIcon from '@app/assets/pdfIcon.svg';
import { convertToTitleCase, formatDate } from '@components/utils/commonUtils';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';

export type ExtendedMetadata = Metadata & {
  company_cin: string;
  attachments?: Array<{
    attachment_name: string;
    attachment_folder_category: string;
    pdf_file_name: string;
  }>;
  filing_date_form?: string;
};

interface AuditorTableProps {
  financialData: Array<{
    year: number;
    data: CompanyFinancialData | LLPFinancialData;
    metadata: ExtendedMetadata;
  }>;
  companyType: COMPANY_TYPE;
}

interface AuditorDisplayData {
  auditor_name: string | null;
  auditor_firm_name?: string | null;
  pan?: string | null;
  membership_number?: string | null;
  firm_registration_number?: string | null;
  address?: string | null;
  fiscal_year: string;
  id: number;
  auditor_comments?: string | null;
}

const AuditorTable: React.FC<AuditorTableProps> = ({ financialData, companyType }) => {
  const [isDocModalOpen, toggleModalOpen] = useToggle();
  const [selectedDocuments, setSelectedDocuments] = useState<
    (typeof financialData)[0] | undefined
  >();

  const auditorData = useMemo(() => {
    if (financialData.length > 0) {
      return financialData
        .map((yearData, index) => {
          if (companyType === COMPANY_TYPE.LimitedLiabilityPartnership) {
            const llpData = yearData.data as LLPFinancialData;
            if (!llpData.auditor) return null;
            return {
              auditor_name: llpData.auditor?.auditor_name || '-',
              auditor_firm_name: llpData.auditor?.auditor_firm_name || '-',
              pan: llpData.auditor?.pan || '-',
              membership_number: llpData.auditor?.membership_number || '-',
              firm_registration_number: llpData.auditor?.firm_registration_number || '-',
              address: llpData.auditor?.address || '-',
              fiscal_year: llpData?.auditor?.financial_year || '-',
              id: index,
            } as AuditorDisplayData;
          } else {
            const companyData = yearData.data as CompanyFinancialData;
            return {
              auditor_name: companyData?.auditor?.auditor_name || '-',
              auditor_firm_name: companyData?.auditor?.auditor_firm_name || '-',
              pan: companyData?.auditor?.pan || '-',
              membership_number: companyData?.auditor?.membership_number || '-',
              firm_registration_number: companyData?.auditor?.firm_registration_number || '-',
              address: companyData?.auditor?.address || '-',
              fiscal_year: companyData?.auditor?.financial_year || '-',
              id: index,
            } as AuditorDisplayData;
          }
        })
        .sort((a, b) => {
          const yearA = new Date(a?.fiscal_year || '-').getFullYear();
          const yearB = new Date(b?.fiscal_year || '-').getFullYear();
          return yearB - yearA;
        });
    }
    return null;
  }, [financialData, companyType]);

  const headers = [
    { name: 'Auditor Name', value: 'auditor_name', classes: 'p-4 text-sm !uppercase' },
    {
      name: 'Auditor Firm Name',
      value: 'auditor_firm_name',
      classes: 'p-4 text-sm !uppercase',
    },
    { name: 'PAN', value: 'pan', classes: 'p-4 text-sm' },
    { name: 'Fiscal Year', value: 'fiscal_year', classes: 'p-4 text-sm' },
    { name: 'Membership Number', value: 'membership_number', classes: 'p-4 text-sm' },
    {
      name: 'Firm Registration Number',
      value: 'firm_registration_number',
      classes: 'p-4 text-sm',
    },
    { name: 'Address', value: 'address', classes: 'p-4 text-sm' },
    { name: 'Documents', value: 'doc', classes: 'p-4 text-sm !ml-5' },
  ];

  const rows = useMemo(() => {
    if (!auditorData) return [];

    return auditorData.map((auditorItem) => {
      const baseRow = {
        fiscal_year:
          auditorItem?.fiscal_year && auditorItem?.fiscal_year !== '-'
            ? formatDate(auditorItem?.fiscal_year)
            : '-',
        doc: (
          <img
            className='cursor-pointer w-8 h-8 ml-6'
            src={pdfIcon}
            onClick={() => {
              setSelectedDocuments(financialData[auditorItem?.id || 0]);
              toggleModalOpen.on();
            }}
          />
        ),
      };

      if (companyType === COMPANY_TYPE.LimitedLiabilityPartnership) {
        return {
          ...baseRow,
          auditor_name: convertToTitleCase(auditorItem?.auditor_name || '-'),
          auditor_firm_name: convertToTitleCase(auditorItem?.auditor_firm_name || '-'),
          pan: auditorItem?.pan || '-',
          membership_number: auditorItem?.membership_number || '-',
          firm_registration_number: auditorItem?.firm_registration_number || '-',
          address: auditorItem?.address || '-',
        };
      } else {
        return {
          ...baseRow,
          auditor_name: auditorItem?.auditor_name || '-',
          auditor_firm_name: auditorItem?.auditor_firm_name || '-',
          pan: auditorItem?.pan || '-',
          membership_number: auditorItem?.membership_number || '-',
          firm_registration_number: auditorItem?.firm_registration_number || '-',
          address: auditorItem?.address || '-',
        };
      }
    });
  }, [auditorData, companyType, financialData]);

  return (
    <div className='mt-6'>
      <DocumentModal
        isOpen={isDocModalOpen}
        onClose={toggleModalOpen.off}
        documentData={selectedDocuments || null}
      />
      <h3 className='text-lg text-heading font-semibold mb-0'>Auditor Information</h3>
      <hr className='mb-4' />
      <Table
        tableDivClassname='w-full border border-gray-200 !p-0 rounded-lg'
        headers={headers}
        rows={rows}
      />
    </div>
  );
};

export default AuditorTable;
