import { MsmeContainer } from '../MsmeContainer';
import { GSTDetails } from '@components/company/company-tabs/Compliances/GSTDetails';
import { EPFO } from '@components/company/company-tabs/Compliances/EPFO';
import { useParams } from 'react-router-dom';
import { MSME_EPFO_DATA, MSME_GST_DATA } from '../../data/msmeComplianceData';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';

export const MsmeCompliances = () => {
  const { id = '' } = useParams();
  const companyId = id.toUpperCase();
  // TODO: Remove this once the API is ready
  const gstData = {
    response_data: {
      gst_details: MSME_GST_DATA[companyId] || [],
      status: ReportStatusEnum.COMPLETED,
      report_date: new Date().toISOString(),
      refresh_available: true,
    },
    status: ReportStatusEnum.COMPLETED,
    message: 'Success',
  };

  const epfoData = {
    response_data: {
      epfo: MSME_EPFO_DATA[companyId] || [],
      status: ReportStatusEnum.COMPLETED,
      report_date: new Date().toISOString(),
      refresh_available: true,
    },
    status: ReportStatusEnum.COMPLETED,
    message: 'Success',
  };

  return (
    <MsmeContainer>
      <div className='space-y-6'>
        <GSTDetails data={gstData} isReportFetching={false} />
        {MSME_EPFO_DATA[companyId] && <EPFO data={epfoData} isReportFetching={false} />}
      </div>
    </MsmeContainer>
  );
};
