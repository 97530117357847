import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfoCard from '@app/components/lib-components/info-card/InfoCard';
import Button from '@app/components/lib-components/button/Button';
import Chart from '@app/components/charts/Chart';
import { PieChartOptions } from '@app/components/charts/chart.data';
import Table from '@app/components/lib-components/table/table/Table';
import Icon from '@app/components/lib-components/icon/Icon';
import Modal from '@app/components/lib-components/modal/Modal';
import { useGetCompanyData } from '@app/store/api/companyApi';
import Loading from '@components/lib-components/loading/Loading';
import {
  ShareholdingData,
  ShareholdingTypes,
  ShareholdingsSummaryResponse,
  JointVentureCompany,
  ShareholdingStructureResponse,
} from './ShareholdingProps.types';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { Header, Row } from '@components/lib-components/table/table/table.types';
import { DocumentIconButton } from '@components/lib-components/DocumentIconButton';
import ExpandableTable from '../../../lib-components/table/table/ExpandableTable';
import { ApiResponse } from '@app/types';
import EntitySection from './EntitySection';
import { formatCurrencyNumber, formatDate } from '@components/utils/commonUtils';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';
import { useLocalNavigation } from '@app/hooks/useLocalNavigation';

interface TableData {
  headers: Header[];
  rows: Row[];
}

const directorTableHeaders = [
  { name: 'DIN / PAN', value: 'din_pan', classes: 'p-4 text-sm' },
  { name: 'Name', value: 'full_name', classes: 'p-4 text-sm' },
  { name: 'Designation', value: 'designation', classes: 'p-4 text-sm' },
  { name: 'Number of Shares', value: 'no_of_shares', classes: 'p-4 xl:pl-10 text-sm' },
  {
    name: '% Shareholding',
    value: 'percentage_shareholding',
    classes: 'p-4 xl:pl-10 text-sm',
  },
];

const individualTableHeaders = [
  { name: 'Name', value: 'full_name', classes: 'p-4 text-sm' },
  { name: 'PAN', value: 'pan', classes: 'p-4 text-sm' },
  { name: 'Number of Shares', value: 'no_of_shares', classes: 'p-4 xl:pl-10 text-sm' },
  {
    name: '% Shareholding',
    value: 'percentage_shareholding',
    classes: 'p-4 xl:pl-10 text-sm',
  },
  { name: 'Notes', value: 'notes', classes: 'p-4 text-sm' },
];

const companyTableHeaders = [
  { name: 'Company Name', value: 'full_name', classes: 'p-4 text-sm' },
  { name: 'CIN', value: 'cin', classes: 'p-4 text-sm' },
  { name: 'Number of Shares', value: 'no_of_shares', classes: 'p-4 xl:pl-10 text-sm' },
  {
    name: '% Shareholding',
    value: 'percentage_shareholding',
    classes: 'p-4 xl:pl-10 text-sm',
  },
  { name: 'Notes', value: 'notes', classes: 'p-4 text-sm' },
];

const otherTableHeaders = [
  { name: 'Name', value: 'full_name', classes: 'p-4 text-sm' },
  { name: 'Country', value: 'country', classes: 'p-4 text-sm' },
  { name: 'Number of Shares', value: 'no_of_shares', classes: 'p-4 xl:pl-10 text-sm' },
  {
    name: '% Shareholding',
    value: 'percentage_shareholding',
    classes: 'p-4 xl:pl-10 text-sm',
  },
  { name: 'Notes', value: 'notes', classes: 'p-4 text-sm' },
];

const ShareHoldingPattern = () => {
  const [patternSummaryChartOptions, setPatternSummaryChartOptions] = useState<
    Record<string, string> | undefined
  >(undefined);
  const [promotersChartOptions, setPromotersChartOptions] = useState<
    Record<string, string> | undefined
  >(undefined);
  const [showModal, setShowModal] = useState<string | undefined>(undefined);
  const [holdingView, setHoldingView] = useState<string>('table');
  const [directorTableData, setDirectorTableData] = useState<TableData>({
    headers: [],
    rows: [],
  });
  const [individualTableData, setIndividualTableData] = useState<TableData>({
    headers: [],
    rows: [],
  });
  const [companyTableData, setCompanyTableData] = useState<TableData>({ headers: [], rows: [] });
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const [otherTableData, setOtherTableData] = useState<TableData>({ headers: [], rows: [] });
  const [promoterPercentage, setPromoterPercentage] = useState<number>(0);
  const [publicPercentage, setPublicPercentage] = useState<number>(0);
  const { id } = useParams<string>();
  const navigate = useLocalNavigation();
  const getShareholdingData = useGetCompanyData<ApiResponse<ShareholdingData>>();
  const { data: shareholdingData, isLoading: isLoadingShareholding } = getShareholdingData({
    cinId: id as string,
    field_name: 'shareholdings_summary',
    identifierType: identifierType,
  });

  const renderJointVenturesTable = (data: JointVentureCompany[]) => (
    <Table
      tableDivClassname='w-full border border-gray-200 !p-0 rounded'
      headers={[
        { name: 'CIN / FCRN', value: 'cin_or_fcrn', classes: 'p-4 text-sm' },
        { name: 'Legal Name', value: 'legal_name', classes: 'p-4 text-sm' },
        { name: 'Share Holding %', value: 'share_holding_percentage', classes: 'p-4 text-sm' },
      ]}
      rows={data.map((item) => ({
        cin_or_fcrn: item.cin_or_fcrn ? (
          <Button
            className='bg-transparent !text-[#5A628B] text-xs font-semibold !py-1 !px-2 rounded-md w-auto border-2 border-gray-200 cursor-pointer'
            onClick={() => onCinRedirection(item.cin_or_fcrn)}
            text={item.cin_or_fcrn}
          />
        ) : (
          '-'
        ),
        legal_name: item.jv_holding_subsidiary_company_name,
        share_holding_percentage: `${item.percentage_held.toFixed(2)}%`,
      }))}
    />
  );

  const isInProgress = (status?: ReportStatusEnum) =>
    status === ReportStatusEnum.INITIALISED || status === ReportStatusEnum.IN_PROGRESS;

  const isReportFetching = isInProgress(shareholdingData?.response_data?.status);

  const onCinRedirection = (cin: string) => {
    navigate(cin as string, 'overview', { state: { cid: cin }, replace: true });
  };

  const sortByNumberOfShares = (rows: Row[]) => {
    return [...rows].sort((a, b) => {
      const sharesA = parseInt(a.no_of_shares as string, 10) || 0;
      const sharesB = parseInt(b.no_of_shares as string, 10) || 0;
      return sharesB - sharesA;
    });
  };

  useEffect(() => {
    if (
      !isReportFetching &&
      shareholdingData?.response_data?.shareholdings_summary?.pattern_summary
    ) {
      const {
        pattern_summary,
        director_shareholdings,
        individual_shareholdings,
        company_shareholdings,
        other_shareholdings,
      } = shareholdingData.response_data.shareholdings_summary;

      if (pattern_summary) {
        const promoterPercentage =
          pattern_summary?.data?.COMPUTED_percentage_promoter_equity_shares ?? 0;
        const publicPercentage =
          pattern_summary?.data?.COMPUTED_percentage_public_equity_shares ?? 0;
        setPromoterPercentage(Number(promoterPercentage));
        setPublicPercentage(Number(publicPercentage));

        const newChartOptions = {
          ...PieChartOptions,
          chart: {
            ...PieChartOptions.chart,
            height: 250,
          },
        };

        const patternChart = {
          ...newChartOptions,
          series: [
            {
              name: 'Holding %',
              visible: true,
              data: [
                {
                  name: 'Promoters',
                  y: parseFloat(promoterPercentage.toFixed(2)),
                  color: '#35B26A',
                },
                { name: 'Public', y: parseFloat(publicPercentage.toFixed(2)), color: '#2E63EE' },
              ],
            },
          ],
        };

        setPatternSummaryChartOptions(patternChart as unknown as Record<string, string>);

        if (director_shareholdings?.data) {
          const directorRows: Row[] = director_shareholdings.data
            .map((item) => ({
              full_name: item.full_name ?? '-',
              no_of_shares:
                item.num_shares !== null && item.num_shares !== undefined
                  ? formatCurrencyNumber(item.num_shares)?.toString() || '0'
                  : '0',
              percentage_shareholding:
                item.percentage_held != null ? `${item.percentage_held.toFixed(2)}%` : '0%',
              din_pan: item.din_pan ?? '-',
              designation: item.designation ?? '-',
              _original_no_of_shares: item.num_shares
                ? parseInt(item.num_shares.toString(), 10)
                : 0,
            }))
            .sort((a, b) => (b._original_no_of_shares ?? 0) - (a._original_no_of_shares ?? 0));

          setDirectorTableData({
            headers: directorTableHeaders,
            rows: directorRows,
          });

          const promotersChart = {
            ...newChartOptions,
            series: [
              {
                name: 'Promoters',
                visible: true,
                data: director_shareholdings.data.map((item) => ({
                  name: item.full_name ?? '-',
                  y: parseFloat(item.percentage_held?.toFixed(2) ?? '0'),
                })),
              },
            ],
          };

          setPromotersChartOptions(promotersChart as unknown as Record<string, string>);
        }

        if (individual_shareholdings?.data) {
          const individualRows: Row[] = individual_shareholdings.data.map((item) => ({
            full_name: item.full_name ?? '-',
            no_of_shares: item.num_shares ? formatCurrencyNumber(item.num_shares)?.toString() : '-',
            percentage_shareholding:
              item.percentage_held != null ? `${item.percentage_held.toFixed(2)}%` : '-',
            pan: item.pan ?? '-',
            notes: item.notes ? item.notes : '-',
          }));
          setIndividualTableData({
            headers: individualTableHeaders,
            rows: sortByNumberOfShares(individualRows),
          });
        }

        if (company_shareholdings?.data) {
          const companyRows: Row[] = company_shareholdings.data.map((item) => ({
            full_name: item.full_name ?? '-',
            cin: (
              <Button
                className='bg-transparent !text-[#5A628B] text-xs font-semibold !py-1 !px-2 rounded-md w-auto border-2 border-gray-200 cursor-pointer'
                onClick={() => onCinRedirection(item.cin ?? 'NA')}
                text={item.cin ?? 'NA'}
              />
            ),
            no_of_shares: item.num_shares?.toString() ?? '-',
            percentage_shareholding:
              item.percentage_held != null ? `${item.percentage_held.toFixed(2)}%` : '-',
            notes: item.notes ? item.notes : '-',
          }));
          setCompanyTableData({
            headers: companyTableHeaders,
            rows: sortByNumberOfShares(companyRows),
          });
        }

        if (other_shareholdings?.data) {
          const otherRows: Row[] = other_shareholdings.data.map((item) => ({
            full_name: item.full_name ?? '-',
            no_of_shares: item.num_shares?.toString() ?? '-',
            percentage_shareholding:
              item.percentage_held != null ? `${item.percentage_held.toFixed(2)}%` : '-',
            notes: item.notes ? item.notes : '-',
            country: item.country ?? '-',
          }));
          setOtherTableData({ headers: otherTableHeaders, rows: sortByNumberOfShares(otherRows) });
        }
      }
    }
  }, [shareholdingData, isReportFetching]);

  const totalShareHoldings = useMemo(() => {
    if (!shareholdingData?.response_data?.shareholdings_summary?.pattern_summary?.data) return 0;

    const { COMPUTED_total_equity_shares, total_preference_shares } =
      shareholdingData.response_data.shareholdings_summary.pattern_summary.data;
    return (COMPUTED_total_equity_shares ?? 0) + (total_preference_shares ?? 0);
  }, [shareholdingData]);

  const isAllFieldsNull = (obj: ShareholdingsSummaryResponse | null): boolean => {
    if (!obj) return true;
    return Object.values(obj).every(
      (value) => value === null || (typeof value === 'object' && isAllFieldsNull(value)),
    );
  };

  if (isLoadingShareholding) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );
  }

  if (isReportFetching) {
    return (
      <ErrorMessage
        message={
          'Kindly wait a few minutes, we are fetching the company shareholding data for you.'
        }
      />
    );
  }
  if (
    !isReportFetching &&
    shareholdingData?.response_data?.shareholdings_summary &&
    isAllFieldsNull(shareholdingData.response_data.shareholdings_summary)
  ) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <div className='flex text-center justify-center w-[40vw] min-h-96 h-auto items-center mt-4 text-slate-400 noDataSection !bg-contain'>
          Shareholding data not available
        </div>
      </div>
    );
  }

  return (
    <div className='py-4 flex flex-col gap-4'>
      {!isReportFetching && shareholdingData ? (
        <>
          <h3 className='text-md font-semibold text-heading'>
            Shareholding Summary (as on{' '}
            {formatDate(
              shareholdingData.response_data?.shareholdings_summary?.pattern_summary?.data
                ?.financial_year ?? '',
            )}
            )
          </h3>
          <InfoCard className='flex w-full justify-between'>
            <section className='flex flex-col gap-4 p-6 w-[60%]'>
              <div className='inline-flex justify-start gap-16 w-max'>
                <div className='flex flex-col w-max'>
                  <span className='text-sm font-medium text-slate-600 mb-2'>
                    Total Shares Outstanding
                  </span>
                  <h1 className='text-slate-900 font-bold text-2xl'>
                    {formatCurrencyNumber(totalShareHoldings) ?? '-'}
                  </h1>
                  <span className='text-xs font-medium text-slate-400'>
                    {shareholdingData.response_data?.shareholdings_summary?.pattern_summary?.data
                      ?.financial_year &&
                      formatDate(
                        shareholdingData.response_data?.shareholdings_summary?.pattern_summary?.data
                          ?.financial_year,
                      )}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <span className='text-sm font-medium text-slate-600 mb-2'>
                    Face value of share
                  </span>
                  <h1 className='text-slate-900 font-bold text-2xl'>
                    &#8377;{' '}
                    {shareholdingData.response_data?.shareholdings_summary?.pattern_summary?.data
                      ?.COMPUTED_value_per_share_authorised_capital ?? '-'}
                  </h1>
                  {/* <Button
                    className='self-start underline text-xs text-gray-500 hover:text-gray-700 font-semibold bg-transparent'
                    variant='text'
                    text='View History'
                    onClick={() => {}}
                  /> */}
                </div>
              </div>
              <hr className='text-gray-200' />
              <div className='inline-flex justify-start gap-16'>
                <div className='flex flex-col'>
                  <span className='text-sm font-medium text-slate-600 mb-2'>Promoter group</span>
                  <h1 className='font-bold text-2xl text-[#35B26A]'>
                    {`${promoterPercentage.toFixed(2)}%`}
                  </h1>
                  <span className='text-xs font-medium text-slate-400'>
                    (
                    {shareholdingData.response_data?.shareholdings_summary?.pattern_summary?.data
                      ?.num_promoters_end_of_year ?? 0}{' '}
                    promoters)
                  </span>
                </div>
                <div className='flex flex-col'>
                  <span className='text-sm font-medium text-slate-600 mb-2'>Public</span>
                  <h1 className='font-bold text-2xl text-[#2E63EE]'>
                    {`${publicPercentage.toFixed(2)}%`}
                  </h1>
                  <span className='text-xs font-medium text-slate-400'>
                    (
                    {shareholdingData.response_data?.shareholdings_summary?.pattern_summary?.data
                      ?.members_not_promoters_end_of_year ?? 0}{' '}
                    public holders)
                  </span>
                </div>
              </div>
            </section>
            <section className='w-[40%]'>
              {patternSummaryChartOptions && (
                <Chart
                  chartOptions={patternSummaryChartOptions}
                  setChartOptions={setPatternSummaryChartOptions}
                />
              )}
            </section>
          </InfoCard>
          <div className='col-span-full text-heading font-semibold inline-flex gap-2 items-center'>
            Top Holdings
            <Icon
              icon='Bars3Icon'
              variant='outline'
              onClick={() => setHoldingView('table')}
              className={`w-4 font-bold cursor-pointer ${holdingView === 'table' ? 'text-blue-600' : 'text-gray-600'}`}
            />
            {shareholdingData?.response_data?.shareholdings_summary?.director_shareholdings
              ?.data && (
              <Icon
                icon='Squares2X2Icon'
                variant='outline'
                onClick={() => setHoldingView('charts')}
                className={`w-4 font-bold cursor-pointer ${holdingView === 'charts' ? 'text-blue-600' : 'text-gray-600'}`}
              />
            )}
          </div>
          {directorTableData.rows.length > 0 ? (
            <InfoCard className=' p-4 w-full flex flex-col'>
              <div className='flex items-start'>
                <p className='text-md font-semibold mb-4 text-heading'>Director Shareholdings</p>
                <DocumentIconButton
                  metadata={
                    shareholdingData?.response_data?.shareholdings_summary?.director_shareholdings
                      ?.metadata ?? null
                  }
                  documentData={
                    shareholdingData?.response_data?.shareholdings_summary
                      ?.director_shareholdings as ShareholdingTypes
                  }
                />
              </div>
              <>
                {holdingView === 'table' ? (
                  <Table
                    tableDivClassname='w-full border border-gray-200 !p-0 rounded'
                    tableHeaderClassname='bg-secondary border-b border-gray-200 p-4 text-sm text-gray-600'
                    headers={directorTableData.headers}
                    rows={directorTableData.rows.slice(0, 5)}
                  />
                ) : (
                  <Chart
                    chartOptions={promotersChartOptions}
                    setChartOptions={setPromotersChartOptions}
                  />
                )}
                {directorTableData.rows.length > 5 && (
                  <Button
                    variant='outline'
                    className='mt-4 self-end text-gray-600 outline-gray-300'
                    text={holdingView === 'table' ? 'View All' : 'View list'}
                    onClick={() => setShowModal('Directors')}
                  />
                )}
              </>
            </InfoCard>
          ) : null}

          {individualTableData.rows.length > 0 ? (
            <InfoCard className=' col-span-full p-4 w-full'>
              <div className='flex items-start'>
                <p className='text-md font-semibold mb-4 text-heading'>Individual Shareholdings</p>
                <DocumentIconButton
                  metadata={
                    shareholdingData?.response_data?.shareholdings_summary?.individual_shareholdings
                      ?.metadata ?? null
                  }
                  documentData={
                    shareholdingData?.response_data?.shareholdings_summary
                      ?.individual_shareholdings as ShareholdingTypes
                  }
                />
              </div>
              <>
                <Table
                  tableDivClassname='w-full border border-gray-200 !p-0 rounded'
                  headers={individualTableData.headers}
                  rows={individualTableData.rows.slice(0, 5)}
                />
                {individualTableData.rows.length > 5 && (
                  <Button
                    variant='outline'
                    className='mt-4 self-end text-gray-600 outline-gray-300'
                    text='View All'
                    onClick={() => setShowModal('Individual')}
                  />
                )}
              </>
            </InfoCard>
          ) : null}
          {companyTableData.rows.length > 0 ? (
            <InfoCard className=' col-span-full p-4 w-full'>
              <div className='flex items-start'>
                <p className='text-md font-semibold mb-4 text-heading'>Company Shareholdings</p>
                <DocumentIconButton
                  metadata={
                    shareholdingData?.response_data?.shareholdings_summary?.company_shareholdings
                      ?.metadata ?? null
                  }
                  documentData={
                    shareholdingData?.response_data?.shareholdings_summary
                      ?.company_shareholdings as ShareholdingTypes
                  }
                />
              </div>
              <>
                <Table
                  tableDivClassname='w-full border border-gray-200 !p-0 rounded'
                  headers={companyTableData.headers}
                  rows={companyTableData.rows.slice(0, 5)}
                />
                {companyTableData.rows.length > 5 && (
                  <Button
                    variant='outline'
                    className='mt-4 self-end text-gray-600 outline-gray-300'
                    text='View All'
                    onClick={() => setShowModal('Company')}
                  />
                )}
              </>
            </InfoCard>
          ) : null}
          {otherTableData.rows.length > 0 ? (
            <InfoCard className=' col-span-full p-4 w-full'>
              <div className='flex items-start'>
                <p className='text-md font-semibold mb-4 text-heading'>Other Shareholdings</p>
                <DocumentIconButton
                  metadata={
                    shareholdingData?.response_data?.shareholdings_summary?.other_shareholdings
                      ?.metadata ?? null
                  }
                  documentData={
                    shareholdingData?.response_data?.shareholdings_summary
                      ?.other_shareholdings as ShareholdingTypes
                  }
                />
              </div>
              <>
                <Table
                  tableDivClassname='w-full border border-gray-200 !p-0 rounded'
                  headers={otherTableData.headers}
                  rows={otherTableData.rows.slice(0, 5)}
                />
                {otherTableData.rows.length > 5 && (
                  <Button
                    variant='outline'
                    className='mt-4 self-end text-gray-600 outline-gray-300'
                    text='View All'
                    onClick={() => setShowModal('Other')}
                  />
                )}
              </>
            </InfoCard>
          ) : null}
          {/* pattern */}
          <InfoCard className=' col-span-full p-4 w-full'>
            {shareholdingData?.response_data?.shareholdings_summary?.shareholdings?.data ? (
              <>
                <div className='flex items-start'>
                  <p className='text-md font-semibold text-heading'>Shareholding Structure</p>
                  <DocumentIconButton
                    metadata={
                      shareholdingData?.response_data?.shareholdings_summary?.shareholdings
                        ?.metadata ?? null
                    }
                    documentData={
                      shareholdingData?.response_data?.shareholdings_summary
                        ?.shareholdings as ShareholdingTypes
                    }
                  />
                </div>
                <ExpandableTable
                  data={
                    shareholdingData?.response_data?.shareholdings_summary
                      .shareholdings as ShareholdingStructureResponse
                  }
                  isShareholdingTable={true}
                  tableType='shareholding'
                />
              </>
            ) : (
              <div className='text-center text-slate-400'>
                Shareholding Pattern not available for the company
              </div>
            )}
          </InfoCard>
          {/* Joint Ventures Section */}
          {shareholdingData?.response_data?.shareholdings_summary?.joint_ventures?.data && (
            <EntitySection
              title='Joint Ventures'
              data={shareholdingData.response_data.shareholdings_summary.joint_ventures}
              metadata={
                shareholdingData.response_data.shareholdings_summary.joint_ventures?.metadata
              }
              renderTable={renderJointVenturesTable}
            />
          )}
          {shareholdingData?.response_data?.shareholdings_summary?.associate_entities?.data && (
            <EntitySection
              title='Associate Entities'
              data={shareholdingData.response_data.shareholdings_summary.associate_entities}
              metadata={
                shareholdingData.response_data.shareholdings_summary.associate_entities?.metadata
              }
              renderTable={renderJointVenturesTable}
            />
          )}
          {shareholdingData?.response_data?.shareholdings_summary?.subsidiary_entities?.data && (
            <EntitySection
              title='Subsidiary Entities'
              data={shareholdingData.response_data.shareholdings_summary.subsidiary_entities}
              metadata={
                shareholdingData.response_data.shareholdings_summary.subsidiary_entities?.metadata
              }
              renderTable={renderJointVenturesTable}
            />
          )}
          {shareholdingData?.response_data?.shareholdings_summary?.holding_entities?.data && (
            <EntitySection
              title='Holding Entities'
              data={shareholdingData.response_data.shareholdings_summary.holding_entities}
              metadata={
                shareholdingData.response_data.shareholdings_summary.holding_entities?.metadata
              }
              renderTable={renderJointVenturesTable}
            />
          )}
          <Modal
            className='w-[70%] max-w-[90vw]'
            title={showModal ?? ''}
            isOpen={!!showModal}
            onClose={() => setShowModal(undefined)}
          >
            <Table
              tableDivClassname='w-full border border-gray-200 !p-0 rounded'
              tableHeaderClassname='bg-secondary border-b border-gray-200-200 text-sm p-4 text-xs p-1 text-gray-600'
              headers={
                showModal === 'Directors'
                  ? directorTableData.headers
                  : showModal === 'Individual'
                    ? individualTableData.headers
                    : showModal === 'Company'
                      ? companyTableData.headers
                      : otherTableData.headers
              }
              rows={
                showModal === 'Directors'
                  ? directorTableData.rows
                  : showModal === 'Individual'
                    ? individualTableData.rows
                    : showModal === 'Company'
                      ? companyTableData.rows
                      : otherTableData.rows
              }
            />
          </Modal>
        </>
      ) : isLoadingShareholding ? (
        <div className='w-full w-md-[83.8vw] '>
          <Loading
            message={
              isReportFetching
                ? 'Kindly wait a few minutes the report is getting fetched'
                : undefined
            }
          />
        </div>
      ) : (
        <div className='flex justify-center w-full  '>
          <div className='flex text-center justify-center w-[40vw] min-h-96 h-auto items-center mt-4 text-slate-400 noDataSection !bg-contain'>
            Shareholding data not available, please try again in some time.
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareHoldingPattern;
