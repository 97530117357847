import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import tokenLedgerApi from '@app/store/api/tokenLedger/tokenLedgerApi';
import authApi from '@app/store/api/authApi/authApi';
import { UserDetails } from '../api/authApi/types';
import companyApi from '../api/companyApi';
import { ReportStatusEnum } from '../api/tokenLedger/types';
import { IdentifierTypeEnum } from '@app/types';

type CustomerCompanyReportsTable = {
  customer_order_id: string;
  cognito_user_id: string;
  customer_team_id: string;
  customer_company_id: string;
  report_order_cin: string;
  report_order_charges: string;
  report_order_status: string;
  event_recorded_at: string;
};

export enum COMPANY_TYPE {
  PublicLimitedCompany = 'Public Limited Company',
  PrivateLimitedCompany = 'Private Limited Company',
  GovernmentOfIndiaCompany = 'Government of India Company',
  StateGovernmentCompany = 'State Government Company',
  FinancialTrustCompany = 'Financial Trust Company',
  GeneralAssociationTrust = 'General Association Trust',
  UnlimitedCompany = 'Unlimited Company',
  LimitedLiabilityPartnership = 'Limited Liability Partnership',
  ForeignCompany = 'Foreign Company',
  OnePersonCompany = 'One Person Company',
}

type UtilState = {
  totalCredits: number;
  reportsTable: CustomerCompanyReportsTable[] | null;
  userDetails: UserDetails | null;
  unlockReport: boolean;
  showNudge: boolean;
  reportStatus: ReportStatusEnum;
  isReportFetching: boolean;
  companyType: COMPANY_TYPE;
  identifierType: IdentifierTypeEnum;
  isRequestNewCompanyModalOpen: boolean;
};

const initialState: UtilState = {
  totalCredits: 0,
  reportsTable: null,
  userDetails: null,
  unlockReport: false,
  showNudge: false,
  reportStatus: 'LOCKED' as ReportStatusEnum,
  isReportFetching: false,
  companyType: COMPANY_TYPE.PrivateLimitedCompany,
  identifierType: IdentifierTypeEnum.CIN,
  isRequestNewCompanyModalOpen: false,
};

export const utilSlice = createSlice({
  name: 'utilState',
  initialState,
  reducers: {
    setTotalCredits: (state, action: PayloadAction<number>) => {
      state.totalCredits = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
    },
    setUnlockReportStatus: (state, action: PayloadAction<boolean>) => {
      state.unlockReport = action.payload;
    },
    setTokenReportsTable: (state, action: PayloadAction<CustomerCompanyReportsTable[]>) => {
      state.reportsTable = action.payload;
    },
    setReportStatus: (state, action: PayloadAction<ReportStatusEnum>) => {
      state.reportStatus = action.payload;
    },
    setShowNudge: (state, action: PayloadAction<boolean>) => {
      state.showNudge = action.payload;
    },
    toggleRequestCompanyModal: (state, action: PayloadAction<boolean>) => {
      state.isRequestNewCompanyModalOpen = action.payload;
    },
  },
  // Update TokenBalance whenever API refetch
  extraReducers: (builder) => {
    builder.addMatcher(
      tokenLedgerApi.endpoints.getTokenBalance.matchFulfilled,
      (state, { payload }) => {
        state.totalCredits = parseFloat(payload.response_data.token_balance);
      },
    );
    builder.addMatcher(authApi.endpoints.getUserData.matchFulfilled, (state, { payload }) => {
      state.userDetails = payload.response_data;
    });
    builder.addMatcher(
      companyApi.endpoints.getCompanyResponse.matchFulfilled,
      (state, { payload }) => {
        // TODO - Make This Better - This check for the unlock status of a company
        const apiResponse = payload as {
          response_data: {
            company?: {
              classification: COMPANY_TYPE;
            };
            status: ReportStatusEnum;
          };
        };
        const status = apiResponse.response_data;
        if (
          status.status === ReportStatusEnum.IN_PROGRESS ||
          status.status === ReportStatusEnum.INITIALISED
        ) {
          state.isReportFetching = true;
        } else {
          state.isReportFetching = false;
        }
        state.reportStatus = status.status;

        if (apiResponse.response_data.company && apiResponse.response_data.company.classification) {
          state.companyType = apiResponse.response_data.company.classification;
          //TODO = This is a temporary fix to handle LLPs
          if (state.companyType === COMPANY_TYPE.LimitedLiabilityPartnership) {
            state.identifierType = IdentifierTypeEnum.LLPIN;
          } else {
            state.identifierType = IdentifierTypeEnum.CIN;
          }
        }
      },
    );
  },
});

export const {
  setTotalCredits,
  setUserDetails,
  setUnlockReportStatus,
  setReportStatus,
  setShowNudge,
  toggleRequestCompanyModal,
} = utilSlice.actions;

export default utilSlice.reducer;
