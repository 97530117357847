export const msmeTabs = [
  {
    text: 'Overview',
    id: 'overview',
  },
  // {
  //   text: 'MSME',
  //   id: 'msme',
  // },
  {
    text: 'Key People',
    id: 'key-people',
  },
  {
    text: 'Locations',
    id: 'locations',
  },
  {
    text: 'Business Information',
    id: 'business-information',
  },
  {
    text: 'Financials',
    id: 'msme-financials',
  },
  {
    text: 'Compliances',
    id: 'msme-compliances',
  },
  {
    text: 'Credit Rating',
    id: 'msme-creditRating',
  },
  // {
  //   text: 'Documents',
  //   id: 'documents',
  // },
];
