import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../axiosBase';
import { AuthForm } from '@pages/Auth/auth.types';
import type {
  AuthenticationResponse,
  UserDetailsResponse,
  SendOTPPayload,
  ConfirmSignUp,
  VerifyOtp,
  SendOtpSuccessResponse,
  VerifyOtpResponse,
  changePasswordAfterLogin,
} from './types';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation<AuthenticationResponse, AuthForm>({
      query: (data: AuthForm) => ({
        url: 'auth/authenticate',
        method: 'post',
        data,
      }),
    }),
    getUserData: builder.query<UserDetailsResponse, string>({
      query: (id: string) => ({
        url: `/user-management/get?customer_user_id=${id}`,
        method: 'get',
      }),
    }),
    signUp: builder.mutation<void, SendOTPPayload>({
      query: (payload) => ({
        url: '/auth/sign-up',
        method: 'post',
        data: payload,
      }),
    }),
    resendOtp: builder.mutation<
      void,
      {
        phone_number: string;
      }
    >({
      query: (payload) => ({
        url: '/auth/resend-otp',
        method: 'post',
        data: payload,
      }),
    }),
    confirmSignup: builder.mutation<void, ConfirmSignUp>({
      query: (payload) => ({
        url: '/auth/confirm-sign-up',
        method: 'post',
        data: payload,
      }),
    }),
    forgetPassWordSendOtp: builder.mutation<SendOtpSuccessResponse, string>({
      query: (number) => ({
        url: '/auth/send-otp-forgot-password',
        method: 'post',
        data: {
          phone_number: number,
        },
      }),
    }),
    forgetPassWordVerifyOtp: builder.mutation<VerifyOtpResponse, VerifyOtp>({
      query: (payload) => ({
        url: '/auth/verify-otp-forgot-password',
        method: 'post',
        data: payload,
      }),
    }),
    changePasswordAfterLogin: builder.mutation<void, changePasswordAfterLogin>({
      query: (payload) => ({
        url: '/auth/change-password-after-login',
        method: 'POST',
        data: payload,
      }),
    }),
    resetPassword: builder.mutation<void, Record<string, string>>({
      query: (payload) => ({
        url: '/auth/set-new-password',
        method: 'POST',
        data: payload,
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useLoginMutation,
  useLazyGetUserDataQuery,
  useGetUserDataQuery,
  useSignUpMutation,
  useResendOtpMutation,
  useConfirmSignupMutation,
  useForgetPassWordSendOtpMutation,
  useForgetPassWordVerifyOtpMutation,
  useChangePasswordAfterLoginMutation,
  useResetPasswordMutation,
} = authApi;

export default authApi;
