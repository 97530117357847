import React, { startTransition, useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import Icon from '@app/components/lib-components/icon/Icon';
import LoginImage from '@app/assets/LoginImage.webp';
import Button from '@app/components/lib-components/button/Button';
import { InputV2 } from '@app/components/lib-components/input/InputV2';
import '@app/index.css';
import { useResetPasswordMutation, ResetPassword } from '@app/store/api/authApi';
import { toast } from 'sonner';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Please enter a new password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
      'Password must be at least 8 characters long and contain at least one letter, one number, and one special character',
    ),
  confirmPassword: yup
    .string()
    .required('Please confirm your new password')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
});

type FormData = yup.InferType<typeof schema>;

const ResetPage: React.FC = () => {
  const form = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get('user_type');
  const userId = queryParams.get('user_id');

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const handleResetSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const payload: ResetPassword = userId
        ? {
            user_id: userId,
            new_password: data.password,
          }
        : {
            phone_number: sessionStorage.getItem('phone_number') || '',
            otp: sessionStorage.getItem('otp') || '',
            user_id: sessionStorage.getItem('userId') || '',
            new_password: data.password,
          };

      await resetPassword(payload).unwrap();
      sessionStorage.removeItem('phone_number');
      sessionStorage.removeItem('otp');
      sessionStorage.removeItem('userId');

      startTransition(() => {
        navigate('/login', { replace: true });
      });

      toast.success('Password reset successful');
    } catch (error) {
      toast.error('Error resetting the password');
    }
  };

  const isNewUser = userType === 'new' && userId;

  return (
    <div className='flex h-screen w-full justify-between'>
      <div className='relative w-8/12'>
        <Icon icon='AppLogo' className='absolute top-4 left-4 z-10' />
        <img src={LoginImage} className='h-screen fill-available login-img' />
      </div>
      <div className='w-5/12 m-auto flex flex-col items-center'>
        <div className='text-center'>
          <Icon icon='CompanyLogo' size={100} className='m-auto' />
          {isNewUser ? (
            <h4 className='text-slate-300 font-bold text-xl mt-6 mb-1'>
              Please set a password for your account
            </h4>
          ) : (
            <>
              <h4 className='text-slate-300 font-bold text-xl mt-6 mb-1'>Welcome back!</h4>
              <p className='text-gray-500'>Please enter and create a new password</p>
            </>
          )}
        </div>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleResetSubmit)}
            className='flex justify-center w-full'
          >
            <section className='flex flex-col gap-3 p-2 px-[5rem] mt-3 items-center text-black w-full'>
              <InputV2
                name='password'
                placeholder='New Password'
                type={showPassword ? 'text' : 'password'}
                suffixIcon={showPassword ? 'EyeSlashIcon' : 'EyeIcon'}
                suffixIconHandler={() => setShowPassword((prev) => !prev)}
                label=''
                className='w-full'
                errorMessageStyle='text-xs'
              />
              <InputV2
                name='confirmPassword'
                placeholder='Confirm Password'
                type={showReset ? 'text' : 'password'}
                suffixIcon={showReset ? 'EyeSlashIcon' : 'EyeIcon'}
                suffixIconHandler={() => setShowReset((prev) => !prev)}
                label=''
                className='w-full'
              />
              <Button
                text={isNewUser ? 'Set Password' : 'Change Password'}
                variant='base'
                type='submit'
                className='w-full'
                isLoading={isLoading}
              />
            </section>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ResetPage;
