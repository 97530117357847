import { forwardRef } from 'react';
import Select, {
  Props as SelectProps,
  GroupBase,
  MultiValue,
  SingleValue,
  components,
  OptionProps,
  SingleValueProps,
  MultiValueProps,
} from 'react-select';
import { Icons } from '../icon/icon.types';
import Icon from '../icon/Icon';
import classNames from 'classnames';
import { hyphenate } from '@app/utils/hyphenate';

export interface BaseOptionType {
  label: string;
  value: string;
}

// Extended option type with icon
export interface OptionTypeWithIcon extends BaseOptionType {
  icon: Icons;
}

// Create a conditional type based on hasIcon
export type OptionType<T extends boolean> = T extends true ? OptionTypeWithIcon : BaseOptionType;

interface DropdownProps<HasIcon extends boolean = false>
  extends Omit<SelectProps<OptionType<HasIcon>, boolean>, 'onChange' | 'value'> {
  label?: string;
  labelClassName?: string;
  hasIcon?: HasIcon;
  onChange?: (value: MultiValue<OptionType<HasIcon>> | SingleValue<OptionType<HasIcon>>) => void;
  value?: OptionType<HasIcon> | OptionType<HasIcon>[] | null;
  'data-testid'?: string;
}

const CustomOption = <
  HasIcon extends boolean = false,
  T extends OptionType<HasIcon> = OptionType<HasIcon>,
>(
  props: OptionProps<T, boolean, GroupBase<T>>,
) => {
  const { data, className } = props;

  return (
    <components.Option {...props}>
      <div
        className={classNames('flex items-center gap-2', className)}
        data-testid={hyphenate(data.label)}
      >
        {'icon' in data && data.icon && <Icon icon={data.icon} className='h-4 w-4' />}
        <span>{data.label}</span>
      </div>
    </components.Option>
  );
};

// Custom SingleValue component with icon support
const CustomSingleValue = <
  HasIcon extends boolean = false,
  T extends OptionType<HasIcon> = OptionType<HasIcon>,
>(
  props: SingleValueProps<T, boolean, GroupBase<T>>,
) => {
  const { data, className } = props;
  return (
    <components.SingleValue {...props}>
      <div className={classNames('flex items-center gap-2', className)}>
        {'icon' in data && data.icon && <Icon icon={data.icon} className='h-4 w-4' />}
        <span>{data.label}</span>
      </div>
    </components.SingleValue>
  );
};

// Custom MultiValue component with icon support
const CustomMultiValue = <
  HasIcon extends boolean = false,
  T extends OptionType<HasIcon> = OptionType<HasIcon>,
>(
  props: MultiValueProps<T, boolean, GroupBase<T>>,
) => {
  const { data } = props;

  return (
    <components.MultiValue {...props}>
      <div className='flex items-center gap-2'>
        {'icon' in data && data.icon && <Icon icon={data.icon} className='h-3 w-3' />}
        <span>{data.label}</span>
      </div>
    </components.MultiValue>
  );
};

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      labelClassName,
      label,
      onChange,
      isMulti = false,
      placeholder = 'Select...',
      hasIcon = false,
      className,
      styles,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={classNames('w-full', className)}
        ref={ref}
        data-testid={props['data-testid'] || hyphenate(label || '')}
      >
        {label && (
          <label className={`block text-sm font-medium text-gray-700 mb-2 ${labelClassName}`}>
            {label}
          </label>
        )}
        <Select<OptionType<typeof hasIcon>, typeof isMulti>
          {...props}
          isMulti={isMulti}
          className='basic-multi-select'
          classNamePrefix='select'
          onChange={(selectedOption) => {
            if (onChange) onChange(selectedOption);
          }}
          placeholder={placeholder}
          isClearable={true}
          components={{
            IndicatorSeparator: () => null,
            Option: CustomOption,
            SingleValue: CustomSingleValue,
            MultiValue: CustomMultiValue,
            ClearIndicator: () => null,
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: '0.375rem',
              borderColor: state.isFocused ? '#2563eb' : '#d1d5db',
              boxShadow: state.isFocused ? '0 0 0 1px #2563eb' : 'none',
              '&:hover': {
                borderColor: '#2563eb',
              },
              '&focus': {
                border: '1px',
                outline: '1px',
              },
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isSelected
                ? '#e5e7eb'
                : state.isFocused
                  ? '#f3f4f6'
                  : 'transparent',
              color: '#374151',
              cursor: 'pointer',
            }),
            multiValue: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: '#f3f4f6',
              borderRadius: '0.375rem',
            }),
            multiValueLabel: (baseStyles) => ({
              ...baseStyles,
              color: '#374151',
              padding: '2px',
            }),
            multiValueRemove: (baseStyles) => ({
              ...baseStyles,
              color: '#374151',
              ':hover': {
                backgroundColor: '#e5e7eb',
                color: '#ef4444',
              },
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: '#9ca3af',
            }),
            ...styles,
          }}
        />
      </div>
    );
  },
);

Dropdown.displayName = 'Dropdown';
