import { EPFOData, GSTDetail } from '@app/types/compliances';

export const MSME_GST_DATA: Record<string, GSTDetail[]> = {
  PANAALFR8552P: [
    {
      gstin: '27AALFR8552P1Z8',
      centre_jurisdiction: 'Central Board of Indirect Taxes and Customs',
      status: 'ACTIVE',
      company_name: 'RED EXIM',
      trade_name: 'RED EXIM',
      state: 'Maharashtra',
      state_jurisdiction: 'Maharashtra',
      date_of_registration: '2017-07-01',
      nature_of_business_activities: 'Manufacturer/ Trader/ Importer/ Exporter',
      taxpayer_type: 'Regular',
      filings: [
        {
          date_of_filing: '2024-02-19',
          financial_year: '2023-24',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'January 2024',
        },
        {
          date_of_filing: '2024-03-20',
          financial_year: '2023-24',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'February 2024',
        },
        {
          date_of_filing: '2024-04-19',
          financial_year: '2023-24',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'March 2024',
        },
        {
          date_of_filing: '2024-05-18',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'April 2024',
        },
        {
          date_of_filing: '2024-06-19',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'May 2024',
        },
        {
          date_of_filing: '2024-07-20',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'June 2024',
        },
        {
          date_of_filing: '2024-08-17',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'July 2024',
        },
        {
          date_of_filing: '2024-09-18',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'August 2024',
        },
        {
          date_of_filing: '2024-10-19',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'September 2024',
        },
        {
          date_of_filing: '2024-11-19',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'October 2024',
        },
      ],
    },
    {
      gstin: '24AALFR8552P1ZE',
      centre_jurisdiction: 'Central Board of Indirect Taxes and Customs',
      status: 'ACTIVE',
      company_name: 'RED EXIM',
      trade_name: 'RED EXIM',
      state: 'Gujarat',
      state_jurisdiction: 'Gujarat',
      date_of_registration: '2017-07-01',
      nature_of_business_activities: 'Manufacturer/ Trader/ Importer/ Exporter',
      taxpayer_type: 'Regular',
      filings: [
        {
          date_of_filing: '2024-11-19',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'October 2024',
        },
        {
          date_of_filing: '2024-10-18',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'September 2024',
        },
        {
          date_of_filing: '2024-09-18',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'August 2024',
        },
        {
          date_of_filing: '2024-08-17',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'July 2024',
        },
        {
          date_of_filing: '2024-07-20',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'June 2024',
        },
        {
          date_of_filing: '2024-06-19',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'May 2024',
        },
        {
          date_of_filing: '2024-05-18',
          financial_year: '2024-25',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'April 2024',
        },
        {
          date_of_filing: '2024-04-19',
          financial_year: '2023-24',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'March 2024',
        },
        {
          date_of_filing: '2024-03-20',
          financial_year: '2023-24',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'February 2024',
        },
        {
          date_of_filing: '2024-02-19',
          financial_year: '2023-24',
          methodOfFiling: 'Online',
          return_type: 'GSTR-3B',
          status: 'Filed',
          tax_period: 'January 2024',
        },
      ],
    },
  ],
  PANAPUPR0030E: [
    {
      gstin: '06APUPR0030E1Z1',
      centre_jurisdiction: 'RANGE-45',
      status: 'ACTIVE',
      company_name: 'CARTER UNIQUE GROUP',
      trade_name: 'CARTER UNIQUE GROUP',
      state: 'Haryana',
      state_jurisdiction: 'Karnal',
      date_of_registration: '2017-12-05',
      nature_of_business_activities: 'Manufacturer/ Trader/ Importer/ Service Provider',
      taxpayer_type: 'Regular',
      filings: [],
    },
    {
      gstin: '22APUPR0030E1Z7',
      centre_jurisdiction: 'RANGE-72',
      status: 'ACTIVE',
      company_name: 'CARTER UNIQUE GROUP',
      trade_name: 'CARTER UNIQUE GROUP',
      state: 'Chattisgarh',
      state_jurisdiction: 'Raipur',
      date_of_registration: '2022-09-19',
      nature_of_business_activities: 'Manufacturer/ Trader/ Importer/ Service Provider',
      taxpayer_type: 'Regular',
      filings: [],
    },
  ],
};

export const MSME_EPFO_DATA: Record<string, EPFOData[]> = {
  PANAALFR8552P: [
    {
      payment_details: [
        {
          trrn: 3102411007349,
          date_of_credit: '2024-11-14',
          amount: 15200,
          wage_month: 'October 2024',
          number_of_employee: 4,
          ecr: 'ECR1124',
        },
        {
          trrn: 3102410007196,
          date_of_credit: '2024-10-11',
          amount: 15200,
          wage_month: 'September 2024',
          number_of_employee: 4,
          ecr: 'ECR1123',
        },
        {
          trrn: 3102409001139,
          date_of_credit: '2024-09-11',
          amount: 15200,
          wage_month: 'August 2024',
          number_of_employee: 4,
          ecr: 'ECR1122',
        },
        {
          trrn: 3102408002267,
          date_of_credit: '2024-08-09',
          amount: 15200,
          wage_month: 'July 2024',
          number_of_employee: 4,
          ecr: 'ECR1121',
        },
        {
          trrn: 3102407002064,
          date_of_credit: '2024-07-04',
          amount: 15200,
          wage_month: 'June 2024',
          number_of_employee: 4,
          ecr: 'ECR1120',
        },
        {
          trrn: 3102406002520,
          date_of_credit: '2024-06-10',
          amount: 15200,
          wage_month: 'May 2024',
          number_of_employee: 4,
          ecr: 'ECR1119',
        },
      ],
      company_details: {
        last_updated: '2024-11-14',
        name_of_establishment_as_per_pan: 'RED EXIM',
        pan_status: 'Active',
        section_applicable: '1(3)(b)',
        ESIC_code: 'ESIC123456',
        ownership_type: 'Partnership',
        address: 'E C- 6051, 6th Floor, Bharat Diamond Bourse, Bandra Kurla Complex',
        city: 'Mumbai',
        date_of_setup: '2010-04-01',
        state: 'Maharashtra',
        epfo_office_name: 'Mumbai Regional Office',
        zone: 'West',
        est_code: 'MHBAN151867',
        est_name: 'RED EXIM',
        est_status: 'Active',
        registeration_status: 'Registered',
        post_coverage_status: 'Covered',
        pf_exemption_status: 'Un-exempted',
        pension_exemption_status: 'Un-exempted',
        primary_business_activity: 'Manufacturer/Trader of Gems & Jewellery',
        edli_exemption_status: 'Un-exempted',
        working_status: 'Working',
        coverage_section: '1(3)(b)',
        actionable_status: 'No Action Required',
        date_of_coverage: '2010-04-01',
      },
    },
  ],
};
