import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

const PaymentResult = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderId = searchParams.get('order_id');
  const status = searchParams.get('status');
  const status_id = searchParams.get('status_id');
  const signature = searchParams.get('signature');
  const signature_algorithm = searchParams.get('signature_algorithm');

  useEffect(() => {
    const orderState = {
      order_id: orderId,
      status: status,
      status_id: status_id,
      signature_algorithm: signature_algorithm,
      signature: signature,
    };

    const token_order_ledger_id = localStorage.getItem('payment_order_id');

    if (status?.toLowerCase() === 'charged') {
      navigate(`/payment-success`, { state: orderState });
    } else {
      navigate(`/payment-pending`, {
        state: {
          ...orderState,
          token_order_ledger_id,
        },
      });
    }
  }, [status, orderId, navigate]);

  return null;
};

export default PaymentResult;
