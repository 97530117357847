import '../../index.css';
import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputV2 } from '@app/components/lib-components/input/InputV2';
import Button from '@app/components/lib-components/button/Button';
import Icon from '@app/components/lib-components/icon/Icon';
import { BaseOptionType, Dropdown } from '@app/components/lib-components/drop-down';
import OTPInput from 'react-otp-input';
import classNames from 'classnames';
import {
  useSignUpMutation,
  useConfirmSignupMutation,
  useResendOtpMutation,
} from '@app/store/api/authApi';
import { getMessage } from '@app/store/errorHandler';
import { toast } from 'sonner';
import { usePostHog } from 'posthog-js/react';

const schema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string(),
  email: yup
    .string()
    .email('Email must have a valid format, eg: example@example.com')
    .required('Please enter a valid email'),
  company: yup.string().required('Please enter your company name'),
  designation: yup.string().required('Please enter your designation'),
  state: yup.string().required('Please enter your state name'),
  city: yup.string().required('Please enter your city name'),
  phoneNumber: yup
    .string()
    .matches(/^(?:[7-9][0-9]{9}|[2-9][0-9]{9})$/, 'Please enter a valid phone number')
    .required('Please enter your phone number'),
});

type Ischema = yup.InferType<typeof schema>;

const Signup: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [countryCode, setCountryCode] = useState({ value: '+91', label: '+91 (India)' });
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(false);

  const posthog = usePostHog();

  const [signUp, { error: signUpError, data: signUpData, isLoading: signUpLoading }] =
    useSignUpMutation();
  const [
    confirmSignUp,
    { error: confirmSignUpError, data: confirmSignUpData, isLoading: confirmOtpLoading },
  ] = useConfirmSignupMutation();
  const [resendOtp] = useResendOtpMutation();

  const form = useForm<Ischema>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { handleSubmit, watch } = form;

  const allFields = watch();
  useEffect(() => {
    let interval!: ReturnType<typeof setInterval>;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setIsTimerActive(false);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isTimerActive, timer]);

  useEffect(() => {
    if (signUpData && !signUpError) {
      setOtpSent(true);
      setIsTimerActive(true);
      setTimer(30);
    }
  }, [signUpError, signUpData]);

  useEffect(() => {
    if (confirmSignUpData && !confirmSignUpError) {
      setOtpVerified(true);
    }
  }, [confirmSignUpData, confirmSignUpError]);

  const handleSignupSubmit = () => {
    if (otpVerified) {
      setIsSubmitted(true);
    }
  };

  const extractCountryFromLabel = (label: string) => {
    const regex = /\(([^)]+)\)/;
    const match = label.match(regex);
    return match ? match[1] : null;
  };

  const handleSendOtp: SubmitHandler<Ischema> = async (data) => {
    try {
      const country = extractCountryFromLabel(countryCode.label);
      const payload = {
        email: data.email,
        phone_number: `${countryCode.value}${data.phoneNumber}`,
        name: `${allFields.firstName} ${allFields.lastName}`,
        city: data.city,
        company_name: data.company,
        designation: data.designation,
        state: data.state,
        country: country as string,
      };
      await signUp(payload);
      posthog.capture('otp_sent', {
        email: data.email,
        company_name: data.company,
      });
    } catch (error) {
      toast.error('Failed to send OTP');
      posthog.capture('error_sending_otp', {
        error,
      });
    }
  };

  const handleResendOtp = async () => {
    try {
      const payload = {
        phone_number: `${countryCode.value}${allFields.phoneNumber}`,
      };
      await resendOtp(payload);
      toast.success('Otp sent successfully');
      posthog.capture('otp_sent');
      setOtpSent(true);
      setIsTimerActive(true);
      setTimer(30);
    } catch (error) {
      posthog.capture('error_resending_otp', {
        error,
      });
      toast.error('Failed to send OTP');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      if (otp.length === 6) {
        await confirmSignUp({
          email: allFields.email as string,
          confirmation_code: otp,
        });
        posthog.capture('otp_verified');
      }
    } catch (error) {
      toast.error('Failed to verify OTP');
      posthog.capture('error_verifying_otp', {
        error,
      });
    }
  };

  if (isSubmitted) {
    return (
      <div className='w-full flex flex-col justify-center items-center text-black self-center basis-2/5 p-8'>
        <div className='p-8 flex flex-col w-full text-center'>
          <Icon icon='CompanyLogo' size={100} className='m-auto' />
          <h4 className='text-slate-300 font-bold text-xl mt-6 mb-3'>
            We’re setting up your account..
          </h4>
          <p className='text-gray-500 mt-1'>
            Once complete, you’ll receive a confirmation email with your username and password in
            next 5 minutes.
          </p>
          <p className='text-gray-500 mt-1'>Thank you for your patience!</p>
          <Button
            text='Go back to login'
            variant='base'
            className='mt-4 w-full'
            onClick={() => (window.location.href = '/login')}
            isLoading={signUpLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='w-full flex flex-col justify-center items-center text-black self-center basis-2/5 px-8 overflow-hidden'>
      <div className='px-8 pt-2 pb-6 flex flex-col w-full'>
        <div className='text-center m-auto'>
          <Icon icon='CompanyLogo' size={80} />
          <h4 className='text-slate-300 font-bold tex-md 2xl:text-xl mt-2'>
            Let’s get to know each other
          </h4>
        </div>
        <section className='flex w-full flex-col gap-2 p-2  items-center overflow-hidden '>
          {!otpSent && (
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(handleSendOtp)}>
                <div className='flex w-full gap-3 '>
                  <InputV2 className='w-full' name='firstName' label='' placeholder='First Name' />
                  <InputV2 className='w-full ' name='lastName' label='' placeholder='Last Name' />
                </div>
                <div className='gap-2 flex flex-col mt-2'>
                  <InputV2
                    className='w-full'
                    name='email'
                    label=''
                    placeholder='Work Email'
                    type='email'
                  />
                  <InputV2 className='w-full ' name='company' label='' placeholder='Company' />
                  <InputV2
                    className='w-full'
                    name='designation'
                    label=''
                    placeholder='Designation, Example: Analyst'
                  />
                </div>
                <div className='flex w-full gap-3 mt-2'>
                  <InputV2 className='w-full' name='city' label='' placeholder='City' />
                  <InputV2 className='w-full' name='state' label='' placeholder='State' />
                </div>
                <div className='flex w-full gap-3 items-center justify-start flex-col mt-2'>
                  <div className='flex justify-between w-full gap-3'>
                    <Dropdown
                      className='self-center'
                      options={[
                        { value: '+91', label: '+91 (India)' },
                        { value: '+1', label: '+1 (US)' },
                      ]}
                      value={countryCode}
                      onChange={(option) => setCountryCode(option as BaseOptionType)}
                      isDisabled={otpVerified}
                    />
                    <input
                      className={classNames(
                        'w-full py-1 border rounded-lg focus:outline-none px-2 bg-white',
                        form.formState.errors.phoneNumber
                          ? 'border-red-500'
                          : 'ring-blue-500 focus:ring-1',
                      )}
                      {...form.register('phoneNumber')}
                      placeholder='Phone Number'
                      type='tel'
                      disabled={otpVerified}
                    />
                  </div>
                  <div>
                    {form.formState.errors.phoneNumber && (
                      <p className='text-red-500 text-sm'>
                        {String(form.formState.errors.phoneNumber.message) || 'Validation error'}
                      </p>
                    )}
                  </div>
                </div>
                {Boolean(signUpError) && (
                  <p className='text-xs text-red-600 font-semibold mt-0 ml-auto cursor-pointer mx-auto  text-center'>
                    {getMessage(signUpError, 'Error Signing up, please try again later')}
                  </p>
                )}
                <Button
                  text='Send OTP'
                  variant='base'
                  className='mt-4 w-full'
                  type='submit'
                  disabled={!form.formState.isValid}
                  isLoading={signUpLoading}
                />
              </form>
            </FormProvider>
          )}
          {otpSent && !otpVerified && (
            <>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle='otp-input'
                renderInput={(props) => <input {...props} />}
                containerStyle='otp-container'
                shouldAutoFocus
              />
              {isTimerActive ? (
                <p className='text-xs text-gray-600 mt-0 ml-auto'>Resend OTP in {timer} seconds</p>
              ) : (
                <p
                  className='text-xs text-gray-600 mt-0 ml-auto cursor-pointer text-end'
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </p>
              )}
              <Button
                text={confirmOtpLoading ? 'Verifying OTP' : 'Verify OTP'}
                variant='base'
                className='mt-4 w-full'
                onClick={handleVerifyOtp}
                disabled={otp.length !== 6 || confirmOtpLoading}
              />
              {Boolean(confirmSignUpError) && (
                <p className='text-xs text-red-600 mt-0 ml-auto cursor-pointer mx-auto'>
                  {getMessage(confirmSignUpError, 'Error Verifying OTP')}
                </p>
              )}
            </>
          )}
          {otpVerified && (
            <>
              {otpVerified && (
                <p className='text-xs text-green-500 font-semibold mt-0'>OTP Verified!</p>
              )}
              <Button
                text='Create an Account'
                variant='base'
                className='mt-4 w-full'
                onClick={handleSubmit(handleSignupSubmit)}
                disabled={!otpVerified || !form.formState.isValid}
              />
            </>
          )}
          <div className='text-center mt-4 text-xs text-gray-500'>
            By signing up, I agree to T&C of this platform
          </div>
        </section>
      </div>
    </div>
  );
};

export default Signup;
