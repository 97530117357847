import { ModalProps } from './modal.types';
import '@app/components/credits/CreditModal.css';
import classNames from 'classnames';
import { useOutsideClick } from '@app/hooks/useOutSideClick';
import { XMarkIcon } from '@heroicons/react/24/outline';

const Modal = ({ isOpen, onClose, children, title, className, titleClass }: ModalProps) => {
  const ref = useOutsideClick<HTMLDivElement>(onClose);

  if (!isOpen) return null;

  const handleOnClose = () => {
    onClose && onClose();
  };

  return (
    <div className='credit-modal-backdrop' data-testid='credhive-common-modal'>
      <div
        className={classNames(
          'bg-white p-5 rounded-lg shadow-md max-h-[90%] overflow-y-auto relative text-primary-900  scrollbar-thin',
          className,
        )}
        ref={ref}
      >
        <div className='flex flex-col'>
          {
            <div
              className='w-full cursor-pointer inline-flex justify-between mb-3 items-center'
              onClick={handleOnClose}
            >
              <span className={classNames('text-lg font-semibold', titleClass)}>{title}</span>
              <div className='text-gray-400 mr-2'>
                <XMarkIcon className='w-5 h-5' />
              </div>
            </div>
          }
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
