import Checkbox from '../../lib-components/checkbox/Checkbox';
import { FieldSelectorProps } from './fieldSelector.types';

const FieldSelector = ({ seriesData, onFieldToggle }: FieldSelectorProps) => {
  return (
    <div className='w-full px-2 flex flex-row flex-wrap justify-items-end gap-6'>
      {seriesData?.length > 0
        ? seriesData.map((serie) => (
            <Checkbox
              key={serie.name}
              checked={!!serie.visible}
              label={serie.name}
              onToggle={(isChecked) => onFieldToggle(isChecked, serie.name)}
            />
          ))
        : null}
    </div>
  );
};

export default FieldSelector;
