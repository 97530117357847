import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../axiosBase';
import {
  CreateTemplateRequest,
  GetTemplateDataParams,
  Template,
  TemplateDataResponse,
  UpdateTemplateRequest,
} from './template.type';
import { ApiResponse } from '@app/types';
import { Pagination } from '../tokenLedger/types';

export const templateApi = createApi({
  reducerPath: 'templateApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['UserTemplate', 'OrgTemplate'],
  endpoints: (builder) => ({
    createTemplate: builder.mutation<ApiResponse<Template>, CreateTemplateRequest>({
      query: (data) => ({
        url: '/template/create',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['UserTemplate', 'OrgTemplate'],
    }),
    updateTemplate: builder.mutation<ApiResponse<Template>, UpdateTemplateRequest>({
      query: ({ template_id, ...data }) => ({
        url: '/template/update',
        method: 'PUT',
        params: { template_id },
        data,
      }),
      invalidatesTags: ['UserTemplate', 'OrgTemplate'],
    }),
    getUserTemplates: builder.query<
      ApiResponse<Template[]>,
      Pagination & { identifier_type: string }
    >({
      query: ({ skip, limit, identifier_type }) => ({
        url: '/template/get-all-customer-user-templates',
        method: 'GET',
        params: { skip, limit, identifier_type },
      }),
      providesTags: ['UserTemplate'],
    }),
    getOrgTemplates: builder.query<ApiResponse<Template[]>, Pagination>({
      query: ({ skip, limit }) => ({
        url: '/template/get-all-customer-company-templates',
        method: 'GET',
        params: { skip, limit },
      }),
      providesTags: ['OrgTemplate'],
    }),
    deleteTemplate: builder.mutation<ApiResponse<null>, string>({
      query: (template_id) => ({
        url: '/template/delete',
        method: 'DELETE',
        params: { template_id },
      }),
      invalidatesTags: ['UserTemplate', 'OrgTemplate'],
    }),
    getTemplateData: builder.query<ApiResponse<TemplateDataResponse>, GetTemplateDataParams>({
      query: ({ template_id, identifier_value, field_name }) => ({
        url: '/template/get-data',
        method: 'GET',
        params: { template_id, identifier_value, field_name },
      }),
    }),
  }),
});

export const {
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useGetUserTemplatesQuery,
  useGetOrgTemplatesQuery,
  useDeleteTemplateMutation,
  useLazyGetTemplateDataQuery,
} = templateApi;
