import { useState } from 'react';
import Icon from '../icon/Icon';
import { numDifferentiation } from '@components/utils/commonUtils';
import { CompanyFinancialData, LLPFinancialData, Metadata } from '@app/types/financial';
import { FigureFormat } from '@components/company/company-tabs/financials/FinancialTab';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';

interface FinancialOverviewAccordionProps {
  data: Array<{
    year: number;
    data: CompanyFinancialData | LLPFinancialData;
    metadata: Metadata;
  }>;
  lastFilingDate: string;
  figureFormat: FigureFormat;
  type: 'pnl' | 'assets' | 'liabilities';
  companyType: COMPANY_TYPE;
}

interface AccordionItemProps {
  title: string;
  value: number;
  percentageChange?: number;
  comparedValue?: number;
  figureFormat: FigureFormat;
  lastFilingDate: string;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  value,
  percentageChange,
  comparedValue,
  lastFilingDate,
  figureFormat,
}) => {
  const isPositiveChange = percentageChange !== undefined && percentageChange >= 0;
  const tooltipContent =
    comparedValue !== undefined && comparedValue !== null
      ? `Compared to FY-${new Date(lastFilingDate).getFullYear() - 1}
    FY-${new Date(lastFilingDate).getFullYear() - 1}: ${numDifferentiation(comparedValue, figureFormat)}`
      : '';

  const renderPercentageChange = () => {
    if (percentageChange === undefined || percentageChange === null) return null;
    return (
      <div
        className={`flex items-center text-sm ${isPositiveChange ? 'text-green-500' : 'text-red-500'}`}
      >
        <Icon icon={isPositiveChange ? 'GainIcon' : 'LossIcon'} className='mr-1' />
        {Math.abs(percentageChange).toFixed(1)}%
      </div>
    );
  };

  const content = (
    <div className='p-3 py-4 rounded-xl min-w-[200px] w-max m-2 border border-gray-200'>
      <div className='flex justify-between items-center mb-2'>
        <span className='text-slate-600 font-semibold text-sm'>{title}</span>
      </div>
      <div className='flex justify-between w-60'>
        <div className='text-lg font-semibold'>
          {value !== null && value !== undefined ? numDifferentiation(value, figureFormat) : '-'}
        </div>
        {renderPercentageChange()}
      </div>
    </div>
  );

  return comparedValue !== undefined && comparedValue !== null ? (
    <Tooltip content={tooltipContent} className='whitespace-pre-line !text-xs !p-3 !rounded-md'>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

const calculatePercentageChange = (
  current: number | null | undefined,
  previous: number | null | undefined,
): number | undefined => {
  if (
    current === null ||
    current === undefined ||
    previous === null ||
    previous === undefined ||
    previous === 0
  ) {
    return undefined;
  }
  return ((current - previous) / previous) * 100;
};

const getCompanyOverviewData = (
  currentYear: CompanyFinancialData,
  previousYear: CompanyFinancialData | undefined,
  type: 'pnl' | 'assets' | 'liabilities',
) => {
  switch (type) {
    case 'pnl':
      return {
        title: 'Profit & Loss Overview',
        items: [
          {
            title: 'Net Revenue',
            current: currentYear.profit_and_loss.line_items.net_revenue,
            previous: previousYear?.profit_and_loss.line_items.net_revenue,
          },
          {
            title: 'Operating Profit',
            current: currentYear.profit_and_loss.line_items.operating_profit,
            previous: previousYear?.profit_and_loss.line_items.operating_profit,
          },
          {
            title: 'Profit Before Tax',
            current: currentYear.profit_and_loss.line_items.profit_before_tax,
            previous: previousYear?.profit_and_loss.line_items.profit_before_tax,
          },
          {
            title: 'Profit After Tax',
            current: currentYear.profit_and_loss.line_items.profit_after_tax,
            previous: previousYear?.profit_and_loss.line_items.profit_after_tax,
          },
        ],
      };
    case 'assets':
      return {
        title: 'Assets Overview',
        items: [
          {
            title: 'Total Assets',
            current: currentYear.balance_sheet.subTotals.given_assets_total,
            previous: previousYear?.balance_sheet.subTotals.given_assets_total,
          },
          {
            title: 'Current Investments',
            current: currentYear.balance_sheet.assets.current_investments,
            previous: previousYear?.balance_sheet.assets.current_investments,
          },
          {
            title: 'Tangible Assets',
            current: currentYear.balance_sheet.assets.tangible_assets,
            previous: previousYear?.balance_sheet.assets.tangible_assets,
          },
          {
            title: 'Trade Receivables',
            current: currentYear.balance_sheet.assets.trade_receivables,
            previous: previousYear?.balance_sheet.assets.trade_receivables,
          },
        ],
      };
    case 'liabilities':
      return {
        title: 'Liabilities Overview',
        items: [
          {
            title: 'Share Capital',
            current: currentYear.balance_sheet.liabilities.share_capital,
            previous: previousYear?.balance_sheet.liabilities.share_capital,
          },
          {
            title: 'Reserves',
            current: currentYear.balance_sheet.liabilities.reserves_and_surplus,
            previous: previousYear?.balance_sheet.liabilities.reserves_and_surplus,
          },
          {
            title: 'Short-term Borrowing',
            current: currentYear.balance_sheet.liabilities.short_term_borrowings,
            previous: previousYear?.balance_sheet.liabilities.short_term_borrowings,
          },
          {
            title: 'Trade Payables',
            current: currentYear.balance_sheet.liabilities.trade_payables,
            previous: previousYear?.balance_sheet.liabilities.trade_payables,
          },
        ],
      };
  }
};

const getLLPOverviewData = (
  currentYear: LLPFinancialData,
  previousYear: LLPFinancialData | undefined,
  type: 'pnl' | 'assets' | 'liabilities',
) => {
  switch (type) {
    case 'pnl':
      return {
        title: 'Income and Expenditure Overview',
        items: [
          {
            title: 'Net Revenue',
            current: currentYear.statement_of_income_and_expenditure.line_items.net_revenue,
            previous: previousYear?.statement_of_income_and_expenditure.line_items.net_revenue,
          },
          {
            title: 'Total Income',
            current: currentYear.statement_of_income_and_expenditure.line_items.total_income,
            previous: previousYear?.statement_of_income_and_expenditure.line_items.total_income,
          },
          {
            title: 'Depreciation',
            current: currentYear.statement_of_income_and_expenditure.line_items.depreciation,
            previous: previousYear?.statement_of_income_and_expenditure.line_items.depreciation,
          },
        ],
      };
    case 'assets':
      return {
        title: 'Assets Overview',
        items: [
          {
            title: 'Total Assets',
            current: currentYear.statement_of_assets_and_liabilities.subTotals.given_assets_total,
            previous:
              previousYear?.statement_of_assets_and_liabilities.subTotals.given_assets_total,
          },
          {
            title: 'Investments',
            current: currentYear.statement_of_assets_and_liabilities.assets.investments,
            previous: previousYear?.statement_of_assets_and_liabilities.assets.investments,
          },
          {
            title: 'Gross Fixed Assets',
            current: currentYear.statement_of_assets_and_liabilities.assets.gross_fixed_assets,
            previous: previousYear?.statement_of_assets_and_liabilities.assets.gross_fixed_assets,
          },
          {
            title: 'Trade Receivables',
            current: currentYear.statement_of_assets_and_liabilities.assets.trade_receivables,
            previous: previousYear?.statement_of_assets_and_liabilities.assets.trade_receivables,
          },
        ],
      };
    case 'liabilities':
      return {
        title: 'Liabilities Overview',
        items: [
          {
            title: 'Contributions Received',
            current:
              currentYear.statement_of_assets_and_liabilities.liabilities.contributions_received,
            previous:
              previousYear?.statement_of_assets_and_liabilities.liabilities.contributions_received,
          },
          {
            title: 'Reserves and Surplus',
            current:
              currentYear.statement_of_assets_and_liabilities.liabilities.reserves_and_surplus,
            previous:
              previousYear?.statement_of_assets_and_liabilities.liabilities.reserves_and_surplus,
          },
          {
            title: 'Short Term Borrowings',
            current:
              currentYear.statement_of_assets_and_liabilities.liabilities.short_term_borrowings,
            previous:
              previousYear?.statement_of_assets_and_liabilities.liabilities.short_term_borrowings,
          },
          {
            title: 'Trade Payables',
            current: currentYear.statement_of_assets_and_liabilities.liabilities.trade_payables,
            previous: previousYear?.statement_of_assets_and_liabilities.liabilities.trade_payables,
          },
        ],
      };
  }
};

export const FinancialOverviewAccordion: React.FC<FinancialOverviewAccordionProps> = ({
  data,
  lastFilingDate,
  figureFormat,
  type,
  companyType,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleAccordion = () => setIsOpen(!isOpen);

  const currentYear = data[0]?.data;
  const previousYear = data[1]?.data;

  const overviewData =
    companyType === COMPANY_TYPE.LimitedLiabilityPartnership
      ? getLLPOverviewData(currentYear as LLPFinancialData, previousYear as LLPFinancialData, type)
      : getCompanyOverviewData(
          currentYear as CompanyFinancialData,
          previousYear as CompanyFinancialData,
          type,
        );

  if (!overviewData) return null;

  return (
    <div className='border rounded-md border-gray-200 w-full mt-3'>
      <div
        className='flex items-center py-3 px-4 cursor-pointer bg-white rounded-md'
        onClick={toggleAccordion}
      >
        <Icon
          icon={isOpen ? 'MinusCircleIcon' : 'PlusCircleIcon'}
          className='mr-3 text-gray-500'
          variant='outline'
          size={20}
        />
        <div className='flex flex-col text-start'>
          <h3 className='text-md font-semibold text-tertiary-600'>{overviewData.title}</h3>
          <div className='text-sm text-gray-500'>As per last filing - {lastFilingDate}</div>
        </div>
      </div>
      {isOpen && (
        <div className='flex m-3 mx-6 overflow-auto cursor-default'>
          {overviewData.items.map((item, index) => (
            <AccordionItem
              key={index}
              lastFilingDate={lastFilingDate}
              title={item.title}
              value={item.current ?? 0}
              percentageChange={calculatePercentageChange(item.current ?? 0, item.previous ?? 0)}
              comparedValue={item.previous ?? 0}
              figureFormat={figureFormat}
            />
          ))}
        </div>
      )}
    </div>
  );
};
