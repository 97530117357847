import '@app/index.css';
import '@components/lib-components/ShimmerMessages/ShimmerMessages.css';
import Icon from '@components/lib-components/icon/Icon';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  message?: string;
}

const Loading = ({ className, message, ...props }: LoadingProps) => {
  return (
    <div
      className={className ? className : 'flex justify-center items-center'}
      {...props}
      data-testid='loading-component'
    >
      {!message ? (
        <div className='ease-in duration-300 loading-content'>
          <div className='shimmer-container flex-col'>
            <Icon icon='AppLogoWithoutLabel' size={50} />
            <div className='shimmer-message'>
              cred<span className='font-normal'>hive</span>
            </div>
          </div>
        </div>
      ) : (
        <ErrorMessage message={message} />
      )}
    </div>
  );
};

export default Loading;
