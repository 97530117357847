import Axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
} from 'axios';
import config from '@app/config';
import { persistor, store } from '@app/store/store';
import { clearAuth } from '@app/store/slices/authSlice';

// Create API handler
export const apiHandler = Axios.create({
  baseURL: config.API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Api-Key': 'x',
  },
});

// Token refresh state
let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];

// Queue management
const addToQueue = (callback: (token: string) => void) => refreshSubscribers.push(callback);
const retryQueue = (newToken: string) => {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
};

// Logout function
export const logout = (): void => {
  store.dispatch(clearAuth());
  persistor.purge();
  localStorage.clear();
  window.location.href = '/login';
};

// Request interceptor
apiHandler.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('idToken');
    const accessToken = localStorage.getItem('accessToken');

    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (accessToken) config.headers.Authentication = `Bearer ${accessToken}`;
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

// Response interceptor
apiHandler.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      originalRequest.url &&
      !originalRequest.url.includes('auth') &&
      !originalRequest._retry
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;

        try {
          const refreshToken = localStorage.getItem('refreshToken');
          const userId = localStorage.getItem('userId');

          if (!refreshToken || !userId) {
            logout();
            return Promise.reject(error);
          }

          const { data } = await apiHandler.post('/auth/re-authenticate', {
            user_id: userId,
            refresh_token: refreshToken,
          });

          const newAccessToken = data.response_data.token_details.access_token;
          const newIdToken = data.response_data.token_details.id_token;

          localStorage.setItem('accessToken', newAccessToken);
          localStorage.setItem('idToken', newIdToken);

          apiHandler.defaults.headers.common['Authorization'] =
            `Bearer ${data.response_data.token_details.id_token}`;
          apiHandler.defaults.headers.common['Authentication'] =
            `Bearer ${data.response_data.token_details.access_token}`;

          retryQueue(data.response_data.token_details.id_token);

          return apiHandler(originalRequest);
        } catch (refreshError) {
          logout();
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else {
        // If a token refresh is already in progress, add this request to the queue
        return new Promise((resolve) => {
          // Add a callback to the queue that will be executed when a new token is available
          addToQueue((token: string) => {
            // Update the original request's headers with the new token
            originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: `Bearer ${token}`,
            };
            // Resolve the promise by retrying the original request with the new token
            resolve(apiHandler(originalRequest));
          });
        });
      }
    }

    return Promise.reject(error);
  },
);

// Global error handler
// Global error handler for unhandled promise rejections
if (typeof window !== 'undefined') {
  window.addEventListener('unhandledrejection', (event) => {
    // Check if the error is an Axios error with a 401 (Unauthorized) status
    if (event.reason instanceof AxiosError && event.reason.response?.status === 401) {
      // If we receive a 401 error that wasn't caught by our interceptors,
      // it likely means our refresh token has expired or is invalid.
      // In this case, we should log the user out for security reasons.
      logout();
    }
  });
}

// We use this global error handler for several reasons:
// 1. It acts as a safety net to catch any 401 errors that might slip through our interceptors.
// 2. It ensures consistent behavior across the application for authentication failures.
// 3. It improves security by immediately logging out the user when their session is no longer valid.
// 4. It provides a centralized place to handle authentication-related errors, making the code more maintainable.

export default apiHandler;
