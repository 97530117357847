import { useState } from 'react';
import './CreditComponent.css';
import { useSelector } from 'react-redux';
import CreditModal from './CreditModal';
import Icon from '@app/components/lib-components/icon/Icon';
import Modal from '@app/components/lib-components/modal/Modal';
import { RootState } from '@app/store/store';

const CreditComponent = () => {
  const totalCredits = useSelector((state: RootState) => state.util.totalCredits);
  const [showBuyCreditsModal, setShowBuyCreditsModal] = useState<boolean>(false);
  const [redirectionModal, setShowRedirectionModal] = useState<boolean>(false);
  return (
    <div className='mr-4'>
      <div
        className={`credit-card flex items-center !border-blue-700`}
        data-testid='credit-card'
        onClick={() => {
          setShowBuyCreditsModal(true);
        }}
      >
        <Icon icon='AppLogoWithoutLabel' size={20} className='mr-1' />
        <p className='text-blue-700'>
          <span className='font-[800] mr-1 text-blue-700'>{totalCredits}</span>Tokens
        </p>
        <Icon icon='PlusCircleIcon' size={15} className='ml-2 text-blue-700' variant='outline' />
      </div>
      {showBuyCreditsModal && (
        <CreditModal
          setShowBuyCreditsModal={setShowBuyCreditsModal}
          setShowRedirectionModal={setShowRedirectionModal}
        />
      )}
      {redirectionModal && (
        <Modal
          className='w-fit max-w-[90vw]'
          isOpen={redirectionModal}
          onClose={() => setShowRedirectionModal(false)}
        >
          Redirecting you to payment gateway...
        </Modal>
      )}
    </div>
  );
};

export default CreditComponent;
