import TabsContainer from '@app/components/lib-components/tab-menu/TabsContainer';
import Modal from '@app/components/lib-components/modal/Modal';
import CreditModal from '../credits/CreditModal';
import { CompanyContentProps } from './companyCard.types';
import { ValidTab } from '@components/lib-components/tab-menu/tabContainer.types';
import { NewReportModal } from './modals/NewReportModal';
import { useLocalNavigation } from '@app/hooks/useLocalNavigation';
import { useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

import { Corporate } from '@app/components/company/company-tabs/Corporate';
import { useActiveCompany } from '@app/hooks/useActiveCompany';
import { CorporateType } from '@app/store/slices/companySlice';

import MsmePage from '@app/components/company/msme/Index';
import { MSME_DATA } from './msme/data/msmeData';
import { MsmeCompanyData } from './msme/data/msmeTypes';
import { Company } from '@app/types/overview';
import { getCompanyTabs } from './utils/getCompanyTabs';
import { TabErrorFallback } from './company-tabs/TabErrorFallback';
import { ErrorBoundary } from '@sentry/react';

type Params = {
  id: string;
  tab?: ValidTab;
};

export const CompanyContent = ({
  companyOverview,
  status,
  reportDate,
  refreshCount,
  tokenCharges,
  freshTokenCharges,
  existingTokenCharges,
}: CompanyContentProps) => {
  const navigate = useLocalNavigation();
  const posthog = usePostHog();

  const { id, tab } = useParams<Params>();
  const [redirectionModal, setShowRedirectionModal] = useState<boolean>(false);
  const [showBuyCreditsModal, setShowBuyCreditsModal] = useState<boolean>(false);

  const { activeCorporate, activeCountry } = useActiveCompany();

  const msmeData = id ? MSME_DATA[id as keyof typeof MSME_DATA] : null;

  const availableTabs = useMemo(() => {
    return getCompanyTabs(activeCountry, activeCorporate).map((tab) => tab.id);
  }, [activeCountry, activeCorporate]);

  const isValidTab = useMemo(() => {
    if (!tab) return false;
    return availableTabs.includes(tab);
  }, [availableTabs, tab]);

  const handleTabChange = (newTab: ValidTab) => {
    posthog.capture('tab_change', { tab: newTab });
    navigate(id as string, newTab);
  };

  function handleBuyTokens(): void {
    setShowBuyCreditsModal(true);
  }

  if (!isValidTab) {
    return <Navigate to={`/${activeCountry}/${activeCorporate}/${id}/overview`} />;
  }

  return (
    <div>
      <TabsContainer
        className='col-span-full'
        selectedTab={tab as ValidTab}
        setSelectedTab={handleTabChange}
        companyOverviewData={
          activeCorporate === CorporateType.NonCorporate
            ? (msmeData as MsmeCompanyData)
            : (companyOverview as Company)
        }
      />
      <ErrorBoundary
        fallback={() => <TabErrorFallback tab={tab as ValidTab} companyCin={id as string} />}
      >
        {activeCorporate === CorporateType.Corporate && (
          <Corporate
            status={status}
            companyOverview={companyOverview as Company}
            selectedTab={tab as ValidTab}
          />
        )}
        {activeCorporate === CorporateType.NonCorporate && (
          <MsmePage msmeData={msmeData as MsmeCompanyData} />
        )}

        {showBuyCreditsModal && (
          <CreditModal
            setShowBuyCreditsModal={setShowBuyCreditsModal}
            setShowRedirectionModal={setShowRedirectionModal}
          />
        )}
        <NewReportModal
          onBuyTokens={handleBuyTokens}
          refreshCount={refreshCount}
          companyOverview={companyOverview as Company}
          reportDate={reportDate || ''}
          tokenCharges={tokenCharges}
          freshTokenCharges={freshTokenCharges}
          existingTokenCharges={existingTokenCharges}
        />
        <Modal isOpen={redirectionModal} onClose={() => setShowRedirectionModal(false)} title={''}>
          Redirecting you to payment gateway...
        </Modal>
      </ErrorBoundary>
    </div>
  );
};
