import { NavigateOptions, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { ValidTab } from '@components/lib-components/tab-menu/tabContainer.types';
import { SupportedCountry, CorporateType } from '@app/store/slices/companySlice';

export const useLocalNavigation = () => {
  const navigate = useNavigate();
  const activeCountry = useSelector(
    (activeSlices: RootState) => activeSlices.company.activeCountry,
  );

  return (
    id: string,
    tab: ValidTab,
    options?: NavigateOptions,
    // These are default values - we rarely overwrite this
    corporateType: CorporateType = id.includes('PAN')
      ? CorporateType.NonCorporate
      : CorporateType.Corporate,
    activeCountryInfo: SupportedCountry = activeCountry,
  ) => {
    navigate(`/${activeCountryInfo}/${corporateType}/${id}/${tab}`, options);
  };
};
