import React, { useState, useMemo } from 'react';
import { ApiResponse } from '@app/types';
import Table from '@components/lib-components/table/table/Table';
import { Header, Row } from '@components/lib-components/table/table/table.types';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { formatDate } from '@components/utils/commonUtils';
import { EPFOWrapper, PaymentDetail } from '@app/types/compliances';
import Accordion from '@components/lib-components/accordian/Accordian';
import Button from '@components/lib-components/button/Button';
import { CompanyDetailsSection } from './CompanyDetailsSection';
import InfoCard from '@components/lib-components/info-card/InfoCard';

interface EPFOProps {
  data: ApiResponse<EPFOWrapper> | undefined;
  isReportFetching: boolean;
}

const headers: Header[] = [
  { name: 'TRRN', value: 'trrn', classes: 'p-4 text-sm' },
  { name: 'Date of Credit', value: 'date_of_credit', classes: 'p-4 text-sm' },
  { name: 'Amount', value: 'amount', classes: 'p-4 text-sm' },
  { name: 'Wage Month', value: 'wage_month', classes: 'p-4 text-sm' },
  { name: 'Number of Employees', value: 'number_of_employee', classes: 'p-4 text-sm' },
  { name: 'ECR', value: 'ecr', classes: 'p-4 text-sm' },
];

export const EPFO: React.FC<EPFOProps> = ({ data, isReportFetching }) => {
  const [openAccordions, setOpenAccordions] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tablePages, setTablePages] = useState<{ [key: number]: number }>({});
  const itemsPerPage = 10;

  const epfoData = data?.response_data.epfo || [];

  const totalPages = useMemo(
    () => Math.ceil(epfoData.length / itemsPerPage),
    [epfoData.length, itemsPerPage],
  );

  const paginatedEPFOData = useMemo(
    () => epfoData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [epfoData, currentPage, itemsPerPage],
  );

  const createRows = (paymentDetails: PaymentDetail[]): Row[] => {
    return paymentDetails.map((item: PaymentDetail) => ({
      trrn: item.trrn,
      date_of_credit: formatDate(item.date_of_credit),
      amount: item.amount,
      wage_month: item.wage_month,
      number_of_employee: item.number_of_employee,
      ecr: item.ecr,
    }));
  };

  const toggleAccordion = (index: number) => {
    setOpenAccordions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleTablePageChange = (index: number, page: number) => {
    setTablePages((prev) => ({ ...prev, [index]: page }));
  };

  if (isReportFetching) {
    return <ErrorMessage message='Fetching EPFO data...' />;
  }

  return (
    <InfoCard className='py-1 flex flex-col gap-4 w-full my-6'>
      {epfoData.length === 0 ? (
        <ErrorMessage message='No public EPFO data available for this company.' />
      ) : (
        <>
          <div className='text-lg w-max text-heading font-semibold'>EPFO Payment Details</div>
          <Accordion title='EPFO Entries' isOpen={true} count={epfoData.length}>
            {paginatedEPFOData.map((epfo, index) => {
              const rows = createRows(epfo.payment_details);
              const status =
                epfo.company_details.working_status === 'LIVE ESTABLISHMENT'
                  ? 'Active'
                  : 'Cancelled';
              const tablePage = tablePages[index] || 1;
              const tableItemsPerPage = 10;
              const paginatedRows = rows.slice(
                (tablePage - 1) * tableItemsPerPage,
                tablePage * tableItemsPerPage,
              );

              const latestPayment = epfo.payment_details.reduce((latest, current) => {
                if (!latest) return current;
                return new Date(current.date_of_credit) > new Date(latest.date_of_credit)
                  ? current
                  : latest;
              }, epfo.payment_details[0]);

              return (
                <Accordion
                  key={index}
                  title={`EPFO Entry ${index + 1}`}
                  isOpen={openAccordions.includes(index)}
                  onToggle={() => toggleAccordion(index)}
                  status={status}
                >
                  <CompanyDetailsSection
                    details={epfo.company_details}
                    latestPayment={latestPayment}
                  />
                  <Table
                    tableDivClassname='w-full border border-gray-200 !p-0 rounded mt-4'
                    headers={headers}
                    rows={paginatedRows}
                    pagination={{
                      currentPage: tablePage,
                      maxLimit: rows.length,
                      onPageChange: (page) => handleTablePageChange(index, page),
                    }}
                  />
                </Accordion>
              );
            })}
            <div className='mt-4 flex justify-between items-center'>
              <span className='text-sm text-gray-600'>Total Entries: {epfoData.length}</span>
              <div className='flex items-center'>
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className='px-4 py-2 mr-2 rounded disabled:opacity-50'
                  text='Previous'
                />
                <span className='px-4 py-2'>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className='px-4 py-2 ml-2 rounded disabled:opacity-50'
                  text='Next'
                />
              </div>
            </div>
          </Accordion>
        </>
      )}
    </InfoCard>
  );
};
