import React from 'react';
import classNames from 'classnames';

export const RenderTabTag: React.FC<{ tab: string }> = ({ tab }) => {
  // TODO - @sreehari-credhive - SOME COLORS ARE HARCODED AND NEED TO BE MOVED TO TAILWIND CONFIG.JS file
  const getTagColor = (tabName: string): string => {
    switch (tabName) {
      case 'Management':
        return 'bg- text-[#5d7235] border-[1.4px] border-[#e2e8cb] bg-[#fbfbf8]';
      case 'Financials':
        return 'bg-utility-indigo-50 text-utility-indigo-700 border-[1.4px] utility-indigo-200';
      case 'Financial Analysis':
        return 'bg-utility-orange-50 text-utility-orange-700 border-[1.4px] border-utility-orange-200';
      case 'Charges':
        return 'bg-utility-indigo-50 text-[#005085] border-[1.4px] utility-indigo-200';
      case 'Compliances':
        return 'bg-utility-indigo-50 text-utility-indigo-700 border-[1.4px] utility-indigo-200';
      case 'Documents':
        return 'bg-[#f8f8fb] text-[#353e72] border-[1.4px] border-[#d5d8eb]';
      case 'Shareholdings':
        return 'bg-[#edfefa] text-[#068d8b] border-[1.4px] border-[#d5d8eb] border-[#aff8f0]';
      default:
        return 'bg-gray-500 text-white';
    }
  };

  return (
    <span
      className={classNames(
        'px-2',
        'py-1',
        'rounded-md',
        'text-xs',
        'font-medium',
        getTagColor(tab),
      )}
    >
      {tab}
    </span>
  );
};
