import InfoCard from '@app/components/lib-components/info-card/InfoCard';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { formatDate, capitalCalculation } from '@app/components/utils/commonUtils';
import Icon from '@app/components/lib-components/icon/Icon';
import { CompanyOverviewProps } from '@components/company/companyCard.types';
import { PreviousAddress } from '@app/components/lib-components/previous-address/PreviousAddress';

export const LLPCompanyOverview = ({ companyOverview }: CompanyOverviewProps) => {
  const isCompanyLocked = useSelector(
    (state: RootState) => state.util.reportStatus === ReportStatusEnum.LOCKED,
  );

  const renderValue = (value: string | number | undefined | null) => {
    if (value === null || value === undefined) return '-';
    return value ?? '-';
  };

  const renderFinancialValue = (value: number | undefined | null) => {
    if (value === null || value === undefined) return '-';
    return value ? `₹${capitalCalculation(value)}` : '-';
  };

  return (
    <div className='w-full h-[calc(60vh)] grid grid-cols-6 gap-4 mt-4'>
      <div className='col-span-4 grid grid-cols-2 gap-4 w-full'>
        <InfoCard className='flex flex-col font-medium justify-around h-full'>
          <div className='p-2 shadow-sm rounded-md border !border-gray-200 w-fit'>
            <Icon icon='ArrowTrendingUpIcon' />
          </div>
          <div>
            <span className='text-sm text-gray-700'>Type of Entity</span>
            <h1 className='text-lg font-bold pb-4'>
              {renderValue(companyOverview?.classification)}
            </h1>
          </div>
        </InfoCard>
        <InfoCard className='flex flex-col font-medium justify-around h-full'>
          <div className='p-2 shadow-sm rounded-md border !border-gray-200 w-fit'>
            <Icon icon='ArrowTrendingUpIcon' />
          </div>
          <div>
            <span className='text-sm text-gray-700'>Total Obligations</span>
            <h1 className='text-lg font-bold pb-4'>
              {renderFinancialValue(companyOverview?.total_obligations_of_contributions)}
            </h1>
          </div>
        </InfoCard>
        <InfoCard className='flex flex-col font-medium justify-around h-full'>
          <div className='p-2 shadow-sm rounded-md border !border-gray-200 w-fit'>
            <Icon icon='ArrowTrendingUpIcon' />
          </div>
          <div>
            <span className='text-sm text-gray-700'>
              {isCompanyLocked ? 'Sum of Charges' : 'Total Number of Partners'}
            </span>
            <h1 className='text-lg font-bold pb-4'>
              {isCompanyLocked
                ? renderFinancialValue(companyOverview?.sum_of_charges)
                : renderValue(companyOverview?.total_num_partners)}
            </h1>
          </div>
        </InfoCard>
        {!isCompanyLocked && (
          <InfoCard className='flex flex-col font-medium justify-around h-full'>
            <div className='p-2 shadow-sm rounded-md border !border-gray-200 w-fit'>
              <Icon icon='ArrowTrendingUpIcon' />
            </div>
            <div>
              <span className='text-sm text-gray-700'>Sum of Charges</span>
              <h1 className='text-lg font-bold pb-4'>
                {renderFinancialValue(companyOverview?.sum_of_charges)}
              </h1>
            </div>
          </InfoCard>
        )}
      </div>

      <InfoCard className='col-span-2 grid gap-2 text-gray-600 text-sm font-medium h-full w-full'>
        <div className='flex flex-col gap-4 my-4'>
          <div>
            <span>Date of Incorporation</span>
            <h1 className='font-semibold text-gray-800'>
              {renderValue(
                companyOverview?.incorporation_date
                  ? formatDate(companyOverview.incorporation_date)
                  : null,
              )}
            </h1>
          </div>
          {companyOverview?.business_address?.city && (
            <div>
              <span>Registrar of Company</span>
              <h1 className='font-semibold text-gray-800'>
                {renderValue(companyOverview?.business_address?.city)}
              </h1>
            </div>
          )}
          <div>
            <span>Address</span>
            <h1 className='font-semibold text-gray-800'>
              {renderValue(companyOverview?.registered_address?.full_address)}
            </h1>
          </div>
          {companyOverview?.last_agm_date && (
            <div>
              <span>Last AGM</span>
              <h1 className='font-semibold text-gray-800'>
                {renderValue(
                  companyOverview?.last_agm_date ? formatDate(companyOverview.last_agm_date) : null,
                )}
              </h1>
            </div>
          )}
          {companyOverview?.email && (
            <div>
              <span>Email</span>
              <h1 className='font-semibold text-gray-800'>
                {renderValue(companyOverview?.email.toLowerCase())}
              </h1>
            </div>
          )}
          {companyOverview?.cirp_status && (
            <div>
              <span>CIRP Status</span>
              <h1 className='font-semibold text-gray-800'>
                {renderValue(companyOverview.cirp_status)}
              </h1>
            </div>
          )}
          {companyOverview?.previous_address && (
            <PreviousAddress addresses={companyOverview.previous_address} />
          )}
          {companyOverview?.next_cin && (
            <div>
              <span>Next CIN</span>
              <h1 className='font-semibold text-gray-800'>
                {renderValue(companyOverview.next_cin)}
              </h1>
            </div>
          )}
        </div>
      </InfoCard>
    </div>
  );
};
