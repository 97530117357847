import { ReactNode } from 'react';
import { Icons } from '@app/components/lib-components/icon/icon.types';

export interface ChildProp {
  children: ReactNode;
}

export interface Item {
  id: string;
  labelStyle?: string;
  text?: string;
  icon?: Icons;
  iconSize?: number;
  pathname?: string;
  onClick?: () => void;
}

export interface ApiResponse<T> {
  status: string;
  message: string;
  response_data: T;
  total?: number;
}

export interface ItemWithPath extends Item {
  pathname: string;
}

export enum IdentifierTypeEnum {
  CIN = 'CIN',
  GST = 'GST',
  UDYAM = 'UDYAM',
  PAN = 'PAN',
  LLPIN = 'LLPIN',
  LEI = 'LEI',
  EPFO = 'EPFO',
  OTHER = 'OTHER',
}

export interface PresignedUrlPayLoad {
  s3_url: string;
  is_download?: boolean;
  identifier_type: IdentifierTypeEnum;
  identifier_value: string;
}
