import { useState, useEffect } from 'react';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { InputV2 } from '@app/components/lib-components/input/InputV2';
import Button from '@app/components/lib-components/button/Button';
import Icon from '@app/components/lib-components/icon/Icon';
import OTPInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { BaseOptionType, Dropdown } from '@app/components/lib-components/drop-down';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  useForgetPassWordVerifyOtpMutation,
  useForgetPassWordSendOtpMutation,
} from '@app/store/api/authApi';
import { getMessage } from '@app/store/errorHandler';
import { toast } from 'sonner';
import { usePostHog } from 'posthog-js/react';

const schema = Yup.object({
  phoneNumber: Yup.string()
    .matches(/^(?:[7-9][0-9]{9}|[2-9][0-9]{9})$/, 'Invalid phone number')
    .required('Phone number is required'),
});

type ISchema = Yup.InferType<typeof schema>;

const ForgetPassword = () => {
  const methods = useForm<ISchema>({
    resolver: yupResolver(schema),
  });

  const posthog = usePostHog();

  const {
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = methods;

  const [sendOtp, { error: sendOtpError, isLoading: isOtpSending }] =
    useForgetPassWordSendOtpMutation();
  const [verifyOtp, { isLoading: isOtpVerifying }] = useForgetPassWordVerifyOtpMutation();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const phoneValue = watch('phoneNumber');
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState({ value: '+91', label: '+91 (India)' });

  const handleOtpResponse = () => {
    setIsOtpSent(true);
    setIsTimerActive(true);
    setTimer(30);
  };

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;

    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsTimerActive(false);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isTimerActive, timer]);

  const handleSendOtp: SubmitHandler<ISchema> = async (data) => {
    try {
      const phoneNumber = `${countryCode.value}${data.phoneNumber}`;
      sessionStorage.setItem('phone_number', phoneNumber);
      await sendOtp(phoneNumber).unwrap();
      handleOtpResponse();
    } catch (error) {
      posthog.capture('error_sending_otp', {
        error,
      });
    }
  };

  const handleVerifyOtp = async () => {
    if (otp.length === 6) {
      try {
        const payload = {
          phone_number: `${countryCode.value}${phoneValue}`,
          otp: otp,
        };
        const response = await verifyOtp(payload).unwrap();
        sessionStorage.setItem('otp', otp);
        sessionStorage.setItem('userId', response.user_id);
        setOtpVerified(true);
        navigate('/reset-password');
      } catch (error) {
        toast.error(getMessage(error, 'Failed to verify OTP'));
        methods.reset();
        setOtp('');
        posthog.capture('Failed to verify OTP', {
          phone_number: `${countryCode.value}${phoneValue}`,
          error,
        });
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      const phoneNumber = sessionStorage.getItem('phone_number');
      if (!phoneNumber) return;
      await sendOtp(phoneNumber).unwrap();
      handleOtpResponse();
    } catch {
      toast.error('Failed to resend OTP');
      posthog.capture('Failed to resend OTP', {
        phone_number: `${countryCode.value}${phoneValue}`,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <div className='w-full flex flex-col justify-center items-center text-black self-center basis-2/5 p-8'>
        {!isOtpSent && (
          <div className='p-8 flex flex-col w-full relative'>
            <div className='text-center m-auto'>
              <Icon icon='CompanyLogo' size={100} />
              <h4 className='text-slate-300 font-bold text-xl mt-6 mb-3'>Forgot Password</h4>
            </div>
            <div className='flex w-full gap-2 mt-4'>
              <div className='w-2/6 h-full py-1'>
                <Dropdown
                  className='w-full'
                  options={[
                    { value: '+91', label: '+91 (India)' },
                    { value: '+1', label: '+1 (US)' },
                  ]}
                  value={countryCode}
                  onChange={(data) => setCountryCode(data as BaseOptionType)}
                  isDisabled={otpVerified}
                />
              </div>
              <div className='w-4/6 relative'>
                <InputV2
                  name='phoneNumber'
                  placeholder='Phone Number'
                  className='h-full py-[0.5rem] mt-1'
                  type='number'
                  errorMessage={
                    Boolean(sendOtpError) && isValid
                      ? getMessage(sendOtpError, 'Error sending otp')
                      : undefined
                  }
                />
              </div>
            </div>
            <Button
              text='Send OTP'
              variant='base'
              className='mt-2 w-full self-end'
              onClick={handleSubmit(handleSendOtp)}
              disabled={!phoneValue || !!errors.phoneNumber}
              isLoading={isOtpSending}
            />
          </div>
        )}
        {isOtpSent && !otpVerified && (
          <div className='p-8 flex flex-col w-full'>
            <div className='text-center m-auto'>
              <Icon icon='CompanyLogo' size={100} />
              <h4 className='text-slate-300 font-bold text-xl mt-6 mb-3'>Enter OTP</h4>
            </div>
            <section className='flex w-full flex-col gap-3 p-2 mt-3 items-center'>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      width: '3rem',
                      height: '3rem',
                      fontSize: '1.5rem',
                      borderRadius: '0.375rem',
                      border: '1px solid #d1d5db',
                      textAlign: 'center',
                    }}
                  />
                )}
                containerStyle='otp-container flex gap-2'
                shouldAutoFocus
              />
              {isTimerActive ? (
                <p className='text-xs text-gray-600 mt-0 ml-auto'>Resend OTP in {timer} seconds</p>
              ) : (
                <p
                  className='text-xs text-brand_color mt-0 ml-auto cursor-pointer text-end'
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </p>
              )}
              <Button
                text={isOtpVerifying ? 'Verifying OTP' : 'Verify OTP'}
                variant='base'
                className='mt-4 w-full'
                onClick={handleVerifyOtp}
                disabled={otp.length !== 6}
                isLoading={isOtpVerifying}
              />
            </section>
          </div>
        )}
      </div>
    </FormProvider>
  );
};

export default ForgetPassword;
