import { Outlet } from 'react-router-dom';
import { HomeLayout } from '@app/layout';
import { Sidebar } from './components/Sidebar';
import { useGetTokenBalanceQuery } from '@app/store/api/tokenLedger';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';

export const MainLayout = () => {
  const userInfo = useSelector((state: RootState) => state.util.userDetails);

  // Update Token Balance On Layout Mount
  useGetTokenBalanceQuery(userInfo?.company_id as string, {
    skip: !userInfo?.company_id,
  });

  return (
    <div className='flex'>
      <Sidebar className='max-h-screen' />
      <main>
        <HomeLayout>
          <Outlet />
        </HomeLayout>
      </main>
    </div>
  );
};
