import { useReportIssueMutation } from '@app/store/api/companyApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface NotFoundProps {
  lastValidUrl: string;
}

const NotFound: React.FC<NotFoundProps> = ({ lastValidUrl }) => {
  const [previousUrl, setPreviousUrl] = useState<string>('');
  const [isIssueReported, setIsIssueReported] = useState(false); // Track whether the issue has been reported
  const navigate = useNavigate();
  const [reportIssue, { isLoading, isError }] = useReportIssueMutation();

  const handleGoHome = () => {
    navigate('/');
  };

  const handleReportIssue = () => {
    if (previousUrl && !isIssueReported) {
      reportIssue({ issue: 'Link not found', description: previousUrl })
        .unwrap()
        .then(() => {
          setIsIssueReported(true);
          setTimeout(() => {
            navigate('/');
          }, 1000);
        })
        .catch((error) => {
          console.error('Failed to report issue:', error);
        });
    }
  };

  useEffect(() => {
    if (lastValidUrl) {
      setPreviousUrl(lastValidUrl);
    }
  }, [lastValidUrl]);

  return (
    <div className='flex flex-col items-center justify-center h-screen bg-white'>
      <h1 className='bg-gradient-to-t from-indigo-500 via-blue-300 to-white text-transparent bg-clip-text text-[12rem] font-extrabold'>
        404
      </h1>
      <p className='text-xl text-gray-700 font-bold mt-0'>
        The page you are looking for doesn’t exist or has been moved.
      </p>
      <p
        className='text-lg
       text-gray-700 font-bold mt-0'
      >
        Please go back to the homepage by clicking the below button
      </p>
      <button
        onClick={handleGoHome}
        className='mt-6 px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-600 focus:outline-none'
      >
        Go to Home
      </button>

      {!isIssueReported ? (
        <button
          onClick={handleReportIssue}
          className='mt-4 px-4 py-2 bg-transparent text-blue-600 text-xs border-0 rounded-md hover:bg-blue-100 focus:outline-none'
          disabled={isLoading}
        >
          {isLoading ? 'Reporting...' : 'Report Issue'}
        </button>
      ) : (
        <p className='mt-4 text-green-600'>Issue reported successfully.</p>
      )}

      {isError && (
        <p className='mt-2 text-red-600'>Failed to report the issue. Please try again later.</p>
      )}
    </div>
  );
};

export default NotFound;
