import React, { useState, useMemo } from 'react';
import { ApiResponse } from '@app/types';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import Accordion from '@components/lib-components/accordian/Accordian';
import { GSTDetailsWrapper, GSTDetail } from '@app/types/compliances';
import GSTEntry from './GSTEntry';
import Button from '@components/lib-components/button/Button';
import InfoCard from '@components/lib-components/info-card/InfoCard';

interface GSTDetailsProps {
  data: ApiResponse<GSTDetailsWrapper> | undefined;
  isReportFetching: boolean;
}

type FilterType = 'all' | 'active' | 'cancelled';

export const GSTDetails: React.FC<GSTDetailsProps> = ({ data, isReportFetching }) => {
  const gstData = data?.response_data.gst_details;
  const [isOpen, setIsOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [openAccordions, setOpenAccordions] = useState<number[]>([]);
  const [filter, setFilter] = useState<FilterType>('active');
  const itemsPerPage = 10;

  const toggleAccordion = () => setIsOpen(!isOpen);

  const toggleChildAccordion = (index: number) => {
    setOpenAccordions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setOpenAccordions([]);
  };

  const filteredGSTData = useMemo(() => {
    if (!gstData) return [];
    return gstData.filter((gst) => {
      if (filter === 'all') return true;
      return filter === 'active' ? gst.status === 'ACTIVE' : gst.status !== 'ACTIVE';
    });
  }, [gstData, filter]);

  const totalPages = useMemo(
    () => Math.ceil(filteredGSTData.length / itemsPerPage),
    [filteredGSTData.length, itemsPerPage],
  );

  const paginatedGSTData = useMemo(
    () => filteredGSTData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [filteredGSTData, currentPage, itemsPerPage],
  );

  if (isReportFetching) {
    return <ErrorMessage message='Fetching GST details...' />;
  }

  return (
    <InfoCard className='py-0 flex flex-col gap-4 w-full my-6'>
      {gstData && gstData.length > 0 ? (
        <>
          <div className='flex justify-between'>
            <div className='text-lg w-max text-heading font-semibold'>GST Details</div>
            <div className='flex justify-end mb-4'>
              {(['all', 'active', 'cancelled'] as FilterType[]).map((filterType) => (
                <Button
                  key={filterType}
                  onClick={() => {
                    setFilter(filterType);
                    setCurrentPage(1);
                    setOpenAccordions([]);
                  }}
                  className={`px-4 py-2 mr-2 rounded ${
                    filter === filterType ? '!bg-blue-600 text-white' : 'bg-gray-400'
                  }`}
                  text={filterType.charAt(0).toUpperCase() + filterType.slice(1)}
                />
              ))}
            </div>
          </div>
          <Accordion
            title='GST Entries'
            isOpen={isOpen}
            onToggle={toggleAccordion}
            count={filteredGSTData.length}
          >
            {paginatedGSTData.map((gst: GSTDetail, index: number) => (
              <Accordion
                key={index}
                title={`GSTIN: ${gst.gstin}`}
                isOpen={openAccordions.includes(index)}
                onToggle={() => toggleChildAccordion(index)}
                status={gst.status === 'ACTIVE' ? 'Active' : 'Cancelled'}
              >
                <GSTEntry gstDetail={gst} />
              </Accordion>
            ))}
            <div className='mt-4 flex justify-between items-center'>
              <span className='text-sm text-gray-600'>Total Entries: {filteredGSTData.length}</span>
              {paginatedGSTData.length > 0 && (
                <div className='flex items-center'>
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className='!bg-blue-600 px-4 py-2 mr-2 rounded disabled:opacity-50'
                    text='Previous'
                  />
                  <span className='px-4 py-2'>
                    Page {currentPage} of {totalPages}
                  </span>
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className='!bg-blue-600 px-4 py-2 ml-2 rounded disabled:opacity-50'
                    text='Next'
                  />
                </div>
              )}
            </div>
          </Accordion>
        </>
      ) : (
        <ErrorMessage message='No public GST info exists for this company.' />
      )}
    </InfoCard>
  );
};
