import './NoResultsMessage.css';
import Icon from '@components/lib-components/icon/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { toggleRequestCompanyModal } from '@app/store/slices/utilSlice';
import { RootState } from '@app/store/store';

interface NoResultsMessageProps {
  message: string;
}

export const NoResultsMessage: React.FC<NoResultsMessageProps> = ({ message }) => {
  const dispatch = useDispatch();
  const { isBlur } = useSelector((state: RootState) => state.auth);

  if (!isBlur) return null;

  return (
    <div className='p-2 justify-start text-sm text-gray-500 flex no-results-message bg-white mt-1 w-full rounded-md flex-col gap-2 shadow-md'>
      <span className='font-medium text-black' data-testid='no-results-message'>
        {message}
      </span>
      <span
        className='text-brand_color font-semibold inline-flex gap-2 cursor-pointer'
        onClick={() => dispatch(toggleRequestCompanyModal(true))}
      >
        Request a company
        <Icon icon='ArrowLongRightIcon' size={15} className='self-center' />
      </span>
    </div>
  );
};
