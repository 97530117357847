import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import posthog from 'posthog-js';
import { PostHogPageviewTracker } from '@components/analytics';
import { PostHogProvider } from 'posthog-js/react';
import * as Sentry from '@sentry/react';
import config from './config';
import { Toaster } from 'sonner';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

// Dont initialize posthog in local development
if (window.location.hostname !== 'localhost') {
  posthog.init(config.POSTHOG_KEY, {
    api_host: config.POSTHOG_HOST,
    person_profiles: 'identified_only',
    capture_pageview: false,
  });

  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    environment: config.ENVIRONMENT,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/credhive\.in\/api/],
    profilesSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <PostHogProvider client={posthog}>
    <BrowserRouter>
      <Toaster />
      <PostHogPageviewTracker />
      <App />
    </BrowserRouter>
  </PostHogProvider>,
);
