import type React from 'react';
import { useFormContext } from 'react-hook-form';
import Icon from '../icon/Icon';
import classNames from 'classnames';
import type { InputV2Props } from './inputV2.props';

export const InputV2: React.FC<InputV2Props> = ({
  name,
  type = 'text',
  prefixIcon,
  label,
  suffixIcon,
  suffixText,
  labelStyle,
  prefixIconHandler,
  prefixIconSize = 20,
  suffixIconSize = 20,
  suffixIconHandler,
  prefixIconStyle,
  suffixIconStyle,
  className,
  errorMessageStyle,
  disabled,
  errorMessage,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = (errors[name]?.message as string) || errorMessage;

  const inputClasses = classNames(
    'w-full border border-primary rounded-md focus:outline-none px-2 bg-white',
    error ? 'border-red-500' : 'ring-blue-500 focus:ring-1',
    prefixIcon && 'pl-8',
    suffixIcon && 'pr-10',
    className,
    disabled && 'text-slate-500 bg-neutral-100 focus:ring-0',
  );

  const inputStyles = {
    paddingLeft: prefixIcon ? '2.5rem' : '0.5rem',
    paddingRight: suffixIcon ? '2.5rem' : '0.5rem',
  };

  return (
    <div className='relative w-full mb-6'>
      {label && (
        <label
          className={classNames('block text-gray-700 mb-2 truncate', labelStyle)}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className='relative'>
        {prefixIcon && (
          <div className='absolute inset-y-0 left-0 flex items-center pl-3'>
            <Icon
              icon={prefixIcon}
              size={prefixIconSize}
              onClick={prefixIconHandler}
              className={classNames('hover:cursor-pointer', prefixIconStyle)}
            />
          </div>
        )}

        {type === 'textarea' ? (
          <textarea
            id={name}
            {...register(name)}
            {...(rest as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
            disabled={disabled}
            className={classNames(inputClasses, 'py-2 min-h-[120px] resize-none')}
            style={{
              ...inputStyles,
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          />
        ) : (
          <input
            id={name}
            type={type}
            {...register(name)}
            {...(rest as React.InputHTMLAttributes<HTMLInputElement>)}
            disabled={disabled}
            className={classNames(inputClasses, 'py-2')}
            style={inputStyles}
          />
        )}

        {suffixIcon && suffixText && (
          <div
            className='absolute inset-y-0 right-0 flex cursor-pointer'
            onClick={suffixIconHandler}
          >
            <span
              className={classNames(
                'text-sm text-gray-700 flex items-center gap-2 border rounded-r-md px-1 py-1 pointer',
                suffixIconStyle,
              )}
            >
              <Icon
                icon={suffixIcon}
                size={suffixIconSize}
                onClick={suffixIconHandler}
                className='hover:cursor-pointer'
              />
              <span>{suffixText}</span>
            </span>
          </div>
        )}
        {suffixIcon && !suffixText && (
          <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
            <Icon
              icon={suffixIcon}
              size={suffixIconSize}
              onClick={suffixIconHandler}
              className={classNames('hover:cursor-pointer', suffixIconStyle)}
            />
          </div>
        )}
        {error && (
          <p className={classNames('absolute left-0 text-red-500 text-xs mt-1', errorMessageStyle)}>
            {error}
          </p>
        )}
      </div>
    </div>
  );
};
