import { useParams } from 'react-router-dom';
import { BreadCrumbs } from '@app/components/lib-components/breadcrumbs/BreadCrumbs';
import { Tooltip } from '@app/components/Tooltip/Tooltip';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { Chip } from './Chip';
import { CompanyContent } from '@components/company/CompanyContent';
import { Navigate } from 'react-router-dom';
import { ApiResponse, IdentifierTypeEnum } from '@app/types';
import { openWebsite } from '@app/components/utils/commonUtils';
import { useActiveCompany } from '@app/hooks/useActiveCompany';
import { constructUrl } from '@app/utils/constructUrl';
import moment from 'moment';
import StatusBox from '@app/components/lib-components/status-box/StatusBox';
import Loading from '@app/components/lib-components/loading/Loading';
import Icon from '@app/components/lib-components/icon/Icon';
import classNames from 'classnames';
import { useState } from 'react';
import { LastUpdatedChip } from './components/LastUpdatedChip';
import { ReportStatus } from './components/ReportStatus';
import { Company, OverviewResponseType } from '@app/types/overview';
import { MsmeCompanyData } from '@components/company/msme/data/msmeTypes';

const CompanyInfo: React.FC = () => {
  const { id: cinId } = useParams<{ id: string; tab: string }>();

  const isReportFetching = useSelector((state: RootState) => state.util.isReportFetching);
  const reportStatus = useSelector((state: RootState) => state.util.reportStatus);

  const getCompanyOverviewData = useGetCompanyData<ApiResponse<OverviewResponseType>>();

  const { activeCorporate, activeCountry } = useActiveCompany();

  const [isReloadingOverview, setIsReloadingOverview] = useState(false);

  const {
    data: overViewData,
    isLoading: isOverviewLoading,
    isError: isOverviewError,
    refetch: refetchCompanyOverview,
  } = getCompanyOverviewData(
    {
      cinId: cinId as string,
      field_name: 'company',
      identifierType: cinId?.startsWith('A') ? IdentifierTypeEnum.LLPIN : IdentifierTypeEnum.CIN,
      force_download: isReloadingOverview,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (isOverviewLoading) {
    return (
      <div className='text-lg text-semibold'>
        <Loading className='h-screen flex justify-center items-center' />
      </div>
    );
  }

  overViewData.response_data.company;

  if (isOverviewError) {
    return <Navigate to='/404' />;
  }

  const formatDate = (dateString: string) => moment(dateString).format('DD MMMM YYYY');

  const isCorporateCompany = (company: Company | MsmeCompanyData): company is Company => {
    return 'llpin' in company || 'cin' in company;
  };

  // const isMsmeCompanyData = (company: Company | MsmeCompanyData): company is MsmeCompanyData => {
  //   return 'msme_number' in company || 'type' in company;
  // };

  const getLEITooltipContent = (lei: Company['lei']) => {
    return `𝙉𝙪𝙢𝙗𝙚𝙧 : ${lei?.number ?? ''}
      𝙎𝙩𝙖𝙩𝙪𝙨 : ${lei?.status ?? ''}
      𝙍𝙚𝙜𝙞𝙨𝙩𝙧𝙖𝙩𝙞𝙤𝙣 𝘿𝙖𝙩𝙚 : ${lei?.registration_date ? formatDate(lei.registration_date) : ''}
      𝙇𝙖𝙨𝙩 𝙐𝙥𝙙𝙖𝙩𝙚𝙙 𝘿𝙖𝙩𝙚 : ${lei?.last_updated_date ? formatDate(lei.last_updated_date) : ''}
      𝙉𝙚𝙭𝙩 𝙍𝙚𝙣𝙚𝙬𝙖𝙡 𝘿𝙖𝙩𝙚 : ${lei?.next_renewal_date ? formatDate(lei.next_renewal_date) : ''}
    `;
  };

  const companyData = overViewData?.response_data?.company;

  return (
    <div
      className={classNames('flex flex-col gap-1 pb-4 mt-14 border-gray-300 h-[calc(100vh-10rem)]')}
    >
      <BreadCrumbs
        pageHistory={[
          { id: 'dashboard', text: 'Dashboard', pathname: '/' },
          {
            id: 'companyPage',
            text: companyData?.legal_name ?? 'Company',
            pathname: constructUrl(
              '/',
              activeCountry,
              activeCorporate,
              cinId as string,
              'overview',
            ),
          },
        ]}
      />
      <div className='flex gap-4 items-center justify-between'>
        <div className='inline-flex gap-2 items-center'>
          <h1 className='text-2xl font-semibold'>{companyData?.legal_name ?? ''}</h1>
          <StatusBox
            statusText={companyData?.efiling_status}
            statusColor={companyData?.efiling_status === 'Active' ? 'bg-green-500' : 'bg-red-500'}
          />
        </div>
        <div className='hidden md:flex md:flex-col md:items-center md:justify-between md:gap-2 md:mt-auto'>
          <ReportStatus
            reportStatus={reportStatus}
            isReportFetching={isReportFetching}
            reportDate={overViewData?.response_data?.report_date}
            refreshCount={overViewData?.response_data?.refresh_count}
            refreshAvailable={overViewData?.response_data?.refresh_available}
          />
        </div>
      </div>
      <div className='w-full flex justify-between items-start mb-3'>
        <div className='flex flex-col gap-2'>
          <div className='flex gap-2 whitespace-nowrap'>
            {companyData?.cin && (
              <Chip
                name={`CIN: ${companyData.cin}`}
                borderColor='border-gray-200'
                bgColor='bg-white'
                textColor='text-gray-700'
              />
            )}
            {companyData?.llpin && (
              <Chip
                name={`LLPIN: ${companyData.llpin}`}
                borderColor='border-gray-200'
                bgColor='bg-white'
                textColor='text-gray-700'
              />
            )}
            {companyData?.pan && (
              <Chip
                name={`PAN: ${companyData.pan}`}
                borderColor='border-gray-200'
                bgColor='bg-white'
                textColor='text-gray-700'
              />
            )}
            {/* {isMsmeCompanyData(companyData) && companyData.msme_number && (
              <Chip
                name={`MSME No.: ${companyData.msme_number}`}
                borderColor='border-green-100'
                bgColor='bg-green-50'
                textColor='text-green-700'
              />
            )}
            {isMsmeCompanyData(companyData) && companyData.tan && (
              <Chip name={`TAN: ${companyData.tan}`} bgColor='transparent' />
            )} */}
            {companyData?.lei?.number ? (
              <Tooltip
                place='right'
                className='whitespace-pre-line pt-2'
                content={getLEITooltipContent(companyData.lei)}
              >
                <Chip
                  name={`LEI: ${companyData.lei.number}`}
                  borderColor='border-blue-100'
                  bgColor='bg-blue-50'
                  textColor='text-blue-700'
                  className='!cursor-pointer'
                />
              </Tooltip>
            ) : (
              <Chip
                name='LEI: N/A'
                borderColor='border-gray-200'
                bgColor='bg-white'
                textColor='text-gray-700'
              />
            )}
          </div>
          <div className='flex gap-3 w-full'>
            <Chip name={companyData?.classification} className='!bg-transparent ' />
            {companyData?.duns && (
              <Chip
                name={`DUNS: ${companyData.duns}`}
                borderColor='border-gray-200'
                bgColor='bg-white'
                textColor='text-gray-700'
              />
            )}
            {companyData?.registered_address?.city && (
              <div className='border rounded-lg gap-2 flex justify-around items-center px-3'>
                <Icon icon='MapPinIcon' size={15} className='text-placeholder' />
                <span className='text-placeholder font-semibold text-xs'>
                  {companyData.registered_address.city}
                  {companyData.registered_address.state &&
                    `, ${companyData.registered_address.state}`}
                </span>
              </div>
            )}
            {companyData?.website && (
              <div
                onClick={() => openWebsite(companyData.website ?? '')}
                className='border border-secondary gap-2 !rounded-lg flex justify-around items-center p-2 cursor-pointer'
              >
                <Icon icon='GlobeAltIcon' size={15} className='text-placeholder' />
                <span className='text-utility-gray-600 font-semibold text-xs'>
                  {companyData.website}
                </span>
                <Icon
                  icon='ChevronRightIcon'
                  size={15}
                  className='text-utility-gray-600'
                  color='text-utility-gray-600 '
                />
              </div>
            )}
            <div className='flex gap-2 items-center'>
              {isCorporateCompany(companyData) && companyData.last_filing_date && (
                <Chip
                  className='!hover:cursor-pointer !cursor-pointer'
                  name={`Last Financial Reporting Date: ${formatDate(companyData.last_filing_date)}`}
                />
              )}
              {isCorporateCompany(companyData) && companyData.last_financial_reporting_date && (
                <Chip
                  className='!hover:cursor-pointer !cursor-pointer'
                  name={`Last Financial Reporting Date: ${formatDate(
                    companyData.last_financial_reporting_date,
                  )}`}
                />
              )}
            </div>
          </div>
          <div className='flex gap-2 items-center'>
            {companyData?.last_checked_date && (
              <span className='text-xs text-gray-500'>
                {`As per last Govt. Records: ${formatDate(companyData.last_checked_date)}`}
              </span>
            )}
            <div
              className='flex items-center gap-2 text-sm border px-2 py-1 rounded-md hover:cursor-pointer hover:opacity-80 bg-brand_color !text-white'
              onClick={() => {
                const icon = document.querySelector('.rotate-icon');
                icon?.classList.add('animate-spin');
                setTimeout(() => {
                  icon?.classList.remove('animate-spin');
                }, 1000);
                setIsReloadingOverview(true);
                isReloadingOverview && refetchCompanyOverview();
              }}
            >
              <span className='text-xs flex'>
                {isCorporateCompany(companyData) &&
                  (companyData.last_filing_date || companyData.last_financial_reporting_date) && (
                    <Icon
                      icon='ArrowPathIcon'
                      variant='outline'
                      size={15}
                      className='text-white-600 hover:text-blue-700 rotate-icon cursor-pointer transition-colors mx-1'
                    />
                  )}
                Click here to check latest financial reporting date
              </span>
            </div>
          </div>
        </div>
        <div className='hidden lg:flex lg:flex-col lg:md:flex-row lg:md:items-center lg:md:justify-between lg:gap-2 lg:mt-auto'>
          <LastUpdatedChip
            reportStatus={reportStatus}
            reportDate={overViewData?.response_data?.report_date}
          />
        </div>
      </div>
      <div className='flex flex-col md:hidden md:flex-row md:items-center md:justify-between gap-2 mt-auto max-w-fit'>
        <LastUpdatedChip
          reportStatus={reportStatus}
          reportDate={overViewData?.response_data?.report_date}
        />
        <ReportStatus
          reportStatus={reportStatus}
          isReportFetching={isReportFetching}
          reportDate={overViewData?.response_data?.report_date}
          refreshCount={overViewData?.response_data?.refresh_count}
          refreshAvailable={overViewData?.response_data?.refresh_available}
        />
      </div>
      <hr />
      {overViewData && (
        <CompanyContent
          refreshCount={overViewData.response_data?.refresh_count ?? 0}
          status={reportStatus}
          companyOverview={overViewData.response_data?.company}
          reportDate={overViewData.response_data?.report_date}
          tokenCharges={overViewData.response_data?.token_charges}
          freshTokenCharges={overViewData.response_data?.fresh_token_charges}
          existingTokenCharges={overViewData.response_data?.existing_token_charges}
        />
      )}
    </div>
  );
};

export default CompanyInfo;
