import { ValidTab } from '@components/lib-components/tab-menu/tabContainer.types';
import Fuse from 'fuse.js';

export const SPOTLIGHT_SEARCH_MAPPINGS = {
  management: {
    title: 'Management',
    fields: ['Director network', 'Current directors', 'Former directors'],
    route: 'management',
  },
  shareholdings: {
    title: 'Shareholdings',
    fields: [
      'Shareholding Pattern',
      'Top Holdings',
      'Directors Shareholdings',
      'Other Shareholdings',
      'Pattern Summary',
    ],
    route: 'shareholdings',
  },
  financials: {
    title: 'Financials',
    fields: [
      'Balance Sheet',
      'Auditor Information',
      'Detailed Financial Data',
      'Profit & Loss',
      'Revenue Breakup',
      'Assets',
      'Liabilities',
    ],
    route: 'financial',
  },
  financialAnalysis: {
    title: 'Financial Analysis',
    fields: ['Financial Analysis'],
    route: 'inancial-analysis',
  },
  charges: {
    title: 'Charges',
    fields: ['Charges'],
    route: 'charges',
  },
  compliances: {
    title: 'Compliances',
    fields: ['Licenses', 'Legal History'],
    route: 'documents',
  },
  documents: {
    title: 'Documents',
    fields: ['Financial Statements', 'Incorporation Documents'],
    route: 'documents',
  },
  creditRating: {
    title: 'Credit Rating',
    fields: ['Credit Rating', 'Brickwork', 'Crisil', 'ICRA', 'CARE', 'India', 'Infomerics'],
    route: 'credit',
  },
};

const searchData = Object.entries(SPOTLIGHT_SEARCH_MAPPINGS).flatMap(([key, value]) =>
  [value.title, ...value.fields].map((field) => ({
    key,
    field,
    route: value.route,
  })),
);

const fuse = new Fuse(searchData, {
  keys: ['field'],
  threshold: 0.3,
  ignoreLocation: true,
  minMatchCharLength: 2,
});

export function searchAndGetRoute(keyword: string): ISpotlightSearchResult[] | null {
  const results = fuse.search(keyword);
  // picking only unique results
  if (results.length > 0) {
    const uniqueResults = new Map();
    results.forEach((result) => {
      if (!uniqueResults.has(result.item.field)) {
        uniqueResults.set(result.item.field, {
          ...result.item,
          title:
            SPOTLIGHT_SEARCH_MAPPINGS[result.item.key as keyof typeof SPOTLIGHT_SEARCH_MAPPINGS]
              .title,
        });
      }
    });
    return Array.from(uniqueResults.values()) as ISpotlightSearchResult[];
  }
  return null;
}

export interface ISpotlightSearchResult {
  key: string;
  field: string;
  route: ValidTab;
  title: string;
}
