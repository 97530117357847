import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartOptionsSerie, ChartProps } from './chart.types';
import FieldSelector from './chart-field-selector/FieldSelector';
import { useMemo } from 'react';

/**
 * For charts reference please visit : https://www.highcharts.com/demo#highcharts-demo-line-charts
 * @returns
 */
const Chart = ({ chartOptions, setChartOptions }: ChartProps) => {
  const isAnyFieldVisible = useMemo(() => {
    const chartSerie: ChartOptionsSerie[] = chartOptions?.series;
    if (chartSerie?.length > 0) {
      for (let i = 0; i < chartSerie.length; i += 1) {
        const currentSerie = chartSerie[i];
        if (currentSerie.visible) {
          return true;
        }
      }
      return false;
    }
  }, [chartOptions]);

  const onFieldToggle = (toggled: boolean, field: string) => {
    let chartOptionObject = chartOptions;
    chartOptionObject = {
      ...chartOptionObject,
      series: chartOptionObject.series.map((serie: ChartOptionsSerie) => {
        if (serie.name === field) {
          serie.visible = toggled;
        }
        return serie;
      }),
    };
    setChartOptions(chartOptionObject);
  };

  return (
    <div className='w-full h-full'>
      {isAnyFieldVisible ? (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      ) : (
        <div className='min-h-[350px] flex justify-center items-center'>Please choose a field!</div>
      )}
      {chartOptions.chart.type === 'line' && (
        <FieldSelector seriesData={chartOptions?.series ?? []} onFieldToggle={onFieldToggle} />
      )}
    </div>
  );
};

export default Chart;
