import { EPFO } from './EPFO';
import { GSTDetails } from './GSTDetails';
import OutstandingDues from './OutstandingDues';
import PrincipleActivities from './PrincipleActivities';
import { LegalHistory } from './LegalHistory';
import { useParams } from 'react-router-dom';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { ApiResponse, IdentifierTypeEnum } from '@app/types';
import {
  OutstandingDuesWrapper,
  EPFOWrapper,
  GSTDetailsWrapper,
  PrincipleBusinessActivityWrapper,
  LegalHistoryWrapper,
} from '@app/types/compliances';
import Loading from '@components/lib-components/loading/Loading';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';
import Button from '@components/lib-components/button/Button';
import classNames from 'classnames';
import { useState, useEffect } from 'react';

enum ComplianceSectionId {
  OUTSTANDING_DUES = 'outstanding-dues',
  EPFO = 'epfo',
  GST = 'gst',
  PRINCIPLE_ACTIVITIES = 'principle-activities',
  LEGAL_HISTORY = 'legal-history',
}

interface NavigationSection {
  id: ComplianceSectionId;
  label: string;
  component: React.ReactNode;
  showFor?: IdentifierTypeEnum[];
}

export function Compliances() {
  const { id } = useParams<{ id: string }>();
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const [activeSection, setActiveSection] = useState<ComplianceSectionId>(
    ComplianceSectionId.OUTSTANDING_DUES,
  );

  const getOutstandingDuesData = useGetCompanyData<ApiResponse<OutstandingDuesWrapper>>();

  const { data: outstandingDuesData, isLoading: isOutstandingDuesLoading } = getOutstandingDuesData(
    {
      cinId: id as string,
      field_name: 'outstanding_dues_msme',
      identifierType: identifierType,
    },
    {
      skip: identifierType === IdentifierTypeEnum.LLPIN,
    },
  );

  const { data: epfoData, isLoading: isEpfoLoading } = useGetCompanyData<
    ApiResponse<EPFOWrapper>
  >()({
    cinId: id as string,
    field_name: 'epfo',
    identifierType: identifierType,
  });

  const { data: gstData, isLoading: isGstLoading } = useGetCompanyData<
    ApiResponse<GSTDetailsWrapper>
  >()({
    cinId: id as string,
    field_name: 'gst_details',
    identifierType: identifierType,
  });

  const { data: principleActivitiesData, isLoading: isPrincipleActivitiesLoading } =
    useGetCompanyData<ApiResponse<PrincipleBusinessActivityWrapper>>()({
      cinId: id as string,
      field_name: 'principal_business_activities',
      identifierType: identifierType,
    });

  const { data: legalHistoryData, isLoading: isLegalHistoryLoading } = useGetCompanyData<
    ApiResponse<LegalHistoryWrapper>
  >()({
    cinId: id as string,
    field_name: 'legal_history',
    identifierType: identifierType,
  });

  const isInProgress = (status?: ReportStatusEnum) =>
    status === ReportStatusEnum.INITIALISED || status === ReportStatusEnum.IN_PROGRESS;

  const isReportFetching =
    isInProgress(outstandingDuesData?.response_data?.status) ||
    isInProgress(epfoData?.response_data?.status) ||
    isInProgress(gstData?.response_data?.status) ||
    isInProgress(principleActivitiesData?.response_data?.status) ||
    isInProgress(legalHistoryData?.response_data?.status);

  const isLoading =
    isOutstandingDuesLoading ||
    isEpfoLoading ||
    isGstLoading ||
    isPrincipleActivitiesLoading ||
    isLegalHistoryLoading;

  const navigationSections: NavigationSection[] = [
    {
      id: ComplianceSectionId.OUTSTANDING_DUES,
      label: 'Outstanding Dues',
      component: <OutstandingDues data={outstandingDuesData} isReportFetching={isReportFetching} />,
      showFor: [IdentifierTypeEnum.CIN],
    },
    {
      id: ComplianceSectionId.EPFO,
      label: 'EPFO Details',
      component: <EPFO data={epfoData} isReportFetching={isReportFetching} />,
    },
    {
      id: ComplianceSectionId.GST,
      label: 'GST Details',
      component: <GSTDetails data={gstData} isReportFetching={isReportFetching} />,
    },
    {
      id: ComplianceSectionId.PRINCIPLE_ACTIVITIES,
      label: 'Principle Activities',
      component: (
        <PrincipleActivities data={principleActivitiesData} isReportFetching={isReportFetching} />
      ),
    },
    {
      id: ComplianceSectionId.LEGAL_HISTORY,
      label: 'Legal History',
      component: <LegalHistory data={legalHistoryData} isReportFetching={isReportFetching} />,
    },
  ];

  const scrollToSection = (sectionId: ComplianceSectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      setActiveSection(sectionId);
    }
  };

  const getNavigationButtonClass = (sectionId: ComplianceSectionId) =>
    classNames('text-sm', {
      'bg-gray-200': activeSection === sectionId,
      'bg-white': activeSection !== sectionId,
    });

  useEffect(() => {
    const observerOptions = {
      outstanding: new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveSection(entry.target.id as ComplianceSectionId);
            }
          });
        },
        {
          threshold: [0, 0.5],
          rootMargin: '-80px 0px 10% 0px',
        },
      ),
      default: new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveSection(entry.target.id as ComplianceSectionId);
            }
          });
        },
        {
          threshold: [0, 0.5],
          rootMargin: '500px 0px -500px 0px',
        },
      ),
    };

    navigationSections
      .filter((section) => !section.showFor || section.showFor.includes(identifierType))
      .forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          const observer =
            section.id === ComplianceSectionId.OUTSTANDING_DUES
              ? observerOptions.outstanding
              : observerOptions.default;
          observer.observe(element);
        }
      });

    return () => {
      observerOptions.outstanding.disconnect();
      observerOptions.default.disconnect();
    };
  }, [navigationSections]);

  if (isLoading) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );
  }

  if (isReportFetching) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage message='Kindly wait a few minutes, we are fetching the company financials data for you.' />
      </div>
    );
  }

  return (
    <div>
      <div className='sticky top-0 z-10 bg-white rounded-lg shadow-sm p-4 mb-4'>
        <h3 className='text-lg font-medium mb-3'>Quick Navigation</h3>
        <div className='flex flex-wrap gap-3'>
          {navigationSections.map((section) => {
            // Skip if section should only show for specific identifier types
            if (section.showFor && !section.showFor.includes(identifierType)) {
              return null;
            }

            return (
              <Button
                key={section.id}
                variant='navigation'
                onClick={() => scrollToSection(section.id)}
                className={getNavigationButtonClass(section.id)}
              >
                {section.label}
              </Button>
            );
          })}
        </div>
      </div>

      {navigationSections.map((section) => {
        if (section.showFor && !section.showFor.includes(identifierType)) {
          return null;
        }

        return (
          <div key={section.id} id={section.id}>
            {section.component}
          </div>
        );
      })}
    </div>
  );
}
