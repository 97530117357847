export enum EnterpriseType {
  Partnership = 'Partnership',
  Proprietorship = 'Proprietorship',
}

export interface MsmeContactInfo {
  name: string;
  role: string;
  contact_no: string[];
  email: string[];
}

export interface MsmeAddress {
  address_line: string;
  city: string;
  state: string;
  pincode: string;
}

export enum CompanyRelationType {
  SisterConcernCompany = 'Sister Concern Company',
  Subsidiary = 'Subsidiary',
  Associate = 'Associate',
}

export interface CompanyEntity {
  name: string;
  country: string;
}

export interface RelatedParty extends CompanyEntity {
  type: CompanyRelationType;
}

export interface CreditRating {
  sNo: string;
  lastVerifiedDate: string;
  amount: string;
  instrumentType: string;
  rating: string;
  ratingAgency: string;
}

export interface MsmeCompanyData {
  legal_name: string;
  pan: string;
  classification: string;
  efiling_status: string;
  registered_address: MsmeAddress;
  website: string;
  last_filing_date: string;
  last_checked_date: string;
  enterprise_type: EnterpriseType;
  registration_date: string;
  employees: number;
  annual_turnover: string;
  industry: string;
  products: string[];
  services?: string[];
  nature_of_activity: string;
  contact_info: MsmeContactInfo;
  previous_names?: Array<{
    name: string;
    date?: string;
  }>;
  parent_group?: string;
  type?: string;
  msme_number?: string;
  tan?: string;
  lei?: {
    number: string;
    status?: string;
    registration_date?: string;
    last_updated_date?: string;
    next_renewal_date?: string;
  };
  competitors: CompanyEntity[];
  relatedParties: RelatedParty[];
  creditRatings?: CreditRating[];
}

export interface MsmeBusinessInformationData {
  importsFrom?: string[];
  exportsTo?: string[];
  domesticTerms?: {
    purchasing: string;
    selling: string;
  };
  internationalTerms?: {
    purchasing: string;
    selling: string;
  };
  majorCustomers?: string[];
  majorSuppliers?: string[];
}
