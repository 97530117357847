import React, { useState, useEffect } from 'react';
import { Metadata } from '@app/types/financial';
import Icon from '@app/components/lib-components/icon/Icon';
import Button from '@app/components/lib-components/button/Button';
import classNames from 'classnames';
import Modal from '@app/components/lib-components/modal/Modal';
import Chart from '@app/components/charts/Chart';
import { FinanceLineChartOptions } from '@app/components/charts/chart.data';
import {
  ShareholdingStructure,
  ShareholdingStructureResponse,
} from '../../../company/company-tabs/ShareHoldingPattern/ShareholdingProps.types';
import pdfIcon from '@app/assets/pdfIcon.svg';
import { DocumentModal } from '@components/lib-components/modal/DocumentModal';
import { setShowNudge } from '@app/store/slices/utilSlice';
import { useDispatch } from 'react-redux';
import { formatCurrencyNumber } from '@components/utils/commonUtils';

interface Props {
  data:
    | ShareholdingStructureResponse
    | {
        [year: string]: {
          data: {
            operational_ratios: {
              quick_ratio: number | null;
              current_ratio: number | null;
              interest_coverage_ratio: number | null;
              debt_to_equity_ratio: number | null;
              FCF: number;
            };
            profitability_ratios: {
              gross_margin: number | null;
              operational_profit_margin: number | null;
              net_profit_margin: number | null;
              ROCE: number | null;
              ROIC: number | null;
              ROE: number | null;
            };
          };
          metadata: Metadata;
        } | null;
      };
  isShareholdingTable?: boolean;
  tableType?: 'financial' | 'shareholding';
}

type RatioValue = number | null;

interface CategoryTotal {
  shares: number | string;
  percentage: number;
}

export type RatioData = {
  operational_ratios: Record<string, RatioValue>;
  profitability_ratios: Record<string, RatioValue>;
};

type ShareholdingTableData = Record<
  string,
  Record<string, Record<string, { shares: number | null; percentage: number | null }>>
>;

type FinancialTableData = Record<string, Record<string, Record<string, number | null>>>;

type TableDataType = ShareholdingTableData | FinancialTableData;

// Add this type guard function
const isShareholdingTableData = (data: TableDataType | null): data is ShareholdingTableData => {
  if (!data) return false;

  // Check if any value in the data has the shares and percentage properties
  return Object.values(data).some((category) => {
    if (!category || typeof category !== 'object') return false;
    return Object.values(category).some((ratios) => {
      if (!ratios || typeof ratios !== 'object') return false;
      return Object.values(ratios).some((value) => {
        if (!value || typeof value !== 'object') return false;
        return 'shares' in value && 'percentage' in value;
      });
    });
  });
};

const ExpandableTable: React.FC<Props> = ({ data, isShareholdingTable = false }) => {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({
    'Operational Ratios': true,
    'Profitability Ratios': true,
    Promoters: true,
    Public: true,
  });
  const [tableHeaders, setTableHeaders] = useState<string[]>([]);
  const [tableData, setTableData] = useState<TableDataType>(
    isShareholdingTable ? ({} as ShareholdingTableData) : ({} as FinancialTableData),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({});
  const [selectedYearData, setSelectedYearData] = useState<{
    data: RatioData;
    metadata: Metadata;
  } | null>(null);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);

  useEffect(() => {
    if (isShareholdingTable) {
      processShareholdingData(data as ShareholdingStructureResponse);
    } else {
      processFinancialRatiosData(data as Exclude<Props['data'], ShareholdingStructureResponse>);
    }
  }, [data, isShareholdingTable]);

  const processFinancialRatiosData = (
    data: Exclude<Props['data'], ShareholdingStructureResponse>,
  ) => {
    if (!data) return;

    const years = Object.entries(data)
      .filter(([, value]) => value !== null)
      .map(([year]) => year)
      .sort((a, b) => parseInt(b) - parseInt(a));

    if (years.length === 0) return;

    const headers = ['Particulars', ...years.map((year) => `FY-${year}`)];
    setTableHeaders(headers);

    const processedData: FinancialTableData = {
      'Operational Ratios': {},
      'Profitability Ratios': {},
    };

    years.forEach((year) => {
      const yearData = data[year];
      if (!yearData?.data) return;

      Object.entries(yearData.data.operational_ratios || {}).forEach(([ratio, value]) => {
        if (!processedData['Operational Ratios'][ratio]) {
          processedData['Operational Ratios'][ratio] = {};
        }
        processedData['Operational Ratios'][ratio][`FY-${year}`] = value as number | null;
      });

      Object.entries(yearData.data.profitability_ratios || {}).forEach(([ratio, value]) => {
        if (!processedData['Profitability Ratios'][ratio]) {
          processedData['Profitability Ratios'][ratio] = {};
        }
        processedData['Profitability Ratios'][ratio][`FY-${year}`] = value as number | null;
      });
    });

    setTableData(processedData);
  };

  const processShareholdingData = (shareholdingResponse: ShareholdingStructureResponse) => {
    const headers = ['Particulars', 'Equity', 'Preference'];
    setTableHeaders(headers);

    const processedData: ShareholdingTableData = {
      Promoters: {},
      Public: {},
    };

    const shareholdingData = shareholdingResponse.data;
    if (!shareholdingData) return;
    const processShares = (
      structure: ShareholdingStructure,
      category: string,
      shareType: string,
    ) => {
      Object.entries(structure).forEach(([key, value]) => {
        if (key.startsWith('num_of_shares_by_')) {
          const ratioName = key.replace('num_of_shares_by_', '');
          const percentageKey = `perc_of_shares_by_${ratioName}` as keyof ShareholdingStructure;

          if (value === 0) return;

          if (!processedData[category][ratioName]) {
            processedData[category][ratioName] = {
              Equity: { shares: null, percentage: null },
              Preference: { shares: null, percentage: null },
            };
          }

          processedData[category][ratioName][shareType] = {
            shares: value,
            percentage: structure[percentageKey] as number,
          };
        }
      });
    };

    if (shareholdingData.shareholding_structure_promoter_equity) {
      processShares(shareholdingData.shareholding_structure_promoter_equity, 'Promoters', 'Equity');
    }
    if (shareholdingData.shareholding_structure_promoter_preference) {
      processShares(
        shareholdingData.shareholding_structure_promoter_preference,
        'Promoters',
        'Preference',
      );
    }
    if (shareholdingData.shareholding_structure_public_equity) {
      processShares(shareholdingData.shareholding_structure_public_equity, 'Public', 'Equity');
    }
    if (shareholdingData.shareholding_structure_public_preference) {
      processShares(
        shareholdingData.shareholding_structure_public_preference,
        'Public',
        'Preference',
      );
    }

    Object.keys(processedData).forEach((category) => {
      Object.keys(processedData[category]).forEach((ratioName) => {
        const ratioData = processedData[category][ratioName];
        const hasNonZeroValue = Object.values(ratioData).some(
          (value) => value.shares !== null && value.shares > 0,
        );
        if (!hasNonZeroValue) {
          delete processedData[category][ratioName];
        }
      });
      if (Object.keys(processedData[category]).length === 0) {
        delete processedData[category];
      }
    });

    setTableData(processedData);
  };

  const getCategoryTotal = (category: string, header: string): CategoryTotal => {
    if (!isShareholdingTableData(tableData)) return { shares: 0, percentage: 0 };

    const categoryData = Object.values(tableData[category]).reduce(
      (acc, values) => {
        if (header.toLowerCase() === 'equity' || header.toLowerCase() === 'preference') {
          const value = values[header];
          return {
            shares: acc.shares + (value?.shares || 0),
            percentage: acc.percentage + (value?.percentage || 0),
          };
        }
        return acc;
      },
      { shares: 0, percentage: 0 },
    );

    return {
      shares: formatCurrencyNumber(categoryData.shares),
      percentage: categoryData.percentage,
    };
  };

  const handleRowToggle = (category: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const formatParticular = (particular: string): string => {
    return particular
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getDifference = (current: RatioValue, previous: RatioValue) => {
    if (current === null || previous === null) return null;
    if (previous === 0) return null;

    const diff = ((current - previous) / Math.abs(previous)) * 100;
    const arrow = diff > 0 ? '↑' : '↓';
    const color = diff > 0 ? 'text-green-500' : 'text-red-500';

    return (
      <span className={classNames('ml-4 text-xs', color)}>
        {arrow} {Math.abs(diff).toFixed(2)}%
      </span>
    );
  };

  const formatValue = (value: RatioValue): string => {
    return value !== null ? value.toFixed(2) : '0.00';
  };

  const openModal = (category: string, ratio: string) => {
    const ratioData = (tableData as FinancialTableData)[category][ratio];
    const years = Object.keys(ratioData);
    const chartSeries = years.map((year) => ({
      y: ratioData[year] as number,
      formattedValue: formatValue(ratioData[year]),
    }));

    const newChartOptions: Highcharts.Options = {
      ...FinanceLineChartOptions,
      chart: {
        ...FinanceLineChartOptions.chart,
        height: 250,
      },
      title: {
        text: `${formatParticular(ratio)} Trend`,
      },
      xAxis: {
        categories: years.reverse(),
      },
      yAxis: {
        title: {
          text: 'Value',
        },
        labels: {
          formatter: function (): string {
            return formatValue(Number(this.value));
          },
        },
      },
      tooltip: {
        formatter: function (): string {
          return `<b>${this.x}</b><br/>${this.series.name}: ${formatValue(Number(this.y))}`;
        },
      },
      series: [
        {
          type: 'spline',
          name: formatParticular(ratio),
          visible: true,
          data: chartSeries,
          dataLabels: chartSeries.reverse(),
        },
      ],
    };

    setChartOptions(newChartOptions);
    setIsModalOpen(true);
  };

  const hasNoData =
    !isShareholdingTable &&
    Object.values(data as Record<string, unknown>).every((value) => value === null);

  if (hasNoData) {
    return (
      <div className='flex items-center justify-center p-8 bg-white rounded-lg border border-gray-200'>
        <div className='flex items-center text-sm text-gray-600'>
          <Icon icon='InformationCircleIcon' size={16} className='mr-2 text-blue-500' />
          <span>Financial data not available for this company.</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='overflow-x-auto w-full border border-gray-200 rounded mt-3'>
        <table className='border-separate border-spacing-0 bg-surface-1'>
          <thead className='bg-gray-100/30 text-sm text-gray-600 font-medium'>
            <tr className='bg-surface-2 sticky top-0'>
              {tableHeaders.map((header, index) => (
                <th
                  key={index}
                  className={classNames('text-sm p-4 pl-0 text-gray-600 text-center', {
                    'sticky left-0 z-10 bg-gray-50 border-r-[0.5px]': index === 0,
                  })}
                  style={{
                    width:
                      index === 0 ? '35rem' : `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                    minWidth: '18rem',
                  }}
                >
                  <div className={classNames('flex items-center justify-center')}>
                    {header}
                    {!isShareholdingTable && index > 0 && (
                      <img
                        className='pl-0 scale-[0.5] cursor-pointer z-[1]'
                        src={pdfIcon}
                        onClick={() => {
                          const yearKey = header.replace('FY-', '');
                          const yearData = (
                            data as Record<
                              string,
                              {
                                data: RatioData;
                                metadata: Metadata;
                              } | null
                            >
                          )[yearKey];
                          if (yearData && yearData !== null) {
                            setSelectedYearData(yearData);
                            setIsDocumentModalOpen(true);
                          }
                        }}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(tableData).map(([category, ratios]) => (
              <React.Fragment key={category}>
                <tr className='bg-[#F7F4ED] text-tertiary font-medium'>
                  <td className='text-sm flex items-center font-medium py-4 pl-8 sticky left-0 z-10 bg-[#F7F4ED] border-r-[0.5px] w-[35rem]'>
                    <span className='!text-tertiary font-medium'>{category}</span>
                    <Button className='bg-transparent' onClick={() => handleRowToggle(category)}>
                      <Icon
                        icon={expandedRows[category] ? 'MinusCircleIcon' : 'PlusCircleIcon'}
                        color='#c6c6c6'
                        size={22}
                        className='cursor-pointer'
                        variant='outline'
                      />
                    </Button>
                  </td>
                  {tableHeaders.slice(1).map((header, index) => (
                    <td
                      key={index}
                      className='text-sm py-4 px-4 pl-6 text-center bg-neutral-100/30'
                      style={{
                        width: `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                        minWidth: '18rem',
                      }}
                    >
                      {isShareholdingTable && (
                        <>
                          {getCategoryTotal(category, header).shares} (
                          {formatValue(getCategoryTotal(category, header).percentage)}%)
                        </>
                      )}
                    </td>
                  ))}
                </tr>
                {expandedRows[category] &&
                  Object.entries(ratios).map(([ratio, values], ratioIndex) => (
                    <tr
                      key={ratio}
                      className={classNames('border-y border-primary', {
                        'bg-gray-50': ratioIndex % 2 === 0,
                      })}
                    >
                      <td
                        className='text-sm flex items-center font-medium py-5 pl-16 sticky left-0 z-10 bg-surface-base bg-table-secondary'
                        style={{ width: '35rem' }}
                      >
                        <span className='!text-tertiary '>{formatParticular(ratio)}</span>
                        {!isShareholdingTable && (
                          <Button
                            className='bg-transparent ml-2'
                            onClick={() => openModal(category, ratio)}
                          >
                            <Icon
                              icon='BarChartIcon'
                              color='#c6c6c6'
                              size={22}
                              className='cursor-pointer'
                            />
                          </Button>
                        )}
                      </td>
                      {tableHeaders.slice(1).map((header, headerIndex) => {
                        const nextYearHeader = tableHeaders[headerIndex + 2]; // Get next year's header
                        return (
                          <td
                            key={header}
                            className={classNames(
                              'text-sm font-semibold py-5 px-4 text-center text-tertiary',
                              ratioIndex % 2 === 0 ? 'bg-[#F5F2EB]' : 'bg-table-secondary',
                            )}
                            style={{
                              width: `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                              minWidth: '18rem',
                            }}
                          >
                            <span>
                              {isShareholdingTable ? (
                                <span className='text-sm font-medium text-tertiary'>
                                  {formatCurrencyNumber(
                                    isShareholdingTableData(tableData) &&
                                      tableData[category][ratio][header]?.shares
                                      ? tableData[category][ratio][header].shares
                                      : 0,
                                  )}{' '}
                                  (
                                  {formatValue(
                                    isShareholdingTableData(tableData) &&
                                      tableData[category][ratio][header]?.percentage
                                      ? tableData[category][ratio][header].percentage
                                      : 0,
                                  )}
                                  %)
                                </span>
                              ) : (
                                <>
                                  {formatValue((values as Record<string, number | null>)[header])}
                                  {nextYearHeader && // Only show difference if there's a next year
                                    getDifference(
                                      (values as Record<string, number | null>)[header],
                                      (values as Record<string, number | null>)[nextYearHeader],
                                    )}
                                </>
                              )}
                            </span>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {!isShareholdingTable && (
          <Modal
            className='w-[70%] max-w-90vw'
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
            <Chart chartOptions={chartOptions} setChartOptions={setChartOptions} />
          </Modal>
        )}
      </div>
      <DocumentModal
        isOpen={isDocumentModalOpen}
        onClose={() => {
          setIsDocumentModalOpen(false);
          dispatch(setShowNudge(false));
        }}
        documentData={selectedYearData}
      />
    </>
  );
};

export default ExpandableTable;
