import { useDispatch, useSelector } from 'react-redux';
import {
  setReportStatus,
  setTotalCredits,
  setUnlockReportStatus,
} from '@app/store/slices/utilSlice';
import { useParams } from 'react-router-dom';
import { usePostPlaceReportOrderMutation } from '@app/store/api/tokenLedger';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { credhiveReportInsights } from '../insights';
import { RootState } from '@app/store/store';
import { useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Company } from '@app/types/overview';
import { useLocalNavigation } from '@app/hooks/useLocalNavigation';
import { formatDate } from '@components/utils/commonUtils';
import Button from '@components/lib-components/button/Button';
import Icon from '@components/lib-components/icon/Icon';
import tokenLedgerApi from '@app/store/api/tokenLedger/tokenLedgerApi';
import companyApi from '@app/store/api/companyApi';

interface Props {
  companyOverview: Company;
  reportDate: string;
  onBuyTokens: () => void;
  refreshCount: number;
  tokenCharges?: number;
  freshTokenCharges?: number;
  existingTokenCharges?: number;
}

export const NewReportModal = ({
  companyOverview,
  reportDate,
  onBuyTokens,
  refreshCount,
  tokenCharges,
  freshTokenCharges,
  existingTokenCharges,
}: Props) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const navigate = useLocalNavigation();
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const { id: cinId } = useParams();
  const { totalCredits, unlockReport, userDetails, reportStatus } = useSelector(
    (state: RootState) => state.util,
  );

  const [postPlaceReportOrder] = usePostPlaceReportOrderMutation();

  const handleReportAction = useCallback(
    async (action: 'confirm' | 'refresh', isFreshReport: boolean = false) => {
      if (totalCredits <= 0) return;
      try {
        const response = await postPlaceReportOrder({
          identifier_value: cinId as string,
          company_name: companyOverview?.legal_name,
          is_fresh_report: action === 'refresh' ? true : isFreshReport,
          identifier_type: identifierType,
        }).unwrap();

        const creditsUsed = action === 'refresh' || isFreshReport ? 2 : 1;
        dispatch(setTotalCredits(totalCredits - creditsUsed));
        dispatch(setUnlockReportStatus(false));
        dispatch(setReportStatus(response.response_data.status));

        dispatch(companyApi.util.invalidateTags([{ type: 'company-api', id: 'company' }]));
        dispatch(tokenLedgerApi.util.invalidateTags(['my-list-company']));
        dispatch(tokenLedgerApi.util.invalidateTags(['org-list-company']));

        posthog.capture(action === 'confirm' ? 'report_unlocked' : 'report_refreshed', {
          company_name: companyOverview?.legal_name,
          company_cin: cinId,
          buyerCompany: userDetails?.company_name,
          buyerName: userDetails?.name,
          isFreshReport: action === 'refresh' ? true : isFreshReport,
        });
      } catch (error) {
        posthog.capture('error_buying_reports', {
          company_name: companyOverview?.legal_name,
          company_cin: cinId,
          buyerCompany: userDetails?.company_name,
          buyerName: userDetails?.name,
          isFreshReport: action === 'refresh' ? true : isFreshReport,
          error,
        });
      }
    },
    [
      cinId,
      companyOverview?.legal_name,
      dispatch,
      posthog,
      postPlaceReportOrder,
      totalCredits,
      userDetails,
      identifierType,
    ],
  );

  const handleConfirm = useCallback(
    (isFreshReport: boolean) => handleReportAction('confirm', isFreshReport),
    [handleReportAction],
  );

  const handleRefreshReport = useCallback(
    () => handleReportAction('refresh'),
    [handleReportAction],
  );

  const handleReportButtonClick = useCallback(() => {
    if (totalCredits <= 0) {
      return onBuyTokens();
    }

    if (refreshCount === 0) {
      return handleConfirm(true);
    }

    return handleRefreshReport();
  }, [totalCredits, refreshCount, onBuyTokens, handleConfirm, handleRefreshReport]);

  const getButtonText = () => {
    if (reportStatus === ReportStatusEnum.LOCKED) {
      return `Use ${tokenCharges ?? 2} Tokens to Unlock`;
    }
    if (reportStatus === ReportStatusEnum.ALREADY_EXISTS) {
      return null;
    }
    if (refreshCount === 0) {
      return `Use ${tokenCharges ?? 2} Tokens for Fresh Report`;
    }
    return tokenCharges === 0 ? 'Refresh for free' : `Use ${tokenCharges ?? 1} Token to Refresh`;
  };

  if (!unlockReport) return null;

  return (
    <div className='credit-modal-backdrop'>
      <div className='bg-white p-6 rounded-lg shadow-lg !w-4/12'>
        <div className='flex justify-between'>
          <p className='font-semibold text-lg'>Unlock Full Report</p>
          <span
            onClick={() => {
              dispatch(setUnlockReportStatus(false));
              navigate(cinId as string, 'overview');
            }}
          >
            <Icon icon={'XMarkIcon'} size={25} variant='outline' color='gray' />
          </span>
        </div>
        <p className='text-gray-500 mt-0 text-sm'>Get comprehensive access to all data tabs</p>

        <div className='mt-4 bg-[#F9F9F9] border-2 border-gray-100 rounded-lg p-4'>
          <p className='font-semibold mb-4'>Why Credhive Report</p>
          <ul className='mt-2 text-gray-700 space-y-2'>
            {credhiveReportInsights.map((el, index) => (
              <div key={index} className='flex justify-between relative'>
                <Icon
                  icon={'CheckCircleIcon'}
                  size={20}
                  color='#17B26A'
                  className='ml-0 absolute left-0 top-2'
                />
                <div className='relative left-7 whitespace-break-spaces text-sm my-2'>
                  <strong>{el.insight_type}</strong>
                  <p className='text-sm'>{el.insight_info}</p>
                </div>
              </div>
            ))}
          </ul>
          {reportDate && (
            <button className='mt-4 ml-7 p-1 rounded-sm text-slate-700 text-xs'>
              Last Updated: {formatDate(reportDate || '')}
            </button>
          )}
        </div>

        {reportStatus === ReportStatusEnum.COMPLETED && (
          <Button
            text={
              refreshCount === 0
                ? `Use ${tokenCharges ?? 2} Tokens`
                : tokenCharges === 0
                  ? 'Refresh for free'
                  : `Use ${tokenCharges ?? 1} Token${tokenCharges === 1 ? '' : 's'}`
            }
            variant='base'
            onClick={handleReportButtonClick}
            className='text-white bg-blue-600 w-full rounded-md p-3 mt-4'
          />
        )}

        {(reportStatus === ReportStatusEnum.LOCKED ||
          reportStatus === ReportStatusEnum.ALREADY_EXISTS) && (
          <>
            {reportStatus === ReportStatusEnum.ALREADY_EXISTS ? (
              <div className='flex gap-2 mt-4'>
                <Button
                  text={`Use ${existingTokenCharges ?? 1} Token (Existing Report)`}
                  variant='base'
                  onClick={() => handleConfirm(false)}
                  className='text-white bg-blue-600 flex-1 rounded-md p-3'
                />
                <Button
                  text={`Use ${freshTokenCharges ?? 2} Tokens (Fresh Report)`}
                  variant='base'
                  onClick={() => handleConfirm(true)}
                  className='text-white bg-blue-600 flex-1 rounded-md p-3'
                />
              </div>
            ) : (
              <Button
                text={
                  totalCredits > 0
                    ? getButtonText() || 'Buy Tokens to Unlock'
                    : 'Buy Tokens to Unlock'
                }
                variant='base'
                onClick={handleReportButtonClick}
                className='text-white bg-blue-600 w-full rounded-md p-3 mt-4'
              />
            )}
            <p className='text-start text-sm mt-2 text-gray-500'>
              Available:
              <span className={totalCredits > 0 ? 'text-green-500' : 'text-red-500'}>
                {totalCredits}
              </span>
            </p>
            <p className='text-center text-xs text-gray-500 mt-1'>
              Includes all info about &nbsp;
              <span className='font-semibold'>{companyOverview?.legal_name}</span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};
