import { useNavigate, useLocation } from 'react-router-dom';
import { SidebarProps } from './sidebar.types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, persistor } from '@app/store/store';
import classNames from 'classnames';
import { useOutsideClick } from '@app/hooks/useOutSideClick';
import { clearAuth } from '@app/store/slices/authSlice';
import Menu from '@components/lib-components/menu/Menu';
import Icon from '@components/lib-components/icon/Icon';
import { Item } from '@app/types';
import { useToggle } from '@app/hooks/useToggle';
import { usePostHog } from 'posthog-js/react';

export const Sidebar = ({ className }: SidebarProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();

  const [showProfileOptions, toggleShowProfileOptions] = useToggle();
  const moreOptionsRef = useOutsideClick<HTMLDivElement>(() => {
    if (showProfileOptions) {
      toggleShowProfileOptions.off();
    }
  });
  const isSearchFocused = useSelector((state: RootState) => state.auth.isBlur);
  const { unlockReport } = useSelector((state: RootState) => state.util);

  const handleOptionClick = async (option: string) => {
    toggleShowProfileOptions.off();
    if (option === 'logout') {
      // Reset posthog and generate new anon id
      posthog.reset(true);
      dispatch(clearAuth());
      persistor.purge();
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
    } else {
      navigate('/settings');
    }
  };

  const handleMenuItemClick = (pathname: string) => {
    navigate(pathname, { replace: true });
  };

  const menuItems: Item[] = [
    { id: 'dashboard', icon: 'HomeIcon', iconSize: 24, pathname: '/' },
    { id: 'companies', icon: 'MagnifyingGlassIcon', iconSize: 24, pathname: '/search' },
  ];

  return (
    <div className='flex w-[4rem] flex-col justify-between blur-0 z-50'>
      <div
        className={classNames(`h-full bg-brand_color border-gray-200`, {
          'pointer-events-none': isSearchFocused || unlockReport,
          className,
        })}
      >
        <div className='flex flex-col gap-6 py-8 px-2'>
          <div onClick={() => navigate('/')} className='justify-center items-center flex'>
            <Icon icon='AppLogoWithoutLabel' className='self-center hover:cursor-pointer' />
          </div>
          <Menu
            handleMenuItemClick={handleMenuItemClick}
            selectedId={location.pathname === '/' ? 'dashboard' : 'companies'}
            itemList={menuItems}
          />
        </div>
      </div>
      <div
        className={classNames('flex flex-col gap-6 py-8 bg-brand_color px-2 relative', {
          'pointer-events-none': isSearchFocused || unlockReport,
        })}
      >
        <div className='w-full flex px-2 relative'>
          <div className='m-auto cursor-pointer' onClick={toggleShowProfileOptions.toggle}>
            <Icon icon='UserCircleIcon' size={30} variant='solid' color='#7BA5FE' />
          </div>
          {showProfileOptions && (
            <div
              className='w-32 bg-white absolute left-[2.5rem] rounded-lg bottom-4'
              ref={moreOptionsRef}
            >
              <ul className='bg-inherit p-0 h-auto overflow-auto rounded-lg shadow-lg shadow-slate-400/50'>
                <li
                  className='my-2 px-2 text-slate-500 font-semibold py-1 text-center w-full text-sm mx-0 hover:bg-slate-100 hover:cursor-pointer'
                  onClick={() => handleOptionClick('settings')}
                >
                  Settings
                </li>
                <li
                  className='my-2 px-2 text-slate-500 font-semibold py-1 text-center w-full text-sm mx-0 hover:bg-slate-100 hover:cursor-pointer'
                  onClick={() => handleOptionClick('logout')}
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
