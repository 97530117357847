import { Tooltip } from '@components/Tooltip/Tooltip';
import classNames from 'classnames';

interface ListWithTooltipProps {
  items: string[];
  maxDisplay?: number;
  className?: string;
  place?: 'top' | 'right' | 'bottom' | 'left';
}

export const ListWithTooltip: React.FC<ListWithTooltipProps> = ({
  items,
  maxDisplay = 3,
  className,
  place,
}) => {
  if (items.length <= maxDisplay) {
    return <h3 className={'text-sm !text-secondary-700 font-medium mt-2'}>{items.join(', ')}</h3>;
  }

  const displayItems = items.slice(0, maxDisplay);
  const remainingItems = items.slice(maxDisplay);

  return (
    <h3 className={classNames('text-sm font-medium inline-flex items-center mt-2', className)}>
      {displayItems.join(', ')}
      <Tooltip
        content={items.map((item) => `${item}`).join('\n')}
        place={place || 'right'}
        className='whitespace-pre-line bg-white !text-gray-700 !p-4 min-w-fit shadow-lg !text-sm font-semibold !leading-7 rounded-lg'
      >
        <span className='ml-2 px-2 py-1 text-xs bg-gray-100 rounded cursor-pointer'>
          +{remainingItems.length}
        </span>
      </Tooltip>
    </h3>
  );
};
