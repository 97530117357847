import { useParams } from 'react-router-dom';
import { MsmeOverview } from './components/overview/MsmeOverview';
import { MsmeBusinessInformation } from './components/business-information/MsmeBusinessInformation';
import { MsmeFinancials } from './components/financials/MsmeFinancials';
import { MsmeCompliances } from './components/compliances/MsmeCompliances';
import { MsmeCreditRating } from './components/documents/MsmeCreditRating';
import { KeyPeople } from './components/key-people/KeyPeople';
import { useMemo } from 'react';
import { MsmeLocations } from './components/locations/components/MsmeLocations';
import { EnterpriseType, MsmeCompanyData } from './data/msmeTypes';

export interface MsmePageProps {
  msmeData: MsmeCompanyData | null;
}

export const MsmePage: React.FC<MsmePageProps> = ({ msmeData }) => {
  if (!msmeData) {
    return <div>No MSME data available</div>;
  }

  return (
    <div>
      <h2>{msmeData.legal_name}</h2>
      <div>Enterprise Type: {msmeData.enterprise_type}</div>
      <div>Classification: {msmeData.classification}</div>
    </div>
  );
};

const Index = ({ msmeData }: MsmePageProps) => {
  const { tab = 'overview' } = useParams<{ tab: string }>();

  const content = useMemo(() => {
    switch (tab) {
      case 'overview':
        return <MsmeOverview />;
      case 'msme':
        return <MsmePage msmeData={msmeData as MsmeCompanyData} />;
      case 'business-information':
        return (
          <MsmeBusinessInformation
            businessType={msmeData?.enterprise_type as EnterpriseType}
            relatedParties={msmeData?.relatedParties || []}
            competitors={msmeData?.competitors || []}
          />
        );
      case 'msme-financials':
        return <MsmeFinancials />;
      case 'msme-compliances':
        return <MsmeCompliances />;
      case 'msme-creditRating':
        return <MsmeCreditRating />;
      case 'key-people':
        return <KeyPeople businessType={msmeData?.enterprise_type as EnterpriseType} />;
      case 'locations':
        return <MsmeLocations businessType={msmeData?.enterprise_type as EnterpriseType} />;
      default:
        return <MsmeOverview />;
    }
  }, [tab, msmeData]);

  return <main>{content}</main>;
};
export default Index;
