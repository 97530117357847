import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setPaymentStatus } from '@app/store/slices/authSlice';
import { RootState } from '@app/store/store';
import { useLazyGetTokenBalanceQuery } from '@app/store/api/tokenLedger';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const PaymentSuccess = () => {
  const userDetails = useSelector((state: RootState) => state.util.userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fetchTokenBalance] = useLazyGetTokenBalanceQuery();

  useEffect(() => {
    const handleSuccess = async () => {
      try {
        if (!userDetails) return;
        await fetchTokenBalance(userDetails.company_id);
        dispatch(setPaymentStatus('SUCCESS'));

        // Get return URL from localStorage
        const returnUrl = localStorage.getItem('payment_return_url');
        localStorage.removeItem('payment_return_url'); // Clean up

        // Navigate to stored URL or fallback to home
        navigate(returnUrl || '/');
      } catch (error) {
        console.error('Error handling success:', error);
        navigate('/');
      }
    };

    handleSuccess();
  }, [userDetails, dispatch, navigate, fetchTokenBalance]);

  return null;
};

export default PaymentSuccess;
