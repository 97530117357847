import Loader from '@app/components/lib-components/loading/Loading';
import { Sidebar } from '@app/layout/components/Sidebar';

export const DashboardLazyLoader = () => {
  return (
    <div className='flex h-screen'>
      <Sidebar />
      <div className='w-full h-full bg-brand_color overflow-hidden'>
        <Loader className='w-screen flex justify-center items-center mt-[0.3rem] bg-white h-full rounded-t-2xl' />
      </div>
    </div>
  );
};
