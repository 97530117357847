import { Navigate, useLocation } from 'react-router-dom';
import { AuthProps } from './auth.types';

const ProtectedRoute = ({ children }: AuthProps) => {
  const isAuthenticated = localStorage.getItem('accessToken');
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
