import Accordion from '@app/components/lib-components/accordian/Accordian';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import Loading from '@components/lib-components/loading/Loading';
import { TabsCommonPropType } from '../../companyCard.types';
import { useParams } from 'react-router-dom';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { DocumentCategory, DocumentsData } from '@app/store/api/tokenLedger/types';
import { useGetDocumentRedirectionLinkMutation } from '@app/store/api/companyApi';
import { ApiResponse, PresignedUrlPayLoad } from '@app/types';
import { convertToTitleCase } from '@components/utils/commonUtils';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { Attachments, Metadata } from '@app/types/financial';
import { toast } from 'sonner';
import { DOCUMENT_CATEGORY_MAP } from '@app/store/api/tokenLedger/types';
import { useCallback, useState, useEffect } from 'react';
import Button from '@components/lib-components/button/Button';
import posthog from 'posthog-js';
import classNames from 'classnames';
import Table from '@app/components/lib-components/table/table/Table';

interface RowData {
  checkbox?: string;
  file_name?: string;
  document_type?: string;
  document_name?: JSX.Element | string;
  attachments?: string | JSX.Element;
  s3_url?: string;
  id?: string;
}

interface PaginatedData {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
}

const ITEMS_PER_PAGE = 10;

const DocumentsTab: React.FC<TabsCommonPropType> = () => {
  const { id: cinId } = useParams<{ id: string }>();
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const getCompanyOverviewData = useGetCompanyData<ApiResponse<DocumentsData>>();
  const [getDocumentUrl] = useGetDocumentRedirectionLinkMutation();

  const openPdfInNewTab = useCallback((url: string) => {
    const decodedUrl = decodeURIComponent(url);
    const newWindow = window.open();
    if (newWindow) {
      newWindow.location.href = `${window.location.origin}/pdf-viewer#${decodedUrl}`;
    }
  }, []);

  const {
    data: responseData,
    isLoading,
    isError,
  } = getCompanyOverviewData({
    cinId: cinId as string,
    field_name: 'document_list',
    identifierType: identifierType,
  });

  const documentsData = (responseData?.response_data as DocumentsData) || {};

  const headers = [
    { name: '', value: 'checkbox', classes: 'p-4 text-sm text-gray-600 w-[5%]' },
    { name: 'Filing Date', value: 'filing_date', classes: 'p-4 text-sm text-gray-600 w-[15%]' },
    { name: 'Document Type', value: 'document_type', classes: 'p-4 text-sm text-gray-600 w-[20%]' },
    {
      name: 'Document Name',
      value: 'document_name',
      classes: 'p-4 text-sm text-gray-600 w-[40%]',
    },
    {
      name: 'Attachments',
      value: 'attachments',
      classes: 'p-4 text-sm text-gray-600 w-[20%]',
    },
  ];

  const handleDocumentClick = useCallback(
    async (item: Metadata, attachment?: Attachments) => {
      try {
        const s3Url = attachment ? attachment.s3_url : item.s3_url;
        if (!s3Url) {
          posthog.capture('document_request_error', {
            document_name: item.file_name,
          });
          toast.error('Please contact us to request this document.');
          return;
        }
        const payload: PresignedUrlPayLoad = {
          s3_url: s3Url,
          is_download: true,
          identifier_type: identifierType,
          identifier_value: cinId as string,
        };

        const response = await getDocumentUrl(payload).unwrap();
        if (response.pre_signed_url) {
          openPdfInNewTab(response.pre_signed_url);
        } else {
          posthog.capture('document_request_error', {
            document_name: item.file_name,
          });
          toast.error('Error fetching document, please contact us to request this document.');
          console.error('No pre-signed URL received');
        }
      } catch (error) {
        posthog.capture('document_request_error', {
          document_name: item.file_name,
        });
        toast.error('Error fetching document, please contact us to request this document.');
        console.error('Error fetching document URL:', error);
      }
    },
    [cinId, getDocumentUrl, identifierType, openPdfInNewTab],
  );

  const filteredDocumentList = Object.keys(documentsData.document_list || {}).filter(
    (key) =>
      Array.isArray(documentsData.document_list[key as DocumentCategory]) &&
      documentsData.document_list[key as DocumentCategory].length > 0,
  );

  const isReportFetching =
    responseData?.response_data?.status === 'INITIALISED' ||
    responseData?.response_data?.status === 'IN_PROGRESS';

  const getDisplayCategory = useCallback((key: string): string => {
    const category = Object.entries(DOCUMENT_CATEGORY_MAP).find(([, value]) => value === key);
    return category ? category[0] : convertToTitleCase(key);
  }, []);

  const [paginationState, setPaginationState] = useState<Record<string, PaginatedData>>(() => {
    const initialState: Record<string, PaginatedData> = {};
    if (documentsData?.document_list) {
      Object.keys(documentsData.document_list).forEach((key) => {
        const documents = documentsData.document_list[key as DocumentCategory] || [];
        initialState[key] = {
          currentPage: 1,
          itemsPerPage: ITEMS_PER_PAGE,
          totalItems: documents.length,
        };
      });
    }
    return initialState;
  });

  const handlePageChange = useCallback((category: string, page: number) => {
    setPaginationState((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        currentPage: page,
      },
    }));
  }, []);

  const [selectedDocuments, setSelectedDocuments] = useState<RowData[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedDocumentUrls, setSelectedDocumentUrls] = useState<Set<string>>(new Set());
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<Set<string>>(new Set());
  const [openAccordions, setOpenAccordions] = useState<number[]>([]);

  const handleSelectRows = useCallback(
    (rows: RowData[], categoryKey: string) => {
      if (isDownloading) return;

      const newSelectedDocs = rows.filter((row) => row.s3_url);
      const newUrls = new Set(newSelectedDocs.map((doc) => doc.s3_url || ''));
      const newSelectedIds = new Set(newSelectedDocs.map((doc) => doc.id as string));

      const otherDocs = selectedDocuments.filter((doc) => !doc.id?.startsWith(categoryKey));
      const totalSelectedCount = otherDocs.length + newSelectedDocs.length;

      if (totalSelectedCount > 5) {
        toast.error('Maximum 5 documents can be selected');
        return;
      }

      setSelectedDocumentIds(
        new Set([...Array.from(newSelectedIds), ...otherDocs.map((doc) => doc.id as string)]),
      );

      setSelectedDocumentUrls(
        new Set([...Array.from(newUrls), ...otherDocs.map((doc) => doc.s3_url as string)]),
      );

      setSelectedDocuments([...otherDocs, ...newSelectedDocs]);
    },
    [isDownloading, selectedDocuments],
  );

  const handleDownloadSelected = async () => {
    if (!selectedDocuments.length) {
      toast.error('Please select documents to download');
      return;
    }

    if (selectedDocuments.length > 5) {
      toast.error('Maximum 5 documents can be downloaded at once');
      return;
    }

    setIsDownloading(true);
    try {
      for (const row of selectedDocuments) {
        try {
          const s3Url = row.s3_url;

          if (!s3Url) {
            toast.error(`Missing URL for document: ${row.document_name}`);
            continue;
          }

          const payload: PresignedUrlPayLoad = {
            s3_url: s3Url,
            is_download: true,
            identifier_type: identifierType,
            identifier_value: cinId as string,
          };

          const response = await getDocumentUrl(payload).unwrap();

          if (response.pre_signed_url) {
            // Get the filename from the document name or URL
            let filename: string;
            if (typeof row.document_name === 'string') {
              filename = row.document_name;
            } else if (row.document_name && 'props' in row.document_name) {
              filename = row.document_name.props.content || 'document.pdf';
            } else {
              // Extract from URL as fallback
              const decodedUrl = decodeURIComponent(response.pre_signed_url);
              const urlObj = new URL(decodedUrl);
              filename =
                urlObj.pathname.split('/').pop()?.split('?')[0].replace(/%20/g, ' ') ||
                'document.pdf';
            }

            // Ensure filename has proper extension
            if (!filename.toLowerCase().endsWith('.pdf')) {
              filename += '.pdf';
            }

            const fileResponse = await fetch(response.pre_signed_url);
            const blob = await fileResponse.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = decodeURIComponent(filename).replace(/%20/g, ' ');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setTimeout(() => {
              window.URL.revokeObjectURL(blobUrl);
            }, 100);

            toast.success(`Downloaded: ${decodeURIComponent(filename).replace(/%20/g, ' ')}`);
          } else {
            toast.error(`Failed to download document: ${row.document_name}`);
          }
        } catch (error) {
          console.error('Download error:', error);
          toast.error(`Error downloading document: ${row.document_name}`);
        }
      }
    } finally {
      setSelectedDocuments([]);
      setSelectedDocumentUrls(new Set());
      setSelectedDocumentIds(new Set());
      setIsDownloading(false);
    }
  };

  const toggleChildAccordion = (index: number) => {
    setOpenAccordions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  useEffect(() => {
    const accordionRefs = document.querySelectorAll('table');
    accordionRefs.forEach((table) => {
      const checkboxes = table.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        const row = checkbox.closest('tr');
        const s3Url = row?.getAttribute('data-s3-url');
        if (s3Url) {
          (checkbox as HTMLInputElement).checked = selectedDocumentUrls.has(s3Url);
        }
      });
    });
  }, [selectedDocumentUrls]);

  if (isLoading) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );
  }

  if (isReportFetching) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading
          message={'Kindly wait a few minutes, we are fetching the company documents data for you.'}
        />
      </div>
    );
  }

  return (
    <div className='space-y-4 w-full py-6'>
      {!isReportFetching && !isLoading && filteredDocumentList.length > 0 && (
        <div className='flex justify-between items-center mb-4 px-4'>
          <div className='text-sm text-gray-600'>
            {selectedDocuments.length > 0 && (
              <div>
                <div className='font-medium mb-2'>
                  Selected {selectedDocuments.length} document(s)
                  {selectedDocuments.length >= 5 && (
                    <span className='text-red-500 ml-2'>(Maximum limit reached)</span>
                  )}
                </div>
                <div className='flex flex-wrap gap-2'>
                  {selectedDocuments.map((doc, index) => {
                    let displayName = 'Unnamed Document';
                    if (typeof doc.document_name === 'string') {
                      displayName = doc.document_name;
                    } else if (doc.document_name && 'props' in doc.document_name) {
                      const tooltipContent = doc.document_name.props.content;
                      if (tooltipContent) {
                        displayName = tooltipContent;
                      }
                    }

                    return (
                      <span
                        key={index}
                        className='inline-flex items-center px-2 py-1 bg-gray-100 rounded-md text-sm'
                      >
                        {displayName}
                        <button
                          disabled={isDownloading}
                          onClick={() => {
                            const newSelectedDocs = selectedDocuments.filter(
                              (d) => d.s3_url !== doc.s3_url,
                            );
                            setSelectedDocuments(newSelectedDocs);
                            setSelectedDocumentUrls(
                              new Set(newSelectedDocs.map((d) => d.s3_url as string)),
                            );
                            setSelectedDocumentIds(
                              new Set(newSelectedDocs.map((d) => d.id as string)),
                            );
                          }}
                          className='ml-2 text-gray-500 hover:text-red-500'
                        >
                          ×
                        </button>
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {selectedDocuments.length > 0 && (
            <Button
              onClick={handleDownloadSelected}
              isLoading={isDownloading}
              loadingText='Downloading...'
              className='px-4 py-2 text-sm !font-bold text-white border border-utility-blue-600 whitespace-pre'
            >
              Download Selected ({selectedDocuments.length})
            </Button>
          )}
        </div>
      )}
      {!isReportFetching && !isLoading && filteredDocumentList.length > 0 ? (
        <>
          {filteredDocumentList.map((key, index) => {
            const documents = documentsData.document_list[key as DocumentCategory] || [];
            const categoryPagination = paginationState[key] || {
              currentPage: 1,
              itemsPerPage: ITEMS_PER_PAGE,
              totalItems: documents.length,
            };

            const startIndex = (categoryPagination.currentPage - 1) * ITEMS_PER_PAGE;
            const endIndex = startIndex + ITEMS_PER_PAGE;
            const paginatedDocuments = documents.slice(startIndex, endIndex);

            return (
              <Accordion
                key={`${key}-${categoryPagination.currentPage}-${index}`}
                title={getDisplayCategory(key)}
                isOpen={openAccordions.includes(index)}
                onToggle={() => toggleChildAccordion(index)}
                count={categoryPagination.totalItems}
              >
                <div className='flex flex-col'>
                  <Table
                    tableDivClassname='w-full border border-gray-200 !p-0 rounded-lg'
                    headers={headers}
                    rows={paginatedDocuments.map((item: Metadata) => ({
                      id: `${key}-${item.s3_url}`,
                      checkbox: '-',
                      filing_date: item.filing_date || '-',
                      document_type:
                        item.document_type && item.document_type.toLowerCase() === 'unknown'
                          ? '-'
                          : item.document_type,
                      document_name: (
                        <Tooltip
                          className='!bg-slate-700 shadow-md rounded-md bg-blend-saturation'
                          content={item.file_name}
                        >
                          <div className='border-2 rounded-md border-gray-200 w-fit'>
                            <span
                              onClick={() => handleDocumentClick(item)}
                              className='bg-transparent block px-1 py-1 text-start text-sm cursor-pointer hover:bg-gray-50 transition-colors duration-150 break-words whitespace-normal'
                            >
                              {item.file_name}
                            </span>
                          </div>
                        </Tooltip>
                      ),
                      s3_url: item.s3_url,
                      attachments: (
                        <div className='flex flex-col space-y-2'>
                          {item.attachments && item.attachments.length > 0
                            ? item.attachments?.map((attachment, i) => (
                                <Tooltip
                                  className='!bg-slate-700 shadow-md rounded-md bg-blend-saturation'
                                  key={i}
                                  content={attachment.file_name || ''}
                                >
                                  <span
                                    onClick={() => handleDocumentClick(item, attachment)}
                                    className='bg-transparent border-2 me-1 p-1  rounded-md w-fit cursor-pointer text-xs'
                                  >
                                    {attachment.file_name && attachment.file_name.length > 20
                                      ? `${attachment.file_name?.slice(0, 32)}...`
                                      : attachment.file_name}
                                  </span>
                                </Tooltip>
                              ))
                            : '-'}
                        </div>
                      ),
                      isSelected: selectedDocumentIds.has(`${key}-${item.s3_url}`),
                    }))}
                    onSelectRows={(rows) => handleSelectRows(rows as RowData[], key)}
                    totalSelectedCount={selectedDocuments.length}
                    selectedUrls={Array.from(selectedDocumentUrls)}
                    isDownloading={isDownloading}
                  />

                  <div className='flex justify-between items-center py-3 px-6 text-sm border-t mt-4'>
                    <div>
                      <span>
                        {categoryPagination.totalItems > 0 ? (
                          <>
                            Showing{' '}
                            {(categoryPagination.currentPage - 1) *
                              categoryPagination.itemsPerPage +
                              1}{' '}
                            -{' '}
                            {Math.min(
                              categoryPagination.currentPage * categoryPagination.itemsPerPage,
                              categoryPagination.totalItems,
                            )}{' '}
                            of {categoryPagination.totalItems}
                          </>
                        ) : (
                          'No items to show'
                        )}
                      </span>

                      <span className='text-sm underline underline-offset-4 text-gray-500 ml-3'>
                        Max 5 downloads available at a time
                      </span>
                    </div>
                    <div>
                      <button
                        onClick={() => handlePageChange(key, categoryPagination.currentPage - 1)}
                        className={classNames(
                          'mx-1 px-4 py-2 text-sm font-medium rounded-md border border-gray-300 focus:outline-none',
                          {
                            'bg-gray-100 text-gray-400 cursor-not-allowed':
                              categoryPagination.currentPage === 1,
                            'bg-white text-gray-700 hover:bg-gray-50':
                              categoryPagination.currentPage > 1,
                          },
                        )}
                        disabled={categoryPagination.currentPage === 1}
                      >
                        Previous
                      </button>
                      <button
                        onClick={() => handlePageChange(key, categoryPagination.currentPage + 1)}
                        className={classNames(
                          'mx-1 px-4 py-2 text-sm font-medium rounded-md border border-gray-300 focus:outline-none',
                          {
                            'bg-gray-100 text-gray-400 cursor-not-allowed':
                              categoryPagination.currentPage >=
                              Math.ceil(
                                categoryPagination.totalItems / categoryPagination.itemsPerPage,
                              ),
                            'bg-white text-gray-700 hover:bg-gray-50':
                              categoryPagination.currentPage <
                              Math.ceil(
                                categoryPagination.totalItems / categoryPagination.itemsPerPage,
                              ),
                          },
                        )}
                        disabled={
                          categoryPagination.currentPage >=
                          Math.ceil(categoryPagination.totalItems / categoryPagination.itemsPerPage)
                        }
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </Accordion>
            );
          })}
        </>
      ) : isLoading ? (
        <div className='w-[93.8vw]'>
          <Loading />
        </div>
      ) : isError ? (
        <div className='w-[93.8vw] mx-[1px] '>
          <ErrorMessage message='Documents data not available for the company' />
        </div>
      ) : (
        <div className='w-[93.8vw] mx-[1px] '>
          <ErrorMessage message='Documents data not available for the company' />
        </div>
      )}
    </div>
  );
};

export default DocumentsTab;
