import classNames from 'classnames';

interface ChipProps {
  name: string;
  className?: string;
  borderColor?: string;
  bgColor?: string;
  textColor?: string;
}

export const Chip = ({
  name,
  className,
  borderColor = 'border-utility-gray-blue-200',
  bgColor = 'bg-utility-gray-blue-50',
  textColor = 'text-utility-gray-blue-700',
}: ChipProps) => {
  return (
    <span
      className={classNames(
        'inline-flex items-center px-2 py-1 rounded-md border text-[12px] font-medium',
        borderColor,
        bgColor,
        textColor,
        className,
      )}
    >
      {name}
    </span>
  );
};
