import React, { useState, useRef, useEffect } from 'react';
import Icon from '@app/components/lib-components/icon/Icon';

interface Option {
  value: string;
  label: string;
  iconName?: string | keyof typeof Icon;
}

interface DropdownProps {
  options: Option[];
  onOptionSelect: (option: Option) => void;
  dropdownClassName?: string;
  suffixButton?: boolean;
  iconButton?: boolean;
  menuPositionClass?: string;
  value?: Option;
}

export const CompanyDropdown: React.FC<DropdownProps> = ({
  options,
  onOptionSelect,
  value,
  dropdownClassName = '',
  suffixButton = false,
  iconButton = false,
  menuPositionClass = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (option: Option) => {
    if (option.value !== 'coming soon') {
      onOptionSelect(option);
      setIsOpen(false);
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={dropdownClassName}>
      <div className='items-center'>
        {suffixButton && (
          <button
            type='button'
            className='inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50'
            onClick={handleToggle}
          >
            <span className='text-slate-400'>Showing&nbsp;</span>
            {value && value.value}
            <Icon icon='ChevronDownIcon' onClick={handleToggle} className='ml-2 mt-1' size={15} />
          </button>
        )}
        {iconButton && (
          <button
            type='button'
            className='inline-flex items-center justify-center w-full rounded-full border border-gray-300 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 h-[25px] hover:bg-gray-50'
            onClick={handleToggle}
          >
            {value?.iconName && (
              <span className='text-slate-400 flex items-center'>
                <Icon icon={value?.iconName as keyof typeof Icon} size={10} /> &nbsp;&nbsp;
              </span>
            )}
            {value?.label}
            <Icon icon='ChevronDownIcon' onClick={handleToggle} className='ml-2 mt-1' size={15} />
          </button>
        )}
      </div>

      {isOpen && (
        <div
          className={`${menuPositionClass ? menuPositionClass : 'mt-2 w-56 '} origin-top-right absolute right-0 font-medium rounded-md shadow-lg bg-[#F9F9F9] ring-1 ring-black ring-opacity-5`}
        >
          <div className='py-1 relative z-40 text-md w-auto whitespace-nowrap bg-inherit'>
            {options.map((option) => (
              <button
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className={`block px-4 py-2 text-sm text-gray-700 w-full text-left ${
                  option.value === 'coming soon'
                    ? 'text-gray-400 pointer-events-none bg-gray-100'
                    : 'hover:bg-gray-100'
                }`}
                disabled={option.value === 'coming soon'}
              >
                {iconButton && (
                  <div className='flex'>
                    {option.iconName && (
                      <Icon icon={option.iconName as keyof typeof Icon} size={16} />
                    )}
                    <span className={option.iconName ? 'pl-3' : ''}>{option.label}</span>
                  </div>
                )}
                {suffixButton && option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
