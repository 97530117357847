import React, { useState } from 'react';
import { PreviousAddress as PreviousAddressType } from '@app/types/overview';
import { formatDate } from '@app/components/utils/commonUtils';
import Button from '@app/components/lib-components/button/Button';

interface PreviousAddressProps {
  addresses: PreviousAddressType[] | null;
}

export const PreviousAddress: React.FC<PreviousAddressProps> = ({ addresses }) => {
  const [showAllAddresses, setShowAllAddresses] = useState(false);

  if (!addresses || addresses.length === 0) return null;

  const currentAddress = addresses
    .filter((addr) => addr.address_event === 'CURRENT')
    .sort((a, b) => {
      if (!a.effective_date) return 1;
      if (!b.effective_date) return -1;
      return new Date(b.effective_date).getTime() - new Date(a.effective_date).getTime();
    })[0];

  const oldAddresses = addresses
    .filter((addr) => {
      if (currentAddress && addr.id === currentAddress.id) return false;
      return true;
    })
    .sort((a, b) => {
      if (!a.effective_date) return 1;
      if (!b.effective_date) return -1;
      return new Date(b.effective_date).getTime() - new Date(a.effective_date).getTime();
    });

  if (oldAddresses.length === 0) return null;

  const getFormattedAddress = (addr: PreviousAddressType) => {
    if (addr.unprocessed_address) {
      return addr.unprocessed_address;
    }

    const addressParts = [
      addr.street,
      addr.house_number,
      addr.city,
      addr.state,
      addr.country,
      addr.pincode,
    ];

    const hasAddressParts = addressParts.some((part) => part !== null && part !== undefined);

    if (!hasAddressParts) return '-';

    return addressParts.filter(Boolean).join(', ');
  };

  const displayAddresses = showAllAddresses ? oldAddresses : [oldAddresses[0]];
  const remainingCount = oldAddresses.length - 1;

  return (
    <div>
      <span>Previous Address</span>
      <div className='font-semibold text-gray-800'>
        {displayAddresses.map((addr, index) => (
          <div key={addr.id} className={index > 0 ? 'mt-2' : ''}>
            {getFormattedAddress(addr)}
            {addr.effective_date && (
              <div className='text-xs my-1 italic text-gray-600'>
                Effective from: {formatDate(addr.effective_date)}
              </div>
            )}
          </div>
        ))}
        {remainingCount > 0 && !showAllAddresses && (
          <Button
            onClick={() => setShowAllAddresses(true)}
            variant='text'
            size='sm'
            className='mt-2 text-utility-blue-600  hover:no-underline hover:text-utility-blue-800  rounded-md'
            text={`+${remainingCount} more ${remainingCount === 1 ? 'address' : 'addresses'}`}
          />
        )}
        {showAllAddresses && oldAddresses.length > 1 && (
          <Button
            onClick={() => setShowAllAddresses(false)}
            variant='text'
            size='sm'
            className='mt-2 text-utility-blue-600  hover:no-underline hover:text-utility-blue-800  rounded-md'
            text='Show less'
          />
        )}
      </div>
    </div>
  );
};
