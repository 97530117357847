import { useParams } from 'react-router-dom';
import { MSME_DATA } from '../../data/msmeData';
import { ListWithTooltip } from './components/ListWithTooltip';

export const MsmeOverview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const msmeData = id ? MSME_DATA[id] : null;

  if (!msmeData) {
    return <div>No MSME data available</div>;
  }

  const renderInfoBox = (label: string, value: string | number) => (
    <div className='bg-white rounded-lg p-6 border border-gray-100 w-full'>
      <p className='text-sm text-tertiary-600'>{label}</p>
      <h4 className='text-2xl font-semibold mt-2'>{value}</h4>
    </div>
  );

  return (
    <div className='flex gap-8 mt-4 mb-4'>
      {/* Main Content (70%) */}
      <div className='flex-[0.7] space-y-8'>
        {/* Top Info Cards */}
        <div className='flex gap-6'>
          {renderInfoBox('Organisation Type', msmeData.enterprise_type)}
          {renderInfoBox('Incorporation', msmeData.registration_date)}
          {renderInfoBox('No. of Employees', msmeData.employees)}
        </div>

        {/* Industry Section */}
        <div className='bg-white rounded-lg p-6 border border-gray-100'>
          <p className='text-sm text-tertiary-600'>Industry</p>
          <p className='text-2xl font-semibold mt-2'>{msmeData.industry}</p>
        </div>

        {/* Products & Activity Section */}
        <div className='bg-white rounded-lg p-6 border border-gray-100'>
          <div className='flex gap-6'>
            <div className='flex-1'>
              <div className='mb-6'>
                <p className='text-sm text-tertiary'>Products</p>
                <ListWithTooltip
                  items={msmeData.products}
                  className='text-secondary-700'
                  place='top'
                />
              </div>

              <div className='mb-6'>
                <p className='text-sm text-tertiary'>Nature of Activity</p>
                <p className='text-sm text-secondary-700 font-medium mt-2'>
                  {msmeData.nature_of_activity}
                </p>
              </div>
            </div>

            <div className='flex-1'>
              {msmeData.services && (
                <div>
                  <p className='text-sm text-tertiary'>Services</p>
                  <ListWithTooltip
                    items={msmeData.services}
                    className='text-secondary-700'
                    place='top'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Side Content (30%) */}
      <div className='flex-[0.3] bg-white rounded-lg p-6 border border-gray-100'>
        <div className='mb-6'>
          <p className='text-sm text-tertiary-600'>{msmeData.contact_info.role} Name</p>
          <p className='text-secondary-700 font-medium mt-2 text-sm'>
            {msmeData.contact_info.name}
          </p>
        </div>
        {msmeData.parent_group && (
          <div>
            <p className='text-sm text-tertiary-600'>Parent Group</p>
            <p className='text-secondary-700 font-medium mt-2 text-sm'>{msmeData.parent_group}</p>
          </div>
        )}
        <div className='my-6'>
          <p className='text-sm text-tertiary-600'>Contact No.</p>
          <ListWithTooltip items={msmeData.contact_info.contact_no} maxDisplay={1} place='top' />
        </div>

        <div className='mb-6'>
          <p className='text-sm text-tertiary-600'>Email</p>
          <ListWithTooltip items={msmeData.contact_info.email} maxDisplay={1} place='top' />
        </div>

        <div className='mb-6'>
          <p className='text-sm text-tertiary-600'>Registered Address</p>
          <p className='text-secondary-700 font-medium mt-2 text-sm'>
            {msmeData.registered_address.address_line}, {msmeData.registered_address.city},{' '}
            {msmeData.registered_address.state} {msmeData.registered_address.pincode}
          </p>
        </div>

        {msmeData.previous_names && (
          <div className='mb-6'>
            <p className='text-sm text-tertiary-600'>Previous Names</p>
            {msmeData.previous_names.map((prev, index) => (
              <p key={index} className='text-secondary-700 font-medium mt-2 text-sm'>
                {prev.name}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
