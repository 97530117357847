import React, { useCallback, useMemo } from 'react';
import { ApiResponse, IdentifierTypeEnum } from '@app/types';
import {
  LLPPrincipleBusinessActivity,
  PrincipleBusinessActivity,
  PrincipleBusinessActivityWrapper,
} from '@app/types/compliances';
import { Header, Row } from '@components/lib-components/table/table/table.types';
import Table from '@components/lib-components/table/table/Table';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { DocumentIconButton } from '@components/lib-components/DocumentIconButton';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import InfoCard from '@components/lib-components/info-card/InfoCard';

interface PrincipleActivitiesProps {
  data: ApiResponse<PrincipleBusinessActivityWrapper> | undefined;
  isReportFetching: boolean;
}

export const PrincipleActivities: React.FC<PrincipleActivitiesProps> = ({
  data,
  isReportFetching,
}) => {
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const headers: Header[] = useMemo(() => {
    if (identifierType === IdentifierTypeEnum.LLPIN) {
      // LLP case
      return [
        { name: 'Year', value: 'year', classes: 'p-4 text-sm' },
        {
          name: 'Business Classification',
          value: 'business_classification',
          classes: 'p-4 text-sm',
        },
        {
          name: 'Principal Business Activity',
          value: 'principal_business_activity',
          classes: 'p-4 text-sm',
        },
      ];
    } else {
      // Regular case
      return [
        {
          name: 'Main Activity Group Code',
          value: 'main_activity_group_code',
          classes: 'p-4 text-sm',
        },
        {
          name: 'Description of Main Activity group',
          value: 'main_activity_group_description',
          classes: 'p-4 text-sm',
        },
        { name: 'Business Activity Code', value: 'business_activity_code', classes: 'p-4 text-sm' },
        {
          name: 'Description of Business Activity',
          value: 'business_activity_description',
          classes: 'p-4 text-sm',
        },
        { name: '% of Turnover', value: 'percent_turnover_of_company', classes: 'p-4 text-sm' },
      ];
    }
  }, [data]);

  const createRows = useCallback(
    (
      activities:
        | PrincipleBusinessActivity[]
        | LLPPrincipleBusinessActivity[]
        | PrincipleBusinessActivity
        | LLPPrincipleBusinessActivity,
    ): Row[] => {
      const activitiesArray = Array.isArray(activities) ? activities : [activities];

      return activitiesArray.map((activity): Row => {
        if (identifierType === IdentifierTypeEnum.LLPIN) {
          const activityData = activity as LLPPrincipleBusinessActivity;
          // LLP case
          return {
            year: activityData.year ?? '-',
            business_classification: activityData.business_classification ?? '-',
            principal_business_activity: activityData.principal_business_activity ?? '-',
          };
        } else {
          // Non LLP case
          const activityData = activity as PrincipleBusinessActivity;
          return {
            main_activity_group_code: activityData.main_activity_group_code ?? '-',
            main_activity_group_description: activityData.main_activity_group_description ?? '-',
            business_activity_code: activityData.business_activity_code ?? '-',
            business_activity_description: activityData.business_activity_description ?? '-',
            percent_turnover_of_company: `${activityData.percent_turnover_of_company}%` ?? '-',
          };
        }
      });
    },
    [],
  );

  if (isReportFetching) {
    return <ErrorMessage message='Fetching Principal Business Activities data...' />;
  }

  const principalActivities = data?.response_data?.principal_business_activities?.data;

  if (!principalActivities) {
    return (
      <ErrorMessage message='Principal Business Activities data not available for this company.' />
    );
  }

  return (
    <div className='py-4 flex flex-col gap-4'>
      <InfoCard className='py-1 flex flex-col gap-4 w-full mb-6'>
        {principalActivities.length === 0 ? (
          <ErrorMessage message='No public principal business activities data available for this company.' />
        ) : (
          <>
            <div className='flex items-start my-4'>
              <p className='text-lg w-max text-heading font-semibold '>
                Principal Business Activities
              </p>
              <DocumentIconButton
                metadata={data?.response_data?.principal_business_activities?.metadata ?? null}
                documentData={data?.response_data?.principal_business_activities ?? null}
              />
            </div>
            <Table
              tableDivClassname='w-full border border-gray-200 !p-0 rounded mt-0'
              headers={headers}
              rows={createRows(principalActivities)}
            />
          </>
        )}
      </InfoCard>
    </div>
  );
};

export default PrincipleActivities;
