export const CREDIT_SELECTORS = {
  CREDIT_CARD: 'credit-card',
  MODAL_CONTENT: 'credit-modal-content',
  MODAL_BACKDROP: 'credit-modal-backdrop',
  NOTIFY_BUTTON: 'notify-button',
  CLOSE_ICON: 'close-icon',
} as const;

export const CREDIT_TEXT = {
  MODAL_TITLE: 'Add CredHive Tokens',
  SUCCESS_MESSAGE: 'Notification sent successfully!',
  LOADING_MESSAGE: 'Sending Notification...',
  NOTIFY_BUTTON_TEXT: 'Notify',
  TOKENS_LABEL: 'Tokens',
} as const;
