import React, { useState } from 'react';
import Accordion from '@components/lib-components/accordian/Accordian';
import Table from '@components/lib-components/table/table/Table';
import { MsmeContainer } from '../../MsmeContainer';
import { EnterpriseType } from '@components/company/msme/data/msmeTypes';

const locationHeaders = [
  { name: 'SNo.', value: 'sNo', classes: 'text-sm !p-4' },
  { name: 'Address', value: 'address', classes: 'text-sm !p-4' },
  { name: 'Country', value: 'country', classes: 'text-sm !p-4' },
  { name: 'Area & Type', value: 'areaType', classes: 'text-sm !p-4' },
];

const previousLocationHeaders = [
  { name: 'SNo.', value: 'sNo', classes: 'text-sm !p-4' },
  { name: 'Address', value: 'address', classes: 'text-sm !p-4' },
  { name: 'Office Type', value: 'officeType', classes: 'text-sm !p-4' },
  { name: 'Country', value: 'country', classes: 'text-sm !p-4' },
  { name: 'Date of Relocation', value: 'dateOfRelocation', classes: 'text-sm !p-4' },
];

interface MsmeLocationsProps {
  businessType: EnterpriseType;
}

interface LocationData {
  headOffice: Array<{
    sNo: string;
    address: string;
    country: string;
    areaType: string;
  }>;
  correspondenceOffice?: Array<{
    sNo: string;
    address: string;
    country: string;
    areaType: string;
  }>;
  branchOffices: Array<{
    sNo: string;
    address: string;
    country: string;
    areaType: string;
  }>;
  plantUnits: Array<{
    sNo: string;
    address: string;
    country: string;
    areaType: string;
  }>;
  previousLocations: Array<{
    sNo: string;
    address: string;
    officeType: string;
    country: string;
    dateOfRelocation: string;
  }>;
}

export const MsmeLocations: React.FC<MsmeLocationsProps> = ({ businessType }) => {
  const [openAccordions, setOpenAccordions] = useState({
    headOffice: true,
    correspondenceOffice: false,
    branchOffices: false,
    plantUnits: false,
    previousLocations: false,
  });

  const carterUniqueData: LocationData = {
    headOffice: [
      {
        sNo: '1',
        address:
          'Khasra No. 32/ 25/ 2/ 2 Min, 33/ 21/ 1, 21/ 2 And 22/ 1, Village Ghogipur, Karnal 132037, Haryana',
        country: 'India',
        areaType: '2,727 Sq. Ft. (Owned), Industrial',
      },
    ],
    correspondenceOffice: [
      {
        sNo: '1',
        address: 'No. 31, Chaman Garden, Railway Road, Karnal 132001, Haryana',
        country: 'India',
        areaType: '-',
      },
    ],
    branchOffices: [
      {
        sNo: '1',
        address:
          'Village Sinoda, Post Nevra, Tehsil Tilda, Sinoda Village, Tilda, Raipur, Chhattisgarh, 493114',
        country: 'India',
        areaType: '-',
      },
      {
        sNo: '2',
        address:
          'No. 118/7, Mile Stone Opp Govt. High School, G. T. Road, Kambopura Village, Karnal 132001, Haryana',
        country: 'India',
        areaType: '-',
      },
      {
        sNo: '3',
        address: 'Hakikat Nagar, Ghogri Pur Road, Karnal 132001, Haryana',
        country: 'India',
        areaType: '-',
      },
      {
        sNo: '4',
        address:
          'Mile Stone, Opposite Govt. High School, 118/7, G T Road, Kambopura Village, Karnal 132001, Haryana',
        country: 'India',
        areaType: '-',
      },
    ],
    plantUnits: [
      {
        sNo: '1',
        address:
          'Gali No. 10, Sec. 3, Extn. Near New Sabzi Mandi, Industrial Area, Mangal Colony, Bazida Road, Karnal 132001, Haryana',
        country: 'India',
        areaType: '-',
      },
    ],
    previousLocations: [
      {
        sNo: '1',
        address: '900/14, Street No.4, Brahm Nagar, Karnal 132001, Haryana',
        officeType: 'Head/Registered Office',
        country: 'India',
        dateOfRelocation: '-',
      },
    ],
  };

  const redEximData: LocationData = {
    headOffice: [
      {
        sNo: '1',
        address:
          'G Block, C W 8011 B, Bharat Diamond Bourse, Bandra Kurla Complex, Bandra E, Mumbai 400051, Maharashtra',
        country: 'India',
        areaType: '3,030 Sq. Ft. (Owned), Commercial',
      },
    ],
    correspondenceOffice: [
      {
        sNo: '1',
        address: 'No. 31, Chaman Garden, Railway Road, Karnal 132001, Haryana',
        country: 'India',
        areaType: '-',
      },
    ],
    branchOffices: [
      {
        sNo: '1',
        address:
          'G Block, E C-6052, Bharat Diamond Bourse, Bandra Kurla Complex, Bandra E, Mumbai 400051, Maharashtra',
        country: 'India',
        areaType: '-',
      },
      {
        sNo: '2',
        address: '4-k-9, Balaji Agora, Ring Road, Chankheda, Ahmedabad-382424, Gujarat',
        country: 'India',
        areaType: '-',
      },
    ],
    plantUnits: [
      {
        sNo: '1',
        address:
          'Unit No. 09-10-12, Agarwal Co-operative Industrial Estate Ltd, 121 Swami Vivekanand Road, Dahisar East, Mumbai 400068, Maharashtra',
        country: 'India',
        areaType: '-',
      },
      {
        sNo: '2',
        address:
          'Shop No 44/47, 4th Floor, Dabbi Falia, Gotalawadi Katargam Road, Surat 395004, Gujarat',
        country: 'India',
        areaType: '-',
      },
    ],
    previousLocations: [
      {
        sNo: '1',
        address:
          'C W 8021 / 8023, Bharat Diamond Bourse, 8th Bandra Kurla Complex, Bandra ( East) Mumbai 400051, Maharashtra',
        officeType: 'Head/Registered Office',
        country: 'India',
        dateOfRelocation: '-',
      },
      {
        sNo: '2',
        address: 'No. 204, 2nd Floor, Som Shanti, Pipla Sheri, Surat 395003, Gujarat',
        officeType: 'Head/Registered Office',
        country: 'India',
        dateOfRelocation: '-',
      },
    ],
  };

  const currentData =
    businessType === EnterpriseType.Proprietorship ? carterUniqueData : redEximData;

  const toggleAccordion = (key: keyof typeof openAccordions) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <MsmeContainer>
      <h4 className='text-brand_color text-lg font-medium'>Locations & Offices</h4>

      <Accordion
        title='Head / Registered Office'
        isOpen={openAccordions.headOffice}
        count={currentData.headOffice.length}
        onToggle={() => toggleAccordion('headOffice')}
      >
        <Table
          headers={locationHeaders}
          rows={currentData.headOffice}
          tableDivClassname='w-full border border-gray-200 !p-0 rounded'
          tableHeaderClassname='bg-secondary border-b border-gray-200 p-4 text-sm font-semibold'
        />
      </Accordion>

      {businessType === EnterpriseType.Proprietorship && (
        <Accordion
          title='Correspondence Office'
          isOpen={openAccordions.correspondenceOffice}
          count={currentData.correspondenceOffice?.length || 0}
          onToggle={() => toggleAccordion('correspondenceOffice')}
        >
          <Table
            headers={locationHeaders}
            rows={currentData.correspondenceOffice || []}
            tableDivClassname='w-full border border-gray-200 !p-0 rounded'
            tableHeaderClassname='bg-secondary border-b border-gray-200 p-4 text-sm font-semibold'
          />
        </Accordion>
      )}

      <Accordion
        title={`Other Branch Offices`}
        count={currentData.branchOffices.length}
        isOpen={openAccordions.branchOffices}
        onToggle={() => toggleAccordion('branchOffices')}
      >
        <Table
          headers={locationHeaders}
          rows={currentData.branchOffices}
          tableDivClassname='w-full border border-gray-200 !p-0 rounded'
          tableHeaderClassname='bg-secondary border-b border-gray-200 p-4 text-sm font-semibold'
        />
      </Accordion>

      <Accordion
        title={`Plant Units`}
        count={currentData.plantUnits.length}
        isOpen={openAccordions.plantUnits}
        onToggle={() => toggleAccordion('plantUnits')}
      >
        <Table
          headers={locationHeaders}
          rows={currentData.plantUnits}
          tableDivClassname='w-full border border-gray-200 !p-0 rounded'
          tableHeaderClassname='bg-secondary border-b border-gray-200 p-4 text-sm font-semibold'
        />
      </Accordion>

      <h4 className='text-brand_color text-lg font-medium mt-6'>Previous Locations</h4>
      <Table
        headers={previousLocationHeaders}
        rows={currentData.previousLocations}
        tableDivClassname='w-full border border-gray-200 !p-0 rounded'
        tableHeaderClassname='bg-secondary border-b border-gray-200 p-4 text-sm font-semibold'
      />
    </MsmeContainer>
  );
};
