import classNames from 'classnames';

interface NumberCardsProps {
  cardClassName?: string;
  count: number;
  cardName: string;
  onClick?: () => void;
}

export const NumberCards = ({ cardClassName, count, cardName, onClick }: NumberCardsProps) => {
  return (
    <div className={classNames(cardClassName, 'cursor-pointer')} onClick={onClick}>
      <div className='flex flex-col'>
        <p className='text-3xl font-bold'>{count}</p>
        <p className='text-sm mt-2'>{cardName}</p>
      </div>
    </div>
  );
};
