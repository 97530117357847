import { MsmeContainer } from '../MsmeContainer';
import Table from '@app/components/lib-components/table/table/Table';
import { Header } from '@app/components/lib-components/table/table/table.types';
import { useParams } from 'react-router-dom';
import { MSME_FINANCIAL_DATA, MSME_BANK_DATA } from '../../data/msmeData';

const bankHeaders: Header[] = [
  {
    name: 'SNo.',
    value: 'sNo',
    classes: 'w-20 text-xs',
  },
  {
    name: 'Entity Name',
    value: 'entityName',
    classes: 'w-1/3 text-xs',
  },
  {
    name: 'Branch',
    value: 'branch',
    classes: 'w-1/3 text-xs',
  },
  {
    name: 'IFSC Code',
    value: 'ifscCode',
    classes: 'w-1/4 text-xs',
  },
];

export const MsmeFinancials = () => {
  const { id = 'PANAALFR8552P' } = useParams<{ id: string }>();
  const financialData = MSME_FINANCIAL_DATA[id];
  const bankRows = MSME_BANK_DATA[id];

  if (!financialData || !bankRows) {
    return <MsmeContainer>No financial data available</MsmeContainer>;
  }

  return (
    <MsmeContainer>
      <div className='text-sm border rounded-lg px-6 py-4'>
        <h4 className='text-lg font-semibold text-heading'>Financial Position</h4>
        <p className='text-gray-500 mb-4'>
          As per last verification - {financialData.lastVerification}
        </p>
        <div className='grid grid-cols-3 gap-4'>
          <div className='border p-4 rounded-lg'>
            <p className='text-gray-600 mb-2'>Turnover</p>
            <p className='text-lg font-semibold text-secondary-700'>{financialData.turnover}</p>
          </div>
          <div className='border p-4 rounded-lg'>
            <p className='text-gray-600 mb-2'>Expenditure</p>
            <p className='text-lg font-semibold text-secondary-700'>{financialData.expenditure}</p>
          </div>
          <div className='border p-4 rounded-lg'>
            <p className='text-gray-600 mb-2'>Net Profit</p>
            <p className='text-lg font-semibold text-secondary-700'>{financialData.netProfit}</p>
          </div>
        </div>
      </div>
      <h4 className='text-lg font-semibold text-heading mt-4 mb-2'>
        Banks & Non-Banking Financial Institutions
      </h4>
      <Table
        headers={bankHeaders}
        rows={bankRows}
        className='min-w-full'
        tableDivClassname='border'
      />
    </MsmeContainer>
  );
};
