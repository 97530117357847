import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { BreadCrumbs } from '@components/lib-components/breadcrumbs/BreadCrumbs';
import StatusBox from '@components/lib-components/status-box/StatusBox';
import CurrentCompanies from './CurrentCompanies';
import PreviousCompanies from './PreviousCompanies';
import ContactInfo from './ContactInfo';

type DirectorTab = 'current-companies' | 'previous-companies' | 'contact-info';

const allTabs: Array<{
  id: DirectorTab;
  label: string;
}> = [
  { id: 'current-companies', label: 'Current Companies' },
  { id: 'previous-companies', label: 'Previous Companies' },
  { id: 'contact-info', label: 'Contact Info' },
];

const DirectorProfile: React.FC = () => {
  const location = useLocation();
  const directorName = location.state?.directorName || 'Director Name';
  const { dinId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'current-companies';

  const directorInfo = {
    name: directorName || 'Director',
    status: 'Active',
    din: dinId || '02425847',
  };

  const currentCompanies = [
    {
      name: 'Kesoram Private Ltd.',
      status: 'Active',
      location: 'Kolkata, West Bengal',
      dateOfIncorporation: '23 Jan, 2003',
      din: '02425847',
      dinStatus: 'Activated',
      designation: 'Director',
      dateOfAppointmentCurrentDesignation: '03 Nov, 2011',
    },
    {
      name: 'Tatabhai Private Ltd.',
      status: 'Active',
      location: 'Asansol, West Bengal',
      dateOfIncorporation: '12 Jan, 1996',
      din: '02425847',
      dinStatus: 'Activated',
      designation: 'Director',
      dateOfAppointmentCurrentDesignation: '15 May, 2010',
    },
  ];

  const previousCompanies = [
    {
      name: 'Old Company Ltd.',
      status: 'Deactivated',
      location: 'Mumbai, Maharashtra',
      dateOfIncorporation: '05 Mar, 1990',
      din: '02425847',
      dinStatus: 'Deactivated',
      designation: 'Former Director',
      dateOfAppointmentCurrentDesignation: '10 Jun, 2000',
    },
    {
      name: 'Past Ventures Inc.',
      status: 'Deactivated',
      location: 'Bangalore, Karnataka',
      dateOfIncorporation: '18 Sep, 1995',
      din: '02425847',
      dinStatus: 'Deactivated',
      designation: 'Former Managing Director',
      dateOfAppointmentCurrentDesignation: '22 Apr, 2005',
    },
  ];

  const contactInfo = {
    pan: 'APHYD00596',
    age: '62 yrs',
    nationality: 'Indian',
    gender: 'Male',
    phoneNumber: '+91 8130456786',
    email: 'snjhaerythdh056@gmail.com',
    address: '9/1 RN MUKHERJEE ROAD, KOLKATA, West Bengal, India, 700001',
    grossSalary: '₹ 84,00,000',
    dscStatus: 'Male',
    dscExpiryDate: '30 Aug, 2024',
  };

  const handleTabChange = (tab: DirectorTab) => {
    searchParams.delete('tab');
    searchParams.append('tab', tab);
    setSearchParams(searchParams);
  };

  const getTabClasses = (tabId: DirectorTab) =>
    classNames(
      'py-2 px-0 w-40 font-semibold transition-colors duration-200 bg-transparent w-auto mr-2',
      {
        'border-b border-brand-secondary text-brand-secondary !w-auto': activeTab === tabId,
        'text-gray-500 hover:text-gray-700': activeTab !== tabId,
      },
    );

  return (
    <div className={classNames('p-6 pl-0 mt-8')}>
      <div className={classNames('mb-4')}>
        <BreadCrumbs
          pageHistory={[
            { id: 'director', text: 'Director', pathname: `/` },
            {
              id: 'DirectorProfile',
              text: directorInfo.name || 'Director',
              pathname: '',
            },
          ]}
        />
      </div>
      <div className={classNames('flex items-center justify-start mb-6')}>
        <h1 className={classNames('text-2xl font-bold pr-3')}>{directorInfo.name}</h1>
        <StatusBox
          statusText={directorInfo.status}
          statusColor={directorInfo.status === 'Active' ? 'bg-green-500' : 'bg-red-500'}
        />
      </div>

      <div className='border-gray-50'>
        <div className='flex gap-4 mb-6 border-b border-gray-200'>
          {allTabs.map((tab) => (
            <button
              key={tab.id}
              className={getTabClasses(tab.id)}
              onClick={() => handleTabChange(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>

      {activeTab === 'current-companies' && <CurrentCompanies companies={currentCompanies} />}
      {activeTab === 'previous-companies' && <PreviousCompanies companies={previousCompanies} />}
      {activeTab === 'contact-info' && <ContactInfo contactInfo={contactInfo} />}
    </div>
  );
};

export default DirectorProfile;
