export const pvtCompanyTabs = [
  { id: 'overview', text: 'Overview' },
  { id: 'shareholdings', text: 'Shareholding Pattern' },
  { id: 'management', text: 'Management' },
  { id: 'charges', text: 'Charges' },
  { id: 'financial', text: 'Financials' },
  { id: 'financial-analysis', text: 'Financial Analysis' },
  { id: 'compliances', text: 'Compliances' },
  { id: 'credit', text: 'Credit Rating' },
  { id: 'documents', text: 'Documents' },
];
