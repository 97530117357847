import React from 'react';
import { CompanyDetails, PaymentDetail } from '@app/types/compliances';
import { formatDate } from '@components/utils/commonUtils';

interface CompanyDetailsProps {
  details: CompanyDetails;
  latestPayment?: PaymentDetail;
}

export const CompanyDetailsSection: React.FC<CompanyDetailsProps> = ({
  details,
  latestPayment,
}) => {
  const displayOrder = [
    'est_code',
    'est_name',
    'city',
    'numberOfEmployees',
    'amount',
    'latestWageMonth',
    'latestDateOfCredit',
    'primary_business_activity',
    'date_of_setup',
    'pf_exemption_status',
    'pension_exemption_status',
    'edli_exemption_status',
  ];

  const labelMap: { [key: string]: string } = {
    est_code: 'Establishment ID',
    est_name: 'Establishment Name',
    city: 'City',
    numberOfEmployees: 'Number of Employees',
    amount: 'Amount (₹)',
    latestWageMonth: 'Latest Wage Month',
    latestDateOfCredit: 'Latest Date of Credit',
    primary_business_activity: 'Primary Business Activity',
    date_of_setup: 'Date of Setup',
    pf_exemption_status: 'PF Exemption Status',
    pension_exemption_status: 'Pension Exemption Status',
    edli_exemption_status: 'EDLI Exemption Status',
  };

  const getValue = (key: string) => {
    switch (key) {
      case 'numberOfEmployees':
        return latestPayment?.number_of_employee || '-';
      case 'amount':
        return latestPayment?.amount || '-';
      case 'latestWageMonth':
        return latestPayment?.wage_month || '-';
      case 'latestDateOfCredit':
        return latestPayment?.date_of_credit ? formatDate(latestPayment.date_of_credit) : '-';
      default:
        return details[key as keyof CompanyDetails] !== undefined
          ? String(details[key as keyof CompanyDetails])
          : '-';
    }
  };

  return (
    <div className='grid grid-cols-12 gap-3 mt-4 content-between w-auto'>
      {displayOrder.map((key, index) => (
        <div key={key} className={`col-span-4 my-1 ${index % 3 === 0 ? 'col-start-1' : ''}`}>
          <p className='font-normal text-sm mb-1 text-tertiary-600'>{labelMap[key]}</p>
          <p className='text-xs font-medium text-secondary-700'>{getValue(key)}</p>
        </div>
      ))}
    </div>
  );
};
