import Icon from '@app/components/lib-components/icon/Icon';
import LoginImage from '../../assets/LoginImage.webp';
import '../../index.css';
import Signup from './Signup';

function SignupPage() {
  return (
    <div className='flex h-screen w-full justify-between overflow-hidden'>
      <div className='w-8/12 relative hidden md:block'>
        <Icon icon='AppLogo' className='absolute top-4 left-4 z-10' />
        <img src={LoginImage} className='h-screen fill-available object-cover' />
      </div>
      <div className=' md:w-5/12 m-auto'>
        <Signup />
      </div>
    </div>
  );
}

export default SignupPage;
