import { StatusBoxProps } from './statusBox.types';

const StatusBox = ({ statusText, statusColor }: StatusBoxProps) => {
  return (
    <div className='text-xs inline-flex items-center gap-1 p-1 border rounded-md border-gray-300 min-w-16 justify-center max-h-fit'>
      <div className={`w-2 h-2 rounded-full ${statusColor}`} />
      {statusText}
    </div>
  );
};

export default StatusBox;
