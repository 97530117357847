import { PvtCompanyOverview } from './components/PvtCompanyOverview';
import { LLPCompanyOverview } from './components/LLPCompanyOverview';
import { Company } from '@app/types/overview';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';

interface Props {
  companyOverviewData: Company;
}

export const Overview = ({ companyOverviewData }: Props) => {
  const companyType = useSelector((state: RootState) => state.util.companyType);

  return (
    <div className='py-4 h-full'>
      {companyType === COMPANY_TYPE.LimitedLiabilityPartnership ? (
        <LLPCompanyOverview companyOverview={companyOverviewData} />
      ) : (
        <PvtCompanyOverview companyOverview={companyOverviewData} />
      )}
    </div>
  );
};
