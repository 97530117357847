import Icon from '@app/components/lib-components/icon/Icon';

export const CompanyRequestSuccess = () => {
  return (
    <div className=' flex flex-col justify-center items-center min-h-[calc(100vh-25rem)] gap-10'>
      <div className='bg-green-500 rounded-full block  justify-center items-center p-4'>
        <Icon icon='CheckIcon' size={100} color='white' className='self-center font-extrabold' />
      </div>
      <h2 className='font-bold text-xl'>Company request sent!</h2>
      <h4 className='text-sm text-utility-gray-400'>
        We will review your request and get back to you shortly.
      </h4>
    </div>
  );
};
