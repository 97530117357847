interface Error extends React.HTMLAttributes<HTMLDivElement> {
  message: string;
}

const ErrorMessage: React.FC<Error> = ({ message, ...props }) => {
  return (
    <div
      className='w-[calc(100vw-7rem)] ml-2  h-[calc(30vh)] flex justify-center items-center'
      {...props}
    >
      <div className='flex justify-center'>
        <div className='flex text-center justify-center w-[40vw] min-h-80 h-auto bg-contain items-center mt-4 text-slate-400 noDataSection'>
          {message}
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
