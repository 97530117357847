import { InfoCardProps } from './infoCard.types';
import classNames from 'classnames';

const InfoCard = ({
  heading,
  headingSubtext,
  children,
  className,
  headingClassName,
}: InfoCardProps) => {
  return (
    <div
      className={classNames(
        'flex outline outline-1 outline-slate-100 flex-col gap-2 items-start h-full rounded-xl shadow-[0px_0.896px_1.793px_0px_rgba(16,24,40,0.05)] p-4',
        className,
      )}
    >
      {(heading || headingSubtext) && (
        <div className='w-full flex flex-col justify-between font-semibold'>
          <div className='flex justify-between'>
            <h2 className={`${headingClassName} text-sm font-extrabold drop-shadow`}>{heading}</h2>
          </div>

          <div className='text-zinc-400 text-xs font-medium'>{headingSubtext}</div>
        </div>
      )}
      <div className={className}>{children}</div>
    </div>
  );
};

export default InfoCard;
