import React from 'react';
import {
  useGetDocumentRedirectionLinkMutation,
  useReportIssueMutation,
} from '@app/store/api/companyApi';
import Modal from '@app/components/lib-components/modal/Modal';
import Button from '@app/components/lib-components/button/Button';
import {
  CompanyFinancialData,
  Attachments,
  LLPFinancialData,
  Metadata,
  FinancialParameters,
} from '@app/types/financial';
import { usePostHog } from 'posthog-js/react';
import { ShareholdingTypes } from '@components/company/company-tabs/ShareHoldingPattern/ShareholdingProps.types';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { OutstandingDuesItem, PrincipleActivitiesItem } from '@app/types/compliances';
import { formatDate } from '@components/utils/commonUtils';
import { PresignedUrlPayLoad } from '@app/types';
import { RatioData } from '../table/table/ExpandableTable';
import { ContributionResponse } from '@components/company/company-tabs/ShareHoldingPattern/ShareholdingProps.types';
import {
  IndividualPartner,
  BodyCorporatePartner,
  SummaryDesignatedPartners,
} from '@components/company/company-tabs/ShareHoldingPattern/ShareholdingProps.types';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { DownloadDocuments } from '@components/company/company-tabs/Charges/DownloadDocuments';

type ExtendedMetadata = Metadata & {
  company_cin?: string;
  attachments?: Array<Attachments>;
  filing_date_form?: string;
  s3_url?: string;
};

type FinancialRatiosResponseJSON = {
  data: RatioData;
  metadata: ExtendedMetadata;
};

type DocumentData = {
  data: CompanyFinancialData | LLPFinancialData | FinancialParameters;
  metadata: ExtendedMetadata;
  year: number;
};

interface DocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentData:
    | ShareholdingTypes
    | DocumentData
    | OutstandingDuesItem
    | FinancialRatiosResponseJSON
    | PrincipleActivitiesItem
    | ContributionResponse<IndividualPartner[]>
    | ContributionResponse<BodyCorporatePartner[]>
    | ContributionResponse<SummaryDesignatedPartners[]>
    | null;
}

interface DocumentItem {
  name: string;
  isAttachment?: boolean;
  attachment?: Attachments;
  s3_url?: string;
}

export const DocumentModal: React.FC<DocumentModalProps> = ({ isOpen, onClose, documentData }) => {
  const { id: cinId } = useParams<{ id: string }>();
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const [getDocumentUrl] = useGetDocumentRedirectionLinkMutation();
  const [reportIssue] = useReportIssueMutation();
  const posthog = usePostHog();

  const handleReportIssue = async (issue: string, description: string, documentName: string) => {
    try {
      await reportIssue({
        issue,
        description: `${description} - Document: ${documentName}`,
      }).unwrap();
    } catch (error) {
      console.error('Failed to report issue:', error);
    }
  };

  const extractFilenameFromUrl = (url: string): string => {
    try {
      const decodedUrl = decodeURIComponent(url);
      const urlObj = new URL(decodedUrl);
      const pathname = urlObj.pathname;
      const fileNameWithParams = pathname.split('/').pop();
      if (fileNameWithParams) {
        return fileNameWithParams.split('?')[0];
      }
      throw new Error('Unable to extract filename from URL');
    } catch (error) {
      console.error('Error extracting filename:', error);
      toast.error('Failed to open the document');
      handleReportIssue('Filename Extraction Error', 'Failed to extract filename from URL', url);
      posthog.capture('error_filename_extraction', {
        url,
        error,
      });
      return 'document.pdf';
    }
  };

  const forceFileDownload = async (url: string, filename: string): Promise<void> => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download the document');
      handleReportIssue('File Download Error', 'Failed to download the document', filename);
      posthog.capture('error_file_download', {
        filename,
        error,
      });
    }
  };

  const openPdfInNewTab = (url: string, documentName: string): void => {
    try {
      const decodedUrl = decodeURIComponent(url);
      window.open(
        `${window.location.origin}/pdf-viewer#${decodedUrl}`,
        '_blank',
        'noopener,noreferrer',
      );
    } catch (error) {
      console.error('Error opening PDF in new tab:', error);
      toast.error('Failed to open the document');
      handleReportIssue('PDF Viewer Error', 'Failed to open the PDF in a new tab', documentName);
      posthog.capture('error_pdf_viewer', {
        document_name: documentName,
        error,
      });
    }
  };

  const handleDocumentClick = async (
    item: DocumentItem,
    isDownload: boolean = false,
  ): Promise<void> => {
    if (!documentData) return;

    const payload: PresignedUrlPayLoad = {
      s3_url: documentData.metadata?.s3_url ?? '',
      is_download: isDownload,
      identifier_type: identifierType,
      identifier_value: cinId as string,
    };

    if (item.isAttachment && item.attachment) {
      Object.assign(payload, {
        s3_url: item.attachment.s3_url,
        is_download: isDownload,
      });
    }

    try {
      const response = await getDocumentUrl(payload).unwrap();

      if (response.pre_signed_url) {
        if (isDownload) {
          const filename = extractFilenameFromUrl(response.pre_signed_url);
          await forceFileDownload(response.pre_signed_url, filename);
        } else {
          openPdfInNewTab(response.pre_signed_url, item.name);
        }
      }
    } catch (error) {
      console.error('Error fetching document URL:', error);
      toast.error('Failed to fetch the document URL');
      handleReportIssue(
        'Document Fetch Error',
        `Failed to fetch the document URL where file path is ${payload?.s3_url}`,
        item.name,
      );
      posthog.capture('error_document_fetch', {
        document_name: item.name,
        error,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<h3 className='text-2xl text-heading font-semibold mb-6'>Supporting Documents</h3>}
      className='!p-10 min-w-[30%] !w-auto'
    >
      {documentData && (
        <div className='space-y-6'>
          <div className='flex gap-12'>
            <div className='space-y-2'>
              <p className='text-gray-600'>Financial Year</p>
              <p className='font-semibold text-tertiary-600'>
                {documentData.metadata?.financial_year
                  ? formatDate(documentData.metadata?.financial_year)
                  : '-'}
              </p>
            </div>
            <div className='space-y-2'>
              <p className='text-gray-600'>Uploaded on</p>
              <p className='font-semibold text-tertiary-600'>
                {documentData.metadata?.filing_date
                  ? formatDate(documentData.metadata.filing_date)
                  : '-'}
              </p>
            </div>
          </div>
          <hr />
          <div className='space-y-2'>
            <p className='text-gray-600 font-bold mb-4'>Primary Document</p>
            <div className='flex'>
              <DownloadDocuments
                name={documentData.metadata?.file_name ?? ''}
                isAttachment={false}
                isDocumentLoading={false}
                handleDocumentClick={handleDocumentClick}
              />
            </div>
          </div>
          <div className='space-y-2 flex-col'>
            <p className='text-gray-600 font-bold mb-4'>Attachments</p>
            <div className='flex'>
              {documentData.metadata?.attachments?.length ? (
                documentData.metadata.attachments.map((attachment, index) => (
                  <React.Fragment key={index}>
                    <DownloadDocuments
                      name={attachment.file_name ?? ''}
                      isAttachment={true}
                      isDocumentLoading={false}
                      attachment={attachment}
                      handleDocumentClick={handleDocumentClick}
                    />
                  </React.Fragment>
                ))
              ) : (
                <p className='text-gray-500'>No attachments available</p>
              )}
            </div>
          </div>
        </div>
      )}
      <Button
        onClick={onClose}
        className='mt-10 flex mx-auto justify-center !py-3 w-full !bg-transparent !text-lg !text-black !p-5 rounded-md border !border-gray-900 hover:bg-gray-200'
      >
        Close
      </Button>
    </Modal>
  );
};
