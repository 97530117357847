import { BreadCrumbsProps } from './breadCrumbs.types';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

export const BreadCrumbs = ({ pageHistory }: BreadCrumbsProps) => {
  const navigate = useNavigate();
  return (
    <div className={classNames('p-1', 'cursor-pointer')}>
      {pageHistory.map((item, index, all) => (
        <div
          key={`${item.id}${index}`}
          className={classNames('text-sm', 'inline-flex', {
            'rounded-md bg-slate-100 px-1 py-0.5 mt-2 ml-2': index === all.length - 1,
          })}
          onClick={() => (item.pathname !== '' ? navigate(item.pathname) : null)}
        >
          <span>{item.text?.trim()}</span>
          {index !== all.length - 1 && (
            <p className='text-slate-400 ml-2'>{index !== all.length - 1 ? ' / / ' : null}</p>
          )}
        </div>
      ))}
    </div>
  );
};
