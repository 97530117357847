import React, { useMemo, useState } from 'react';
import Modal from '@app/components/lib-components/modal/Modal';
import Accordion from '@app/components/lib-components/accordian/Accordian';
import Button from '@app/components/lib-components/button/Button';
import {
  useGetDocumentRedirectionLinkMutation,
  useReportIssueMutation,
} from '@app/store/api/companyApi';

import { ChargeDetailsModalProps } from '@app/types/charges';
import { Attachments, Metadata } from '@app/types/financial';
import { RootState } from '@app/store/store';
import { PresignedUrlPayLoad } from '@app/types';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useSelector } from 'react-redux';
import { DownloadDocuments } from './DownloadDocuments';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';

interface ChargeOverviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  chargeDetails?: ChargeDetailsModalProps;
  documents?: Metadata;
}

interface DocumentItem {
  name: string;
  isAttachment?: boolean;
  attachment?: Attachments;
}

export const ChargeOverviewModal: React.FC<ChargeOverviewModalProps> = ({
  isOpen,
  onClose,
  chargeDetails,
  documents,
}) => {
  if (!chargeDetails) return null;

  const [openAccordion, setOpenAccordion] = useState<string | null>(null);
  const [getDocumentUrl, { isLoading: isDocumentLoading }] =
    useGetDocumentRedirectionLinkMutation();
  const [reportIssue] = useReportIssueMutation();

  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const { id: cinId } = useParams<{ id: string }>();

  const onToggle = (accordionId: string) => {
    setOpenAccordion((prevState) => (prevState === accordionId ? null : accordionId));
  };

  const chargeDetailsFields = useMemo(() => {
    if (!chargeDetails) return [];
    return [
      {
        label: 'Instrument Description',
        value: chargeDetails?.data.instrument_description,
      },
      { label: 'Rate of Interest', value: chargeDetails?.data.rate_of_interest },
      { label: 'Terms of Payment', value: chargeDetails?.data.terms_of_payment },
      { label: 'Property Particulars', value: chargeDetails?.data.property_particulars },
      { label: 'Property Type', value: chargeDetails?.data.property_type },
      { label: 'Extent and Operation', value: chargeDetails?.data.extent_and_operation },
      { label: 'Other Terms', value: chargeDetails?.data.other_terms },
      { label: 'Joint Holding', value: chargeDetails?.data.joint_holding },
      { label: 'Consortium Holding', value: chargeDetails?.data.consortium_holding },
    ];
  }, [chargeDetails]);

  const hasNonNullValues = useMemo(() => {
    return chargeDetailsFields.some((field) => field.value !== null);
  }, [chargeDetailsFields]);

  const handleReportIssue = async (issue: string, description: string, documentName: string) => {
    try {
      await reportIssue({
        issue,
        description: `${description} - Document: ${documentName}`,
      }).unwrap();
    } catch (error) {
      console.error('Failed to report issue:', error);
    }
  };

  const extractFilenameFromUrl = (url: string): string => {
    try {
      const decodedUrl = decodeURIComponent(url);
      const urlObj = new URL(decodedUrl);
      const pathname = urlObj.pathname;
      const fileNameWithParams = pathname.split('/').pop();
      if (fileNameWithParams) {
        return fileNameWithParams.split('?')[0];
      }
      throw new Error('Unable to extract filename from URL');
    } catch (error) {
      console.error('Error extracting filename:', error);
      toast.error('Failed to extract filename from URL');
      handleReportIssue('Filename Extraction Error', 'Failed to extract filename from URL', url);
      return 'document.pdf';
    }
  };

  const forceFileDownload = async (url: string, filename: string): Promise<void> => {
    if (isDocumentLoading) return;
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download the document');
      handleReportIssue('File Download Error', 'Failed to download the document', filename);
    }
  };

  const openPdfInNewTab = (url: string, documentName: string): void => {
    if (isDocumentLoading) return;
    try {
      const decodedUrl = decodeURIComponent(url);
      window.open(
        `${window.location.origin}/pdf-viewer#${decodedUrl}`,
        '_blank',
        'noopener,noreferrer',
      );
    } catch (error) {
      console.error('Error opening PDF in new tab:', error);
      toast.error('Failed to open the PDF in a new tab');
      handleReportIssue('PDF Viewer Error', 'Failed to open the PDF in a new tab', documentName);
    }
  };

  const handleDocumentClick = async (
    item: DocumentItem,
    isDownload: boolean = false,
  ): Promise<void> => {
    if (!chargeDetails || !documents) return;
    const payload: PresignedUrlPayLoad = {
      s3_url: documents.s3_url,
      is_download: isDownload,
      identifier_type: identifierType,
      identifier_value: cinId as string,
    };
    if (item.isAttachment && item.attachment) {
      Object.assign(payload, {
        s3_url: item.attachment.s3_url,
        is_download: isDownload,
      });
    }
    try {
      const response = await getDocumentUrl(payload).unwrap();

      if (response.pre_signed_url) {
        if (isDownload) {
          const filename = extractFilenameFromUrl(response.pre_signed_url);
          await forceFileDownload(response.pre_signed_url, filename);
        } else {
          openPdfInNewTab(response.pre_signed_url, item.name);
        }
      }
    } catch (error) {
      console.error('Error fetching document URL:', error);
      toast.error('Failed to fetch the document URL');
      handleReportIssue(
        'Document Fetch Error',
        `Failed to fetch the document URL for path ${payload?.s3_url} `,
        item.name,
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='Charge Overview'
      className='w-[calc(100vw-36rem)]'
    >
      <div className='p-6'>
        {chargeDetails && (
          <div className='flex justify-between mb-6'>
            <div>
              <p className='text-secondary-700 text-sm'>Charge ID</p>
              <p className='text-sm font-semibold text-gray-700'>{chargeDetails?.data.charge_id}</p>
            </div>
            <div>
              <p className='text-secondary-700 text-sm'>Date</p>
              <p className='text-sm font-semibold text-gray-700'>{chargeDetails?.data.date}</p>
            </div>
            <div>
              <p className='text-secondary-700 text-sm'>Amount</p>
              <p className='text-sm font-semibold text-gray-700'>{chargeDetails?.data.amount} cr</p>
            </div>
          </div>
        )}

        {hasNonNullValues ? (
          <Accordion
            icon={null}
            title='Charge Details'
            isOpen={openAccordion === 'charge-details'}
            onToggle={() => onToggle('charge-details')}
          >
            <table className='max-w-full min-w-full divide-y divide-gray-200 !border-b-tertiary-600'>
              <tbody className='bg-white divide-y divide-gray-200'>
                {chargeDetailsFields.map((field, index) => (
                  <tr key={index} className='border b-2'>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 font-normal border-r'>
                      {field.label}
                    </td>
                    <td className='px-6 py-4 whitespace-break-spaces text-sm text-secondary-700 font-medium max-w-xl overflow-auto'>
                      {field.value || '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Accordion>
        ) : (
          <div className='p-4 text-center text-gray-600 w-full flex justify-center'>
            <ErrorMessage
              message='Form is not available in public registry MCA V2'
              className='w-fit'
            />
          </div>
        )}

        {/* Document section */}
        {chargeDetails && documents && Object.keys(documents).length > 0 && (
          <Accordion
            icon={null}
            title='Supporting Documents'
            isOpen={openAccordion === 'documents-details'}
            onToggle={() => onToggle('documents-details')}
          >
            <div className='mt-0 ml-8'>
              <h4 className='text-md text-gray-600 font-semibold mb-3'>Primary Document</h4>
              <div className='flex'>
                <DownloadDocuments
                  name={documents.file_name ?? ''}
                  isAttachment={false}
                  isDocumentLoading={isDocumentLoading}
                  handleDocumentClick={handleDocumentClick}
                />
              </div>
              {chargeDetails.metadata.attachments &&
                chargeDetails.metadata.attachments.length > 0 && (
                  <>
                    <h4 className='text-md text-gray-600 font-semibold mb-3 mt-4'>Attachments</h4>
                    <div className='flex'>
                      {chargeDetails.metadata?.attachments?.length ? (
                        chargeDetails.metadata.attachments.map((attachment, index) => (
                          <React.Fragment key={index}>
                            <DownloadDocuments
                              name={attachment.file_name ?? ''}
                              isAttachment={true}
                              isDocumentLoading={isDocumentLoading}
                              attachment={attachment}
                              handleDocumentClick={handleDocumentClick}
                            />
                          </React.Fragment>
                        ))
                      ) : (
                        <p className='text-gray-500'>No attachments available</p>
                      )}
                    </div>
                  </>
                )}
            </div>
          </Accordion>
        )}
        <div className='mt-4 flex justify-center'>
          <Button
            text='Close'
            onClick={onClose}
            className='w-5/12 text-center bg-transparent border font-semibold !text-slate-500'
          />
        </div>
      </div>
    </Modal>
  );
};
