import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Payment.css';
import { formatDate } from '@components/utils/commonUtils';
import { useCheckOrderStatusQuery, useReportIssueMutation } from '@app/store/api/companyApi';
import Modal from '@app/components/lib-components/modal/Modal';

const PaymentPending = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [message, setMessage] = useState('Your payment is being processed. Please wait...');
  const [transactionId, setTransactionId] = useState('');
  const [executedDate, setExecutedDate] = useState('');
  const [isTimeout, setIsTimeout] = useState(false);
  const [isIssueReported, setIsIssueReported] = useState(false);

  const { token_order_ledger_id: juspay_order_id } = location.state || {};

  const { data: orderStatus } = useCheckOrderStatusQuery(juspay_order_id, {
    pollingInterval: 5000,
    skip: !juspay_order_id,
  });

  const [reportIssue, { isLoading: isReportLoading }] = useReportIssueMutation();

  const handleReportIssue = () => {
    if (!isIssueReported) {
      reportIssue({
        issue: 'Payment Status Pending',
        description: `Payment status remained pending for order ID: ${juspay_order_id}`,
      })
        .unwrap()
        .then(() => {
          setIsIssueReported(true);
          setTimeout(() => {
            navigate('/');
          }, 1000);
        })
        .catch((error) => {
          console.error('Failed to report issue:', error);
        });
    }
  };

  const handleGoBack = () => {
    const returnUrl = localStorage.getItem('payment_return_url');
    localStorage.removeItem('payment_return_url');
    localStorage.removeItem('payment_order_id');
    navigate(returnUrl || '/');
  };

  useEffect(() => {
    if (!juspay_order_id) {
      setMessage('No order ID provided. Redirecting to the dashboard...');
      localStorage.removeItem('payment_return_url');
      localStorage.removeItem('payment_order_id');
      setTimeout(() => navigate('/'), 100);
      return;
    }

    // Set timeout for 2 minutes
    const timeout = setTimeout(() => {
      setIsTimeout(true);
      setMessage('Payment status is still pending. You can:');
    }, 120000);

    return () => clearTimeout(timeout);
  }, [juspay_order_id, navigate]);

  useEffect(() => {
    if (orderStatus) {
      const { status: responseStatus, response_data } = orderStatus;

      if (responseStatus === 'SUCCESS') {
        const { juspay_order_status, juspay_order_id, created_at } = response_data;

        setTransactionId(juspay_order_id);
        setExecutedDate(formatDate(created_at));

        if (juspay_order_status === 'COMPLETED') {
          setMessage('Payment Successful!');
        } else if (juspay_order_status === 'PENDING') {
          setMessage('Your payment is being processed. Please wait...');
        } else if (juspay_order_status === 'FAILED') {
          setMessage('Your payment has failed.');
        }
      }
    }
  }, [orderStatus]);

  useEffect(() => {
    if (
      orderStatus?.response_data?.juspay_order_status === 'COMPLETED' ||
      orderStatus?.response_data?.juspay_order_status === 'FAILED'
    ) {
      localStorage.removeItem('payment_return_url');
      localStorage.removeItem('payment_order_id');
      const redirectTimeout = setTimeout(() => {
        navigate('/');
      }, 20000);
      return () => clearTimeout(redirectTimeout);
    }
  }, [orderStatus, navigate]);

  const paymentStatus = orderStatus?.response_data?.juspay_order_status;
  const isSuccess = paymentStatus === 'COMPLETED';
  const isFailed = paymentStatus === 'FAILED';
  const isPending = !isSuccess && !isFailed;

  return (
    <Modal isOpen={true} onClose={handleGoBack}>
      <div className='text-center'>
        <div className='icon-container mb-4'>
          {isSuccess && <div className='icon success'>✔</div>}
          {isFailed && <div className='icon failed'>✘</div>}
          {isPending && <div className='loader'></div>}
        </div>
        <h1 className='text-md font-semibold mb-2 text-brand_color'>{message}</h1>
        {isSuccess && (
          <div className='mt-4 text-start'>
            <p className='text-sm text-slate-500 mb-3'>
              Transaction ID{' '}
              <span className='text-brand_color'>
                {transactionId ? transactionId : 'Fetching...'}
              </span>
            </p>
            <p className='text-sm text-slate-500'>
              Executed on{' '}
              <span className='text-brand_color'>
                {executedDate ? executedDate : 'Fetching...'}
              </span>
            </p>
          </div>
        )}
        {(isTimeout || isFailed) && (
          <div className='flex flex-col gap-3 mt-4'>
            <button
              onClick={handleGoBack}
              className='px-6 py-2 bg-brand_color text-white rounded-md'
            >
              Go Back
            </button>
            {!isIssueReported ? (
              <button
                onClick={handleReportIssue}
                className='px-4 py-2 bg-transparent text-brand_color text-sm border border-brand_color rounded-md hover:bg-blue-50'
                disabled={isReportLoading}
              >
                {isReportLoading ? 'Reporting...' : 'Report Issue'}
              </button>
            ) : (
              <p className='text-green-600 text-sm'>Issue reported successfully!</p>
            )}
          </div>
        )}
        {isSuccess && !isTimeout && (
          <button
            onClick={handleGoBack}
            className='mt-6 px-6 py-2 bg-brand_color text-white rounded-md'
          >
            Go Back
          </button>
        )}
      </div>
    </Modal>
  );
};

export default PaymentPending;
