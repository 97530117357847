import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setShowNudge } from '@app/store/slices/utilSlice';
import pdfIcon from '@app/assets/pdfIcon.svg';
import { DocumentModal } from '@components/lib-components/modal/DocumentModal';
import {
  ShareholdingTypes,
  ContributionResponse,
  IndividualPartner,
  BodyCorporatePartner,
  SummaryDesignatedPartners,
} from '@components/company/company-tabs/ShareHoldingPattern/ShareholdingProps.types';
import { Metadata } from '@app/types/financial';
import { OutstandingDuesItem, PrincipleActivitiesItem } from '@app/types/compliances';

interface DocumentIconButtonProps {
  metadata: Metadata | null;
  documentData:
    | ShareholdingTypes
    | OutstandingDuesItem
    | PrincipleActivitiesItem
    | ContributionResponse<IndividualPartner[]>
    | ContributionResponse<BodyCorporatePartner[]>
    | ContributionResponse<SummaryDesignatedPartners[]>
    | null;
}

export const DocumentIconButton: React.FC<DocumentIconButtonProps> = ({
  metadata,
  documentData,
}) => {
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const dispatch = useDispatch();

  const handleDocumentClick = () => {
    setShowDocumentsModal(true);
  };

  const handleCloseModal = () => {
    setShowDocumentsModal(false);
    dispatch(setShowNudge(false));
  };

  if (!metadata) return null;

  return (
    <>
      <img
        className='pl-0 ml-3 cursor-pointer'
        src={pdfIcon}
        width={24}
        onClick={handleDocumentClick}
        alt='Document'
      />
      <DocumentModal
        isOpen={showDocumentsModal}
        onClose={handleCloseModal}
        documentData={documentData}
      />
    </>
  );
};
