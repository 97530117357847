import { Attachments } from '@app/types/financial';
import { Tooltip } from '@components/Tooltip/Tooltip';
import pdfIcon from '@app/assets/pdfIcon.svg';
import Icon from '@components/lib-components/icon/Icon';
import Button from '@components/lib-components/button/Button';

interface ChargesDocumentProps {
  name: string;
  isAttachment: boolean;
  attachment?: Attachments;
  isDocumentLoading: boolean;
  handleDocumentClick: (
    payload: { name: string; isAttachment: boolean; attachment?: Attachments },
    newWindow?: boolean,
  ) => void;
}

export const DownloadDocuments = ({
  name,
  isAttachment,
  attachment,
  isDocumentLoading,
  handleDocumentClick,
}: ChargesDocumentProps) => {
  const truncateText = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  return (
    <div className='flex-col mr-4'>
      <div className='flex align-baseline items-end !mb-2'>
        <img
          className={`pl-0 ${isDocumentLoading ? '' : 'cursor-pointer'} z-[1]`}
          src={pdfIcon}
          width={60}
          onClick={() => handleDocumentClick({ name, isAttachment, attachment })}
          alt='PDF Icon'
        />
        <Button
          onClick={() => handleDocumentClick({ name, isAttachment, attachment }, true)}
          className='bg-transparent !py-0'
          disabled={isDocumentLoading}
        >
          <Icon icon='DownloadIcon' size={30} />
        </Button>
      </div>
      <Tooltip content={name}>
        <span
          onClick={() => handleDocumentClick({ name, isAttachment, attachment })}
          className='truncate cursor-pointer text-sm'
        >
          {truncateText(name, 10)}
        </span>
      </Tooltip>
    </div>
  );
};
