import React, { useState, useEffect } from 'react';
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
  Edge,
  Node,
  EdgeTypes,
} from 'react-flow-renderer';
import { nanoid } from '@reduxjs/toolkit';
import { CustomEdge, CustomLabel } from './CustomEdge';
import Modal from '../../../lib-components/modal/Modal';
import { DirectorNetwork, NetworkCompany, NetworkLLP } from '@app/types/management';
import { useLocalNavigation } from '@app/hooks/useLocalNavigation';

const edgeTypes: EdgeTypes = {
  custom: CustomEdge,
};

interface DirectorsNetworkProps {
  directorNetwork: DirectorNetwork[];
  selectedDirector: DirectorNetwork;
  isOpen: boolean;
  onClose: () => void;
}

type NetworkEntity = NetworkCompany | NetworkLLP;

const DirectorsNetwork: React.FC<DirectorsNetworkProps> = ({
  directorNetwork,
  selectedDirector,
}) => {
  const navigate = useLocalNavigation();
  const [nodes, setNodes, onNodesChange] = useNodesState<Node[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>([]);
  const [selectedEntitiesList, setSelectedEntitiesList] = useState<NetworkEntity[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleCountClick = (entities: NetworkEntity[]) => {
    setSelectedEntitiesList(entities);
    setModalVisible(true);
  };

  const getAllEntities = (director: DirectorNetwork): NetworkEntity[] => {
    return [
      ...(director.network?.companies?.current || []),
      ...(director.network?.companies?.previous || []),
      ...(director.network?.llps?.current || []),
      ...(director.network?.llps?.previous || []),
      ...(director.network?.foreign_companies?.current || []),
      ...(director.network?.foreign_companies?.previous || []),
    ];
  };

  const prepareGraphData = () => {
    // Initialize arrays to store nodes and edges for the graph
    const newNodes: Node[] = [];
    const newEdges: Edge[] = [];
    // Map to store directors with their generated IDs
    const directorMap: Record<string, DirectorNetwork & { id: string }> = {};
    // Set to store DIINs of directors connected to the selected director
    const connectedDirectors: Set<string> = new Set();

    // Get all entities (companies, LLPs, etc.) associated with the selected director
    const selectedDirectorEntities = getAllEntities(selectedDirector);

    // First pass: identify directors connected to the selected director
    directorNetwork.forEach((director) => {
      // Check if the director has a DIN and is not the selected director
      if (director.din && director.din !== selectedDirector.din) {
        const directorEntities = getAllEntities(director);
        // Find entities that are shared between this director and the selected director
        const sharedEntities = directorEntities.filter((e1) =>
          selectedDirectorEntities.some(
            (e2) =>
              // Compare CIN for companies or LLPIN for LLPs
              ('cin' in e1 && 'cin' in e2 && e1.cin === e2.cin) ||
              ('llpin' in e1 && 'llpin' in e2 && e1.llpin === e2.llpin),
          ),
        );
        // If there are shared entities, add this director to the connected set
        if (sharedEntities.length > 0) {
          connectedDirectors.add(director.din);
        }
      }
    });

    // Add selected director node to the graph
    const selectedDirectorId = nanoid();
    directorMap[selectedDirector.din] = { ...selectedDirector, id: selectedDirectorId };
    newNodes.push({
      id: selectedDirectorId,
      data: { label: selectedDirector.name },
      position: { x: 300, y: 300 }, // Position the selected director in the center
      className: 'highlighted-node',
    });

    // Add connected directors and create edges
    let index = 0;
    connectedDirectors.forEach((din) => {
      // Find the director object from the network
      const director = directorNetwork.find((d) => d.din === din)!;
      const directorId = nanoid();
      directorMap[din] = { ...director, id: directorId };

      // Calculate vertical position for the connected director node
      const yPosition = 100 + index * 100;
      newNodes.push({
        id: directorId,
        data: { label: director.name },
        position: { x: 700, y: yPosition }, // Position connected directors to the right
        className: 'dimmed-node',
      });

      // Recalculate shared entities for edge data
      const directorEntities = getAllEntities(director);
      const sharedEntities = directorEntities.filter((e1) =>
        selectedDirectorEntities.some(
          (e2) =>
            ('cin' in e1 && 'cin' in e2 && e1.cin === e2.cin) ||
            ('llpin' in e1 && 'llpin' in e2 && e1.llpin === e2.llpin),
        ),
      );

      // Create an edge between the selected director and this connected director
      const edgeId = nanoid();
      newEdges.push({
        id: edgeId,
        source: selectedDirectorId,
        target: directorId,
        type: 'custom',
        data: { entities: sharedEntities },
        label: (
          <CustomLabel
            text={`${sharedEntities.length}`}
            onClick={() => handleCountClick(sharedEntities)}
          />
        ),
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        style: {
          strokeWidth: 2,
          stroke: 'blue',
        },
      });

      index++;
    });

    // Update the state with the new nodes and edges
    setNodes(newNodes);
    setEdges(newEdges);
  };

  useEffect(() => {
    prepareGraphData();
  }, [directorNetwork]);

  return (
    <div style={{ width: '100%', height: '600px' }} className='relative'>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        edgeTypes={edgeTypes}
        fitView
      >
        <Controls />
        <Background />
      </ReactFlow>
      {modalVisible && (
        <Modal
          className='w-[70%] max-w-[90vw]'
          isOpen={modalVisible}
          onClose={() => setModalVisible(false)}
          title='Shared Entities'
        >
          <ul>
            {selectedEntitiesList.map((entity, index) => (
              <div className='flex items-center mt-3' key={index}>
                <li className='mr-2 items-center font-normal'>
                  {entity.legal_name}
                  <span
                    className='text-blue-700 font-bold cursor-pointer'
                    onClick={() => {
                      const id = 'cin' in entity ? entity.cin : entity.llpin;
                      navigate(id, 'overview', {
                        replace: true,
                      });
                    }}
                  >
                    &nbsp;({'cin' in entity ? entity.cin : entity.llpin})
                  </span>
                </li>
              </div>
            ))}
          </ul>
        </Modal>
      )}
    </div>
  );
};

export default DirectorsNetwork;
