import React from 'react';
import Icon from '@components/lib-components/icon/Icon';

interface ContactInfoProps {
  contactInfo: {
    pan: string;
    age: string;
    nationality: string;
    gender: string;
    phoneNumber: string;
    email: string;
    address: string;
    grossSalary: string;
    dscStatus: string;
    dscExpiryDate: string;
  };
}

const ContactInfo: React.FC<ContactInfoProps> = ({ contactInfo }) => {
  return (
    <div className='bg-white p-6 rounded-lg border border-gray-200 mt-4'>
      <div className='mb-6'>
        <div className='flex items-center gap-2 mb-4'>
          <Icon
            icon='UserIcon'
            variant='outline'
            size={25}
            className='text-gray-700 border border-gray-600 p-1 rounded-full'
          />
          <h2 className='text-md font-semibold text-primary-900'>Identity details</h2>
        </div>
        <div className='flex gap-8 justify-between mb-6'>
          <p className='w-1/4 text-sm'>
            <span className='font-medium text-sm text-tertiary-600'>PAN:</span>
            <br /> {contactInfo.pan}
          </p>
          <p className='w-1/4 text-sm'>
            <span className='font-medium text-sm text-tertiary-600'>Age:</span>
            <br /> {contactInfo.age}
          </p>
          <p className='w-1/4 text-sm'>
            <span className='font-medium text-sm text-tertiary-600'>Nationality:</span>
            <br /> {contactInfo.nationality}
          </p>
          <p className='w-1/4 text-sm'>
            <span className='font-medium text-sm text-tertiary-600'>Gender:</span>
            <br /> {contactInfo.gender}
          </p>
        </div>
        <hr />
      </div>
      <div className='mb-6'>
        <div className='flex items-center gap-2 mb-4'>
          <Icon
            icon='PhoneIcon'
            variant='outline'
            size={25}
            className='text-gray-800 rounded-full border border-gray-600 py-0.5 px-1'
          />
          <h2 className='text-md font-semibold text-primary-900 '>Contact details</h2>
        </div>
        <div className='flex gap-8 justify-between mb-6'>
          <p className='w-1/3 text-sm'>
            <span className='font-medium  text-sm text-tertiary-600'>Phone Number:</span>
            <br /> {contactInfo.phoneNumber}
          </p>
          <p className='w-1/3 text-sm'>
            <span className='font-medium text-sm text-tertiary-600'>Email ID:</span>
            <br /> {contactInfo.email}
          </p>
          <p className='w-1/3 text-sm'>
            <span className='font-medium text-sm text-tertiary-600'>Address:</span>
            <br /> {contactInfo.address}
          </p>
        </div>
        <hr />
      </div>
      <div>
        <div className='flex items-center gap-2 mb-4'>
          <Icon
            icon='BanknotesIcon'
            variant='outline'
            size={25}
            className='text-gray-800 rounded-full border border-gray-600 p-1'
          />
          <h2 className='text-md font-semibold text-primary-900 '>
            Financial & Compliance Information
          </h2>
        </div>
        <div className='flex gap-8 justify-between'>
          <p className='w-1/3 text-sm'>
            <span className='font-medium text-sm text-tertiary-600'>Gross Salary:</span>
            <br /> {contactInfo.grossSalary}
          </p>
          <p className='w-1/3 text-sm'>
            <span className='font-medium text-sm text-tertiary-600'>DSC Status:</span>
            <br /> {contactInfo.dscStatus}
          </p>
          <p className='w-1/3 text-sm'>
            <span className='font-medium text-sm text-tertiary-600'>DSC Expiry Date:</span>
            <br /> {contactInfo.dscExpiryDate}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
