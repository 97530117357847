import { useMemo } from 'react';
import TableComponent from '@components/lib-components/table/table/TableComponent';
import { ChargesResponse } from '@app/types/charges';
import Loading from '@components/lib-components/loading/Loading';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';

type OpenCharges = ChargesResponse['charges']['open_charges'];
interface OpenChargesProps {
  data: OpenCharges;
  isLoading: boolean;
  isError: boolean;
}

const headers = ['ID', 'Date', 'Holder Name', 'Status', 'Amount (in Cr)'];

const transformOpenChargesData = (data: OpenCharges) => {
  if (data) {
    return data.map((charge) => ({
      id: charge.charge_id.toString() || '-',
      date: charge.date || '-',
      holderName: charge.holder_name || '-',
      status: charge.event_type || '-',
      amount: (charge.amount / 1e7).toFixed(2) || '-', // Convert to Cr
    }));
  } else return null;
};

export const OpenCharges = ({ data, isLoading, isError }: OpenChargesProps) => {
  const tableData = useMemo(() => {
    return data ? transformOpenChargesData(data) : [];
  }, [data]);

  if (isLoading)
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );
  if (isError)
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage message='Error fetching open charges details, please try again later.' />;
      </div>
    );

  return (
    <div className='my-5'>
      <h3 className='text-xl text-heading font-semibold mb-3'>Open Charges Summary</h3>
      {tableData && tableData.length > 0 ? (
        // @ts-expect-error @sreehari-credhive TODO - FIX TS
        <TableComponent headers={headers} rows={tableData} badgeStatus={true} />
      ) : (
        <ErrorMessage message='No open charges data available for this company.' />
      )}
    </div>
  );
};
