import MenuItem from '../menu-item/MenuItem';
import { MenuProps } from './menu.types';

const Menu = ({ itemList, selectedId, handleMenuItemClick }: MenuProps) => {
  return (
    <div className='w-full h-full pb-2 flex flex-col gap-2.5'>
      {itemList.map(({ text, id, icon, iconSize, pathname }, index) => (
        <MenuItem
          key={index}
          text={text}
          icon={icon}
          iconSize={iconSize}
          id={id}
          isDefaultSelected={selectedId === id}
          handleMenuItemClick={handleMenuItemClick}
          pathname={pathname ? pathname : ''}
        />
      ))}
    </div>
  );
};

export default Menu;
