import { useState, useMemo, useCallback } from 'react';
import { ChargeOverviewModal } from './ChargeOverviewModal';
import TableComponent from '@components/lib-components/table/table/TableComponent';
import {
  ApiResponse,
  ChargesResponse,
  ChargeDetailsModalProps,
  TableRow,
} from '@app/types/charges';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { useParams } from 'react-router-dom';
import Loading from '@components/lib-components/loading/Loading';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { OpenCharges } from './OpenCharges';
import { TabsCommonPropType } from '@components/company/companyCard.types';
import { transformData } from './utils';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';

const headers = [
  'Charge ID',
  'Date',
  'Holder Name',
  'Status',
  'Amount(in Cr)',
  'Total Holders',
  '',
];

const Charges: React.FC<TabsCommonPropType> = () => {
  const { id } = useParams<string>();
  const identifierType = useSelector((state: RootState) => state.util.identifierType);

  const [showChargesModal, setShowChargesModal] = useState<boolean>(false);
  const getOpenChargesData = useGetCompanyData<ApiResponse<ChargesResponse>>();

  const { data, isLoading, isError } = getOpenChargesData({
    cinId: id as string,
    identifierType: identifierType,
    field_name: 'charges',
  });
  const [selectedChargeDetails, setSelectedChargeDetails] = useState<ChargeDetailsModalProps>();

  const isReportFetching =
    data?.response_data?.status === ReportStatusEnum.INITIALISED ||
    data?.response_data?.status === ReportStatusEnum.IN_PROGRESS;

  const { tableData, chargeDetailsMap, hasPartialData } = useMemo(() => {
    if (data && Array.isArray(data.response_data?.charges?.all_charges_latest_events)) {
      return transformData(data.response_data?.charges?.all_charges_latest_events);
    }
    return { tableData: [], chargeDetailsMap: {}, hasPartialData: false };
  }, [data]);

  const handleViewClick = useCallback(
    (row: TableRow) => {
      if (row?.id) {
        const chargeDetails = chargeDetailsMap[row.id];
        setSelectedChargeDetails(chargeDetails);
        setShowChargesModal(true);
      }
    },
    [chargeDetailsMap],
  );

  const handleCloseModal = useCallback(() => {
    setShowChargesModal(false);
    setSelectedChargeDetails(undefined);
  }, []);

  if (isReportFetching) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage
          message={'Kindly wait a few minutes, we are fetching the company charges data for you.'}
        />
      </div>
    );
  }
  if (isLoading)
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );

  if (isError)
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage message='Error fetching open charges details, please try again later.' />
      </div>
    );

  return (
    <div className='w-full'>
      <OpenCharges
        data={data.response_data.charges.open_charges}
        isLoading={isLoading}
        isError={isError}
      />
      {tableData?.length > 0 ? (
        <div className='mb-4'>
          <h3 className='text-xl text-heading font-semibold mb-3'>
            All detailed Charges{' '}
            <span className='text-sm text-gray-500'> (including Open/Closed)</span>
          </h3>
          <TableComponent
            headers={headers}
            rows={tableData}
            onViewClick={handleViewClick}
            badgeStatus={true}
          />
          <ChargeOverviewModal
            isOpen={showChargesModal}
            chargeDetails={selectedChargeDetails}
            onClose={handleCloseModal}
            documents={selectedChargeDetails?.metadata}
          />
        </div>
      ) : (
        <ErrorMessage
          message={
            hasPartialData
              ? 'Charges data is not available for the company.'
              : 'Charges data is not available for the company, please try in a while.'
          }
        />
      )}
    </div>
  );
};

export default Charges;
