import {
  MsmeCompanyData,
  EnterpriseType,
  CompanyRelationType,
  MsmeBusinessInformationData,
} from './msmeTypes';

export const MSME_DATA: Record<string, MsmeCompanyData> = {
  PANAPUPR0030E: {
    legal_name: 'CARTER UNIQUE GROUP',
    pan: 'APUPR0030E',
    lei: {
      number: '335800HG6F7P863D3766',
      status: 'ACTIVE',
    },
    classification: 'Proprietorship',
    efiling_status: 'Active',
    annual_turnover: '₹10 Crores',
    registered_address: {
      address_line: 'Khasra No. 32/ 25/ 2/ 2 Min, 33/ 21/ 1, 21/ 2 And 22/1, Village Ghogripur',
      city: 'Karnal',
      state: 'Haryana',
      pincode: '132037',
    },
    website: 'www.uniquecolorsorter.com',
    last_filing_date: '2023-11-30',
    last_checked_date: '2024-03-15',
    enterprise_type: EnterpriseType.Proprietorship,
    registration_date: '2011',
    employees: 100,
    industry: 'Machinery & Tools',
    products: [
      'Dry Fruits Color Sorter',
      'Packing Machine',
      'Spices Color Sorter',
      'Herbs Color Sorter',
      'Mineral Color Sorter',
      'Weaving Machines',
      'Auxiliary Machinery',
      'Vibro Separator',
      'Air Compressor',
      'Gravity Separator',
    ],
    services: [
      'After Sale Services',
      'Repairing and Maintenance',
      'Installation Services',
      'Technical Support',
      'Annual Maintenance Contract',
      'Operator Training',
      'Software Updates',
    ],
    nature_of_activity: 'Manufacturer/ Trader/ Importer/ Service Provider',
    contact_info: {
      name: 'Mr. Ravinder Mann',
      role: 'Proprietor',
      contact_no: ['+91 184 4066066', '+91 98180 40660'],
      email: ['uniquekarnal@gmail.com', 'carter@uniquecolorsorter.com'],
    },
    previous_names: [{ name: 'UNIQUE TECH SOLUTIONS - NA' }, { name: 'UNIQUE COLOR SORTER - NA' }],
    parent_group: 'CARTER',
    type: 'MSME',
    competitors: [
      {
        name: 'Lakshmi Machinery',
        country: 'India',
      },
      {
        name: 'G P International',
        country: 'India',
      },
    ],
    relatedParties: [
      {
        name: 'UNIQUE TECHNICAL SOLUTIONS',
        country: 'India',
        type: CompanyRelationType.SisterConcernCompany,
      },
      {
        name: 'CARTER GROUP INC',
        country: 'India',
        type: CompanyRelationType.SisterConcernCompany,
      },
      {
        name: 'ALLIANCE AIR SOLUTION',
        country: 'India',
        type: CompanyRelationType.SisterConcernCompany,
      },
      {
        name: 'UNIQUE COLOR SORTER PRIVATE LIMITED',
        country: 'India',
        type: CompanyRelationType.SisterConcernCompany,
      },
    ],
  },
  PANAALFR8552P: {
    legal_name: 'RED EXIM',
    pan: 'AALFR8552P',
    msme_number: 'UDYAM-MH-18-0015254',
    tan: 'MUMR28282C',
    lei: {
      number: '335800HG6F7P863D3766',
      status: 'ACTIVE',
    },
    classification: 'Partnership',
    efiling_status: 'Active',
    registered_address: {
      address_line: 'E C- 6051, 6th Floor, Bharat Diamond Bourse, Bandra Kurla Complex',
      city: 'Mumbai',
      state: 'Maharashtra',
      pincode: '400051',
    },
    website: 'www.redexim.in',
    last_filing_date: '2023-12-31',
    last_checked_date: '2024-03-15',
    enterprise_type: EnterpriseType.Partnership,
    registration_date: '2010',
    employees: 4,
    industry: 'Gems And Jewellery',
    products: [
      'Diamonds',
      'Cut and Polished Diamonds',
      'Natural Diamonds',
      'Lab-Grown Diamonds',
      'Loose Diamonds',
      'Certified Diamonds',
      'Fancy Color Diamonds',
    ],
    nature_of_activity: 'Manufacturer/ Trader/ Importer/ Exporter',
    contact_info: {
      name: 'Mr. Sujal Hiralal Shah',
      role: 'Partner',
      contact_no: ['+91 22 40974848', '+91 98200 40974'],
      email: ['accounts@redexim.co.in', 'redexim@redexim.co.in'],
    },
    annual_turnover: '₹15 Crores',
    type: 'MSME',
    competitors: [
      {
        name: 'Suresh And Company',
        country: 'India',
      },
      {
        name: 'Blue Petal Diamond',
        country: 'India',
      },
    ],
    relatedParties: [
      {
        name: 'N GOPALDAS & CO.',
        country: 'India',
        type: CompanyRelationType.SisterConcernCompany,
      },
      {
        name: 'AARNA JEWELS',
        country: 'India',
        type: CompanyRelationType.SisterConcernCompany,
      },
    ],
    creditRatings: [
      {
        sNo: '1',
        lastVerifiedDate: '06 Oct, 2023',
        amount: '₹95,00,00,000',
        instrumentType: 'Long Term Bank Facilities',
        rating: 'CARE BBB; Stable',
        ratingAgency: 'CARE',
      },
      {
        sNo: '2',
        lastVerifiedDate: '06 Oct, 2023',
        amount: '₹15,00,00,000',
        instrumentType: 'Long Term / Short Term Bank Facilities',
        rating: 'CARE BBB; Stable / CARE A3+',
        ratingAgency: 'CARE',
      },
    ],
  },
};

export const isMsmeCompany = (id: string) => {
  return id.startsWith('PAN') && (id === 'PANAPUPR0030E' || id === 'PANAALFR8552P');
};

export const redEximData: MsmeBusinessInformationData = {
  importsFrom: ['China - CN', 'Europe - EU', 'United States - US', 'Dubai - AE'],
  exportsTo: [
    'Belgium - BE',
    'Russia - RU',
    'United States - US',
    'Dubai - AE',
    'South Africa - ZA',
    'Israel - IL',
  ],
  domesticTerms: {
    purchasing: 'N.A',
    selling: 'N.A',
  },
  internationalTerms: {
    purchasing: 'N.A',
    selling: 'N.A',
  },
  majorCustomers: [
    'CHOW TAI FOOK JEWELLERY CO LTD - HONG KONG',
    'STERLING JEWELERS INC.- UNITED STATES',
    'MOSHE NAMDAR MASINDITA LTD- ISRAEL',
    'B B BURKHARDT BISCHOFF GMBH CO- GERMANY',
    'GEM STAR INC- CANADA',
    'PRAVARA DIAM BVBA- BELGIUM',
    'G R THANGA MALIGAI- INDIA',
  ],
  majorSuppliers: [
    'AKSHAR IMPEX PRIVATE LIMITED- INDIA',
    'HIRAL EXPORTS- INDIA',
    'KARP IMPEX LTD- INDIA',
    'KGK DIAMONDS (I) PRIVATE LIMITED- INDIA',
    'LAXMI DIAMOND PRIVATE LIMITED- INDIA',
  ],
};

export const carterUniqueData: MsmeBusinessInformationData = {
  importsFrom: ['China - CN'],
  exportsTo: [],
  domesticTerms: {
    purchasing: 'Cheque, Credit Days',
    selling: 'Cheque, Credit Days',
  },
  internationalTerms: {
    purchasing: 'N.A',
    selling: 'N.A',
  },
  majorCustomers: [
    'SHRI JAGDAMBA RICE MILL -INDIA',
    'SHIRADHA AGRO INDUSTRIES -INDIA',
    'LEKH RAJ HARINDER KUMAR -INDIA',
    'BAVRAJ RICE MILL -INDIA',
    'SHREE SHYAMJI FOOD PRODUCTS - INDIA',
    'GRM FOODS PRIVATE LIMITED-INDIA',
  ],
  majorSuppliers: [
    'HEFEI AUGUS MACHINERY IMPORT & EXPORT CO. LTD - CHINA',
    'ANHUI ZHONGKE OPTIC-ELECTRONIC COLOR SORTER MACHINERY CO. LTD. - CHINA',
    'ANQING CART MACHINERY COMPLETE EQUIPMENT SALES CO- CHINA',
    'ANHUI ZHONGKE OPTIC- ELECTRONIC COLOR SORTER CO LIMITED- CHINA',
  ],
};

interface FinancialPosition {
  turnover: string;
  expenditure: string;
  netProfit: string;
  lastVerification: string;
}

interface BankInfo {
  [key: string]: string;
  sNo: string;
  entityName: string;
  branch: string;
  ifscCode: string;
}

export const MSME_FINANCIAL_DATA: Record<string, FinancialPosition> = {
  PANAALFR8552P: {
    turnover: '₹7,44,73,00,000',
    expenditure: '₹7,26,91,00,000',
    netProfit: '₹17,82,00,000',
    lastVerification: '29 Nov, 2023',
  },
  PANAPUPR0030E: {
    turnover: '₹80,00,00,000',
    expenditure: '₹78,00,00,000',
    netProfit: '₹2,00,00,000',
    lastVerification: '07 Oct, 2024',
  },
};

export const MSME_BANK_DATA: Record<string, BankInfo[]> = {
  PANAALFR8552P: [
    {
      sNo: '1',
      entityName: 'BANK OF INDIA',
      branch: 'Bharat Diamond Bourse',
      ifscCode: 'BKID0000167',
    },
    { sNo: '2', entityName: 'BANK OF INDIA', branch: 'Clock Tower', ifscCode: 'BKID0002701' },
    {
      sNo: '3',
      entityName: 'AXIS BANK LIMITED',
      branch: 'Bdb Branch Bandra',
      ifscCode: 'UTIB0001586',
    },
    {
      sNo: '4',
      entityName: 'HDFC BANK LIMITED',
      branch: 'Mumbai Central Branch',
      ifscCode: 'HDFC0000522',
    },
    {
      sNo: '5',
      entityName: 'SARASWAT CO-OPERATIVE BANK LIMITED',
      branch: 'BANDRA KURLA COMPLEX',
      ifscCode: 'SRCB0000418',
    },
  ],
  PANAPUPR0030E: [
    { sNo: '1', entityName: 'HDFC BANK LIMITED', branch: 'Karnal', ifscCode: 'HDFC0002376' },
    { sNo: '2', entityName: 'AXIS BANK LIMITED', branch: 'Karnal', ifscCode: 'UTIB0001956' },
    {
      sNo: '3',
      entityName: 'INDUSIND BANK LIMITED',
      branch: 'Sham Nagar Karnal',
      ifscCode: 'INDB0001426',
    },
    {
      sNo: '4',
      entityName: 'ICICI BANK LIMITED',
      branch: 'Karnal Sector-2',
      ifscCode: 'ICIC0002480',
    },
    {
      sNo: '5',
      entityName: 'PUNJAB NATIONAL BANK',
      branch: 'Prem Nagar Karnal',
      ifscCode: 'PUNB0074500',
    },
    {
      sNo: '6',
      entityName: 'IDBI BANK LIMITED',
      branch: 'Sector-12, Karnal',
      ifscCode: 'IBKL0000351',
    },
  ],
};
