import { Status, ChargesResponse, TableRow, ChargeDetails } from '@app/types/charges';
import { Metadata } from '@app/types/financial';

type TransformDataPayload = ChargesResponse['charges']['all_charges_latest_events'];

type ChargeDetailMapping = {
  data: ChargeDetails;
  metadata: Metadata;
};

export const transformData = (data: TransformDataPayload) => {
  let hasPartialData = false;
  const groupedCharges: {
    [key: string]: TableRow;
  } = {};

  const chargeDetailsMap: { [key: string]: ChargeDetailMapping } = {};

  data.forEach((charge) => {
    if (!charge?.data || Object.keys(charge.data).length === 0) {
      hasPartialData = true;
      return; // Skip this iteration and continue with the next item
    }

    // Set hasPartialData to true if any required field is null or undefined
    const charge_id = charge.data.charge_id;
    if (charge.data?.date == null || charge_id == null) {
      hasPartialData = true;
      return;
    }
    const status = charge.data?.event_type || '-';
    const uniqueId = String(charge_id ?? '-');

    const chargeDetails = {
      data: {
        instrument_description: charge.data?.instrument_description ?? null,
        rate_of_interest: charge.data?.rate_of_interest ?? null,
        terms_of_payment: charge.data?.terms_of_payment ?? null,
        property_particulars: charge.data?.property_particulars ?? null,
        property_type: charge.data?.property_type ?? null,
        extent_and_operation: charge.data?.extent_and_operation ?? null,
        other_terms: charge.data?.other_terms ?? null,
        joint_holding: charge.data?.joint_holding ?? null,
        consortium_holding: charge.data?.consortium_holding ?? null,
        charge_id: charge_id ?? null,
        date: charge.data?.date ?? null,
        filing_date: charge.data?.filing_date ?? null, // Added
        created_or_modified_outside_India: charge.data.created_or_modified_outside_India ?? null, // Added
        modification_particulars: charge.data?.modification_particulars ?? null, // Added
        amount:
          typeof charge.data?.amount === 'number' ? (charge.data.amount / 1e7).toFixed(2) : null,
        id: uniqueId,
        holderName: charge.data?.holder_name ?? '-', // holder_name in JSON
        totalHolders:
          charge.data?.number_of_chargeholder != null
            ? String(charge.data.number_of_chargeholder)
            : '-', // number_of_chargeholder in JSON
        status: status,
      },
      metadata: charge.metadata ?? null,
    };

    // @ts-expect-error - TODO @sreehari-credhive
    chargeDetailsMap[uniqueId] = chargeDetails;

    if (!groupedCharges[uniqueId]) {
      groupedCharges[uniqueId] = {
        id: uniqueId,
        date: charge.data?.date ?? '-',
        holderName: charge.data?.holder_name ?? '-',
        status: status as Status,
        amount:
          typeof charge.data?.amount === 'number' ? (charge.data.amount / 1e7).toFixed(2) : '-',
        totalHolders:
          charge.data?.number_of_chargeholder != null
            ? String(charge.data.number_of_chargeholder)
            : '-',
        subRows: [],
      };
    } else {
      const subRowId = `${uniqueId}-${groupedCharges[uniqueId].subRows!.length + 1}`;
      groupedCharges[uniqueId].subRows!.push({
        id: subRowId,
        date: charge.data?.date ?? null,
        holderName: charge.data?.holder_name ?? '-',
        status: status as Status,
        amount:
          typeof charge.data?.amount === 'number' ? (charge.data.amount / 1e7).toFixed(2) : null,
        totalHolders:
          charge.data?.number_of_chargeholder != null
            ? String(charge.data.number_of_chargeholder)
            : '-',
      });

      // @ts-expect-error @sreehari-credhive TODO
      chargeDetailsMap[subRowId] = chargeDetails;
    }
  });

  return {
    tableData: Object.values(groupedCharges)
      .map((charge) => {
        if (charge.subRows?.length === 0) {
          delete charge.subRows;
        }
        return charge;
      })
      .reverse(),
    chargeDetailsMap,
    hasPartialData,
  };
};
