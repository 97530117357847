import Icon from '../../components/lib-components/icon/Icon';
import LoginImage from '../../assets/LoginImage.webp';
import '../../index.css';
import ForgetPassword from './ForgetPassword';

function ForgetPage() {
  return (
    <div className='flex h-screen w-full justify-between'>
      <div className='w-8/12 relative hidden md:block'>
        <Icon icon='AppLogo' className='absolute top-4 left-4 z-10' />
        <img src={LoginImage} className='h-screen fill-available object-cover' />
      </div>
      <div className='md:w-5/12 m-auto'>
        <ForgetPassword />
      </div>
    </div>
  );
}

export default ForgetPage;
