import { RootState } from '@app/store/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  setActiveCountry,
  setCorporateType,
  supportedCountry,
} from '@app/store/slices/companySlice';
import type { SupportedCountry, CorporateType } from '@app/store/slices/companySlice';

type UseActiveCountry = {
  changeCountry: (country: SupportedCountry) => void;
  changeCorporateType: (corporateType: CorporateType) => void;
  activeCountry: SupportedCountry;
  activeCorporate: CorporateType;
  supportedCountry: SupportedCountry[];
};

export const useActiveCompany = (): UseActiveCountry => {
  const dispatch = useDispatch();
  const activeCountry = useSelector(
    (activeSlices: RootState) => activeSlices.company.activeCountry,
  );

  const activeCorporate = useSelector(
    (activeSlices: RootState) => activeSlices.company.corporateType,
  );

  const changeCountry = (countryName: SupportedCountry) => {
    dispatch(setActiveCountry(countryName));
  };

  const changeCorporateType = (corporateType: CorporateType) => {
    setCorporateType(corporateType);
  };

  return { changeCountry, activeCountry, activeCorporate, changeCorporateType, supportedCountry };
};
