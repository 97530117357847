import { CompanyEntity, RelatedParty } from '@components/company/msme/data/msmeTypes';
import Table from '@components/lib-components/table/table/Table';
import React, { useMemo } from 'react';

interface CompanyRelationsProps {
  competitors: CompanyEntity[];
  relatedParties: RelatedParty[];
}

export const CompanyRelations: React.FC<CompanyRelationsProps> = ({
  competitors,
  relatedParties,
}) => {
  const competitorHeaders = [
    { name: 'SNo.', value: 'sno', classes: 'w-20 text-sm' },
    { name: 'Entity Name', value: 'name', classes: 'text-sm' },
    { name: 'Country', value: 'country', classes: 'w-32 text-sm' },
  ];

  const relatedPartyHeaders = [
    { name: 'SNo.', value: 'sno', classes: 'w-20 text-sm' },
    { name: 'Entity Name', value: 'name', classes: 'text-sm' },
    { name: 'Country', value: 'country', classes: 'w-32 text-sm' },
    { name: 'Type', value: 'type', classes: 'w-48 text-sm' },
  ];

  const competitorRows = useMemo(
    () =>
      competitors.map((competitor, index) => ({
        sno: `${index + 1}.`,
        name: competitor.name,
        country: competitor.country,
      })),
    [competitors],
  );

  const relatedPartyRows = useMemo(
    () =>
      relatedParties.map((party, index) => ({
        sno: `${index + 1}.`,
        name: party.name,
        country: party.country,
        type: party.type,
      })),
    [relatedParties],
  );

  return (
    <div className='space-y-8 mt-6'>
      <div>
        <h3 className='text-heading font-medium mb-4'>Competitors</h3>
        <Table
          headers={competitorHeaders}
          rows={competitorRows}
          className='w-full'
          showCheckbox={false}
        />
      </div>

      <div>
        <h3 className='text-heading font-medium mb-4'>Related Parties/Subsidiaries/Associates</h3>
        <Table
          headers={relatedPartyHeaders}
          rows={relatedPartyRows}
          className='w-full'
          showCheckbox={false}
        />
      </div>
    </div>
  );
};
