import React, { useState, useMemo } from 'react';
import { GSTDetail, GSTFiling } from '@app/types/compliances';
import Table from '@components/lib-components/table/table/Table';
import { Header, Row } from '@components/lib-components/table/table/table.types';
import { convertToTitleCase, formatDate } from '@components/utils/commonUtils';

interface GSTEntryProps {
  gstDetail: GSTDetail;
}

const headers: Header[] = [
  { name: 'Date of Filing', value: 'date_of_filing', classes: 'p-4 text-sm' },
  { name: 'Financial Year', value: 'financial_year', classes: 'p-4 text-sm' },
  { name: 'Method of Filing', value: 'methodOfFiling', classes: 'p-4 text-sm' },
  { name: 'Return Type', value: 'return_type', classes: 'p-4 text-sm' },
  { name: 'Status', value: 'status', classes: 'p-4 text-sm' },
  { name: 'Tax Period', value: 'tax_period', classes: 'p-4 text-sm' },
];

const createRows = (filings: GSTFiling[]): Row[] => {
  return filings.map((filing: GSTFiling) => ({
    date_of_filing: formatDate(filing.date_of_filing),
    financial_year: filing.financial_year,
    methodOfFiling: filing.methodOfFiling,
    return_type: filing.return_type,
    status: filing.status,
    tax_period: filing.tax_period,
  }));
};

const GSTEntry: React.FC<GSTEntryProps> = ({ gstDetail }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const rows = useMemo(() => createRows(gstDetail.filings), [gstDetail.filings]);

  const paginatedRows = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return rows.slice(startIndex, endIndex);
  }, [rows, currentPage, itemsPerPage]);

  return (
    <div>
      <h3 className='text-lg font-semibold text-heading mb-4'>GST Entry Details</h3>
      <div className='grid grid-cols-12 gap-3 mt-4 content-between w-auto'>
        {Object.entries(gstDetail).map(([key, value], index) => {
          if (key !== 'filings') {
            return (
              <div key={key} className={`col-span-4 my-1 ${index % 3 === 0 ? 'col-start-1' : ''}`}>
                <p className='font-normal text-sm mb-1 text-tertiary-600'>
                  {convertToTitleCase(key)}
                </p>
                <p className='text-xs font-medium text-secondary-700'>
                  {key === 'date_of_registration' ? formatDate(value as string) : (value as string)}
                </p>
              </div>
            );
          }
          return null;
        })}
      </div>
      <Table
        tableDivClassname='w-full border border-gray-200 !p-0 rounded mt-4'
        headers={headers}
        rows={paginatedRows}
        pagination={{
          currentPage,
          maxLimit: rows.length,
          pageSize: itemsPerPage,
          onPageChange: setCurrentPage,
        }}
      />
    </div>
  );
};

export default GSTEntry;
