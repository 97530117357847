import Icon from '../icon/Icon';
import { MenuItemProps } from './menuItem.types';

const MenuItem = ({
  text,
  pathname,
  isDefaultSelected,
  handleMenuItemClick = () => {},
  icon,
  iconSize,
}: MenuItemProps) => {
  return (
    <div
      onClick={() => handleMenuItemClick(pathname)}
      className={`rounded py-2 px-2 text-xs justify-center gap-1 inline-flex ${isDefaultSelected ? 'bg-blue-800' : ''} hover:bg-blue-800 cursor-pointer`}
    >
      <Icon
        variant='outline'
        icon={icon}
        color={isDefaultSelected ? 'white' : '#7BA5FF'}
        size={iconSize ?? 18}
      />
      {text}
    </div>
  );
};

export default MenuItem;
