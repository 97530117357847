import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';
import ShareHoldingPattern from './PvtShareHoldingPattern';
import LLPShareholdingPattern from './LLP/LLPShareholdingPattern';

export const ShareholdingPatternContainer: React.FC = () => {
  const companyType = useSelector((state: RootState) => state.util.companyType);
  return (
    <div>
      {companyType === COMPANY_TYPE.LimitedLiabilityPartnership ? (
        <LLPShareholdingPattern />
      ) : (
        <ShareHoldingPattern />
      )}
    </div>
  );
};
