import { useSelector } from 'react-redux';
import { TabContainerProps, ValidTab } from './tabContainer.types';
import { RootState } from '@app/store/store';
import classNames from 'classnames';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { useRef, useState, useEffect } from 'react';
import Icon from '@components/lib-components/icon/Icon';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';
import { getCompanyTabs } from '@app/components/company/utils/getCompanyTabs';
import { useActiveCompany } from '@app/hooks/useActiveCompany';

const TabsContainer = ({
  className,
  selectedTab,
  companyOverviewData,
  setSelectedTab,
}: TabContainerProps) => {
  const reportStatus = useSelector((state: RootState) => state.util.reportStatus);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const { activeCountry, activeCorporate } = useActiveCompany();

  const classification = companyOverviewData.classification as COMPANY_TYPE;

  const tabOptions = getCompanyTabs(activeCountry, activeCorporate, classification);
  // @mohit to update it once the MSME data is updated in DB
  const isLocked =
    companyOverviewData.type === 'MSME'
      ? false
      : reportStatus === ReportStatusEnum.LOCKED ||
        reportStatus === ReportStatusEnum.ALREADY_EXISTS;

  const checkScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setShowLeftScroll(container.scrollLeft > 0);
      setShowRightScroll(
        container.scrollWidth > container.clientWidth &&
          container.scrollLeft < container.scrollWidth - container.clientWidth - 1, // Add a small threshold
      );
    }
  };

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = container.clientWidth / 2;
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });

      // Add a small delay to check scroll after animation
      setTimeout(checkScroll, 300);
    }
  };

  return (
    <div className='w-full flex justify-center col-span-full text-gray-500 font-medium mt-3'>
      <div className='w-full flex items-center border rounded-lg bg-gray-100'>
        {showLeftScroll && (
          <button
            onClick={() => scroll('left')}
            className='flex-shrink-0  rounded-md shadow-md px-1 py-2 mr-2'
          >
            <Icon icon='ChevronLeftIcon' size={20} />
          </button>
        )}
        <div
          ref={scrollContainerRef}
          className={classNames(
            'flex-grow justify-between inline-flex p-1 gap-8 bg-gray-100 overflow-x-auto rounded-xl',
            'scrollbar scrollbar-none scrollbar-thumb-gray-300 scrollbar-track-transparent',
            className,
          )}
          onScroll={checkScroll}
        >
          {tabOptions.map((tab, index) => (
            <div key={`${tab.id}${index}`} className='relative flex-shrink-0'>
              <div
                tabIndex={0}
                onClick={() => {
                  !isLocked && setSelectedTab(tab.id as ValidTab);
                }}
                className={classNames(
                  { 'bg-white shadow-sm rounded-md text-secondary-700': selectedTab === tab.id },
                  { 'hover:bg-white round hover:text-gray-700 hover:rounded-md': !isLocked },
                  'whitespace-nowrap px-3 py-1 cursor-pointer flex items-center gap-2',
                )}
              >
                {tab.text}
                {isLocked && tab.id !== 'overview' && <Icon icon='LockClosedIcon' size={16} />}
              </div>
            </div>
          ))}
        </div>
        {showRightScroll && (
          <button
            onClick={() => scroll('right')}
            className='flex-shrink-0 rounded-md shadow-md px-1 py-2 ml-2'
          >
            <Icon icon='ChevronRightIcon' size={20} />
          </button>
        )}
      </div>
    </div>
  );
};

export default TabsContainer;
