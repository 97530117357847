import React from 'react';
import Table from '@app/components/lib-components/table/table/Table';
import Button from '@app/components/lib-components/button/Button';
import { NetworkCompany, NetworkLLP, DirectorNetwork } from '@app/types/management';
import { convertDateFormat } from '../../../utils/commonUtils';

interface DirectorNetworkTablesProps {
  network: DirectorNetwork['network'];
  onCinRedirection: (cin: string) => void;
}

const tableHeaders = [
  { name: 'Company / LLP', value: 'name' },
  { name: 'CIN / LLPIN', value: 'id' },
  { name: 'Date of Appointment', value: 'date_of_appointment' },
  { name: 'Date of Cessation', value: 'date_of_cessation' },
  { name: 'Designation', value: 'designation' },
  { name: 'Type', value: 'type' },
];

const DirectorNetworkTables: React.FC<DirectorNetworkTablesProps> = ({
  network,
  onCinRedirection,
}) => {
  const createTableRows = (items: (NetworkCompany | NetworkLLP)[], type: string) =>
    items.map((item) => ({
      name: item.legal_name || '-',
      id: (
        <Button
          className='bg-transparent !text-[#5A628B] text-xs font-semibold !py-1 !px-2 rounded-md w-auto border-2 border-gray-200 cursor-pointer'
          onClick={() => onCinRedirection('cin' in item ? item.cin : item.llpin)}
          text={'cin' in item ? item.cin : item.llpin}
        />
      ),
      date_of_appointment: item.date_of_appointment
        ? convertDateFormat(item.date_of_appointment)
        : '-',
      date_of_cessation: item.date_of_cessation ? convertDateFormat(item.date_of_cessation) : '-',
      designation: item.designation || '-',
      type: type,
    }));

  const currentDirectorships = [
    ...createTableRows(network.companies.current, 'Company'),
    ...createTableRows(network.llps.current, 'LLP'),
    ...createTableRows(network.foreign_companies.current, 'Foreign Company'),
  ];

  const previousDirectorships = [
    ...createTableRows(network.companies.previous, 'Company'),
    ...createTableRows(network.llps.previous, 'LLP'),
    ...createTableRows(network.foreign_companies.previous, 'Foreign Company'),
  ];

  return (
    <div className='space-y-8'>
      {currentDirectorships.length > 0 && (
        <div>
          <h3 className='text-sm font-semibold mb-4'>Current Directorships</h3>
          <Table
            className='!table-auto'
            tableDivClassname='w-full border border-gray-200 !p-0 rounded-lg h-[calc(50vh-10rem)] overflow-y-auto'
            headers={tableHeaders}
            rows={currentDirectorships}
            onSelectRows={() => {}}
          />
        </div>
      )}

      {previousDirectorships.length > 0 && (
        <div>
          <h3 className='text-sm font-semibold mb-4'>Previous Directorships</h3>
          <Table
            className='!table-auto'
            tableDivClassname='w-full border border-gray-200 !p-0 rounded-lg h-[calc(50vh-10rem)] overflow-y-auto'
            headers={tableHeaders}
            rows={previousDirectorships}
            onSelectRows={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default DirectorNetworkTables;
