import { useParams } from 'react-router-dom';
import { MSME_DATA } from '../../data/msmeData';
import { MsmeContainer } from '../MsmeContainer';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { Header, Row } from '@components/lib-components/table/table/table.types';
import Table from '@components/lib-components/table/table/Table';

export const MsmeCreditRating = () => {
  const { id = '' } = useParams();
  const companyData = MSME_DATA[id];
  const creditRatings = companyData?.creditRatings;

  if (!creditRatings?.length) {
    return <ErrorMessage message='No credit ratings available for the company' />;
  }

  const headers: Header[] = [
    {
      name: 'SNo.',
      value: 'sNo',
      classes: 'w-16 text-sm',
    },
    {
      name: 'Last Verified Date',
      value: 'lastVerifiedDate',
      classes: 'w-40 text-sm',
    },
    {
      name: 'Amount',
      value: 'amount',
      classes: 'w-40 text-sm',
    },
    {
      name: 'Instrument Type',
      value: 'instrumentType',
      classes: 'w-64 text-sm',
    },
    {
      name: 'Rating',
      value: 'rating',
      classes: 'w-40 text-sm',
    },
    {
      name: 'Rating Agency',
      value: 'ratingAgency',
      classes: 'w-32 text-sm',
    },
  ];

  const rows: Row[] = creditRatings.map((rating) => ({
    sNo: rating.sNo,
    lastVerifiedDate: rating.lastVerifiedDate,
    amount: rating.amount,
    instrumentType: rating.instrumentType,
    rating: rating.rating,
    ratingAgency: rating.ratingAgency,
  }));

  return (
    <MsmeContainer>
      <h3 className='text-heading text-lg font-medium my-4'>Credit Ratings</h3>

      <Table headers={headers} rows={rows} className='w-full' tableDivClassname='mt-4' />
    </MsmeContainer>
  );
};
