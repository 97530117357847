import moment from 'moment';

function getMetric(val: number): string {
  if (val >= 10000000) {
    return `${(val / 10000000).toFixed(2)} CR`;
  } else if (val >= 100000 && val < 10000000) {
    return `${(val / 100000).toFixed(2)} L`;
  } else {
    return `${(val / 1000).toFixed(2)} K`;
  }
}

function convertDateFormat(dateString: string): string {
  if (dateString) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const [year, month, day] = dateString.split('-');
    const monthIndex = parseInt(month) - 1;
    const monthName = months[monthIndex];

    return `${day}, ${monthName}, ${year}`;
  } else {
    return '-';
  }
}

function replacePeriodWithSpace(str: string): string {
  return str.replace(/\./g, ' ');
}

function currencyFormatter(value: number, figureFormat: string): string | number {
  if (value || value === 0) {
    const thresholds = [
      { threshold: 10000000, suffix: 'crores', suffixValues: 'CR' },
      { threshold: 100000, suffix: 'lacs', suffixValues: 'L' },
      { threshold: 1000, suffix: 'thousands', suffixValues: 'K' },
    ];

    const formatValue = (val: number, format: string) => {
      for (const { threshold, suffix, suffixValues } of thresholds) {
        if (format === suffix.toLowerCase()) {
          return `${(val / threshold).toFixed(2)} ${suffixValues}`;
        }
      }
      return val;
    };

    return formatValue(value, figureFormat.toLowerCase());
  }
  return value;
}

function removeCountryCode(phoneNumber: string): string {
  // Regular expression to match Indian and US country codes
  const indianPattern = /^\+91\s*/;
  const usPattern = /^\+1\s*/;

  if (indianPattern.test(phoneNumber)) {
    return phoneNumber.replace(indianPattern, '');
  } else if (usPattern.test(phoneNumber)) {
    return phoneNumber.replace(usPattern, '');
  }

  return phoneNumber;
}

function convertToTitleCase(status: string): string {
  return status
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const cameltoTitleCase = (str: string): string => {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (char) => char.toUpperCase());
};
function capitalCalculation(value: number): string | number {
  const val = Math.abs(value);
  if (val >= 10000000) return `${(value / 10000000).toFixed(2)} Cr`;
  if (val >= 100000) return `${(value / 100000).toFixed(2)} Lac`;
  if (val >= 1000) return `${(value / 1000).toFixed(2)} Thousand`;
  return value;
}

export function numDifferentiation(value: number, figureFormat: string): string {
  if (value === 0) {
    return `0.0 ${figureFormat}`;
  }
  if (value) {
    const thresholds = [
      { threshold: 10000000, suffix: 'crores' },
      { threshold: 1000000, suffix: 'millions' },
      { threshold: 100000, suffix: 'lacs' },
      { threshold: 1000, suffix: 'thousands' },
    ];

    const formatValue = (val: number, format: string): string => {
      for (const { threshold, suffix } of thresholds) {
        if (format === suffix.toLowerCase()) {
          return `${(val / threshold).toFixed(2)} ${suffix}`;
        }
      }
      return val.toString();
    };

    return formatValue(value, figureFormat?.toLowerCase());
  }

  return '';
}

const formatDate = (date: string) => {
  return date ? moment(date).format('DD MMM, YYYY') : '-';
};

export const openWebsite = (website: string | undefined) => {
  if (website) {
    window.open(website.startsWith('http') ? website : `https://${website}`, '_blank');
  }
};

/**
 * Formats a number as a currency string with comma separators.
 * @param {number} num - The number to format.
 * @returns {string} The formatted currency string.
 *
 * This function:
 * 1. Converts the number to a string and splits it into integer and decimal parts.
 * 2. Adds commas as thousands separators to the integer part.
 * 3. Rejoins the integer and decimal parts (if any) and returns the result.
 */
export const formatCurrencyNumber = (num: number): string => {
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts.length === 1) {
    return parts[0];
  }
  return `${parts[0]}.${parts[1].slice(0, 2)}`;
};

const formatUTCDate = (date: string, formatType: 'DATE' | 'TIME' = 'DATE'): string => {
  if (!date) return 'NA';

  const formats = {
    DATE: 'DD MMM, YYYY',
    TIME: 'hh:mm A',
  };

  return moment.utc(String(date), 'YYYY-MM-DDTHH:mm:ss.SSSSSS').local().format(formats[formatType]);
};

const FORCE_UPPERCASE = new Set(['csr', 'fc']);
const KEEP_LOWERCASE = new Set(['of', 'and', 'or', 'by', 'with', 'as', 'in', 'from', 'for']);

const memoizedFormats = new Map<string, string>();

export const formatParticular = (particular: string): string => {
  if (!particular) return '-';

  const memoized = memoizedFormats.get(particular);
  if (memoized) return memoized;

  const formatted = particular
    .split('_')
    .map((word: string) => {
      const lowerWord = word.toLowerCase();

      if (FORCE_UPPERCASE.has(lowerWord)) {
        return word.toUpperCase();
      }

      if (KEEP_LOWERCASE.has(lowerWord)) {
        return lowerWord;
      }

      return lowerWord.charAt(0).toUpperCase() + lowerWord.slice(1);
    })
    .join(' ');

  memoizedFormats.set(particular, formatted);

  return formatted;
};

export {
  getMetric,
  convertDateFormat,
  replacePeriodWithSpace,
  currencyFormatter,
  removeCountryCode,
  convertToTitleCase,
  cameltoTitleCase,
  formatDate,
  capitalCalculation,
  formatUTCDate,
};
