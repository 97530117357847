export const getMessage = (error: unknown, defaultMessage: string): string => {
  if (typeof error === 'object' && error !== null) {
    const err = error as { data?: { detail?: string } };
    if (typeof err.data?.detail === 'object') return defaultMessage;
    if (err.data?.detail) {
      return err.data.detail;
    }
  }
  return defaultMessage;
};
