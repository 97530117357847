import { CorporateType, SupportedCountry } from '@app/store/slices/companySlice';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';
import { msmeTabs } from './tabs/msmeTab';
import { llptab } from './tabs/llpTab';
import { pvtCompanyTabs } from './tabs/pvtCompanyTabs';

export const getCompanyTabs = (
  location: SupportedCountry,
  corporateType: CorporateType,
  classification: COMPANY_TYPE | null = null,
) => {
  if (location === 'in') {
    switch (corporateType) {
      case CorporateType.NonCorporate:
        return msmeTabs;
      case CorporateType.Corporate:
        if (classification === COMPANY_TYPE.LimitedLiabilityPartnership) {
          return llptab;
        }
        return pvtCompanyTabs;
    }
  }

  // Fallback value - TODO - Add Company Tabs For All Country and Types
  return pvtCompanyTabs;
};
