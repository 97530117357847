import React, { useEffect, useState } from 'react';
import Chart from '@app/components/charts/Chart';
import Modal from '@app/components/lib-components/modal/Modal';
import Icon from '@app/components/lib-components/icon/Icon';
import Button from '@app/components/lib-components/button/Button';
import { FinanceLineChartOptions } from '@app/components/charts/chart.data';
import { FigureFormat, FinancialSubTabs } from './FinancialTab';
import classNames from 'classnames';
import {
  CompanyFinancialData,
  LLPFinancialData,
  Metadata,
  FinancialParameters,
} from '@app/types/financial';
import Loading from '@components/lib-components/loading/Loading';
import pdfIcon from '@app/assets/pdfIcon.svg';
import { DocumentModal } from '@components/lib-components/modal/DocumentModal';
import { usePostHog } from 'posthog-js/react';
import { useDispatch } from 'react-redux';
import { setShowNudge } from '@app/store/slices/utilSlice';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';
import { formatParticular } from '@app/components/utils/commonUtils';

interface Props {
  figureFormat: FigureFormat;
  selectedSubTab: FinancialSubTabs | 'parameters';
  tableBody: Array<{
    year: number;
    data: CompanyFinancialData | LLPFinancialData | FinancialParameters;
    metadata: Metadata;
  }>;
  companyType: COMPANY_TYPE;
  isParametersTable?: boolean;
}

const FinancialTable = ({
  selectedSubTab,
  tableBody,
  figureFormat,
  companyType,
  isParametersTable = false,
}: Props) => {
  const [tableHeaders, setTableHeaders] = useState(['']);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const [tableData, setTableData] = useState<Record<string, Record<string, number>>>({});
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedYearData, setSelectedYearData] = useState<{
    data: CompanyFinancialData | LLPFinancialData | FinancialParameters;
    metadata: Metadata;
    year: number;
  } | null>(null);
  const posthog = usePostHog();
  const dispatch = useDispatch();

  useEffect(() => {
    const header = ['Particulars'];
    tableBody.forEach((yearData) => {
      const year = yearData.year ? `FY-${yearData.year}` : 'N/A';
      header.push(year);
    });
    setTableHeaders(header);
  }, [tableBody]);

  const getTableData = (
    category: FinancialSubTabs | 'parameters',
  ): Record<string, Record<string, number>> => {
    const rows: Record<string, Record<string, number>> = {};

    if (isParametersTable) {
      tableBody.forEach((yearData) => {
        const year = yearData.year ? `FY-${yearData.year}` : 'N/A';
        const parameters = yearData.data as FinancialParameters;

        const excludedFields = [
          'document_id',
          'entity_id',
          'year',
          'financial_year',
          'nature',
          'id',
          'created_at',
          'updated_at',
        ];

        Object.entries(parameters).forEach(([key, value]) => {
          if (!excludedFields.includes(key) && (typeof value === 'number' || value === null)) {
            if (!(key in rows)) {
              rows[key] = {};
            }
            rows[key][year] = value as number;
          }
        });

        return;
      });

      return rows;
    }

    tableBody.forEach((yearData) => {
      const year = yearData.year ? `FY-${yearData.year}` : 'N/A';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let categoryData: any;

      if (companyType === COMPANY_TYPE.LimitedLiabilityPartnership) {
        const llpData = yearData.data as LLPFinancialData;
        if (category === 'assets') {
          categoryData = llpData.statement_of_assets_and_liabilities.assets;
        } else if (category === 'liabilities') {
          categoryData = llpData.statement_of_assets_and_liabilities.liabilities;
        } else if (category === 'subTotals') {
          categoryData = llpData.statement_of_assets_and_liabilities.subTotals;
        } else if (category === 'line_items') {
          categoryData = llpData.statement_of_income_and_expenditure.line_items;
        } else if (category === 'revenue_breakup') {
          categoryData = llpData.statement_of_income_and_expenditure.revenue_breakup;
        }
      } else {
        const companyData = yearData.data as CompanyFinancialData;
        if (category === 'cash_flow') {
          categoryData = companyData.cash_flow_statement;
        } else if (category === 'assets') {
          categoryData = companyData.balance_sheet.assets;
        } else if (category === 'liabilities') {
          categoryData = companyData.balance_sheet.liabilities;
        } else if (category === 'subTotals') {
          categoryData = companyData.balance_sheet.subTotals;
        } else if (category === 'line_items') {
          categoryData = companyData.profit_and_loss.line_items;
        } else if (category === 'revenue_breakup') {
          categoryData = companyData.profit_and_loss.revenue_breakup;
        }
      }

      if (!categoryData) return;
      Object.entries(categoryData).forEach(([key, value]) => {
        // Skip metadata fields
        if (
          [
            'document_id',
            'entity_id',
            'year',
            'financial_year',
            'nature',
            'id',
            'created_at',
            'updated_at',
            'filing_type',
          ].includes(key)
        ) {
          return;
        }

        if (key.startsWith('COMPUTED_')) {
          key = key.replace('COMPUTED_', '');
        }

        if (typeof value === 'number') {
          if (!rows[key]) {
            rows[key] = {};
          }
          rows[key][year] = value;
        }
      });
    });

    return rows;
  };

  useEffect(() => {
    const generatedTableBodySchema = getTableData(selectedSubTab);
    setTableData(generatedTableBodySchema);
  }, [selectedSubTab, figureFormat]);

  const openModal = (particular: string) => {
    const data = tableData[particular];
    const years = Object.keys(data).filter(
      (year) => year !== 'expandable' && year !== 'children' && tableHeaders.includes(year),
    );

    const chartSeries = years.map((year) => ({
      y: data[year],
      formattedValue: numDifferentiation(data[year]),
    }));

    const newChartOptions: Highcharts.Chart['options'] = {
      ...FinanceLineChartOptions,
      chart: {
        ...FinanceLineChartOptions.chart,
        height: 250,
      },
      title: {
        text: `Financial Data for ${formatParticular(particular)}`,
      },
      xAxis: {
        categories: years.reverse(),
      },
      yAxis: {
        title: {
          text: 'Finances',
        },
        labels: {
          formatter: function (): string {
            return numDifferentiation(Number(this.value));
          },
        },
      },
      tooltip: {
        formatter: function (): string {
          return `<b>${this.x}</b><br/>${this.series.name}: ${numDifferentiation(Number(this.y))}`;
        },
      },
      series: [
        {
          type: 'spline',
          name: formatParticular(particular),
          visible: true,
          data: chartSeries,
          dataLabels: chartSeries.reverse(),
        },
      ],
    };

    setChartOptions(newChartOptions);
    posthog.capture('financial_document_modal_graph_opened', {
      financial_parameter: particular,
    });
    setIsModalOpen(true);
  };

  const hasNonZeroValue = (data: Record<string, number>) => {
    return data && Object.values(data).some((value) => value !== 0 && value !== null);
  };

  const handleRowToggle = (particular: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [particular]: !prev[particular],
    }));
  };

  function numDifferentiation(value: number): string {
    if (value === null || value === undefined) return '-';
    if (value === 0) return '0.00';

    const formatValue = (val: number, format: FigureFormat): string => {
      switch (format) {
        case 'millions':
          return `${(val / 1000000).toFixed(2)} M`;
        case 'crores':
          return `${(val / 10000000).toFixed(2)} Cr`;
        case 'lacs':
          return `${(val / 100000).toFixed(2)} L`;
        case 'thousands':
        default:
          return `${(val / 1000).toFixed(2)} K`;
      }
    };

    return formatValue(value, figureFormat);
  }

  const getDifference = (current: number, previous: number) => {
    if (!current || !previous || current === undefined || previous === undefined) return null;

    if (previous === 0) return <span className='ml-4 text-xs text-slate-400'>0.00%</span>;

    const diff = ((current - previous) / Math.abs(previous)) * 100;

    const arrow = !isNaN(diff) ? (diff > 0 ? '↑' : diff < 0 ? '↓' : '') : '';
    const color = diff > 0 ? 'text-green-500' : diff < 0 ? 'text-red-500' : 'text-slate-400';

    return (
      <span className={`ml-4 text-xs ${color}`}>
        {arrow} {isNaN(diff) ? '' : Math.abs(diff).toFixed(2)}%
      </span>
    );
  };

  const openDocumentModal = (yearData: {
    year: number;
    data: CompanyFinancialData | LLPFinancialData | FinancialParameters;
    metadata: Metadata;
  }) => {
    setSelectedYearData(yearData);
    setIsDocumentModalOpen(true);
  };

  return (
    <>
      {Object.keys(tableData).length > 0 ? (
        <div className='overflow-x-auto w-full border border-gray-200 rounded mt-3'>
          <div className='max-h-[600px] overflow-y-auto'>
            <table className='min-w-max border-separate border-spacing-0 bg-surface-1'>
              <thead className='bg-gray-100/30 text-sm text-gray-600 sticky top-0 z-20'>
                <tr className='bg-surface-2'>
                  {tableHeaders.map((header, index) => (
                    <th
                      key={index}
                      className={`text-md text-gray-600 p-2 pl-0 text-center ${
                        index === 0 ? 'sticky left-0 bg-gray-50 z-30 border-r-[0.5px]' : ''
                      } ${index === 1 ? 'bg-neutral-100' : 'pl-5 bg-neutral-100'}`}
                      style={{
                        width:
                          index === 0
                            ? '35rem'
                            : `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                        minWidth: '18rem',
                      }}
                    >
                      <div
                        className={`flex items-center ${index === 1 ? 'justify-center pl-0' : 'justify-center'}`}
                      >
                        {index === 1 && (
                          <div className='w-1.5 h-1.5 mr-1 rounded-full bg-green-500' />
                        )}
                        {header}
                        {index !== 0 && (
                          <img
                            className='pl-0 scale-[0.5] cursor-pointer z-[1]'
                            src={pdfIcon}
                            onClick={() => {
                              const yearData = tableBody[index - 1];
                              openDocumentModal(yearData);
                            }}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(tableData).map((particular, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    <tr
                      className={classNames('text-tertiary ', {
                        'bg-gray-50': rowIndex % 2 !== 0,
                        'bg-white': tableData[particular].children,
                      })}
                    >
                      <td
                        className={classNames(
                          'text-sm',
                          'flex',
                          'items-center',
                          'font-medium',
                          'text-tertiary',
                          'py-5',
                          'pl-8',
                          'sticky',
                          'left-0',
                          'z-10',
                          'bg-surface-base',
                          'border-r-[0.5px]',
                          {
                            'bg-gray-50': rowIndex % 2 !== 0,
                            'bg-white': rowIndex % 2 === 0 && !tableData[particular].children,
                            'bg-[#F7F4ED]': tableData[particular].children,
                          },
                        )}
                        style={{ width: '35rem' }}
                      >
                        <span className='w-9/12	'>{formatParticular(particular)}</span>
                        {hasNonZeroValue(tableData[particular]) && (
                          <Button className='bg-transparent' onClick={() => openModal(particular)}>
                            <Icon
                              icon='BarChartIcon'
                              color='#c6c6c6'
                              size={22}
                              className='cursor-pointer'
                            />
                          </Button>
                        )}
                        {tableData[particular].children &&
                          hasNonZeroValue(tableData[particular]) && (
                            <Button
                              className='bg-transparent'
                              onClick={() => handleRowToggle(particular)}
                            >
                              <Icon
                                icon={
                                  expandedRows[particular] ? 'MinusCircleIcon' : 'PlusCircleIcon'
                                }
                                color='#c6c6c6'
                                size={22}
                                className='cursor-pointer'
                                variant='outline'
                              />
                            </Button>
                          )}
                      </td>
                      {tableHeaders.slice(1).map((header, index) => {
                        const nextYearHeader = tableHeaders[index + 2]; // Get next year's header
                        return (
                          <td
                            key={index}
                            className={`text-sm font-medium text-tertiary py-5 px-4 pl-6 text-center whitespace-pre ${index === 0 ? 'bg-neutral-200/30' : ''}`}
                            style={{
                              width: `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                              minWidth: '18rem',
                            }}
                          >
                            {(figureFormat
                              ? numDifferentiation(tableData[particular][header])
                              : tableData[particular][header]) ?? `0.0`}
                            {nextYearHeader && // Only show difference if there's a next year
                              getDifference(
                                tableData[particular][header],
                                tableData[particular][nextYearHeader],
                              )}
                          </td>
                        );
                      })}
                    </tr>
                    {expandedRows[particular] &&
                      tableData[particular].children &&
                      Object.entries(tableData[particular].children).map(
                        ([key, value], subIndex) => (
                          <tr
                            key={subIndex}
                            className={classNames('border-y border-primary', {
                              'bg-gray-50': subIndex % 2 == 0,
                            })}
                          >
                            <td
                              className='text-md flex items-center font-medium py-5 pl-16 sticky left-0  bg-surface-base  bg-table-secondary'
                              style={{ width: '35rem' }}
                            >
                              <span>{formatParticular(key)}</span>
                            </td>
                            <td
                              key={subIndex}
                              className={classNames(
                                'text-md font-medium py-5 px-4 text-center',
                                subIndex % 2 == 0 ? 'bg-[#F5F2EB]' : 'bg-table-secondary',
                              )}
                              style={{
                                width: `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                                minWidth: '18rem',
                              }}
                            >
                              <span>{numDifferentiation(value)}</span>
                            </td>
                          </tr>
                        ),
                      )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <Modal
            className='w-[70%] max-w-[90vw]'
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
            <Chart chartOptions={chartOptions} setChartOptions={setChartOptions} />
          </Modal>
          <DocumentModal
            isOpen={isDocumentModalOpen}
            onClose={() => {
              setIsDocumentModalOpen(false);
              dispatch(setShowNudge(false));
            }}
            documentData={selectedYearData}
          />
        </div>
      ) : (
        <div className='w-[93.8vw] mx-[1px]'>
          <Loading />
        </div>
      )}
    </>
  );
};

export default FinancialTable;
