import { ReactElement, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { NumberCards } from './NumberCard';
import Table from '@app/components/lib-components/table/table/Table';
import Button from '@app/components/lib-components/button/Button';
import Modal from '@app/components/lib-components/modal/Modal';
import Icon from '@app/components/lib-components/icon/Icon';
import DirectorNetworkTables from './DirectorNetworkTables';
import DirectorsNetwork from './DirectorNetwork';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';
import {
  AssociationHistory,
  AuthorizedSignatoriesResponse,
  DirectorNetwork,
  DirectorNetworkResponse,
} from '@app/types/management';
import Loading from '@components/lib-components/loading/Loading';
import InfoCard from '@components/lib-components/info-card/InfoCard';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { convertDateFormat, replacePeriodWithSpace } from '../../../utils/commonUtils';
import { Tooltip } from '@components/Tooltip/Tooltip';
import AssociationHistoryModal from './AssociationHistoryModal';
import { usePostHog } from 'posthog-js/react';
import { ApiResponse } from '@app/types';
import { useLocalNavigation } from '@app/hooks/useLocalNavigation';

interface TableData {
  headers: { name: string | ReactElement; value: string; classes: string }[];
  rows: { [key: string]: string | ReactElement }[];
}

const ManagementTab = () => {
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const pastDirectorsTableHeaders = [
    { name: 'DIN', value: 'din', classes: 'text-sm' },
    { name: 'Director Name', value: 'name', classes: 'p-4 text-sm' },
    { name: 'Date of Appointment', value: 'date_of_appointment', classes: 'p-4 text-sm' },
    {
      name: (
        <span className='flex items-center z-20'>
          PAN &nbsp;
          <Tooltip content='PAN might not be available because of PII' place='bottom'>
            <Icon icon='InformationCircleIcon' variant='outline' size={20} />
          </Tooltip>
        </span>
      ),
      value: 'pan',
      classes: ' text-sm',
    },
    { name: 'Designation', value: 'designation', classes: 'text-sm' },
    { name: 'Date of Cessation', value: 'date_of_cessation', classes: ' text-sm' },
  ];

  const directorsTableHeaders = [
    { name: 'DIN', value: 'din', classes: 'text-sm' },
    { name: 'Director Name', value: 'name', classes: ' text-sm' },
    { name: 'Date of Appointment', value: 'date_of_appointment', classes: 'text-sm' },
    {
      name: (
        <span className='flex items-center z-20'>
          PAN &nbsp;
          <Tooltip content='PAN might not be available because of PII' place='bottom'>
            <Icon icon='InformationCircleIcon' variant='outline' size={20} />
          </Tooltip>
        </span>
      ),
      value: 'pan',
      classes: ' text-sm',
    },
    { name: 'Designation', value: 'designation', classes: 'text-sm' },
    { name: 'Other Directorship', value: 'other_network', classes: 'text-sm' },
    { name: 'Association History', value: 'association_history', classes: 'text-sm' },
    {
      name: '',
      value: 'explore_network',
      classes: 'flex justify-center text-sm',
    },
  ];

  const [directorsTableData, setDirectorsTableData] = useState<TableData>({
    headers: [],
    rows: [],
  });
  const [pastDirectorsTableData, setPastDirectorsTableData] = useState<TableData>({
    headers: [],
    rows: [],
  });

  const [showDirectorNetwork, setShowDirectorNetwork] = useState<boolean>(false);
  const [showDirectorNetworkTables, setShowDirectorNetworkTables] = useState<boolean>(false);
  const [directorNetwork, setDirectorNetwork] = useState<DirectorNetwork[]>([]);
  const [selectedDirector, setSelectedDirector] = useState<DirectorNetwork | null>(null);
  const [showAssociationHistory, setShowAssociationHistory] = useState<boolean>(false);
  const [selectedAssociationHistory, setSelectedAssociationHistory] = useState<
    AssociationHistory[]
  >([]);
  const [selectedDesignation, setSelectedDesignation] = useState<string | null>(null);

  const [currentDirectorsPage, setCurrentDirectorsPage] = useState<number>(1);
  const [pastDirectorsPage, setPastDirectorsPage] = useState<number>(1);
  const rowsPerPage = 10;

  const navigate = useLocalNavigation();
  const posthog = usePostHog();
  const { id } = useParams<string>();

  const getDirectorNetworkData = useGetCompanyData<ApiResponse<DirectorNetworkResponse>>();
  const getAuthorizedSignatoriesData =
    useGetCompanyData<ApiResponse<AuthorizedSignatoriesResponse>>();

  const {
    data: directorNetworkData,
    isLoading: isLoadingDirectorNetwork,
    isError: directorResponseError,
  } = getDirectorNetworkData(
    {
      cinId: id as string,
      field_name: 'current_management',
      identifierType: identifierType,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: authorizedSignatoriesData,
    isLoading: isLoadingAuthorizedSignatories,
    isError: authorizedSignatoriesResponseError,
  } = getAuthorizedSignatoriesData(
    {
      cinId: id as string,
      field_name: 'previous_management',
      identifierType: identifierType,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const isInProgress = (data?: { response_data?: { status?: string } }) =>
    data?.response_data?.status === 'INITIALISED' || data?.response_data?.status === 'IN_PROGRESS';

  const directorNetworkDataInProgress = isInProgress(directorNetworkData);
  const authorizedSignatoriesDataInProgress = isInProgress(authorizedSignatoriesData);

  const isReportFetching = directorNetworkDataInProgress || authorizedSignatoriesDataInProgress;

  const directorsCount = directorNetworkData?.response_data?.current_management?.length || 0;

  const designationCounts = useMemo(() => {
    const directors = directorNetworkData?.response_data?.current_management || [];

    const designationMap = directors.reduce((acc, director) => {
      const designation = director.designation?.trim() || 'Unknown';
      acc.set(designation, (acc.get(designation) || 0) + 1);
      return acc;
    }, new Map<string, number>());

    return Array.from(designationMap.entries())
      .sort((a, b) => b[1] - a[1])
      .map(([designation, count]) => ({
        designation,
        count,
      }));
  }, [directorNetworkData?.response_data?.current_management]);

  const filteredDirectors = useMemo(() => {
    if (!selectedDesignation) return directorsTableData.rows;
    return directorsTableData.rows.filter((row) => row.designation === selectedDesignation);
  }, [selectedDesignation, directorsTableData.rows]);

  useEffect(() => {
    if (
      directorNetworkData?.response_data?.current_management?.length &&
      designationCounts.length &&
      !selectedDesignation
    ) {
      setSelectedDesignation(designationCounts[0].designation);
    }
  }, [directorNetworkData?.response_data?.current_management, designationCounts.length]);

  useEffect(() => {
    if (!isReportFetching && directorNetworkData?.response_data?.current_management) {
      const directorsTableRows = directorNetworkData.response_data.current_management.map(
        (item) => {
          const dateOfAppointment = item.date_of_appointment
            ? convertDateFormat(item.date_of_appointment)
            : '-';
          const otherNetworkCount =
            item.network.companies?.current?.length -
            1 +
            (item.network?.companies?.previous?.length || 0) +
            (item.network?.llps?.current?.length || 0) +
            (item.network?.llps?.previous?.length || 0) +
            (item.network?.foreign_companies?.current?.length || 0) +
            (item.network?.foreign_companies?.previous?.length || 0);

          return {
            din: item.din || '-',
            name:
              // <Link
              //   to={`/company/${id}/director/${encodeURIComponent(item.din)}/current-companies`}
              //   className='!text-blue-600 hover:underline'
              //   state={{ directorName: replacePeriodWithSpace(item.name) }}
              // >
              //   {replacePeriodWithSpace(item.name) || '-'}
              // </Link>
              replacePeriodWithSpace(item.name),
            date_of_appointment: dateOfAppointment,
            pan: item.pan || '-',
            designation: item.designation || '-',
            other_network:
              otherNetworkCount > 0 ? (
                <Button
                  className='bg-white !text-gray-600 text-xs font-medium !py-1 !px-2 rounded-lg w-auto border-2 border-gray-200'
                  onClick={() => {
                    setSelectedDirector(item);
                    setShowDirectorNetworkTables(true);
                  }}
                  text={`${otherNetworkCount} results`}
                />
              ) : (
                '-'
              ),
            association_history:
              item.association_history && item.association_history.length > 0 ? (
                <Button
                  className='bg-white !text-gray-600 text-xs font-medium !py-1 !px-2 rounded-lg w-auto border-2 border-gray-200'
                  onClick={() => {
                    setSelectedAssociationHistory(item.association_history);
                    setShowAssociationHistory(true);
                  }}
                  text='View History'
                />
              ) : (
                '-'
              ),
            explore_network: (
              <button className='bg-transparent' onClick={() => handleExploreNetwork(item)}>
                <Icon className='cursor-pointer' icon={'NetworkIcon'} />
              </button>
            ),
          };
        },
      );

      setDirectorsTableData({
        headers: directorsTableHeaders,
        rows: directorsTableRows,
      });
    }
  }, [directorNetworkData, isReportFetching]);

  useEffect(() => {
    if (!isReportFetching && authorizedSignatoriesData?.response_data?.previous_management) {
      const pastDirectorsTableRows =
        authorizedSignatoriesData.response_data.previous_management.map((item) => ({
          din: item.din || '-',
          name: replacePeriodWithSpace(item.name) || '-',
          date_of_appointment: item.date_of_appointment
            ? convertDateFormat(item.date_of_appointment)
            : '-',
          pan: item.pan || '-',
          designation: item.designation || '-',
          date_of_cessation: item.date_of_cessation
            ? convertDateFormat(item.date_of_cessation)
            : '-',
        }));

      setPastDirectorsTableData({
        headers: pastDirectorsTableHeaders,
        rows: pastDirectorsTableRows,
      });
    }
  }, [authorizedSignatoriesData, isReportFetching]);

  const handleCinRedirection = (cin: string) => {
    setShowDirectorNetwork(false);
    setShowDirectorNetworkTables(false);
    navigate(cin, 'overview', { state: { cid: cin }, replace: true });
  };

  const handleExploreNetwork = (selectedItem: DirectorNetwork) => {
    setSelectedDirector(selectedItem);
    setShowDirectorNetwork(true);
    setDirectorNetwork(directorNetworkData?.response_data?.current_management || []);
    // Send Director Graph Network Event
    posthog.capture('explore_network', {
      cin: id,
      director_name: selectedItem.name,
      director_din: selectedItem.din,
    });
  };

  const handlePageChange =
    (setPage: React.Dispatch<React.SetStateAction<number>>) => (page: number) => {
      setPage(page);
    };

  const isDirectorsLoading = isLoadingDirectorNetwork || isLoadingAuthorizedSignatories;

  if (directorResponseError && authorizedSignatoriesResponseError)
    return (
      <ErrorMessage message={'Error fetching management data, please try again in some time'} />
    );

  if (isDirectorsLoading) {
    return (
      <h2 className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </h2>
    );
  }

  if (isReportFetching) {
    return (
      <div className='w-full flex justify-center items-center  h-[calc(60vh)] '>
        <ErrorMessage
          message={
            'Kindly wait a few minutes, we are fetching the company management data for you.'
          }
        />
      </div>
    );
  }

  return (
    <div className='w-full'>
      <div className='my-5'>
        <InfoCard className='pb-0 pt-2 w-full'>
          <p className='mb-4 text-lg text-heading font-semibold'>
            {`Current Directorship (${directorsCount})`}
          </p>
          <div className='flex flex-row justify-start flex-wrap gap-4'>
            {designationCounts.map((item, index) => {
              const isSelected = selectedDesignation === item.designation;

              return (
                <NumberCards
                  key={item.designation + index}
                  cardClassName={`px-6 py-3 w-72 rounded-xl cursor-pointer transition-all duration-200 ${
                    isSelected
                      ? 'bg-gradient-to-b from-blue-700 to-blue-500 text-white'
                      : 'bg-white border-2 border text-brand_color'
                  }`}
                  count={item.count}
                  cardName={item.designation}
                  onClick={() => setSelectedDesignation(isSelected ? null : item.designation)}
                />
              );
            })}
          </div>
          <div className='my-5'>
            {directorsTableData?.rows?.length > 0 ? (
              <Table
                tableDivClassname='w-full border border-gray-200 !p-0 rounded-lg'
                headers={directorsTableData.headers}
                rows={filteredDirectors.slice(
                  (currentDirectorsPage - 1) * rowsPerPage,
                  currentDirectorsPage * rowsPerPage,
                )}
                pagination={{
                  currentPage: currentDirectorsPage,
                  maxLimit: filteredDirectors.length,
                  onPageChange: handlePageChange(setCurrentDirectorsPage),
                }}
              />
            ) : (
              <ErrorMessage message={'Current management data not available'} />
            )}
          </div>
        </InfoCard>
        {showAssociationHistory && selectedAssociationHistory && (
          <AssociationHistoryModal
            isOpen={showAssociationHistory}
            onClose={() => setShowAssociationHistory(false)}
            associationHistory={selectedAssociationHistory}
          />
        )}
        <InfoCard className='w-full my-5 py-0'>
          <p className='mb-2 mt-0 text-lg text-heading font-semibold'>{`Past Directors (${pastDirectorsTableData?.rows?.length})`}</p>
          {pastDirectorsTableData?.rows?.length > 0 ? (
            <div className='mt-5'>
              <Table
                tableDivClassname='w-full border border-gray-200 !p-0 rounded-lg'
                headers={pastDirectorsTableData.headers}
                rows={pastDirectorsTableData.rows.slice(
                  (pastDirectorsPage - 1) * rowsPerPage,
                  pastDirectorsPage * rowsPerPage,
                )}
                onSelectRows={() => {}}
                pagination={{
                  currentPage: pastDirectorsPage,
                  maxLimit: pastDirectorsTableData.rows.length,
                  onPageChange: handlePageChange(setPastDirectorsPage),
                }}
              />
            </div>
          ) : (
            <div className='flex justify-center w-[93.8vw] mx-[1px] '>
              <div className='flex text-center justify-center w-[40vw] min-h-52 h-auto bg-contain items-center mt-4 text-slate-400 noDataSection'>
                {isReportFetching
                  ? 'Loading past directors...'
                  : 'No Past Directors in this company.'}
              </div>
            </div>
          )}
        </InfoCard>
        {showDirectorNetwork && (
          <Modal
            title={'Director Network'}
            isOpen={showDirectorNetwork}
            onClose={() => setShowDirectorNetwork(false)}
            className='min-h-[calc(100vh-20rem)] min-w-[calc(100vw-40rem)]'
          >
            {selectedDirector && (
              <DirectorsNetwork
                isOpen={showDirectorNetwork}
                onClose={() => setShowDirectorNetwork(false)}
                directorNetwork={directorNetwork}
                selectedDirector={selectedDirector}
              />
            )}
          </Modal>
        )}

        {showDirectorNetworkTables && selectedDirector && (
          <Modal
            title={'Director Network Detailed View'}
            isOpen={showDirectorNetworkTables}
            onClose={() => setShowDirectorNetworkTables(false)}
          >
            <DirectorNetworkTables
              network={selectedDirector.network}
              onCinRedirection={handleCinRedirection}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ManagementTab;
