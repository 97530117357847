import React, { useEffect, useMemo, useState } from 'react';
import Badge from '@app/components/lib-components/badge/Badge';
import { TableRow, Status } from '@app/types/charges';
import { JSX } from 'react/jsx-runtime';
import classNames from 'classnames';
import { formatDate } from '@components/utils/commonUtils';

interface TableComponentProps {
  headers: string[];
  rows: TableRow[];
  rowsPerPage?: number;
  onViewClick?: (row: TableRow) => void;
  badgeStatus?: boolean;
}

const TableComponent: React.FC<TableComponentProps> = ({
  headers,
  rows,
  rowsPerPage = 5,
  onViewClick,
  badgeStatus,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRows, totalPages] = useMemo(() => {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = rows.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    return [currentRows, totalPages];
  }, [currentPage, rowsPerPage, rows]);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [totalPages]);

  const renderRows = (rows: TableRow[]) => {
    const renderedRows: JSX.Element[] = [];
    rows.forEach((row, index) => {
      const hasSubRows = row.subRows && row.subRows.length > 0;
      const bgColor = index % 2 === 0 ? 'bg-white' : 'bg-gray-50';
      const subRowBgColor = index % 2 === 0 ? 'bg-white' : 'bg-gray-50';

      renderedRows.push(
        <tr
          key={`${row.id}-${index}`}
          className={`${bgColor} ${hasSubRows ? ' border-b-0' : 'border-b-0'} font-medium text-tertiary`}
        >
          <td
            className={`cursor-pointer text-tertiary font-medium px-2 py-4 ${headers[0] === '' && '!border-0'}  ${hasSubRows ? 'border-r-2 !border-b-0 border-slate-100' : 'border-r-2 border-slate-100'} text-sm font-medium text-gray-600`}
          >
            {row.id}
          </td>
          {Object.keys(row).map(
            (key) =>
              key !== 'id' &&
              key !== 'subRows' &&
              key !== 'link' &&
              key !== 'additionalDetails' && (
                <td
                  key={key}
                  className='px-2 py-4 text-sm font-medium text-tertiary whitespace-break-spaces'
                >
                  {key === 'status' && badgeStatus ? (
                    <Badge status={row[key] as Status} />
                  ) : key === 'date' ? (
                    formatDate(row[key] as string)
                  ) : (
                    // @ts-expect-error status can be different as its a common component
                    row[key]
                  )}
                </td>
              ),
          )}
          {onViewClick && row.status !== 'SATISFACTION' ? (
            <td className='px-2 py-4'>
              <button
                onClick={() => {
                  onViewClick(row);
                }}
                className='text-blue-500 text-sm bg-transparent'
              >
                View
              </button>
            </td>
          ) : (
            <td></td>
          )}
        </tr>,
      );

      if (hasSubRows) {
        row?.subRows?.forEach((subRow, subIndex) => {
          renderedRows.push(
            <tr
              key={`${row.id}-${index}-${subIndex}`}
              className={`${subRowBgColor} font-medium text-tertiary`}
            >
              <td className='pl-10 px-2 py-4 !border-r-2 border-slate-100 text-sm text-tertiary'></td>
              {Object.keys(subRow).map(
                (key) =>
                  key !== 'id' &&
                  key !== 'additionalDetails' && (
                    <td
                      key={key}
                      className='px-2 py-4 border-b-0 text-sm font-medium text-tertiary'
                    >
                      {key === 'status' ? (
                        <Badge status={subRow[key] as Status} />
                      ) : key === 'date' ? (
                        formatDate(subRow[key] as string)
                      ) : (
                        // @ts-expect-error status can be different as its a common component
                        subRow[key]
                      )}
                    </td>
                  ),
              )}
              {onViewClick && subRow.status !== 'SATISFACTION' ? (
                <td className='px-2 py-4 border-b-0 text-sm'>
                  <button
                    onClick={() => onViewClick(subRow)}
                    className='text-blue-500 text-sm bg-transparent'
                  >
                    View
                  </button>
                </td>
              ) : null}
            </tr>,
          );
        });
      }
    });
    return renderedRows;
  };

  return (
    <div className='overflow-x-auto'>
      <div className='relative border rounded-lg overflow-hidden'>
        <table className='min-w-full  divide-y divide-gray-200'>
          <thead className='bg-gray-100 text-gray-600'>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className='px-2 py-3 text-sm text-left font-semibold text-gray-600'>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderRows(currentRows)}</tbody>
        </table>
        {Boolean(totalPages > 1) && (
          <div className='flex justify-between items-center py-3 bg-white px-3 pl-5 text-sm text-gray-600  '>
            <div>
              Page {currentPage} of {totalPages}
            </div>
            <div>
              <button
                onClick={handlePrevious}
                className={classNames(
                  'mx-1 px-4 py-2 text-sm font-medium rounded-md border border-gray-300 focus:outline-none',
                  currentPage === 1
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-white text-gray-700 hover:bg-gray-50',
                )}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                onClick={handleNext}
                className={classNames(
                  'mx-1 px-4 py-2 text-sm font-medium rounded-md border border-gray-300 focus:outline-none',
                  currentPage === totalPages
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-white text-gray-700 hover:bg-gray-50',
                )}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableComponent;
