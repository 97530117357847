import { useWindowSize } from '@app/hooks/useWindowSize';
import { hyphenate } from '@app/utils/hyphenate';
import React, { useState, useEffect } from 'react';

interface DynamicTextSlicerProps {
  text: string;
  className?: string;
}

export const DynamicTextSlicer: React.FC<DynamicTextSlicerProps> = ({ text, className }) => {
  const { width } = useWindowSize();
  const [slicedText, setSlicedText] = useState(text);

  useEffect(() => {
    let charLimit: number;
    if (width < 640) {
      // Mobile
      charLimit = 20;
    } else if (width < 768) {
      // Tablet
      charLimit = 30;
    } else if (width < 1024) {
      // Small desktop
      charLimit = 40;
    } else {
      // Large desktop
      charLimit = 50;
    }

    setSlicedText(text.length > charLimit ? `${text.slice(0, charLimit)}...` : text);
  }, [text, width]);

  return (
    <span className={className} data-testid={hyphenate(text)}>
      {slicedText}
    </span>
  );
};
