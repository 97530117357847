import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SupportedCountry = 'in' | 'hk';

export const supportedCountry: SupportedCountry[] = ['in', 'hk'];

export enum CorporateType {
  // TODO - make this non-corporate when the API is updated
  NonCorporate = 'non-corporate',
  Corporate = 'corporate',
}

type InitialCompanySlice = {
  activeCountry: SupportedCountry;
  corporateType: CorporateType;
};

const initialState: InitialCompanySlice = {
  activeCountry: 'in',
  corporateType: CorporateType.Corporate,
};

export const companySlice = createSlice({
  name: 'companySlice',
  initialState,
  reducers: {
    setActiveCountry: (state, action: PayloadAction<SupportedCountry>) => {
      state.activeCountry = action.payload;
    },
    setCorporateType: (state, action: PayloadAction<CorporateType>) => {
      state.corporateType = action.payload;
    },
  },
});

export const { setActiveCountry, setCorporateType } = companySlice.actions;

export default companySlice;
