import React from 'react';
import { EdgeProps } from 'react-flow-renderer';

interface CustomLabelProps {
  text: string;
  onClick: () => void;
}

const CustomLabel: React.FC<CustomLabelProps> = ({ text, onClick }) => (
  <div
    className='count-box'
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  >
    {text}
  </div>
);

const CustomEdge: React.FC<EdgeProps> = ({ id, sourceX, sourceY, targetX, targetY, label }) => {
  const edgePath = `M${sourceX},${sourceY} C ${sourceX + (targetX - sourceX) / 2},${sourceY} ${targetX - (targetX - sourceX) / 2},${targetY} ${targetX},${targetY}`;

  return (
    <>
      <path id={id} className='react-flow__edge-path' d={edgePath} />
      {label && typeof label !== 'string' && (
        <foreignObject
          width={80}
          height={40}
          x={sourceX + (targetX - sourceX) / 2 - 40}
          y={sourceY + (targetY - sourceY) / 2 - 20}
          className='edge-label'
        >
          {label}
        </foreignObject>
      )}
    </>
  );
};

export { CustomEdge, CustomLabel };
