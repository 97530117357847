import React, { useState, useEffect } from 'react';
import TableComponent from '../../../lib-components/table/table/TableComponent';
import Accordion from '../../../lib-components/accordian/Accordian';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { useParams } from 'react-router-dom';
import Loading from '@components/lib-components/loading/Loading';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { TabsCommonPropType } from '@components/company/companyCard.types';
import { ApiResponse } from '@app/types';
import { CreditRating, CreditRatingResponse } from '@app/types/creditRatings';
import { formatDate } from '@components/utils/commonUtils';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';

interface TableRow {
  'Rating Date': string;
  Name: string;
  Instrument: string;
  Rating: string;
}

const CreditTab: React.FC<TabsCommonPropType> = () => {
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const { id } = useParams<{ id: string }>();
  const getCompanyCreditRatingData = useGetCompanyData<ApiResponse<CreditRatingResponse>>();
  const { data, isLoading, isError } = getCompanyCreditRatingData({
    cinId: id as string,
    field_name: 'credit_ratings',
    identifierType: identifierType,
  });
  const [groupedRatings, setGroupedRatings] = useState<Record<string, CreditRating[]>>({});
  const [hasRatings, setHasRatings] = useState(false);

  useEffect(() => {
    if (data?.response_data?.credit_ratings) {
      const grouped = data.response_data.credit_ratings.reduce(
        (acc, rating) => {
          const agencyName = rating.rating_agency || 'Unknown Agency';
          if (!acc[agencyName]) {
            acc[agencyName] = [];
          }
          acc[agencyName].push(rating);
          return acc;
        },
        {} as Record<string, CreditRating[]>,
      );

      setGroupedRatings(grouped);
      setHasRatings(Object.keys(grouped).length > 0);
    } else {
      setGroupedRatings({});
      setHasRatings(false);
    }
  }, [data]);

  const isReportFetching =
    data?.response_data?.status === 'INITIALISED' || data?.response_data?.status === 'IN_PROGRESS';
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(null);

  if (isLoading) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );
  }

  if (isError) {
    return (
      <ErrorMessage message={'Error fetching credit ratings, please try again in some time'} />
    );
  }

  if (isReportFetching) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading
          message={
            'Kindly wait a few minutes, we are fetching the company credit-ratings data for you.'
          }
        />
      </div>
    );
  }

  const renderTableRows = (ratings: CreditRating[]): TableRow[] => {
    return ratings.map((rating) => ({
      'Rating Date': formatDate(rating.ratings_date) || 'N/A',
      Name: rating.rating_agency || 'N/A',
      Instrument: rating.instrument
        ? rating.instrument
            .split(',')
            .map((instr) => instr.trim())
            .join('\n')
        : 'N/A',
      Rating: rating.rating || 'N/A',
    }));
  };

  return (
    <div className='w-full mt-5 border-0'>
      {hasRatings ? (
        <>
          <h2 className='text-xl font-semibold'>Credit Ratings by Agency</h2>
          {Object.entries(groupedRatings).map(([agencyName, ratings], index) => (
            <Accordion
              key={index}
              title={agencyName}
              icon={agencyName}
              isOpen={openAccordionIndex === index}
              onToggle={() => setOpenAccordionIndex(openAccordionIndex === index ? null : index)}
            >
              <div className='p-0 rounded-lg'>
                {ratings.length > 0 ? (
                  <div>
                    <div className='text-end m-2 mt-0 text-sm'>
                      <a
                        href={ratings[0].ratings_url}
                        target='_blank'
                        className='text-blue-700 font-semibold'
                        rel='noreferrer'
                      >
                        Link to the rating url
                      </a>
                    </div>
                    <TableComponent
                      headers={['', 'Rating Date', 'Name', 'Instrument', 'Rating']}
                      rows={renderTableRows(ratings)}
                    />
                  </div>
                ) : (
                  <p className='m-auto w-full text-center text-gray-400 text-sm'>
                    {agencyName} rating not found
                  </p>
                )}
              </div>
            </Accordion>
          ))}
        </>
      ) : (
        <ErrorMessage message='No ratings available for this company.' />
      )}
    </div>
  );
};

export default CreditTab;
