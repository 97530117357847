import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { setUnlockReportStatus } from '@app/store/slices/utilSlice';
import Button from '@app/components/lib-components/button/Button';
import { Chip } from '../Chip';
import moment from 'moment';
import { useDispatch } from 'react-redux';

interface ReportStatusProps {
  reportStatus: ReportStatusEnum;
  isReportFetching: boolean;
  reportDate?: string;
  refreshCount?: number;
  refreshAvailable?: boolean;
}

export const ReportStatus: React.FC<ReportStatusProps> = ({
  reportStatus,
  isReportFetching,
  reportDate,
  refreshCount,
  refreshAvailable,
}) => {
  const dispatch = useDispatch();

  if (isReportFetching) {
    return (
      <div className='shimmer-message !text-sm border p-2 rounded-lg'>
        Report fetching in progress
      </div>
    );
  }

  if (reportStatus === ReportStatusEnum.IN_PROGRESS_ALREADY_EXISTS) {
    return (
      <div className='inline-flex flex-col gap-2'>
        <div className='shimmer-message !text-sm border p-2 rounded-lg flex flex-col gap-2'>
          <span>Latest Report is being processed</span>
        </div>
        {reportDate && (
          <span className='text-xs font-semibold text-gray-400 ml-auto'>
            Last Updated Date: &nbsp;
            {moment(reportDate).format('DD MMMM YYYY')}
          </span>
        )}
      </div>
    );
  }

  if (
    reportStatus === ReportStatusEnum.LOCKED ||
    reportStatus === ReportStatusEnum.ALREADY_EXISTS
  ) {
    return (
      <div className='flex gap-2 flex-col'>
        {reportDate && (
          <Chip name={`Last Updated Date: ${moment(reportDate).format('DD MMMM YYYY')}`} />
        )}
        <Button
          className='py-1 font-medium ml-auto'
          onClick={() => dispatch(setUnlockReportStatus(true))}
          text='Unlock Report'
          variant='base'
        />
      </div>
    );
  }

  if (reportStatus === ReportStatusEnum.COMPLETED) {
    if (refreshCount && (refreshCount > 0 || refreshAvailable)) {
      return (
        <div className='flex flex-col gap-0 !md:ml-auto sm:mr-auto'>
          <Button
            className='py-1 !text-md font-medium !md:ml-auto sm:mr-auto'
            onClick={() => dispatch(setUnlockReportStatus(true))}
            text='Refresh Report'
            variant='base'
          />
          <span className='text-xs text-center font-semibold mt-1 text-gray-400'>
            No. of refreshes left: {refreshCount}
          </span>
        </div>
      );
    }

    if (refreshCount === 0) {
      return (
        <div className='inline-flex gap-4 !md:ml-auto sm:mr-auto'>
          <Button
            className='py-1 font-medium !md:ml-auto sm:mr-auto'
            onClick={() => dispatch(setUnlockReportStatus(true))}
            text='Buy Fresh Report'
            variant='base'
          />
        </div>
      );
    }
  }

  return null;
};
