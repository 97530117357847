const config = {
  AUTH_SECRET_KEY: import.meta.env.VITE_SECRET_KEY,
  API_BASE_URL: import.meta.env.VITE_BASE_URL,
  POSTHOG_KEY: import.meta.env.VITE_POSTHOG_KEY,
  POSTHOG_HOST: import.meta.env.VITE_POSTHOG_HOST,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
};

export default config;
