import React from 'react';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { ValidTab } from '@components/lib-components/tab-menu/tabContainer.types';
import { useReportIssueMutation } from '@app/store/api/companyApi';

interface TabErrorFallbackProps {
  tab: ValidTab;
  companyCin: string;
}

export const TabErrorFallback: React.FC<TabErrorFallbackProps> = ({ tab, companyCin }) => {
  const [reportIssue, { isLoading, isError, isSuccess }] = useReportIssueMutation();

  const handleReportIssue = () => {
    if (!isSuccess) {
      reportIssue({
        issue: `Error rendering ${tab} data`,
        description: `Company CIN: ${companyCin}`,
      })
        .unwrap()
        .catch((error) => {
          console.error('Failed to report issue:', error);
        });
    }
  };

  return (
    <div className='w-[93.8vw] flex flex-col items-center'>
      <ErrorMessage message={`Data for ${tab} is not available, please try again in some time`} />
      {!isSuccess ? (
        <button
          onClick={handleReportIssue}
          className='mt-4 px-4 py-2 bg-transparent text-blue-600 text-xs border-0 rounded-md hover:bg-blue-100 focus:outline-none'
          disabled={isLoading}
        >
          {isLoading ? 'Reporting...' : 'Report Issue'}
        </button>
      ) : (
        <p className='mt-4 text-green-600'>Issue reported successfully.</p>
      )}

      {isError && (
        <p className='mt-2 text-red-600'>Failed to report the issue. Please try again later.</p>
      )}
    </div>
  );
};
