import { Navigate, useNavigate } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AuthForm } from './auth.types';
import { useLoginMutation, useLazyGetUserDataQuery } from '@app/store/api/authApi/authApi';
import { useLazyGetTokenBalanceQuery } from '@app/store/api/tokenLedger';
import { InputV2 } from '@app/components/lib-components/input/InputV2';
import { yupResolver } from '@hookform/resolvers/yup';
import { getMessage } from '@app/store/errorHandler';
import { useToggle } from '@app/hooks/useToggle';
import { usePostHog } from 'posthog-js/react';
import { toast } from 'sonner';
import Button from '@app/components/lib-components/button/Button';
import * as Yup from 'yup';
import Icon from '@app/components/lib-components/icon/Icon';

const Schema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

type Ischema = Yup.InferType<typeof Schema>;

const Login = () => {
  const isAuth = localStorage.getItem('accessToken');
  const [showPassword, togglePassword] = useToggle();
  const [loginUser, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const [getUserInfo] = useLazyGetUserDataQuery();
  const [fetchTokens] = useLazyGetTokenBalanceQuery();
  const posthog = usePostHog();

  const form = useForm<AuthForm>({
    mode: 'all',
    resolver: yupResolver(Schema),
  });

  const handleLoginSubmit: SubmitHandler<Ischema> = async (data) => {
    try {
      const response = await loginUser(data).unwrap();
      navigate('/');
      const userInfo = await getUserInfo(response.response_data.user_id).unwrap();
      await fetchTokens(userInfo.response_data.company_id).unwrap();

      // Identify the user with PostHog
      posthog.identify(userInfo.response_data.id, {
        email: data.email,
        company: userInfo.response_data.company_name,
      });

      // Capture successful login event
      posthog.capture('user_logged_in', {
        distinct_id: userInfo.response_data.id,
        email: data.email,
        company: userInfo.response_data.company_name,
      });
    } catch (error) {
      toast.error(getMessage(error, 'Error Login'));
      // Capture failed login event
      posthog.capture('login_failed', {
        email: data.email,
        error: error instanceof Error ? error.message : 'Unknown error',
      });

      // Reset the form to clear the password field
      form.reset({ email: data.email, password: '' });
    }
  };

  if (isAuth) {
    return <Navigate to='/' replace />;
  }

  return (
    <div className='w-full flex flex-col justify-center items-center text-black self-center basis-2/5 p-8'>
      <div className='p-8 flex flex-col w-full'>
        <div className='text-center m-auto'>
          <Icon icon='CompanyLogo' size={100} />
          <h4 className='text-slate-300 font-bold text-xl mt-6 mb-3' data-testid='login-title'>
            Welcome to CredHive
          </h4>
        </div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleLoginSubmit)}>
            <section className='flex w-full flex-col gap-3 p-2 mt-3 items-center'>
              <InputV2
                className='w-full'
                name='email'
                placeholder='Email Address / User Name'
                type='email'
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                prefixIcon='EnvelopeIcon'
                label=''
                prefixIconStyle='text-primary'
                data-testid='email-input'
              />
              <InputV2
                className='w-full'
                name='password'
                placeholder='Password'
                type={showPassword ? 'text' : 'password'}
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                label=''
                prefixIcon={showPassword ? 'EyeSlashIcon' : 'EyeIcon'}
                prefixIconHandler={togglePassword.toggle}
                prefixIconStyle='text-primary'
                data-testid='password-input'
              />
              <div className='flex justify-between items-center w-full'>
                <div className='flex items-center'>
                  <input type='checkbox' id='remember' className='mr-2' />
                  <label htmlFor='remember' className='text-sm font-medium'>
                    Remember me
                  </label>
                </div>
                <a href='/forgot-password' className='text-sm text-brand_color font-semibold'>
                  Forgot Password?
                </a>
              </div>
              <Button
                text='Login'
                variant='base'
                className='mt-2 w-full self-end'
                type='submit'
                isLoading={isLoading}
              />
              <div className='flex justify-center items-center w-full mt-4 gap-1'>
                <span className='text-sm text-gray-400 font-semibold'>Not registered yet?</span>
                <a href='/register' className='text-brand_color font-semibold text-sm'>
                  Create an Account
                </a>
              </div>
            </section>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Login;
