import Modal from '@app/components/lib-components/modal/Modal';
import Button from '@app/components/lib-components/button/Button';
import Icon from '@components/lib-components/icon/Icon';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPaymentStatus } from '@app/store/slices/authSlice';
import Loading from '@components/lib-components/loading/Loading';

export const CreditsSuccessModal = () => {
  const paymentStatus = useSelector((state: RootState) => state.auth.paymentStatus);
  const tokenCount = localStorage.getItem('latest_purchase');

  const dispatch = useDispatch();

  const { totalCredits } = useSelector((state: RootState) => state.util);
  const handleClose = () => {
    dispatch(setPaymentStatus(''));
    setShowSuccessModal(false);
    localStorage.removeItem('latest_purchase');
  };

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (paymentStatus === 'SUCCESS') {
      const timer = setTimeout(() => {
        setIsLoading(false);
        setShowSuccessModal(true);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setShowSuccessModal(false);
      setIsLoading(true);
    }
  }, [paymentStatus]);

  return (
    <Modal isOpen={showSuccessModal && paymentStatus === 'SUCCESS'} onClose={handleClose}>
      {isLoading ? (
        <div className='min-h-[200px] flex items-center justify-center'>
          <Loading />
        </div>
      ) : (
        <div className='text-center animate-fade-in'>
          <h4 className='text-slate-700 font-bold mb-2'>Thank you!</h4>
          <p className='text-gray-500 text-sm'>
            Your purchase of {tokenCount} tokens was successful.
          </p>
          <div className='flex items-center border mx-auto w-max px-4 py-3 text-3xl my-4 rounded-xl !border-[#5e470c1f] bg-[#FFE6A51F]'>
            <Icon icon='AppLogoWithoutLabel' size={20} className='mr-1' />
            <p className='text-brand_color'>
              <span className='font-[800] mr-1 text-brand_color'>{totalCredits}</span>
              Tokens
            </p>
          </div>
          <Button text='Continue' onClick={handleClose} className='text-center w-full my-4' />
        </div>
      )}
    </Modal>
  );
};
