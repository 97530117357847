import { ChangeEvent } from 'react';
import { CheckboxProps } from './checkbox.types';

const Checkbox = ({ checked, label, className, onToggle }: CheckboxProps) => {
  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    onToggle(event.target.checked);
  };

  return (
    <label
      className={`flex self-end items-center cursor-pointer ${className} ${checked ? 'scale-105' : ''}`}
    >
      <input
        type='checkbox'
        checked={checked}
        onChange={handleToggle}
        className='form-checkbox h-3 w-3 text-indigo-600'
      />
      <span className='ml-1 text-xs whitespace-nowrap'>{label}</span>
    </label>
  );
};

export default Checkbox;
