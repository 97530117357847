import React, { useState } from 'react';
import {
  RelatedParty,
  RelatedPartyTableProps,
  RelatedPartyCategory,
  RelatedPartyTableHeader,
} from '@app/types/financial';
import { numDifferentiation } from '@components/utils/commonUtils';
import Accordion from '@components/lib-components/accordian/Accordian';
import Table from '@components/lib-components/table/table/Table';
import Button from '@components/lib-components/button/Button';
import { PartyDetailsModal } from './PartyDetailsModal';

const headers: RelatedPartyTableHeader[] = [
  { name: 'Name', value: 'name', classes: 'p-4 text-sm' },
  { name: 'Type', value: 'type', classes: 'p-4 text-sm text-left whitespace-pre-wrap' },
  { name: 'PAN', value: 'pan', classes: 'p-4 text-sm text-left' },
  { name: 'Amount Payable', value: 'amounts_payable', classes: 'p-4 text-sm text-left' },
  {
    name: 'Transaction Expenses',
    value: 'transactions_expenses',
    classes: 'p-4 text-sm text-left',
  },
  { name: ' ', value: 'actions', classes: 'p-4 text-sm text-left' },
];

const categories: RelatedPartyCategory[] = [
  { id: 'individuals', title: 'Individual Related Parties' },
  { id: 'companies', title: 'Company Related Parties' },
  { id: 'others', title: 'Other Related Parties' },
];

const RelatedPartyTable: React.FC<RelatedPartyTableProps> = ({ data, figureFormat }) => {
  const [openAccordions, setOpenAccordions] = useState<string[]>([]);
  const [selectedParty, setSelectedParty] = useState<RelatedParty | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleAccordion = (accordionId: string) => {
    setOpenAccordions((prev) =>
      prev.includes(accordionId) ? prev.filter((id) => id !== accordionId) : [...prev, accordionId],
    );
  };

  const handleViewDetails = (party: RelatedParty) => {
    setSelectedParty(party);
    setIsModalOpen(true);
  };

  const formatType = (type: string): string => {
    if (!type) return '-';

    let formattedType = type.replace(/Member$/, '');

    formattedType = formattedType.split(/(?=[A-Z])/).join(' ');

    return formattedType
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const createRows = (parties: RelatedParty[]) => {
    return parties.map((party) => ({
      name: party.name_of_related_party || '-',
      type: formatType(party.type) || '-',
      pan: party.pan || '-',
      amounts_payable:
        party.amounts_payable !== null
          ? numDifferentiation(party.amounts_payable, figureFormat)
          : '-',
      transactions_expenses:
        party.transactions_expenses !== null
          ? numDifferentiation(party.transactions_expenses, figureFormat)
          : '-',
      actions: (
        <Button
          text='View Details'
          variant='text'
          className='text-utility-600 hover:text-brand-600 bg-transparent'
          onClick={() => handleViewDetails(party)}
        />
      ),
    }));
  };

  return (
    <div className='w-full'>
      {categories.map((category) => {
        const categoryCount = data.reduce((sum, yearData) => {
          if (yearData.data) {
            return sum + (yearData.data[category.id]?.length || 0);
          }
          return sum;
        }, 0);

        return (
          <Accordion
            key={category.id}
            title={category.title}
            isOpen={openAccordions.includes(category.id)}
            onToggle={() => toggleAccordion(category.id)}
            count={categoryCount}
          >
            {categoryCount > 0 ? (
              data.map((yearData, index) => {
                const parties = yearData.data[category.id] || [];

                // Skip if no parties
                if (parties.length === 0) {
                  return null;
                }

                const displayYear =
                  yearData.year || yearData.metadata?.financial_year?.split('-')[0] || 'N/A';
                const accordionId = `${category.id}-${displayYear}-${index}`;

                const rows = createRows(parties);
                return (
                  <Accordion
                    key={accordionId}
                    title={`FY-${displayYear}`}
                    isOpen={openAccordions.includes(accordionId)}
                    onToggle={() => toggleAccordion(accordionId)}
                    count={parties.length}
                  >
                    <Table
                      tableDivClassname='w-full border border-gray-200 !p-0 rounded mt-4'
                      headers={headers}
                      rows={rows}
                    />
                  </Accordion>
                );
              })
            ) : (
              <div className='w-full p-4 text-center text-gray-600'>
                No data available for {category.title.toLowerCase()}
              </div>
            )}
          </Accordion>
        );
      })}

      {selectedParty && (
        <PartyDetailsModal
          party={selectedParty}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          figureFormat={figureFormat}
          formatType={formatType}
        />
      )}
    </div>
  );
};

export default RelatedPartyTable;
