import { Header, Row, TableProps } from './table.types';
import TableHeader from '../table-header/TableHeader';
import Loading from '../../loading/Loading';
import Icon from '../../icon/Icon';
import { formatDate } from '@components/utils/commonUtils';
import classNames from 'classnames';
import { toast } from 'sonner';

const Table = ({
  isLoading = false,
  tableDivClassname,
  className,
  headers = [],
  rows = [],
  fixedColumnIndex,
  fixedRowIndex,
  tableHeaderClassname,
  tableBodyClassname,
  sortParam,
  setSortParam,
  onSelectRows,
  pagination,
  totalSelectedCount = 0,
  selectedUrls = [],
  showCheckbox = true,
  isDownloading = false,
}: TableProps) => {
  const stickyStyle = 'sticky top-0 z-10 bg-surface-base';

  const handleCheckboxChange = (row: Row) => {
    if (isDownloading) {
      toast.error('Please wait for current downloads to complete');
      return;
    }

    if (!row.id || typeof row.id === 'object') return;

    if (!row.s3_url) {
      toast.error('Error fetching document, please contact us to request this document');
      return;
    }

    const isSelected = selectedUrls.includes(row.s3_url);

    const currentSelectedRows = rows.filter((r) => r.s3_url && selectedUrls.includes(r.s3_url));

    let newSelectedRows: Row[];
    if (isSelected) {
      newSelectedRows = currentSelectedRows.filter((r) => r.s3_url !== row.s3_url);
    } else {
      if (currentSelectedRows.length >= 5) {
        return;
      }
      newSelectedRows = [...currentSelectedRows, row];
    }

    if (onSelectRows) {
      onSelectRows(newSelectedRows);
    }
  };

  const handleDownload = (attachments: string[]) => {
    attachments.forEach((attachment) => {
      const link = document.createElement('a');
      link.href = `./documents/${attachment}`;
      link.download = attachment;
      link.click();
    });
  };

  const renderCell = (header: Header, headerIndex: number, row: Row) => {
    if (header.value === 'checkbox' && showCheckbox) {
      if (!row.s3_url) {
        return <td key={`table-${headerIndex}`} className='p-2 pl-3'></td>;
      }

      const isSelected = row.s3_url && selectedUrls.includes(row.s3_url);
      const isDisabled = (!isSelected && totalSelectedCount >= 5) || isDownloading;

      return (
        <td key={`table-${headerIndex}`} className='p-2 pl-3 text-tertiary font-medium'>
          <input
            type='checkbox'
            data-s3-url={row.s3_url}
            className={classNames('form-checkbox', {
              'opacity-50 cursor-not-allowed': isDisabled,
              'cursor-pointer': !isDisabled,
            })}
            checked={Boolean(isSelected)}
            onChange={() => handleCheckboxChange(row)}
            disabled={isDisabled && !isSelected}
          />
        </td>
      );
    } else if (header.value === 'checkbox') {
      return <td key={`table-${headerIndex}`} className='p-2 pl-3'></td>;
    } else if (header.value === 'download') {
      return (
        <td
          key={`table-${headerIndex}`}
          className='p-2 text-center !w-32 !max-w-32 text-tertiary font-medium'
        >
          <button
            className='p-2 text-blue-600 bg-transparent'
            onClick={() =>
              row.attachments && Array.isArray(row.attachments)
                ? handleDownload(row.attachments)
                : undefined
            }
          >
            <Icon icon='DownloadIcon' size={20} color='lightgray' />
          </button>
        </td>
      );
    } else if (header.value === 'view') {
      return (
        <td
          key={`table-${headerIndex}`}
          className='p-2 text-center !w-32 !max-w-32 text-tertiary font-medium'
        >
          <button
            className='p-2 text-blue-600 bg-transparent'
            onClick={() => window.open('#', '_blank')}
          >
            <Icon icon='EyeIcon' size={20} color='lightgray' />
          </button>
        </td>
      );
    } else if (
      header.value.toLowerCase().includes('date') &&
      row[header.value] &&
      row[header.value] !== '-'
    ) {
      return (
        <td
          key={`table-${headerIndex}`}
          className='p-2 pl-4 text-sm !w-32 !max-w-32 text-tertiary font-medium'
        >
          {formatDate(row[header.value] as string)}
        </td>
      );
    } else if (header.value.toLowerCase().includes('date')) {
      return (
        <td
          key={`table-${headerIndex}`}
          className='p-2 text-sm !w-32 !max-w-32 text-tertiary font-medium'
        >
          {row[header.value]}
        </td>
      );
    } else if (header.value.includes('changes')) {
      const changeValue = parseFloat(row[header.value] as string);
      const changeColor = classNames({
        'text-green-600': changeValue > 0,
        'text-red-600': changeValue < 0,
        'text-gray-600': changeValue === 0,
      });
      const changeIcon =
        changeValue > 0 ? 'ArrowUpIcon' : changeValue < 0 ? 'ArrowDownIcon' : 'MinusIcon';

      return (
        <td
          key={`table-${headerIndex}`}
          className={classNames(
            'p-4 text-tertiary',
            header.classes,
            tableBodyClassname,
            'relative',
            { [stickyStyle]: headerIndex === fixedColumnIndex },
          )}
        >
          <span className={classNames('flex items-center', changeColor)}>
            {row[header.value]}
            {row[header.value] !== '00.00%' && (
              <Icon
                icon={changeIcon}
                size={16}
                color={changeValue > 0 ? 'green' : changeValue < 0 ? 'red' : 'gray'}
                className='ml-1'
              />
            )}
          </span>
        </td>
      );
    } else if (header.value === 'attachments') {
      return (
        <td
          key={`table-${headerIndex}`}
          className={classNames(
            'p-2 whitespace-nowrap overflow-x-auto !w-94 !max-w-96 text-tertiary font-medium',
            header.classes,
            tableBodyClassname,
            'relative',
            { [stickyStyle]: headerIndex === fixedColumnIndex },
          )}
        >
          {row[header.value]}
        </td>
      );
    } else {
      return (
        <td
          key={`table-${headerIndex}`}
          className={classNames(
            'p-4 text-tertiary font-medium',
            header.classes,
            tableBodyClassname,
            'relative',
            { [stickyStyle]: headerIndex === fixedColumnIndex },
          )}
        >
          {row[header.value]}
        </td>
      );
    }
  };
  const isNextDisabled = pagination && pagination.currentPage * 10 >= pagination.maxLimit;

  return (
    <div
      className={classNames(tableDivClassname, 'border rounded-md overflow-auto relative z-0')}
      data-testid='credhive-common-table'
    >
      {isLoading ? (
        <Loading />
      ) : (
        rows.length > 0 && (
          <>
            <table
              className={classNames(
                className,
                'w-full border-separate border-spacing-0 bg-surface-1 xl:table-fixed md:table-auto',
              )}
            >
              <thead className='sticky top-0 z-10 bg-surface-2 text-sm'>
                <tr
                  className={classNames('text-left ', { [stickyStyle]: fixedRowIndex === 0 })}
                  data-testid='credhive-common-table-header'
                >
                  {headers.map((header, index) => (
                    <TableHeader
                      key={`table-header-${index}`}
                      header={header}
                      index={index}
                      fixedColumnIndex={fixedColumnIndex}
                      classname={tableHeaderClassname || header.headerClasses}
                      sortParam={sortParam}
                      setSortParam={setSortParam as React.Dispatch<React.SetStateAction<string>>}
                    />
                  ))}
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {rows.map((row, rowIndex) => (
                  <tr
                    key={`table-${rowIndex}`}
                    data-s3-url={row.s3_url}
                    className={classNames(
                      'hover:bg-gray-50 text-tertiary font-medium',
                      { 'bg-gray-50': rowIndex % 2 !== 0 },
                      { [stickyStyle]: fixedRowIndex === rowIndex + 1 },
                    )}
                  >
                    {headers.map((header, headerIndex) => renderCell(header, headerIndex, row))}
                  </tr>
                ))}
              </tbody>
            </table>

            {pagination && typeof pagination === 'object' && (
              <div
                className='flex justify-between items-center py-3 px-6 border-t border-gray-200 text-sm rounded-b-lg'
                style={{ position: 'sticky', bottom: 0, width: '100%', background: 'white' }}
              >
                <div>
                  {(() => {
                    const pageSize = pagination.pageSize || 10; // Default to 10 if not provided
                    const start = (pagination.currentPage - 1) * pageSize + 1;
                    const end = Math.min(pagination.currentPage * pageSize, pagination.maxLimit);
                    return `Showing ${start} - ${end} of ${pagination.maxLimit}`;
                  })()}
                </div>
                <div>
                  <button
                    onClick={() => pagination.onPageChange(pagination.currentPage - 1)}
                    className={classNames(
                      'mx-1 px-4 py-2 text-sm font-medium rounded-md border border-gray-300 focus:outline-none',
                      {
                        'bg-gray-100 text-gray-400 cursor-not-allowed':
                          pagination.currentPage === 1,
                        'bg-white text-gray-700 hover:bg-gray-50': pagination.currentPage > 1,
                      },
                    )}
                    disabled={pagination.currentPage === 1}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => pagination.onPageChange(pagination.currentPage + 1)}
                    className={classNames(
                      'mx-1 px-4 py-2 text-sm font-medium rounded-md border border-gray-300 focus:outline-none',
                      {
                        'bg-gray-100 text-gray-400 cursor-not-allowed':
                          pagination.currentPage * (pagination.pageSize || 10) >=
                          pagination.maxLimit,
                        'bg-white text-gray-700 hover:bg-gray-50':
                          pagination.currentPage * (pagination.pageSize || 10) <
                          pagination.maxLimit,
                      },
                    )}
                    disabled={isNextDisabled}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};

export default Table;
