import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import authApi from '@app/store/api/authApi/authApi';

type AuthState = {
  isAuthLoading: boolean;
  isBlur: boolean;
  paymentStatus: string;
};

const initialState: AuthState = {
  isAuthLoading: false,
  isBlur: false,
  paymentStatus: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.isAuthLoading = action.payload;
    },
    setIsBlur: (state, action: PayloadAction<boolean>) => {
      state.isBlur = action.payload;
    },
    setPaymentStatus: (state, action: PayloadAction<string>) => {
      state.paymentStatus = action.payload;
    },
    clearAuth: (state) => {
      state.isAuthLoading = false;
      state.isBlur = false;
      localStorage.clear();
      state.paymentStatus = '';
    },
  },
  extraReducers: (builder) => {
    // This is like a subscriber which will be called when the login api is successful
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (_, { payload }) => {
      const accessToken = payload.response_data.token_details.AccessToken;
      const refreshToken = payload.response_data.token_details.RefreshToken;
      const idToken = payload.response_data.token_details.IdToken;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('idToken', idToken);
      localStorage.setItem('userId', payload.response_data.user_id);
    });
  },
});

export const { setIsAuthLoading, setIsBlur, setPaymentStatus, clearAuth } = authSlice.actions;

export default authSlice.reducer;
