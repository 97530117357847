import React, { useMemo } from 'react';
import Modal from '@app/components/lib-components/modal/Modal';
import Table from '@app/components/lib-components/table/table/Table';
import { cameltoTitleCase, formatDate } from '../../../utils/commonUtils';
import { AssociationHistory } from '@app/types/management';

interface AssociationHistoryProps {
  isOpen: boolean;
  onClose: () => void;
  associationHistory: AssociationHistory[];
}

const AssociationHistoryModal: React.FC<AssociationHistoryProps> = ({
  isOpen,
  onClose,
  associationHistory,
}) => {
  const headers = [
    { name: 'Event', value: 'event', classes: 'p-4 text-sm text-gray-600' },
    { name: 'Designation After Event', value: 'designation_after_event', classes: 'p-4 text-sm' },
    {
      name: 'Date of Appointment',
      value: 'date_of_appointment_for_designation',
      classes: 'p-4 text-sm',
    },
    {
      name: 'Date of Cessation',
      value: 'date_of_cessation_for_designation',
      classes: 'p-4 text-sm',
    },
  ];

  const rows = useMemo(() => {
    return associationHistory.map((item) => ({
      event: cameltoTitleCase(item.event).split('_').join(' '),
      designation_after_event: item.designation_after_event,
      date_of_appointment_for_designation: item.date_of_appointment_for_designation
        ? formatDate(item.date_of_appointment_for_designation)
        : '-',
      date_of_cessation_for_designation: item.date_of_cessation_for_designation
        ? formatDate(item.date_of_cessation_for_designation)
        : '-',
    }));
  }, [associationHistory]);

  return (
    <Modal className='!w-[60%]' title='Association History' isOpen={isOpen} onClose={onClose}>
      <Table
        tableDivClassname='w-full border border-gray-200 !p-0 rounded-lg'
        headers={headers}
        rows={rows}
      />
    </Modal>
  );
};

export default AssociationHistoryModal;
