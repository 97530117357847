import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '@app/store/store';
import SignupPage from './pages/Auth/SignupPage';
import ForgetPage from './pages/Auth/ForgetPage';
import ResetPage from './pages/Auth/ResetPage';
import PaymentResult from './pages/Payment/PaymentResult';
import PaymentSuccess from './pages/Payment/PaymentSuccess';
import PaymentPending from './pages/Payment/PaymentPending';
import ProtectedRoute from './pages/Auth/Auth';
import { MainLayout, DashboardLazyLoader } from './layout';
import CompanyInfo from './pages/company-info/CompanyInfo';
import * as Sentry from '@sentry/react';
import Loading from '@components/lib-components/loading/Loading';
import LoginPage from './pages/Auth/LoginPage';
import NotFound from '@pages/Errors/Notfound';
import DirectorProfile from './components/company/company-tabs/Management/DirectorProfile/DirectorProfile';

const PdfViewer = lazy(() => import('@components/company/company-tabs/Overview/PDFViewer'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const Home = lazy(() => import('./pages/HomePage'));
const Settings = lazy(() => import('./pages/Settings/Settings'));

import { UrlKeys, UrlMapping } from './routes';

const App = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const [lastValidUrl, setLastValidUrl] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/404') {
      setLastValidUrl(location.pathname);
    }
  }, [location]);

  return (
    <Provider store={store}>
      <PersistGate loading={<Loading className='loading h-screen' />} persistor={persistor}>
        <SentryRoutes>
          <Route path={UrlMapping[UrlKeys.login]} element={<LoginPage />} />
          <Route path={UrlMapping[UrlKeys.register]} element={<SignupPage />} />
          <Route path={UrlMapping[UrlKeys.forgotPassword]} element={<ForgetPage />} />
          <Route path={UrlMapping[UrlKeys.reset]} element={<ResetPage />} />

          <Route
            path='/'
            element={
              <Suspense fallback={<DashboardLazyLoader />}>
                <ProtectedRoute>
                  <MainLayout />
                </ProtectedRoute>
              </Suspense>
            }
          >
            <Route index element={<Home />} />
            <Route path={UrlMapping[UrlKeys.search]} element={<Dashboard />} />
            <Route path={UrlMapping[UrlKeys.settings]} element={<Settings />} />
            <Route path={UrlMapping[UrlKeys.corporateCompanyInfo]} element={<CompanyInfo />} />
            <Route path={UrlMapping[UrlKeys.director]} element={<DirectorProfile />} />
            <Route path={UrlMapping[UrlKeys.paymentResult]} element={<PaymentResult />} />
            <Route path={UrlMapping[UrlKeys.paymentPending]} element={<PaymentPending />} />
            <Route path={UrlMapping[UrlKeys.paymentSuccess]} element={<PaymentSuccess />} />
            <Route
              path={UrlMapping[UrlKeys.corporateCompanyInfoRedirect]}
              element={<CompanyInfo />}
            />
            <Route
              path={UrlMapping[UrlKeys.nonCorporateCompanyInfoRedirect]}
              element={<CompanyInfo />}
            />
          </Route>
          <Route
            path='/pdf-viewer'
            element={
              <Suspense fallback={<Loading className='loading h-screen' />}>
                <PdfViewer />
              </Suspense>
            }
          />
          <Route path='*' element={<NotFound lastValidUrl={lastValidUrl} />} />
          <Route path='/404' element={<NotFound lastValidUrl={lastValidUrl} />} />
        </SentryRoutes>
      </PersistGate>
    </Provider>
  );
};

export default App;
