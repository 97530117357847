import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { Chip } from '../Chip';
import moment from 'moment';

interface LastUpdatedChipProps {
  reportStatus: ReportStatusEnum;
  reportDate?: string;
}

export const LastUpdatedChip: React.FC<LastUpdatedChipProps> = ({ reportStatus, reportDate }) => {
  if (reportStatus === ReportStatusEnum.COMPLETED && reportDate) {
    return (
      <Chip
        className='whitespace-nowrap'
        name={`Last Updated Date: ${moment(reportDate).format('DD MMMM YYYY')}`}
      />
    );
  }
  return null;
};
