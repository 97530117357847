import React from 'react';
import InfoCard from '@components/lib-components/info-card/InfoCard';
import StatusBox from '@components/lib-components/status-box/StatusBox';
import Table from '@components/lib-components/table/table/Table';

export interface CompanyInfo {
  name: string;
  status: string;
  location: string;
  dateOfIncorporation: string;
  din: string;
  dinStatus: string;
  designation: string;
  dateOfAppointmentCurrentDesignation: string;
}

const CurrentCompanies: React.FC<{ companies: CompanyInfo[] }> = ({ companies }) => {
  return (
    <div>
      {companies.map((company) => (
        <InfoCard key={company.name} className='mb-4 w-full'>
          <div className='flex justify-between items-center mb-4'>
            <h3 className='text-lg font-semibold'>{company.name}</h3>
            <StatusBox
              statusText={company.status}
              statusColor={company.status === 'Active' ? 'bg-green-500' : 'bg-red-500'}
            />
          </div>
          <div className='flex justify-start lg:justify-between gap-4 w-6/12 lg:w-4/12 mb-4'>
            <div>
              <p className='text-sm text-gray-600'>Location</p>
              <p className='text-sm font-medium'>{company.location}</p>
            </div>
            <div>
              <p className='text-sm text-gray-600'>Date of Incorporation</p>
              <p className='text-sm font-medium'>{company.dateOfIncorporation}</p>
            </div>
          </div>
          <Table
            headers={[
              { name: 'DIN', value: 'din', classes: 'text-sm' },
              { name: 'DIN status', value: 'dinStatus', classes: 'text-sm' },
              { name: 'Designation', value: 'designation', classes: 'text-sm' },
              {
                name: 'Date of Appointment',
                value: 'dateOfAppointmentCurrentDesignation',
                classes: 'text-sm',
              },
            ]}
            rows={[
              {
                din: company.din,
                dinStatus: company.dinStatus,
                designation: company.designation,
                dateOfAppointmentCurrentDesignation: company.dateOfAppointmentCurrentDesignation,
              },
            ]}
            tableDivClassname='w-full border border-gray-200 !p-0 rounded'
          />
        </InfoCard>
      ))}
    </div>
  );
};

export default CurrentCompanies;
