import { Navigate, useParams } from 'react-router-dom';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { ShareholdingPatternContainer } from './ShareHoldingPattern/ShareholdingPatternContainer';
import { Overview } from './Overview/Overview';
import { Compliances } from './Compliances/Compliances';
import FinancialTab from './financials/FinancialTab';
import FinancialAnalysisTab from './financial-analysis/FinancialAnalysisTab';
import ManagementTab from './Management/ManagementTab';
import DocumentsTab from './documents/DocumentsTab';
import CreditTab from './CreditRating/CreditTab';
import Charges from './Charges/Charges';
import { ValidTab } from '@components/lib-components/tab-menu/tabContainer.types';
import { Company } from '@app/types/overview';
import { useActiveCompany } from '@app/hooks/useActiveCompany';

interface CorporateProps {
  selectedTab: ValidTab;
  status: ReportStatusEnum;
  companyOverview: Company;
}

export const Corporate = ({ selectedTab, status, companyOverview }: CorporateProps) => {
  const { activeCorporate, activeCountry } = useActiveCompany();
  const { id } = useParams();

  return (
    <>
      {selectedTab === 'overview' && <Overview companyOverviewData={companyOverview as Company} />}
      {status !== ReportStatusEnum.LOCKED && (
        <>
          {selectedTab === 'shareholdings' && <ShareholdingPatternContainer />}
          {selectedTab === 'financial' && <FinancialTab />}
          {selectedTab === 'financial-analysis' && <FinancialAnalysisTab />}
          {selectedTab === 'management' && <ManagementTab />}
          {selectedTab === 'documents' && <DocumentsTab status={status} />}
          {selectedTab === 'compliances' && <Compliances />}
          {selectedTab === 'charges' && <Charges />}
          {selectedTab === 'credit' && <CreditTab status={status} />}
        </>
      )}
      {status === ReportStatusEnum.LOCKED && selectedTab !== 'overview' && (
        <Navigate to={`/${activeCountry}/${activeCorporate}/${id}/overview`} />
      )}
    </>
  );
};
