import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

// TO TRACK PAGEVIEW
// REF - https://posthog.com/tutorials/react-analytics#capturing-pageviews

export const PostHogPageviewTracker = () => {
  const location = useLocation();
  const posthog = usePostHog();
  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageview');
    }
  }, [location, posthog]);

  return null;
};
