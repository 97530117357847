import { axiosBaseQuery } from '@app/store/axiosBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GetByIdentifeirPayload,
  GetCompanyByIdentifierResponse,
  CreateCompanyRequestPayload,
} from './types';
import { ApiResponse } from '@app/types';

const requestCompanyApi = createApi({
  reducerPath: 'requestCompanyApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['company-api'],
  endpoints: (builder) => ({
    searchCompanyByIdentifier: builder.query<
      ApiResponse<GetCompanyByIdentifierResponse[]>,
      GetByIdentifeirPayload
    >({
      query: (payload) => ({
        url: `/entity/get-all-by-identifier`,
        method: 'get',
        params: payload,
      }),
    }),
    createCompanyRequest: builder.mutation<void, CreateCompanyRequestPayload>({
      query: (payload) => ({
        url: `/request_company/create`,
        method: 'post',
        data: payload,
      }),
    }),
  }),
});

export const { useLazySearchCompanyByIdentifierQuery, useCreateCompanyRequestMutation } =
  requestCompanyApi;

export default requestCompanyApi;
