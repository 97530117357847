import React from 'react';
import { PartyDetailsModalProps } from '@app/types/financial';
import { numDifferentiation } from '@components/utils/commonUtils';
import Modal from '@components/lib-components/modal/Modal';

export const PartyDetailsModal: React.FC<PartyDetailsModalProps> = ({
  party,
  isOpen,
  onClose,
  figureFormat,
  formatType,
}) => {
  const details = [
    { label: 'Name', value: party.name_of_related_party },
    { label: 'Type', value: formatType(party.type) },
    { label: 'Filing Type', value: party.filing_type },
    { label: 'Country', value: party.country_of_incorporation_or_residence },
    { label: 'PAN', value: party.pan || '-' },
    { label: 'CIN', value: party.cin || '-' },
    {
      label: 'Nature of Related Party',
      value: party.description_of_nature_of_related_party || '-',
    },
    {
      label: 'Nature of Transaction',
      value: party.description_of_nature_of_transaction_with_related_party || '-',
    },
    {
      label: 'Amount Payable',
      value: party.amounts_payable ? numDifferentiation(party.amounts_payable, figureFormat) : '-',
    },
    {
      label: 'Transfers Under Finance (From)',
      value: party.transfers_under_finance_agreements_from_entity
        ? numDifferentiation(party.transfers_under_finance_agreements_from_entity, figureFormat)
        : '-',
    },
    {
      label: 'Transfers Under Finance (To)',
      value: party.transfers_under_finance_agreements_to_entity
        ? numDifferentiation(party.transfers_under_finance_agreements_to_entity, figureFormat)
        : '-',
    },
    {
      label: 'Purchase of Goods',
      value: numDifferentiation(party.purchase_of_goods, figureFormat),
    },
    {
      label: 'Bad Debts Expense',
      value: numDifferentiation(
        party.expense_recognised_during_period_for_bad_and_doubtful_debts,
        figureFormat,
      ),
    },
    {
      label: 'Income',
      value: party.income ? numDifferentiation(party.income, figureFormat) : '-',
    },
    {
      label: 'Transaction Expenses',
      value: party.transactions_expenses
        ? numDifferentiation(party.transactions_expenses, figureFormat)
        : '-',
    },
    {
      label: 'Contributions Made',
      value: party.contributions_made
        ? numDifferentiation(party.contributions_made, figureFormat)
        : '-',
    },
    {
      label: 'Contributions Received',
      value: party.contributions_received
        ? numDifferentiation(party.contributions_received, figureFormat)
        : '-',
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='Related Party Details'
      className='!max-w-[900px]'
      titleClass='text-xl font-semibold text-utility-blue-600'
    >
      <div className='grid grid-cols-12 gap-x-5 gap-y-3 mt-4 content-between w-auto'>
        {details.map(({ label, value }) => (
          <div key={label} className='col-span-4 my-1'>
            <p className='font-medium text-sm mb-1 text-utility-gray-600'>{label}</p>
            <p className='text-sm font-semibold text-tertiary-600'>{value}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};
