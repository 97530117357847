import { FC } from 'react';
import Table from '@components/lib-components/table/table/Table';
import { MsmeContainer } from '../MsmeContainer';
import { EnterpriseType } from '../../data/msmeTypes';

export const KeyPeople: FC<{ businessType: EnterpriseType }> = ({ businessType }) => {
  // keeping this for future removal and referece
  // TODO - Remove this once we have a new API
  // Owner Table Configuration (for Sole Proprietorship)
  const ownerHeaders = [
    {
      name: 'SNo.',
      value: 'sNo',
      headerClass: 'w-20',
    },
    {
      name: 'Director Name',
      value: 'directorName',
    },
    {
      name: 'Designation',
      value: 'designation',
    },
    {
      name: 'PAN Card',
      value: 'panCard',
    },
    {
      name: 'Phone No.',
      value: 'phoneNo',
    },
  ];

  const ownerRows = [
    {
      sNo: '1',
      directorName: 'Mr. Ravinder Mann',
      designation: 'Owner / Proprietor',
      panCard: 'APUPR0030E',
      phoneNo: '+91 9896327066',
    },
  ];

  // Partners Table Configuration (for Partnership)
  const partnerHeaders = [
    {
      name: 'SNo.',
      value: 'sNo',
      headerClass: 'w-20',
    },
    {
      name: 'Director Name',
      value: 'directorName',
    },
    {
      name: 'Partnership %',
      value: 'partnership',
    },
    {
      name: 'Designation',
      value: 'designation',
    },
    {
      name: 'PAN Card',
      value: 'panCard',
    },
    {
      name: 'Phone No.',
      value: 'phoneNo',
    },
  ];

  const partnerRows = [
    {
      sNo: '1',
      directorName: 'Mr. Sujal Hiralal Shah',
      partnership: '50%',
      designation: 'Partner',
      panCard: 'AADPS7630P',
      phoneNo: '+91 9820152898',
    },
    {
      sNo: '2',
      directorName: 'Mr. Heman Hiralal Shah',
      partnership: '50%',
      designation: 'Partner',
      panCard: 'AADPS7631N',
      phoneNo: '+91 9819850080',
    },
  ];

  // Key People Table Configuration
  const keyPeopleHeaders = [
    {
      name: 'SNo.',
      value: 'sNo',
      headerClass: 'w-20',
    },
    {
      name: 'Name',
      value: 'name',
    },
    {
      name: 'Designation',
      value: 'designation',
    },
    {
      name: 'PAN Card',
      value: 'panCard',
    },
    {
      name: 'Phone No.',
      value: 'phoneNo',
    },
  ];

  const keyPeopleRows =
    businessType === EnterpriseType.Proprietorship
      ? [
          {
            sNo: '1',
            name: 'Mrs. Nisha Mann',
            designation: 'Authorised Signatory',
            panCard: '-',
            phoneNo: '-',
          },
          {
            sNo: '2',
            name: 'Mr. Surender Kumar',
            designation: 'Accountant',
            panCard: '-',
            phoneNo: '+91 93884 83648',
          },
        ]
      : [
          {
            sNo: '1',
            name: 'Mr. Bhaskar Rao',
            designation: 'Manager - Accounts / Finance',
            panCard: '-',
            phoneNo: '+91 9619558656',
          },
        ];

  return (
    <MsmeContainer>
      <div>
        <h2 className='text-lg font-medium text-brand_color mb-4'>
          {businessType === EnterpriseType.Proprietorship ? 'Owner Details' : 'All Partners'}
        </h2>
        <Table
          headers={businessType === EnterpriseType.Proprietorship ? ownerHeaders : partnerHeaders}
          rows={businessType === EnterpriseType.Proprietorship ? ownerRows : partnerRows}
          className='w-full'
          tableBodyClassname='font-medium text-sm'
          tableHeaderClassname='text-start text-[#667085] font-medium'
          tableDivClassname='border border-secondary rounded-md overflow-x-auto'
        />
      </div>

      <div className='mt-8'>
        <h2 className='text-lg font-medium text-brand_color mb-4'>
          Key People{' '}
          {businessType === EnterpriseType.Proprietorship
            ? 'other than Owner'
            : 'other than Partners'}
        </h2>
        <Table
          headers={keyPeopleHeaders}
          rows={keyPeopleRows}
          className='w-full'
          tableBodyClassname='font-medium text-sm'
          tableHeaderClassname='text-start text-[#667085] font-medium'
          tableDivClassname='border border-secondary rounded-md overflow-x-auto'
        />
      </div>
    </MsmeContainer>
  );
};
