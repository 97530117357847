import React from 'react';
import classNames from 'classnames';
import Icon from '../icon/Icon';

type ButtonVariant = 'base' | 'outline' | 'text' | 'navigation';
type ButtonSize = 'sm' | 'md' | 'lg';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  isLoading?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  text?: React.ReactElement | string;
  loadingText?: string;
  spinnerColor?: string;
}

const getButtonStyles = (
  variant: ButtonVariant = 'base',
  size: ButtonSize = 'md',
  disabled: boolean = false,
) => {
  // Base styles that apply to all variants
  const baseStyles = classNames(
    'inline-flex items-center justify-center rounded font-medium transition-colors',
    'focus:outline-none',
    { 'opacity-50 cursor-not-allowed': disabled },
  );

  // Size styles
  const sizeStyles = {
    sm: 'px-3 py-1.5 text-xs',
    md: 'px-4 py-1.5 text-sm',
    lg: 'px-6 py-3 text-base',
  }[size];

  // Variant styles
  const variantStyles = {
    base: classNames('bg-brand_color text-white', { 'hover:opacity-80': !disabled }),
    outline: classNames('border border-blue-800 text-blue-700', { 'hover:bg-blue-50': !disabled }),
    text: classNames('text-blue-700', { 'hover:underline': !disabled }),
    navigation: classNames(
      'text-md font-semibold text-tertiary px-3 py-2 rounded-md transition-colors',
      { 'hover:bg-gray-300': !disabled },
    ),
  }[variant];

  return classNames(baseStyles, sizeStyles, variantStyles);
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'base',
      size = 'md',
      isLoading = false,
      iconLeft,
      iconRight,
      disabled = false,
      children,
      text,
      loadingText,
      spinnerColor,
      ...props
    },
    ref,
  ) => {
    const buttonStyles = getButtonStyles(variant, size, disabled || isLoading);

    return (
      <button
        ref={ref}
        className={classNames(buttonStyles, className)}
        disabled={isLoading || disabled}
        {...props}
      >
        {isLoading ? (
          <>
            <Icon
              icon='Spinner'
              className={classNames('h-4 w-4 animate-spin mx-auto', spinnerColor)}
            />
            {loadingText ? loadingText : ''}
          </>
        ) : (
          <>
            {iconLeft && <span className='mr-2'>{iconLeft}</span>}
            {text || children}
            {iconRight && <span className='ml-2'>{iconRight}</span>}
          </>
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
