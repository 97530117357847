import * as HeroIconsSolid from '@heroicons/react/24/solid';
import * as HeroIconsOutline from '@heroicons/react/24/outline';
import * as HeroIconsMini from '@heroicons/react/20/solid';
import * as CustomIcons from './custom-icons/';
import IconPath from './iconPath';
import { IconProps } from './icon.types';

const Icon = ({
  id = '',
  icon = 'RocketLaunchIcon',
  color = '',
  size,
  variant = 'solid',
  className = 'cursor-pointer',
  onClick,
  ...rest
}: IconProps) => {
  let IconComponent = IconPath[icon];

  const heroIconsSolid = { ...HeroIconsSolid, ...CustomIcons };
  const heroIconsOutline = { ...HeroIconsOutline, ...CustomIcons };
  const heroIconsMini = { ...HeroIconsMini, ...CustomIcons };

  switch (variant) {
    case 'solid':
      IconComponent = heroIconsSolid[icon];
      break;
    case 'outline':
      IconComponent = heroIconsOutline[icon];
      break;
    case 'mini':
      IconComponent = heroIconsMini[icon];
      break;
    default:
      IconComponent = heroIconsSolid[icon];
      break;
  }

  const getSize = () => {
    if (size) {
      return size;
    }

    switch (variant) {
      case 'mini':
        return '20';
      case 'outline':
      case 'solid':
        return '24';
      default:
        return '24';
    }
  };

  return (
    <IconComponent
      id={id}
      color={color}
      width={getSize()}
      height={getSize()}
      className={className}
      onClick={onClick}
      {...rest}
    />
  );
};

export default Icon;
